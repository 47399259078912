SNI.Application.addBehavior('button-link', (context) => {
  let config = context.getConfig();

  function getUrl(type, target) {
    let base = config[type];
    return (base ||'') + target;
  }
  
  return {
    onclick(event, element, elementType){
      if (elementType === 'button-link') {
        let data = element && element.dataset || {};
        let target = data.target;
        let type = 'base-url';
        if (target) {
          location.href=getUrl(type, target);
        }
      }
    }
  };
});