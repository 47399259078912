SNI.Application.addBehavior('maps', (context) => {

  const defaults = {
    title: '',
    street1: '',
    street2: '',
    street3: '',
    city: '',
    state: '',
    zip: '',
    iOS: false,
    iOSBase: 'https://maps.apple.com/?daddr=',
    defaultBase: 'https://maps.google.com/?q='
  };

  let debug = context.getService('logger').create('behavior.maps');
  let device = context.getService('device-type');
  let config = Object.assign({}, defaults, context.getConfig('maps'));
  let element = context.getElement();

  function constructDefaultMapsURL() {
    let base = config.defaultBase,
        title = (config.title === '' ? '' : `${config.title}`),
        st1 = (config.street1 === '' ? '' : `${config.street1}+`),
        st2 = (config.street2 === '' ? '' : `${config.street2}+`),
        st3 = (config.street3 === '' ? '' : `${config.street3}+`),
        cty = (config.city === '' ? '' : `${config.city}+`),
        st = (config.state === '' ? '' : `${config.state}+`),
        z = (config.zip === '' ? '' : `${config.zip}`),
        country = (config.country === '' ? '' : (config.country === 'US' ? '' : `+${config.country}`));
    title = title.replace(/\&/ig,'%26');
    let defaultUrl = `${base}${title}+near+${st1}${st2}${st3}${cty}${st}${z}${country}`;
    return defaultUrl;
  }

  function constructAppleMapsURL() {
    let base = config.iOSBase,
        st1 = (config.street1 === '' ? '' : `${config.street1},`),
        st2 = (config.street2 === '' ? '' : `${config.street2},`),
        st3 = (config.street3 === '' ? '' : `${config.street3},`),
        cty = (config.city === '' ? '' : `${config.city},`),
        st = (config.state === '' ? '' : `${config.state},`),
        z = (config.zip === '' ? '' : `${config.zip}`);
    let iOSUrl = `${base}${st1}${st2}${st3}${cty}${st}${z}`;
    return iOSUrl;
  }

  function constructMapsURL(isApple) {
    let mapsUrl;

    if (isApple) {
      mapsUrl = constructAppleMapsURL();
    } else {
      mapsUrl = constructDefaultMapsURL();
    }

    return mapsUrl;
  }

  function insertMapsURL(finalUrl, element) {
    let $element = $(element);
    // $element.find('a').attr('href', finalUrl);
    if (device.isMobile) {
      $element.find('a:contains(\'Get Directions\')').hide();
    } else {
      $element.find('a:contains(\'Get Directions\')').attr('href', finalUrl);
    }
    $element.find('a:contains(\'Directions\')').attr('href', finalUrl);
  }

  return {

    init() {
      debug.log('device.isIOS: ', device, device.isIOS);
      config.iOS = device.isIOS;
      insertMapsURL(constructMapsURL(config.iOS), element);
    },

    constructMapsURL,
    constructDefaultMapsURL,
    constructAppleMapsURL,
    insertMapsURL
  };
});
