SNI.Application.addModule('vertical-gallery', function(context) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let debug = context.getService('logger').create('module.vertical-gallery'),
      check = context.getService('check').new(debug),
      modUtil = context.getService('utility'),
      template = context.getService('template'),
      modal = context.getService('modal'),
      metadata = context.getService('metadata'),
      analytics = context.getService('analytics'),
      mediaStream = context.getService('media-stream'),
      deviceType = context.getService('device-type'),
      ajaxService = context.getService('ajax'),
      pinSvc = context.getService('pinterest-button'),
      shopping = context.getService('track-shopping'),
      pinConfig = context.getConfig('social-pinterest'),
      coldLoad = true,
      SniAds,
      mdManager,
      adLibLoaded,
      collapsibleFooterTemplate,
      module,
      element,
      $element,
      config,
      defaults;

  defaults = {
    autoLoadMore: false,
    loadMoreCheck: false,
    loadSelector: '[data-ui-loader]',
    galleryType: 'dynamic',
    galleryAdsContainer: '.photoLibraryViewerPage',
    watchScroll: true,
    modContainer: '',
    photoBoxId: (window.location.href.indexOf('qa1') > -1) ? '00000147-5e54-d873-a7cf-fff6fe0f0000' : '00000147-82ed-dfc8-ad6f-bbede4210000',
    headlineSelector: '.o-PhotoGalleryPromo__a-Headline',
    hotspot: true,
    hotspotUrl: window.location.pathname,
    hotspotUrlName: 'hotspotUrl',
    hotspotElement: '[data-hotspot-el]',
    currHotspotEnabled: '',
    modalScrollOffset: '',
    cardScrollOffset: '',
    filterScrollOffset: '',
    backButton: '<button type="button" class="icon icon-angle-up btn btn-primary btn-back-to-top smooth-hide">TOP</button>',
    topClass: '.btn-back-to-top',
    cardTextSelector: '[data-text]',      // Container for card text
    cardSelector: '[data-card]',
    previousCard: $(),
    currentCard: $('[data-card]').first(),
    previousPartialCard: $(),
    currentPartialCard: $('[data-card]').first(),
    secondPass: false,
    currentModal: '',
    overlayBigboxName: '',
    overlayPhotoIntName: '',
    overlayPhotoIntMobileName: '',
    mpbRefreshHref: '',
    mpbAssetId: '',
    device: '',
    currentSite: '',
    animatedClass: 'animated',
    animatedSelector: '.animated',
    separatorClass: '.o-Article__m-Divider',
    collapsibleFooterButton: '.collapsible-footer',
    desktopTopButton: '.back-to-top-btn',
    desktopTopHolder: '.back-to-top-desktop',
    nextUpClass: '.nextUpAnimated',
    nextUpText: 'nextUpAnimated',
    nextUpWrapper: '.o-Article__m-NextUpWrap',
    weRecWrapper: '.o-Article__m-WeRecommendWrap',
    interstitialCardWrapper: '.interstitial-card',
    scrollDirection: '',
    scrollableInstances: [],
    previousScrollPosition: 0,
    collapsibleFooterContent: {},
    footer: '.footer',
    isPhotoSavedMPB: false,
    adFreq: false,
    triggerOK: true,
    waitingForContent: true,
    interstitialCard: '<div class="card interstitial-card"></div>',
    interstitialWrapper: '<div id="photo_interstitial_wrapper"></div>',
    interstitialWrapperMobile: '<div id="photo_interstitial_wrapper_mobile" class="interstitial"></div>',
    interstitialMobileCloseBtn: '<div id="photo_interstitial_mobile_close"><i class="icon icon-times"></i></div>',
    interstitialScrollOffset: '',
    mediaStream: '',
    galleryTitle: '',
    galleryUrl: '',
    qString: '',
    heightOffset: 90,
    maxImageHeight: 964,
    minImageHeight: 0,
    prevInt: 0,
    intCounter: 1,
    bigCounter: 1,
    isWoz: navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false,
    pinOnStart: false, // Show pinterest button without waiting for a scroll event
    newTemplate: false,
    scrollRate: 150,
    bigBoxAdCounter: 0,
    imagesLLoad: false,
    imagesLLoaded: false
  };

  function setStickyFooterHandlers() {
    if (!config.isMobile) {
      $(config.collapsibleFooterButton).off('click.stickyFeet');
      if ($(config.footer).length > 0) {
        $(config.footer).addClass('sticky-feet-closed');
      }
      $(config.collapsibleFooterButton).on('click.stickyFeet', function(e) {
        e.preventDefault();
        if ($(config.footer).length > 0) {
          if ($(config.footer).hasClass('sticky-feet-open')) {
            $(config.footer).removeClass('sticky-feet-open');
            $(config.footer).addClass('sticky-feet-closed');
          } else {
            $(config.footer).removeClass('sticky-feet-closed');
            $(config.footer).addClass('sticky-feet-open');
          }
        }
      });
    }
  }

  function setQueryToJSON() {
    let pairs = window.location.search.slice(1).split('&'),
        result = {};

    pairs.forEach(function(pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
  }

  function setQString() {
    if (config.galleryType === 'curated' && location.search) {
      config.qString = setQueryToJSON();
      return true;
    } else {
      return false;
    }
  }

  function setDeepLink() {
    let hash = window.location.hash;

    if (setQString() && config.qString.im) {
      config.qString.im = config.qString.im.replace(/[^0-9]/gim, '').trim();
      if (config.qString.im !== '') {
        $(`${config.cardSelector}[data-card-index="${config.qString.im}"]`)[0].scrollIntoView();
      }
      let timestamp = new Date().getTime();
      context.broadcast('stateChanged', {
        gallery: timestamp,
        href: window.location.pathname + window.location.search.replace(/[\?|\&]im\=[^\&]+/gim, '').replace(/^\&/, '?')
      });
    }

    if (hash && hash.indexOf('#item-') > -1) {
      const queryStart = hash.indexOf('?');
      if (queryStart > -1) {
        hash = hash.slice(0, queryStart);
      }
      let slideIndex = hash.replace('#item-', '');
      if (!Number.isNaN(slideIndex)) {
        $(`${config.cardSelector}[data-card-index="${slideIndex}"]`)[0].scrollIntoView();
        let timestamp = new Date().getTime();
        context.broadcast('stateChanged', {
          gallery: timestamp,
          href: window.location.href
        });

      }
    }
  }

  function getSite(content) {
    if (check.exists('mdManager') && mdManager.getParameterString('site') && typeof content !== 'undefined') {
      config.currentSite = mdManager.getParameterString('site').toUpperCase();
      if (typeof content === 'object') {
        content.site = config.currentSite;
      }
    }
  }

  function loadCollapsibleFooter(content) {
    if (typeof content !== 'undefined') {
      collapsibleFooterTemplate = template.collapsibleFooterButton(content);
    }
  }

  function resizeImageHeight() {
    $(window).off('resize.imageHeight');
    $(window).on('resize.imageHeight', function() {
      $(config.cardSelector).each(function() {
        let $photoCard = $(this),
            currentImage = $photoCard.find('.media img');
        setImageHeight($(currentImage));
      });
      if (!config.isMobile && config.galleryType === 'curated') {
        config.topScrollOffset = $(config.cardSelector).eq(3).offset().top;
      }
    });
  }

  function setImageHeight($card) {
    let diffHeight = $(window).outerHeight() - config.heightOffset;

    if (diffHeight < config.maxImageHeight || diffHeight >= config.minImageHeight) {
      $card.css('max-height', diffHeight);
    }
  }

  function addBackToTop() {
    if (config.isMobile) {
      config.topScrollOffset = $(config.cardSelector).eq(3).offset().top + 21;
      $('body').prepend(config.backButton);
      $(config.topClass).off('click.top');
      $(config.topClass).on('click.top', function() {
        $('html,body').animate({
          scrollTop: 0
        }, 1000);
      });
    } else {
      config.topScrollOffset = $(config.cardSelector).eq(3).offset().top + 98;
      $('body').prepend(collapsibleFooterTemplate);
      $(config.desktopTopButton).off('click.top');
      $(config.desktopTopButton).on('click.top', function(e) {
        e.preventDefault();
        $('html,body').animate({
          scrollTop: 0
        }, 1000);
        $(config.footer).removeClass('sticky-feet');
        $('.next-up-wrapper').each(function() {
          $(this).removeClass('fixer');
        });
        $('.we-recommend-wrapper').each(function() {
          $(this).removeClass('lefty');
        });
      });
    }
  }

  function cacheElements(element) {
    $element = $(element);
    debug.log($element);
  }

  function isInterstitialShown() {
    if (getInterstitial().length > 0) {
      $('.filters').hide();
    } else {
      if (window.location.href.indexOf('photos/viewer') === -1) {
        $('.filters').show();
      }
    }
    return (getInterstitial().length > 0);
  }

  function placeDesktopInterstitials() {
    let intmarker,
        prevmarker,
        prevbig;

    setTimeout(function() {
      if (adLibLoaded && $('.currentCard').length > 0) {
        $('.currentCard').after(config.interstitialCard);
        config.prevInt = config.intCounter;
        prevmarker = '.int-' + config.prevInt;
        intmarker = 'int-' + config.intCounter;
        prevbig = config.bigCounter;
        $('.currentCard').next().addClass(intmarker);
        $('.' + intmarker).hide();
        SniAds.appendSlot(intmarker, 'dfp_photo_vertical_interstitial', {}, true);
        SniAds.appendSlot(intmarker, 'dfp_bigbox', {}, true);
        SniAds.refreshSlots(['dfp_photo_vertical_interstitial_' + config.intCounter, 'dfp_bigbox_' + config.bigCounter]);
        setTimeout(function() {
          $('#dfp_bigbox_' + prevbig).hide();
          if ($(prevmarker).children('div:not(#dfp_bigbox_' + prevbig + ')').find('iframe').contents().find('body:not(:empty)').length > 0) {
            $(prevmarker).show();
          } else {
            $(prevmarker).remove();
          }
        }, 500);
        config.intCounter++;
        config.bigCounter++;
        intmarker = 'int-' + config.intCounter;
      }
    }, 100);
  }

  function setMediaStreamData() {

    mediaStream.recordCurrentAndPreviousSS({
      'title': config.galleryTitle,
      'url': config.galleryUrl
    });

    if ((config.mediaStream !== '') && (config.mediaStream.length > 0)) {
      mediaStream.setupNextSS(config.mediaStream);
    }
  }

  function getNextUpImage(ajaxUrl) {
    let image;
    $.ajax({
      url: ajaxUrl,
      type: 'get',
      dataType: 'html',
      async: false,
      success: function(data) {
        image = $(data).filter('meta[property="og:image"]').attr('content').replace(/^(.+?)(\.rend\..+?)$/igm, '$1') || '';
      }
    });
    return image;
  }

  function updateNextUpContent(context, className, content, accepted) {
    let renditionedImage;
    if (!accepted && $(context).find('.media img').length !== 0) {
      renditionedImage = $(context).find('.media img').attr('src').replace(/^(.+?)(\.rend\..+?)$/igm, content.image + '$2');
      $(context)
        .find('.media a')
        .attr('href', content.url)
        .end()
        .find('.media img')
        .attr('src', renditionedImage)
        .end()
        .find('.text a')
        .attr('href', content.url)
        .end()
        .find('.text a h4')
        .text(content.title);
      $(context).removeClass(className);
    }
  }

  function checkNextUpContent(context, data) {
    let currentNextUpTitle = $(context).find('.text a h4').text(),
        isMatching = currentNextUpTitle === data.title ? true : false;
    return isMatching;
  }

  function fetchNextUp() {
    let nextUpContent,
        isMatching;

    nextUpContent = mediaStream.getNextSS();
    if (nextUpContent && nextUpContent.url) {
      nextUpContent.image = getNextUpImage(nextUpContent.url);
    }
    isMatching = checkNextUpContent(config.nextUpClass, nextUpContent);
    updateNextUpContent(config.nextUpClass, config.nextUpText, nextUpContent, isMatching);
  }

  function initLoadMoreTrigger($loadMoreTrigger) {
    let $window = $(window),
        scrollEvent = 'scroll.lazyload',
        resizeEvent = 'resize.lazyload',
        currentAjaxUrl,
        nextGallery,
        nextGalleryUrl;

    if (config.galleryType === 'curated') {
      setMediaStreamData();
      nextGallery = mediaStream.getNextSS();
      nextGalleryUrl = nextGallery ? nextGallery.url : false;
      if (!config.isMobile) {
        fetchNextUp();
      }
    }

    if (!$loadMoreTrigger.length) return;

    $window.on(scrollEvent + ' ' + resizeEvent,
      modUtil.throttle(function() {
        if (modUtil.isInViewport($loadMoreTrigger[0])) {

          if (config.galleryType === 'curated' && nextGalleryUrl) {
            currentAjaxUrl = nextGalleryUrl;
          } else {
            currentAjaxUrl = $loadMoreTrigger.data('ajaxUrl');
          }

          context.broadcast('loadMore.triggered', {
            type: 'loadMore.triggered',
            element: $loadMoreTrigger,
            ajaxUrl: currentAjaxUrl
          });

          if (adLibLoaded && config.galleryType === 'dynamic') {
            SniAds.appendSlot('.bigbox-' + $loadMoreTrigger.attr('data-pagenum'), 'dfp_bigbox');
          }
        }
      }, config.scrollRate));

    return $loadMoreTrigger;
  }

  function loadEmUp(deets) {
    let cqWCMDisabled = context.getGlobal('cqWCMDisabled') || false,
        $currentTrigger;

    if (cqWCMDisabled && config.autoLoadMore) {
      $currentTrigger = deets.element;
      if (typeof $currentTrigger !== 'undefined') {
        if (config.triggerOK) {
          let ajaxURL = deets.ajaxUrl,
              page;

          if (config.galleryType === 'dynamic') {
            page = ajaxURL.split('/p/')[1] || '';
          }

          debug.log('init: ajaxURL: ', ajaxURL, page);

          if (!ajaxURL) {
            debug.error('init: Yikes! No URL for next page, exiting');
            return false;
          }

          $('body').addClass('lazy');
          $('.loadmore-trigger, .loadmore-fallback').remove();

          ajaxService.loadAreaContent({
            target: 'photoScroll',
            suppressLoadAnim: true,
            href: ajaxURL
          });

          config.triggerOK = false;
          return false;
        }
      }
    }
  }

  function addTagsButton($tagsButton, $tagList) {
    let tagModal;

    $tagsButton.on('click', function() {
      config.watchScroll = false;
      config.modalScrollOffset = $(window).scrollTop();
      tagModal = modal.open('tag-overlay', $tagList);
      config.currentModal = tagModal;
    });
  }

  function setItUp() {
    // context.broadcast('socialShare.remove', {});

    $(config.cardSelector).each(function() {
      let $photoCard = $(this),
          $utilsWrapper = $photoCard.find('.photocard-utils-wrapper'),
          $tagsButton = $utilsWrapper.find('.tagsButton'),
          $tagList = $photoCard.find('.tag-list').html(),
          theCount = modUtil.getCounter();

      if (typeof $photoCard.data('photocardSeen') === 'undefined') {
        $photoCard.data('photocardSeen', true);
        if (config.galleryType === 'curated') {
          theCount--;
          modUtil.setCounter(theCount);
        }

        // if ( check.exists('mdManager') && mdManager.getParameterString('site').toLowerCase().match('hgtv') && config.galleryType === 'dynamic') {
        //   addPhotoBox($photoCard, $photoWrapper, $mpbContainer, $saveButton);
        // }

        if ($($tagList).length) {
          addTagsButton($tagsButton, $tagList);
        }

        // HOTSPOTS GO HERE
        // addHotspots($photoCard);
        getHotspotInstance(context, $photoCard.data(config.hotspotUrlName), $photoCard.data('hotspot'), $photoCard.find(config.hotspotElement), config.hotspotElement, config.hotspotUrlName, window.location.pathname, false);

        if (adLibLoaded && config.galleryType === 'curated') {
          if (config.device === 'mobile' && modUtil.getCounter() === 0) {
            $photoCard.after('<p class="ad-text">More photos after this Ad</p><div class="bigbox-ad bigbox-trigger"></div>');
            modUtil.setCounter(config.adFreq);
          }
        }
      }
      if (!config.isMobile && config.galleryType === 'curated') {
        setImageHeight($($(this).find('.media img')));
      }
    });
  }

  function replaceURLFromCurrentSlide($card) {
    if (!check.exists('history.pushState')) {
      return;
    }

    let $photoCardContent = $card,
        assetURL = $photoCardContent.data('assetUrl') || '',
        socialShare = $photoCardContent.find('[social-share]').length !== 0,
        assetURLMatch = assetURL.match(/^https?:\/\/[^\/]*/),
        assetURLOrigin = '';

    if (assetURLMatch) {
      assetURLOrigin = assetURLMatch[0];
    }

    if ((document.location.origin === assetURLOrigin) || assetURL.indexOf('//') === -1) {
      if (!socialShare) {
        if (config.isT3) {
          assetURL = config.routingService.getPreviousURL();
        }
      }

      config.cardScrollOffset = $card.position().top;

      let timestamp = new Date().getTime(),
          slideID = config.cardScrollOffset;

      let sObj = { sID: slideID, gallery: timestamp, href: assetURL };

      if (window.location.href.indexOf(assetURL) === -1) {
        debug.log('replaceURLFromCurrentSlide: assetURL: ', assetURL);
        if (typeof window.history !== 'undefined' && assetURL) {
          window.history.pushState({}, '', assetURL);
        }
        context.broadcast('stateChanged', sObj);
      }
    }

  }

  function refreshAds() {
    handleInterstitial();
  }

  function handleInterstitial() {
    if (!adLibLoaded) { return false; }

    if (!config.currentCard.is(config.previousCard) && config.watchScroll) {
      config.interstitialScrollOffset = config.currentCard.position().top;
    }
  }

  function getInterstitial() {
    return $('#site').closest('.interstitial-show');
  }

  function handleProductTracking(mdString) {
    const mdmData = JSON.parse(mdString);

    if (mdmData.products && mdmData.shopping === 'shopping') {
      shopping.trackProductImpression({
        partner: mdmData.partner,
        products: mdmData.products
      });
    }
  }

  function processCardMetadata($card) {
    let mdmData = JSON.parse($card.attr('data-mdm')) || false,
        behavioralInteraction,
        uniqueIDPrime;

    if (check.exists('mdManager')) {
      mdManager.setParameter('CurrentRoom', $card.find(config.headlineSelector).text());
    }

    if (config.galleryType === 'curated') {
      uniqueIDPrime = metadata.updateConsecutiveViewCount(mdmData);
      behavioralInteraction = metadata.getMediaStreamNumber(mdmData, 0);
    }

    if (mdmData) {
      metadata.updateFromString(mdmData);
      if (config.galleryType === 'curated' && uniqueIDPrime) {
        metadata.updateFromString({ Overlay_UniqueId: uniqueIDPrime });
        if (behavioralInteraction) {
          metadata.updateFromString({ behavioralInteraction: behavioralInteraction });
        }
        metadata.updateFromString({ Type: 'verticalPhotoGallery' });
      }
    } else {
      debug.log('Slide MDM data missing');
    }

    if (mdmData && mdmData.shopping && mdmData.shopping === 'shopping') {
      handleProductTracking($card.attr('data-mdm'));
    }

    analytics.callDynamicPageview();
  }

  function getProductsList($card) {
    return $card && $card.find('[data-target-curatedsrc]');
  }

  function fullMonty(data) {
    let $photoCard = data.card;
    const galleryData = metadata.getGalleryData();
    const galleryCount = typeof galleryData.consecutiveGallery !== 'undefined' ? galleryData.consecutiveGallery : 0;
    replaceURLFromCurrentSlide(data.card);
    refreshAds();
    let cProds = getProductsList($photoCard);
    updateShopElement(context, $photoCard, cProds);

    if (config.currentCard.index() > 0) {
      config.secondPass = true;
    } else if (typeof $photoCard.data('metadataProcessed') === 'undefined' && config.currentCard.index() === 0 && galleryCount === 1) {
      handleProductTracking($photoCard.attr('data-mdm'));
      $photoCard.data('metadataProcessed', true);
    }

    if (config.secondPass && config.watchScroll) {
      if (typeof $photoCard.data('metadataProcessed') === 'undefined') {
        processCardMetadata(data.card);
        ++config.originalGallery;
        metadata.setGalleryData({
          originalGallery: config.originalGallery,
          consecutiveGallery: config.consecutiveGallery
        });
        $photoCard.data('metadataProcessed', true);
      }
    }
  }

  function setupModalHandlers() {
    $('#photo_interstitial_mobile_close').on('click', function() {
      $(window).scrollTop(config.interstitialScrollOffset);
    });
  }

  function onScreen($element) {
    let viewport = {
          top: $(window).scrollTop(),
          bottom: $(window).scrollTop() + $(window).height()
        },
        bounds = {
          top: $element.offset().top,
          bottom: $element.offset().top + $element.outerHeight()
        };

    return (bounds.top > viewport.top && bounds.top < viewport.bottom);
  }

  function checkScrollDirection() {
    let currentScrollPosition = $(window).scrollTop(),
        direction;
    if (currentScrollPosition > config.previousScrollPosition) {
      direction = 'down';
    } else {
      direction = 'up';
    }
    config.previousScrollPosition = currentScrollPosition;
    return direction;
  }

  function setupScrollHandler() {
    let $window = $(window),
        scrollEvent = 'scroll',
        resizeEvent = 'resize';

    $window.on(scrollEvent + ' ' + resizeEvent,
      modUtil.throttle(function(event) {
        $(config.cardSelector).each(function() {
          let $photoCard = $(this),
              textElem = $photoCard.find(config.cardTextSelector),
              visibleElem = config.newTemplate ? $photoCard : textElem,
              visibleCheck = config.newTemplate ? 'partial' : 'full',
              isInFullView = !config.newTemplate,  // check full view separately for new template (true for old template)
              aside,
              bigmarker,
              hasButton = $photoCard.data('hasPinButton');

          if (event.type === resizeEvent) {
            context.broadcast('viewportUpdated', {
              element: $photoCard.find(config.hotspotElement)
            });
          }

          if (modUtil.isInViewport(visibleElem[0], visibleCheck)) {
            $photoCard.addClass('currentCard');
            config.currentCard = $photoCard;
            if (config.newTemplate) {
              isInFullView = modUtil.isInViewport(textElem[0], 'full');
            }
            if (!hasButton) {
              let imageURL = $photoCard.find('[data-og-image]').attr('src') || $photoCard.find('[data-lz-image]').attr('src');
              pinSvc.setPinButton({
                $element: $photoCard, itemConfig: pinConfig, data: {
                  imageURL
                }
              });
              $photoCard.data('hasPinButton', true);
            }

            if (!config.currentCard.is(config.previousCard) && config.watchScroll && isInFullView) {
              fullMonty({
                card: $photoCard
              });
              config.previousCard = config.currentCard;
            }
          } else {
            $photoCard.removeClass('currentCard');
          }

          if (config.galleryType === 'curated' && config.device === 'desktop') {
            aside = $photoCard.find('.card-aside');
            if (adLibLoaded && modUtil.isInViewport(aside[0]) && !$(aside).hasClass('loaded')) {
              bigmarker = 'big-' + config.bigCounter;
              $(aside).addClass(bigmarker);
              SniAds.appendSlot(bigmarker, 'dfp_bigbox', {}, true);
              SniAds.refreshSlot('dfp_bigbox_' + config.bigCounter);
              $(aside).addClass('loaded');
              config.bigCounter++;
              bigmarker = 'big-' + config.bigCounter;
            }
          }
        });
        if (config.galleryType === 'curated') {
          let topContainer = config.isMobile ? config.topClass : config.desktopTopHolder;
          if ($(this).scrollTop() >= config.topScrollOffset) {
            $(topContainer).removeClass('smooth-hide').addClass('smooth-move');
          } else {
            $(topContainer).removeClass('smooth-move').addClass('smooth-hide');
          }
          if ($('.gallery-end').length > 0) {
            $('.gallery-end').each(function() {
              if (modUtil.isInViewport($(this)[0])) {
                $(this).remove();
                if (typeof config !== 'undefined' && typeof config.consecutiveGallery !== 'undefined' && typeof config.originalGallery !== 'undefined') {
                  ++config.consecutiveGallery;
                  metadata.setGalleryData({
                    originalGallery: config.originalGallery,
                    consecutiveGallery: config.consecutiveGallery
                  });
                }
              }
            });
          }
          if ($('.loadmore-fallback').length > 0 && modUtil.isInViewport($('.loadmore-fallback')[0])) {
            context.broadcast('loadMore.triggered', {
              type: 'loadMore.triggered',
              ajaxUrl: mediaStream.getNextSS().url
            });
          }
          if (!config.isMobile) {
            $(config.separatorClass).each(function() {
              if ($(this).find('.next-up-wrapper .media .label-next').length < 1) {
                $(this).find('.next-up-wrapper .media').prepend('<div class="label label-default label-next">Next Up</div>');
              }
              if (onScreen($(this))) {
                if (!$(this).hasClass(config.animatedClass)) {
                  $(this).addClass(config.animatedClass);
                }
              } else {
                $(this).removeClass(config.animatedClass);
              }
            });
            $(config.animatedSelector).each(function() {
              if (!$(this).hasClass('measured')) {
                config.originalTopOffset = $(this).find('.next-up-wrapper').offset().top;
                $(this).addClass('measured');
              }
              if (!$(this).hasClass('locked')) {
                let $gallerySeperator = $(this),
                    $nextUp = $gallerySeperator.find(config.nextUpWrapper),
                    $weRec = $gallerySeperator.find(config.weRecWrapper),
                    nextUpBottomOffset = $nextUp.offset().top + $nextUp.outerHeight(),
                    viewportBottom = $(window).scrollTop() + $(window).height();
                config.scrollDirection = checkScrollDirection();
                if ($nextUp.find('.next-up').length > 0 && $weRec.find('.we-recommend').length > 0) {
                  if (config.scrollDirection === 'down') {
                    if (nextUpBottomOffset < viewportBottom + 5) {
                      $nextUp.addClass('fixer');
                      if (typeof config.newTopOffset !== 'undefined') {
                        $nextUp.css('bottom', ((viewportBottom - nextUpBottomOffset)) + 'px');
                      }
                      $weRec.addClass('lefty');
                    }
                    if (Math.floor($nextUp.find('img').first().offset().top) >= Math.floor($weRec.find('img').first().offset().top)) {
                      $nextUp.removeClass('fixer');
                      $weRec.removeClass('lefty');
                      $nextUp.css('bottom', '0');
                      $weRec.css('padding-left', '0');
                      $nextUp.offset({ top: $weRec.offset().top, left: $nextUp.offset().left });
                      $gallerySeperator.addClass('locked');
                    }
                  } else {
                    if ($nextUp.hasClass('fixer')) {
                      if (($nextUp.offset().top > config.originalTopOffset) && ($nextUp.offset().top - config.originalTopOffset) < 180) {
                        config.newTopOffset = 180 - ($nextUp.offset().top - config.originalTopOffset);
                        $nextUp.css('bottom', (180 - ($nextUp.offset().top - config.originalTopOffset)) + 'px');
                      }
                      $nextUp.removeClass('fixer');
                      $weRec.removeClass('lefty');
                    }
                  }
                } else if ($nextUp.find('.next-up').length > 0 && $weRec.find('.we-recommend').length < 1) {
                  $gallerySeperator.css('text-align', 'left');
                  $nextUp.css({ bottom: '40px', left: '3rem' });
                }
              }
            });
          } else {
            if (adLibLoaded) {
              $('.bigbox-trigger').each(function() {
                config.bigBoxAdCounter++;
                let targetClass = 'bigBoxAdSlot' + config.bigBoxAdCounter;
                $(this).addClass(targetClass);
                let bigboxID = SniAds.appendSlot(targetClass, 'dfp_bigbox', {}, true);
                $(this).removeClass('bigbox-trigger');

                //smooth the ad load experience...
                var $bb = $('#' + bigboxID);
                $bb.after(template.loadingHTML('is-Subtle'));
                $bb.closest('.bigbox-ad').addClass('bigbox-height-enforced');
              });
            }
          }
        }
      }, config.scrollRate));
  }

  function setupFilterHandlers() {
    let filterModal;

    $('.filter-control').on('click', function() {
      if (config.isWoz) {
        config.watchScroll = false;
        config.modalScrollOffset = $(window).scrollTop();
        filterModal = modal.open('filter-overlay', $($('.filters').html()));
        config.currentModal = filterModal;
        $('#filter-overlay').find('.filter-control').html('Hide Filters <i class="icon icon-times"></i>');
        $('#filter-overlay').find('.filter-control').on('click.photoScroll.modal', function() {
          config.currentModal.hide();
        });
      } else {
        if (!$('.filters').hasClass('sticky')) {
          $(window).scrollTop($('.filters').offset().top + 1);
        }
        $('.filter-shade').slideToggle();
      }
    });

    if (window.location.href.indexOf('photos/viewer') === -1) {
      $(window).scroll(function() {
        if (!isInterstitialShown()) {

          if ($(this).scrollTop() > ($('.header').outerHeight() + $('.dataDrivenTitle').outerHeight() + 28)) {
            $('.filters').addClass('sticky');
            $('#site').before($('.filters'));
            $(config.cardSelector).first().css('padding-top', $('.filters').outerHeight() + parseInt($('.filters').css('marginBottom').replace(/[^-\d\.]/g, '')));
          } else {
            $('.filters').removeClass('sticky');
            $('.dataDrivenTitle').after($('.filters'));
            $(config.cardSelector).first().css('padding-top', 0);
          }
        } else {
          debug.log('setupFilterHandlers: isInterstitialShown: true');
        }
      });
    }

  }

  function getHotspotInstance(currentContext, currentUrl, hasHotSpot, currentImg, hotspotElement, defaultUrl, single) {
    let img,
        hotspotUrl,
        elementUrl,
        deferredHotspots;

    if (!hasHotSpot) return;

    img = $(currentImg);
    if (!img.data(hotspotElement)) {
      hotspotUrl = currentUrl;
      deferredHotspots = $.Deferred();
      img.data(hotspotElement, deferredHotspots.promise());
      elementUrl = img.attr('src') || '';
      elementUrl = elementUrl.replace(/\.rend.*/, '');
      let hotspotData = {
        element: img,
        url: hotspotUrl || defaultUrl,
        imageUrl: elementUrl,
        hotspotShowAll: !single,
        initDeferred: deferredHotspots
      };
      currentContext.broadcast('elementUpdated', hotspotData);
    }
  }

  function updateShopElement(currentContext, currentCard, cProds) {
    currentContext.broadcast('shopElementChanged', {
      newElement: currentCard,
      type: config.galleryType,
      cProds
    });
  }

  function pinOnStart() {
    let $card = $('[data-card-index="1"]');
    if ($card.length && $card.length === 1) {
      // [data-lz-image] is used for lazy loaded images, otherwise [data-og-image]
      let imageURL = $card.find('[data-og-image]').attr('src') || $card.find('[data-lz-image]').attr('src');
      pinSvc.setPinButton({
        $element: $card, itemConfig: pinConfig, data: {
          imageURL
        }
      });
      $card.data('hasPinButton', true);
    }
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  module = {
    behaviors: ['shop-this-look', 'hotspot', 'affiliate'],
    messages: ['loadMore.triggered', 'ajax.areaContentLoaded', 'photoScroll.contentSet', 'photoScroll.imgFullViewport', 'modal.shown', 'modal.hidden'],
    init: function() {
      let $element;

      SniAds = context.getGlobal('SniAds');
      mdManager = context.getGlobal('mdManager');
      adLibLoaded = check.exists('SniAds.Gallery');
      element = context.getElement();
      $element = $(element);

      if (element) {

        config = Object.assign({}, defaults, context.getConfig());
        config.isMobile = deviceType.isMobile;

        if (window.location.href.indexOf('photos/viewer') > -1) {
          $('.filters').hide();
        }

        if (adLibLoaded) {
          if (!coldLoad) {
            SniAds.Gallery.reset();
          }
          coldLoad = false;
        }
        if (config.pinOnStart) {
          pinOnStart();
        }

        config.hotspotUrl = window.location.pathname;

        if (config.galleryType === 'curated') {
          let carryoverMeta;
          config.galleryAdsContainer = '.photoGalleryPage';
          setDeepLink();
          modUtil.setCounter(config.adFreq);
          config.originalGallery = 2;
          config.consecutiveGallery = 1;
          metadata.setGalleryData({
            originalGallery: config.originalGallery,
            consecutiveGallery: config.consecutiveGallery
          });
          if (!config.isMobile) {
            getSite(config.collapsibleFooterContent);
            loadCollapsibleFooter(config.currentSite);
            resizeImageHeight();
          }
          config.galleryTitle = $element.data('galleryTitle');
          config.galleryUrl = $element.data('galleryUrl');
          if ($element.data('mediaStream')) {
            config.mediaStream = $element.data('mediaStream');
          }
          config.triggerOK = true;
          config.waitingForContent = true;
          carryoverMeta = metadata.getGalleryData();
          if (typeof carryoverMeta.originalGallery !== 'undefined') {
            config.originalGallery = carryoverMeta.originalGallery;
          }
          if (typeof carryoverMeta.consecutiveGallery !== 'undefined') {
            config.consecutiveGallery = carryoverMeta.consecutiveGallery;
          }
        }

        if (!$('body').hasClass('lazy') && config.galleryType === 'curated') {
          setTimeout(function() {
            addBackToTop();
            setStickyFooterHandlers();
          }, 500);
        }

        let $loadMoreTrigger = config.loadMoreCheck,
            loaderSel = config.loadSelector,
            moduleContainer = $(config.modContainer),
            cqWCMDisabled = context.getGlobal('cqWCMDisabled') || false;

        cacheElements(element);

        if (!$('body').hasClass('lazy')) {
          if (adLibLoaded) {
            SniAds.Event.subscribe('_pageTargetingUpdated', function(e) {
              if (typeof e.int_type !== 'undefined' && config.galleryType === 'curated' && config.device === 'desktop') {
                placeDesktopInterstitials();
              }
            });
          }
        }

        if (cqWCMDisabled) {
          if (config.autoLoadMore) {
            $loadMoreTrigger = initLoadMoreTrigger($('.loadmore-trigger'));
            if (typeof $loadMoreTrigger === 'undefined' && config.isMobile) {
              $(loaderSel).remove();
            }
          }
        }

        if (config.isMobile && !moduleContainer.data('loaderDots') && (typeof $loadMoreTrigger !== 'undefined')) {
          moduleContainer.data('loaderDots', true).after(template.loadingHTML());
        }
      }

      if (!$('body').hasClass('lazy')) {
        setupModalHandlers();
        if (config.galleryType === 'dynamic') {
          setupFilterHandlers();
        }
        setupScrollHandler();
        context.broadcast('photoScroll.contentSet');
      }
    },
    onmessage: function(name, data) {
      switch (name) {
        case 'loadMore.triggered':
          loadEmUp(data);
          break;
        case 'ajax.areaContentLoaded':
          if (config.waitingForContent) {
            $(config.loadSelector).remove();
            if (data.target === 'photoScroll') {
              context.broadcast('photoScroll.contentSet');
            }
            config.waitingForContent = false;
          }
          break;
        case 'photoScroll.contentSet':
          setItUp();
          break;
        case 'modal.shown':
          $('#site').addClass('no-scroll');
          break;
        case 'modal.hidden':
          if (config.galleryType === 'dynamic' && config.isWoz) {
            $('#filter-overlay').find('.filter-control').off('click.photoScroll.modal');
          }
          $('#site').removeClass('no-scroll');
          $(window).scrollTop(config.modalScrollOffset);
          config.watchScroll = true;
          break;
      }
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'product-link':
          const productTitle = $(element).closest('[data-product-title]').data('product-title');
          const mdmData = JSON.parse($(element).closest('[data-card]').data('mdm'));

          shopping.trackProductClick({
            partner: mdmData.partner,
            title: productTitle
          });

          // go nuclear and STOP THE CLICK
          event.stopPropagation();
          break;
        default:
          break;
      }
    }
  };

  return module;
});
