/**
 * simple "view more" functionality: reveal hidden content on click
 *   data-type='control-view-more'  clicked user control 
 *   data-type='content-view-more'  revealed content, assumed initially syled with display: none
 * 
 */


SNI.Application.addModule('view-more', (context) => {

  let $element = $(context.getElement());

  function handleClick(evt, elt, eltType) {
    if (eltType === 'control-view-more')  {
      $element.find('[data-type="content-view-more"]').show();
      $(elt).hide();
      evt.preventDefault();
    }
  }


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {

    // init,

    onclick: handleClick

  };

});
