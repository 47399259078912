import '../services/check';

SNI.Application.addModule('reviews-that-matter', (context) => {
  'use strict';

  const filters = [];
  const tryList = [];

  let $flist;
  let filter;
  let paginationIndex = -1;

  const debug = context.getService('logger').create('module.reviews-that-matter');
  const check = context.getService('check').new(debug);
  const mdManager = check.exists('mdManager') ? context.getGlobal('mdManager') : false;
  const detailId = mdManager ? mdManager.getDetailId() : -1;
  const element = context.getElement();
  const $reviewFilters = $('[data-review-filters]', element);
  const defaults = {
    minReviews: 0,
    maxFilters: 20,
    loading: $('[data-loading]', element),
    global_review_filters: $reviewFilters,
    global_review_template: $('#global_review_template', element).html(),
    global_filter_template: $('#global_filter_template', element).html(),
    global_tryout_template: $('#global_tryout_template', element).html(),
    global_rating_template: $('#global_rating_template', element).html(),
    global_review: null,
    global_filters: null,
    base_url: '//api.reviews.foodnetwork.com/api/external/',
    content_class_initial: '.o-ReviewsThatMatter__m-Content',
    content_class_modified: 'm-ContentLoaded'
  };
  const config = Object.assign({}, defaults, context.getConfig());

  function error(msg) {
    config.global_review_filters.parent().hide();
    debug.error(msg);
  }

  function errorF() {
    error('getFilters service did not complete and resulted in error.');
  }

  function errorR() {
    error('getReview service did not complete and resulted in error.');
  }

  function omniture(params) {
    if (mdManager && typeof params !== 'undefined') {
      $.each(params, function(key, value) {
        mdManager.setParameter(key, value);
      });
    }
  }

  function updateUI() {

    $(config.content_class_initial).addClass(config.content_class_modified);

    const $element = $(element);
    const i = config.global_review.index + 1;
    const t = config.global_review.filter.count;

    if (i === 1) {
      $element.find('.btn.prev').addClass('disabled');
    } else if (i === t) {
      $element.find('.btn.next').addClass('disabled');
    }

    config.flist.removeClass('disabled')
      .eq( paginationIndex )
      .addClass('disabled');
  }

  function updateRatings($starMarkup, rating) {
    $starMarkup
      .slice(0,rating)
      .removeClass('gig-rating-star-empty')
      .addClass('gig-rating-star-full');
  }

  function renderRatings($ratingMarkup) {
    const rating = $ratingMarkup.data('star-rating');

    $ratingMarkup.append(config.global_rating_template);

    updateRatings($ratingMarkup.find('.gig-rating-star'), rating);
  }

  function renderReview() {
    const $content = $('[data-type="content"]', element);

    // clean body
    $content.empty();

    // template populated with a review
    $content.append(config.global_review.markup);

    renderRatings($content.find('[data-star-rating]'));

    updateUI();
  }

  function createReview(object) {
    const index  = object.index,
          filter = object.filter,
          review = object.review,
          title  = object.title,
          user   = object.user;

    const stars = `<div data-star-rating="${review.ratingCount}"></div>`;

    let markup  = config.global_review_template;
    markup  = markup.replace(/{{body}}/g, decodeURIComponent(review.text).replace(/\+/g, ' '));
    markup  = markup.replace(/{{rating}}/g, stars);
    markup  = markup.replace(/{{helpful}}/g, review.helpfulCount);
    markup  = markup.replace(/{{displayName}}/g, user.displayName);
    markup  = markup.replace(/{{location}}/g, user.location);
    markup  = markup.replace(/{{reviewDate}}/g, user.reviewDate);
    markup  = markup.replace(/{{avatar}}/g, user.avatar);
    markup  = markup.replace(/{{userUrl}}/g, user.userUrl);
    markup  = markup.replace(/{{current}}/g, index + 1 );
    markup  = markup.replace(/{{total}}/g, filter.count);
    markup  = markup.replace(/{{name}}/g, filter.name);

    return {
      index,
      filter,
      review,
      title,
      user,
      markup
    };
  }

  function successR(response) {
    if (response.status !== 'success') {
      return error('getReviews failed : ' + response.statusMsg);
    }

    config.global_review = createReview({
      'index'   : filter.query.offset,
      'filter'  : filter,
      'review'  : response.reviews[0],
      'title'   : response.recipeTitle,
      'user'    : response.reviews[0].userInfo
    });

    renderReview();
  }

  function getReviews(filterIndex, reviewIndex) {
    let url, data;  // eslint-disable-line

    //current filter
    filter = filters[ filterIndex ];

    // get review at index of what is passed in
    filter.query.offset = reviewIndex || 0;

    // set up params
    data = $.param(filter.query);

    // set up request url
    url = config.base_url + 'getReviews.jsp?' + data;

    // do ajax
    $.ajax({
      url      : url,
      cache    : true,
      success  : successR,
      error    : errorR
    });
  }

  function goTo(filter, offset, allowOmnitureOverride) {
    // send omniture
    if (typeof offset === 'undefined' || allowOmnitureOverride) {
      const i  = filter + 1;

      /* eslint-disable */
      omniture({
        "Filter" : "Recipe Reviews Filter - " + config.global_filters[i].name,
        "Review" : "Recipe Reviews Index - " + i + " of " + config.global_filters.length
      });
      /* eslint-enable */
    }

    paginationIndex = filter;

    getReviews(filter, offset);
  }

  function next() {
    const i = config.global_review.index + 1,
          len = config.global_review.filter.count;

    /* eslint-disable */
    // send omniture
    omniture({
      "Filter" : "Recipe Reviews Filter - " + config.global_review.filter.name
    });
    /* eslint-enable */

    if (i === len) return false;

    goTo(paginationIndex, i);

    return true;
  }

  function prev() {
    const i = config.global_review.index;

    /* eslint-disable */
    // send omniture
    omniture({
      "Filter" : "Recipe Reviews Filter - " + config.global_review.filter.name
    });
    /* eslint-enable */

    if (i === 0) return false;

    goTo(paginationIndex, i - 1);

    return true;
  }

  function createFilter(object) {
    const name   = object.name,
          id     = object.id,
          count  = object.count,
          index  = object.index,
          title  = object.title,
          url    = object.url,
          last   = object.last || false,
          first  = object.first || false,
          allReviews = object.allReviews || false,

          // building unqiue query obj for this filter
          query = {};

    // making markup available
    let markup = config.global_filter_template;
    markup = markup.replace(/{{name}}/g, name);
    markup = markup.replace(/{{count}}/g, count < 0 ? '' : '(' + count + ')');
    markup = markup.replace(/{{id}}/g, id);
    markup = markup.replace(/{{index}}/g, index);

    query.cqid = detailId;
    query.filterid = id;
    query.sort     = 'timestamp';
    query.markup   = true;

    return {
      name,
      id,
      count,
      index,
      title,
      url,
      last,
      first,
      allReviews,
      markup,
      query
    };
  }

  function getTotalReviews() {
    let count = $('[itemprop="reviewCount"]').text().trim();

    if (count) {
      if (!isNaN(count)) {
        count -= 0;
      }
    } else {
      count = -1;
    }
    return count;
  }

  function renderFilters(renderTo) {
    let i;

    const len = filters.length;

    // Get number of columns from the DOM
    const columns = renderTo.find('ul').length;

    // Divide by the number of columns and round up
    const resultsPerColumn = Math.ceil(len / columns);

    // Split filters into (n) number of columns
    for (i = 0; i < columns; i++) {
      let results = '';
      let begin = i * resultsPerColumn;
      let end = begin + resultsPerColumn;
      let arr = filters.slice(begin,end);

      arr.forEach((result) => {
        results += result.markup;
      });

      // render filter results
      renderTo.find('ul').eq(i).append(results);
    }
  }

  function renderTryLinks() {
    const $content = $('[data-type="content"]', element);

    $content.append(config.global_tryout_template);

    const $tnl = $content.find('ul');

    try {
      //'trynow' param is passed for tracking purposes, so we know when users click on the links presented vs. the main links on top
      $tnl.append($flist.eq(tryList[0]).clone().on('click', function() {
        goTo(tryList[0], 0, 1);
        return false;
      }));

      $tnl.append($flist.eq(tryList[1]).clone().on('click', function() {
        goTo(tryList[1], 0, 1);
        return false;
      }));

      $tnl.append($flist.eq(tryList[2]).clone().on('click', function() {
        goTo(tryList[2], 0, 1);
        return false;
      }));

      $tnl.find('li').removeClass('disabled').addClass('try-now');
    } catch (e) {
      debug.error(e);
    }
  }

  function successF(response) {
    let len,
        items;

    const maxFilters = config.maxFilters;

    if (response.status !== 'success') {
      return error('getFilters failed : ' + response.statusMsg);
    }

    items = response.items;
    len = items.length;

    if (len === 0) {
      return error('There are no filters for this recipe.');
    }

    //remove last entry
    if (len >= maxFilters) {
      len = maxFilters - 1;
      items = items.splice(0, len);
    }

    //save try links
    try {
      items[0].try = 0;
      items[1].try = 1;
      items[2].try = 2;
    } catch (e) {
      debug.error(e);
    }

    //sort alphabetically
    items.sort(function(a, b) {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return  1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    });

    let i, item, f1, f2;
    for (i=0; i < len; i++) {

      item = items[i];
      item.index = i;
      //count += (item.count - 0);
      f1 =  createFilter(item);
      filters.push(f1);

      if (item.try >= 0) {
        tryList[item.try]=i; //save filter position
      }
    }

    const count = getTotalReviews();

    if ( count >= 0 && count < config.minReviews) {
      config.global_review_filters.parent().hide();
      return;
    }

    //add all reviews to filters
    item.index = i;
    item.name  = 'all reviews';
    item.count = count;
    item.allReviews = true;
    item.id = 'allReviews';
    f2 =  createFilter(item);
    filters.push(f2);

    renderFilters(config.global_review_filters);

    // on complete
    // SNI.Food.Ellipsis({ bindTo:'.stack [rel=ellipsis]' });

    $flist = config.global_review_filters.find('li');

    //export filters node list
    config.flist = $flist;

    //export filters array
    config.global_filters = filters;

    //select first filter  -- EAT-3289  20150714 no longer do this:
    //      var lnk = $flist.eq(0).addClass("current disabled").find('a');
    //      reviews.Pagination.goTo(lnk.data("index"));
    renderTryLinks();
  }

  function getFilters(base_url, detailId) {
    const parameterPair = {
      cqid: detailId
    };
    const data = $.param(parameterPair);
    const url = base_url + 'getFilters.jsp?' + data;

    $.ajax({
      url     : url,
      success : successF,
      error   : errorF
    });
  }

  return {
    onclick(event, element, elementType) {



      let lnk;

      switch (elementType) {
        case 'filter':
          event.preventDefault();



          lnk = $(element);

          if (lnk.parent().hasClass('disabled')) {
            return false;
          }



          if (element.id === 'filter_allReviews') {
            //scroll to reviews section
            $('html, body').animate({
              scrollTop: $('#communityReviews').offset().top
            }, 400);
          } else {
            goTo(lnk.data('index'));
          }



          break;

        case 'prevLink':
          event.preventDefault();

          lnk = $(this);

          if (lnk.hasClass('disabled')) {
            return false;
          }

          prev();

          break;

        case 'nextLink':
          event.preventDefault();

          lnk = $(this);

          if (lnk.hasClass('disabled')) {
            return false;
          }

          next();

          break;

        default:
          break;
      }
    },

    init() {
      getFilters(config.base_url, detailId);
    },

    getFilters
  };
});
