SNI.Application.addBehavior('theme-selector', (context) => {
  let debug = context.getService('logger').create('behavior.theme-selector');
  let util = context.getService('utility');
  let device = context.getService('device-type');

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let selectBoxName = 'sni-theme-selector-theme-sel';
  let $fonts = $('link[data-sni-css-fonts]');
  let $v2Css = $('link[data-sni-css-v2]');
  let v2Css = $v2Css.attr('href');
  let initialBrand = $v2Css.attr('data-sni-css-v2');

  if (!$v2Css.length) {
    debug.warn('cannot find v2 css in the page.  aborting.');
    return;
  }

  let themes = {
    'white-label' : 'White Label',
    'hgtv'        : 'HGTV',
    'diy'         : 'DIY Network',
    'gac'         : 'Great American Country',
    'food'        : 'Food Network',
    'cctv'        : 'Cooking Channel',
    'travel'      : 'Travel Channel',
    'investigationdiscovery'  : 'Investigation Discovery',
    'tlc'      : 'TLC',
    'discovery'    : 'Discovery'
  };

  let queryString = util.getUrlParam(window.location.href, 'themeSelect');
  const existingTheme = queryString ? queryString : localStorage.getItem('theme');

  if (themes[initialBrand]) {
    themes[initialBrand] = `${themes[initialBrand]} (page default)`;
  }

  function loadCss($el, uri, callback, fonts) {
    let $link = $el.clone().attr('href', uri).on('load', function() {
      $el.remove();
      if (fonts) {
        $fonts = $link;
      } else {
        $v2Css = $link;
      }
      if (typeof callback === 'function') {
        callback();
      }
    }).on('error', function() {
      $link.remove();
      alert(`Could not load:\n ${window.location.protocol + '//' + window.location.hostname + uri}`);
    }).each(function() {
      if (this.complete) $link.trigger('load');
    }).insertAfter($el);
  }

  function setStyleSheet(brandName) {
    function cssPath(brand) {
      return `/v2/css/${brand}`;
    }
    function getNewCssPath(oldBrand, newBrand) {
      var output = '';
      if (newBrand === 'white-label') {
        output = cssPath(newBrand) + '-' + (device.isMobile ? 'mobile' : 'desktop') + '.css';
      } else {
        var re = RegExp(cssPath(oldBrand) + '(.*)\.css', 'g');
        output = v2Css.replace(re, cssPath(newBrand) + '$1.css');
        output = output.replace('-rest.css', '.css'); //support critical styles properly
      }
      return output;
    }
    let themeStyle = getNewCssPath(initialBrand, brandName);
    if (util.getUrlParam(window.location.href, 'bust')) {
      themeStyle = themeStyle + (themeStyle.indexOf('?') !== -1 ? '&' : '?') + 'bust=' + new Date().getTime();
    }
    if ($fonts.length) {
      let thisFont = $fonts.attr('href').replace(/[^\/]*\-fonts\./g, `${brandName}-fonts.`);
      loadCss($fonts, thisFont, false, true);
    }
    loadCss($v2Css, themeStyle, function() {
      debugActive();
      $('[data-sni-css-critical]').remove();
      lazyLoadHelper($v2Css[0]);
      context.broadcast('theme-changed', { theme: brandName });
    });
  }

  function isHomeBrand(brand) {
    return ((brand === 'hgtv') || (brand === 'diy') || (brand === 'gac'));
  }

  function debugActive() {
    setTimeout(function() {
      let $activeSheets = $('link[rel=stylesheet]:not([href*="about:"])');
      debug.log(`${$activeSheets.length} active stylesheets:`);
      $activeSheets.each(function() {
        debug.log(`      ${$(this).attr('href')}`);
      });
    }, 1);
  }

  //needed because of all the weird shifting around that happens during page load when using the theme selector
  function lazyLoadHelper(linkEl) {
    let fi = setInterval(function() {
      try {
        linkEl.sheet.cssRules;
        debug.log('triggering scroll for lazy load fix');
        $(window).trigger('scroll resize');
        clearInterval(fi);
      } catch (e){ return; }
    }, 10);
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  function init() {
    if (context.getGlobal('cqWCMDisabled') || context.getGlobal('cqWCMshowThemeSelector')) {
      insert([createSel()]);
    } else {
      debug.log('disabled in author mode');
    }
  }

  function createSel() {
    let selector = document.createElement('select');
    $(selector).on('change', function() {
      let selectedValue = this.options[this.options.selectedIndex].value;
      localStorage.setItem('theme', selectedValue);
      setStyleSheet(selectedValue);
    });
    for (let key in themes) {
      let value = themes[key];
      let option = document.createElement('option');
      option.setAttribute('value', key);
      if (key === existingTheme) {
        option.setAttribute('selected', 'selected');
        setTimeout(function() {
          setStyleSheet(key);
        }, 2000);
      }
      option.appendChild(document.createTextNode(value));
      selector.appendChild(option);
    }
    return $(selector)
      .addClass(selectBoxName)
      .css({
        'width': 220,
        marginRight: 12,
        padding: 3,
        font: '15px Arial',
        background: 'white',
        border: '1px solid gray',
        outline: 'none'
      })[0];
  }

  function insert(items) {
    let container = document.createElement('div');
    for (let item of items) {
      if (item) {
        container.appendChild(item);
      }
    }
    $(container)
      .css({
        position: 'fixed',
        top: 0,
        left: 0,
        padding: 8,
        zIndex: 9999999999,
        background: 'white',
        boxShadow: '0 0 7px rgba(0,0,0,.4)',
        //  width: '100%'
      })
      .prependTo('body');

    if (queryString) {
      if (isHomeBrand(existingTheme)) {
        setStyleSheet(existingTheme);
      }
      $(container).hide();
    }

    $(`.${selectBoxName}`)
      .val(localStorage.getItem('theme') || initialBrand);

    if (!queryString) {
      //prevent from visually covering the top part of the page
      $('body').css({marginTop: $(container).outerHeight()});
    }
  }

  return {
    init: init
  };

});
