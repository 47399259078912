/*
 * User Rating Summary Module: get & dipslay rating for an asset with number of reviews, etc.
 */

SNI.Application.addModule('user/rating-summary', (context) => {

  const debug = context.getService('logger').create('module.user/rating-summary'),
        queryState = context.getService('user/summary-query-state').create(context.getConfig('assetType'), context.getConfig('assetId') ),
        network = context.getService('user/network').create(context.getConfig('chitterProxyEndpoint'), queryState),
        dataAdapter = context.getService('user/chitter-adapter').create(queryState),
        templates = context.getService('user/templates');

  let element = context.getElement();
  // let commentsContainer, commentsFooter, commentsSortMenu, commentsSortHeader, compReviewInit;

  // adapt Chitter response to expected format
  function processResponse(resp) {
    return dataAdapter.processResponse(resp, 'summary');
  }

  function insertSummary(html) {
    element.querySelector('.review-summary').innerHTML = html;
  }

  function updateUI(mode) {
    debug.log('updateUI');
    let opts = {mode: context.getConfig('mode'), link: context.getConfig('link'), txtWrapElt: context.getConfig('txtWrapElt')};
    network.getSummary()
      .then(dta => insertSummary(templates.fmtSummary({...opts, ...processResponse(dta)})))
      .catch(e => {
        debug.error('request failed: ' + e);
        insertSummary(templates.fmtSummary({...opts, allCt: 0, avgRating: 0.0}));
      });
  }

  // module entry point
  function init() {
    debug.log('initial load');
    updateUI();
  }


  return {
    init,

  };
});
