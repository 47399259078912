/**
  * Toggle Proxy
  * Author: jpencola
  * This utility allows you to take element A (proxy) and have it toggle a
  * classname on element B (toggle) based on an event.
  * This allows for a basic wrapper around jQuery's toggleClass
  * with capability of a dis-jointed toggle and other
  * features like "hover intent" through the `toggleDelay` setting.
  * -------------------------------------------------------------
  * Broadcasts:
  *   toggledOn
  *   toggledOff
  */

SNI.Application.addBehavior('toggle-proxy', (context) => {

  // let _supports = {}; //private cache of feature support
  let debug = context.getService('logger').create('service.toggle-proxy');
  let $element = $(context.getElement());
  let $proxyEl, $targetEl;

  const defaults = {
    toggleID: null,
    toggleState    : 0,                // the current state of the toggle. 0 = off, 1 = on
    toggleDelay    : 0,                // the delay in ms before the proxy will trigger the toggling
    proxySelector  : false,            // $selector for 1-many nodes that cause the toggling (defaults to module target)
    targetSelector : '.toggleable',    // $selector for 1-many target nodes that will be toggled
    toggleOnEvent  : 'mouseenter.tog', // the namespaced event the proxy element is bound to, which triggers toggling-on of the target
    toggleOffEvent : 'mouseleave.tog', // the namespaced event the proxy element is bound to, which triggers toggling-off of the target
    offClassname   : 'toggledOff',     // the classname the target is given when toggled-off
    onClassname    : 'toggledOn',      // the classname the target is given when toggled-on
    toggleIgnore   : '',               // selector of elements to ignore for toggle
  };
  let config = Object.assign({}, defaults, context.getConfig('toggleProxy'));

  function init() {

    debug.log('toggle-proxy init');

    $proxyEl = (config.proxySelector) ? $element.find(config.proxySelector) : $element;
    $targetEl = $element.find(config.targetSelector);

    config.toggleID = $element.attr('id');

    if (config.toggleDelay) config.toggleDelay = 100; // this has been VERY carefully chosen by design. Don't screw it up.
    if ($targetEl.length === 0) $targetEl = $element; // when targetEl isn't found, module & target are the same

    unbindEvents();
    bindEvents();

  }

  function unbindEvents() {
    $proxyEl.off(config.toggleOnEvent);
    $proxyEl.off(config.toggleOffEvent);
  }

  function bindEvents() {

    // When the on and off events are the same, we only bind once
    let bindSingle = (config.toggleOnEvent === config.toggleOffEvent);
    $proxyEl.each(function(){
      if (bindSingle){
        $(this).on(config.toggleOnEvent, function(e){
          toggle(e.target);
        });
      } else {
        $(this).on(config.toggleOnEvent, function(e){
          toggleOn(e.target);
        });
        $(this).on(config.toggleOffEvent, function(e){
          toggleOff(e.target);
        });
      }
    });
  }

  // Calls to this method will manage toggle between on/off for you
  function toggle(tgt) {
    if ($(tgt).is(config.toggleIgnore))
      return;
    else if (config.toggleState){
      toggleOff();
    } else {
      toggleOn();
    }
  }

  // Explicitly toggles ON
  function toggleOn(tgt) {

    if ($(tgt).is(config.toggleIgnore))
      return;
    else {
      clearTimeout($(this).data('timerId'));
  
      var timerId = setTimeout(function(){
        config.toggleState = 1;
        $proxyEl.removeClass(config.offClassname).addClass(config.onClassname);
        $targetEl.removeClass(config.offClassname).addClass(config.onClassname);
        context.broadcast('toggledOn', config.toggleID);
      }, config.toggleDelay);
  
      $(this).data('timerId', timerId);
    }
  }

  // Explicitly toggles OFF
  function toggleOff(tgt) {

    if ($(tgt).is(config.toggleIgnore)) 
      return;
    else {
      clearTimeout($(this).data('timerId'));
  
      config.toggleState = 0;
      $proxyEl.removeClass(config.onClassname).addClass(config.offClassname);
      $targetEl.removeClass(config.onClassname).addClass(config.offClassname);
      context.broadcast('toggledOff', config.toggleID);
    }
  }

  return {

    init,
    toggle,
    toggleOn,
    toggleOff

  };


});
