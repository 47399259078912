SNI.Application.addModule('photo-grid', (context) => {

  const cqWCMDisabled = context.getGlobal('cqWCMDisabled'),
        localStorage = context.getGlobal('localStorage'),
        SniAds = context.getGlobal('SniAds'),
        check = context.getService('check'),
        template = context.getService('template'),
        analytics = context.getService('analytics'),
        deviceType = context.getService('device-type'),
        utility = context.getService('utility'),
        ajax = context.getService('ajax'),
        adLibLoaded = check.exists('SniAds'),
        debug = context.getService('logger').create('module.photo-grid');

  let defaults,
      $element,
      module,
      config,
      currentSize,
      $switchButtonGroup,
      $switchButtons,
      $grid,
      $gridImages;

  defaults = {
    loadingHTML: template.loadingHTML(),
    hasLocalStorage: check.supports('localStorage'),
    isMobile: deviceType.isMobile,
    autoLoadMore: false,
    loadMoreTrigger: false,
    loaderSel: '#loadingdot',
    moduleContainer: $('.area[data-sni-area=searchGrid]'),
    wrapper: '.slideshow-wrapper',
    triggerSelector: '.loadmore-trigger',
    buttonGroupSelector: '.switch-grid-view',
    gridSelector: '.search-grid',
    ajaxTarget: 'searchGrid',
    gridImageSelector: '.media img',
    localStorageGridSize: 'grid-size',
    smooth: 'smooth',
    gridSize: 'searchGridSize',
    scrollEvent: 'scroll.lazyload',
    resizeEvent: 'resize.lazyload',
    readyToTrigger: true
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setGridConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function cacheElements(element) {
    $switchButtonGroup = element.find(config.buttonGroupSelector);
    $switchButtons = $switchButtonGroup.find('button');
    $grid = element.find(config.gridSelector);
    $gridImages = $grid.find(config.gridImageSelector);
  }

  function getGridSize() {
    if (config.isMobile) {
      return 'small';
    }
    if (config.hasLocalStorage && localStorage.getItem(config.localStorageGridSize)) {
      return localStorage.getItem(config.localStorageGridSize);
    }
    return 'small';
  }

  function setSwitchButtons() {
    $switchButtons.removeClass('active');
    $switchButtonGroup.find('button[data-search-grid-size=' + self.currentSize + ']').addClass('active');
  }

  function setGridClass() {
    $grid.removeClass('search-grid-small search-grid-large');
    $grid.addClass('search-grid-' + currentSize);
  }

  // need to replace this with lazy-load behavior
  // have to figure out how to handle the currentSize stuff
  function loadImages() {
    $gridImages.each(function() {
      let $img = $(this),
          newSource = $img.attr(`data-src-${currentSize}`);
      $img.attr('src', newSource);
    });
  }

  function saveGridSize(size) {
    if (config.hasLocalStorage) {
      localStorage.setItem('config.localStorageGridSize', size);
    }
  }

  function setGridSize(el) {
    let newSize = $(el).data(config.gridSize);
    if (currentSize !== newSize) {
      currentSize = newSize;
      saveGridSize(newSize);
      $gridImages.hide();
      setSwitchButtons();
      setGridClass();
      loadImages();
      $gridImages.fadeIn('10');
    }
  }

  function smoothItOut(element, addition) {
    $(window).load(function() {
      element.addClass(addition);
    });
  }

  function setTrigger() {
    if (deviceType.isMobile) {
      config.loadMoreTrigger = initLoadMoreTrigger($element.find(config.triggerSelector));
    }
  }

  function autoLoad(data) {
    let $currentTrigger,
        ajaxURL,
        page;
    if (cqWCMDisabled && config.autoLoadMore) {
      config.readyToTrigger = false;
      $currentTrigger = data.element;
      if (typeof $currentTrigger !== 'undefined') {
        ajaxURL = data.ajaxUrl;
        page = ajaxURL.split('/p/')[1] || '';
        context.broadcast('photos.searchPageUpdated', {
          currentSearchResultsPage: page
        });

        if (!ajaxURL) {
          debug.error('init: Yikes! No URL for next page, exiting');
          return false;
        }

        ajax.loadAreaContent({
          target: config.ajaxTarget,
          suppressLoadAnim: true,
          href: ajaxURL
        });
      } else {
        if (deviceType.isMobile) {
          $(config.loaderSel).remove();
        }
      }
    }
    if (deviceType.isMobile && !config.moduleContainer.data('loaderDots') && (typeof config.loadMoreTrigger !== 'undefined')) {
      config.moduleContainer.data('loaderDots', true).after(config.loadingHTML);
    }
  }

  function initLoadMoreTrigger($loadMoreTrigger) {
    if (!$loadMoreTrigger.length) {
      return;
    }

    $(window).off(`${config.scrollEvent} ${config.resizeEvent}`);
    $(window).on(`${config.scrollEvent} ${config.resizeEvent}`, utility.throttle(function() {
      if (utility.isInViewport($loadMoreTrigger[0])) {
        $loadMoreTrigger.remove();
        context.broadcast('loadMore.triggered', {
          type: 'loadMore.triggered',
          element: $loadMoreTrigger,
          ajaxUrl: $loadMoreTrigger.data('ajaxUrl')
        });
        if (adLibLoaded) {
          SniAds.appendSlot('.bigbox-' + $loadMoreTrigger.attr('data-pagenum'), 'dfp_bigbox');
        }
      }
    }, 150));
    return $loadMoreTrigger;
  }

  module = {
    messages: ['loadMore.triggered', 'ajax.areaContentLoaded'],
    init() {
      setGridConfig(defaults, context.getConfig());
      $element = $(context.getElement());
      cacheElements($element);
      currentSize = getGridSize();
      setSwitchButtons();
      setGridClass();
      loadImages();
      smoothItOut($grid, config.smooth);
      setTrigger();
    },

    updateObject,
    setGridConfig,

    onmessage: function(name, data) {
      switch (name) {
        case 'loadMore.triggered':
          debug.log('loadMore.triggered: data: ', data);
          if (deviceType.isMobile && config.readyToTrigger) {
            autoLoad(data);
          }
          break;
      }
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'set-size':
          setGridSize(element);
          analytics.callDynamicPageview();
          break;
      }
    }
  };

  return module;

});
