SNI.Application.addBehavior('hotspot', function(context) {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let behavior,
      hotspotService,
      template,
      debug = context.getService('logger').create('behavior.hotspot'),
      check = context.getService('check').new(debug),
      device,
      config,
      location,
      attentionCount,
      defaults,
      hotspotToggleDefaults,
      hotspotToggleConfig;

  if (!check.jqueryPlugin('tooltip')) return {};

  defaults = {
    hotspotWidth: 0,
    hotspotHeight: 0,
    hotspotShowHide: true,
    hotspotShowAll: true,
    hotspotClass: '',
    hotspotHref: '#',
    hotspotTitle: '',
    tooltipPosition: 'top',
    collectionHotspots: false,
    elementHotspots: false,
    scrollOffset: 0,
    element: '',
    url: '',
    imageUrl: '',
    hotspotMessages: {
      dataUpdated: 'hotspotDataUpdated',
      contentUpdated: 'elementUpdated',
      viewportUpdated: 'viewportUpdated',
      hotspotsFound: 'hotspotsFound',
      hotspotsShown: 'hotspotsShown',
      hotspotsHidden: 'hotspotsHidden',
      productModalShown: 'hotspotProductModalShown'
    },
    dom: {},
    initDeferred: false,
    classContainer: 'hotspotContainer',
    classAttention: 'hotspotAttention',
    classHide: 'hotspotHide',
    attentionMax: 2,
    attentionTimeout: 500,
    isMobile: false,
    pageTypes: {
      inline: false,
      photoLibrary: false,
      vertical: false
    }
  };

  hotspotToggleDefaults = {
    position: 'top right'
  };

  function getServices(currentContext) {
    device = currentContext.getService('device-type');
    hotspotService = context.getService('hotspot');
    template = context.getService('template');
  }

  function getGlobalVars(currentContext) {
    location = currentContext.getGlobal('location');
    attentionCount = 0;
  }

  function setDeviceType(currentContext, updateName, deviceService) {
    let currentDevice = deviceService.isMobile;
    currentContext.broadcast(updateName, {
      isMobile: currentDevice
    });
  }

  function setPageTypes(currentContext, updateName) {
    let newTypes = {
      inline: $('.container-site').hasClass('inline-horizontal'),
      photoLibrary: $('body').hasClass('photoLibraryViewerPage'),
      vertical: $('.container-site').hasClass('vertical')
    };
    currentContext.broadcast(updateName, {
      pageTypes: newTypes
    });
  }

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setHotspotConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function setHotspotToggleConfig(oldSettings, newSettings) {
    hotspotToggleConfig = updateObject(oldSettings, newSettings);
  }

  function broadcastConfigChange(currentContext, update, currentData) {
    currentContext.broadcast(update, currentData);
  }

  function getHotspots(currentContext, updateName, showName, foundName, service, currentUrl, currentImageUrl, deferred, el) {
    debug.log('Getting hotspot:', currentUrl, updateName, showName,  foundName, service, currentUrl, currentImageUrl, el);
    if (deferred) {
      service.get({
        url: currentUrl
      }).done(function(data) {
        let currentHotspots;
        currentContext.broadcast(updateName, {
          collectionHotspots: data
        });
        currentHotspots = data[currentImageUrl];
        if (currentHotspots && currentHotspots.length > 0) {
          currentContext.broadcast(updateName, {
            elementHotspots: currentHotspots
          });
          currentContext.broadcast(foundName, {
            elem: el
          });
          currentContext.broadcast(showName);
        } else {
          debug.warn('getHotspots: no hotspots for this image');
          deferred.fail();
        }
      }).fail(function() {
        debug.warn('getHotspots: hotspot service failed to fetch hotspots from: ', currentUrl);
        deferred.fail();
      });
    }
  }

  function createContainer(currentContext, currentDom, containerClass, customClass, type, updateName) {
    let positionType,
        hotspotDom;

    positionType = type;
    hotspotDom = currentDom;

    hotspotDom.container = $('<div>')
      .addClass(containerClass)
      .css({
        position: positionType,
        left: 0,
        top: 0,
        display: 'none'
      });

    if (customClass) {
      hotspotDom.container.addClass(customClass);
    }

    currentContext.broadcast(updateName, {
      dom: hotspotDom
    });
  }

  function appendContainer(currentContainer, currentElement) {
    debug.log('appendContainer: currentContainer: ', currentContainer);
    debug.log('appendContainer: currentElement: ', currentElement);
    currentContainer.insertAfter(currentElement);
  }

  function appendElementHotspots(currentContext, updateName, currentDom, currentElementHotspots, getCurrentHref, getCurrentTitle, getCurrentPosition, positionType) {
    $.each(currentElementHotspots, function() {
      let href,
          title,
          currentPosition,
          updatedDom;

      href = getCurrentHref(this);
      title = getCurrentTitle(this);
      currentPosition = getCurrentPosition(this.x, this.y);
      updatedDom = currentDom;

      $('<a>')
        .attr({
          href: href,
          title: title,
          'data-trigger': 'hover focus',
          'data-placement': currentPosition,
          'data-animation':true,
          'data-delay': 300,
          'data-type': 'launch-product-modal'
        })
        .tooltip()
        .data('hotspot', this)
        .css({
          position: positionType,
          left: this.x + '%',
          top: this.y + '%'
        })
        .appendTo(updatedDom.container);

      currentContext.broadcast(updateName, {
        tooltipPosition: currentPosition,
        hotspotHref: href,
        hotspotTitle: title,
        dom: updatedDom
      });
    });
  }

  function createHotspotToggle(show, templateService, inline, mobile, currentElement) {
    let hotspotToggle;

    if (show) {
      hotspotToggle = $(templateService.hotspotToggle(hotspotToggleConfig));

      if (inline && !mobile) {
        hotspotToggleConfig.newTemplate ? currentElement.parent().append(hotspotToggle) : currentElement.parent().parent().append(hotspotToggle);
      } else {
        currentElement.parent().append(hotspotToggle);
      }
    }
  }

  function updateToggleText(toggleLink) {
    let hotspotToggleLink = toggleLink;
    hotspotToggleLink.html() === 'Hide' ? hotspotToggleLink.html('Show') : hotspotToggleLink.html('Hide');
  }

  function toggle(currentContext, showName, hideName, currentDom, hiddenClass) {
    if (!currentDom.hasClass(hiddenClass)) {
      currentDom.addClass(hiddenClass);
    } else {
      currentDom.removeClass(hiddenClass);
    }
  }

  function show(currentContext, updateName, currentDom, hiddenClass, count, max, currentAttention, attentionClass, time) {
    currentDom.container.removeClass(hiddenClass).show();
    if (count++ < max) {
      currentAttention(currentDom, attentionClass, time);
    }
    // element shown event ? is it needed
    currentContext.broadcast(updateName, {
      dom: currentDom
    });
  }

  function hide(currentContext, updateName, currentDom, hiddenClass)  {
    currentDom.container.addClass(hiddenClass);
    currentContext.broadcast(updateName, {
      dom: currentDom
    });
    // element hide event ? is it needed
  }

  function attention(currentDom, attentionClass, time) {
    currentDom.container.addClass(attentionClass);
    setTimeout(function() {
      currentDom.container.removeClass(attentionClass);
    }, time);
  }

  function doClick(currentContext, name, currentHotspot, checkService, allHotspots, settings) {
    let renderer = currentHotspot.renderer,
        legacy_s = context.getGlobal('s'),
        mdManager = context.getGlobal('mdManager'),
        eID,
        eName,
        room,
        hText;

    if (checkService.exists(['s', 'mdManager']))  {
      eID   = mdManager.getParameterString('EditorialTracking').replace(/[^a-z0-9\s]/gi, '') + ' Photo Gallery Hotspots',
      eName = mdManager.getParameterString('Title').replace(/[^a-z0-9\s]/gi, ''),
      room = mdManager.getParameterString('CurrentRoom').replace(/[^a-z0-9\s]/gi, '') + ' Pictures',
      hText = currentHotspot.title.replace(/[^a-z0-9\s]/gi, '');

      legacy_s.linkTrackVars = 'prop1,prop2,eVar46,eVar47,eVar48,events';
      legacy_s.linkTrackEvents = 'event47';
      legacy_s.events = 'event47';
      legacy_s.eVar46 = eID;
      legacy_s.eVar47 = eID + ':' + hText;
      legacy_s.eVar48 = eID + ':Photos:' + eName + ':' + room + ':' + hText;
      legacy_s.tl(this,'o', eID+':Hotspot Click');
      legacy_s.eVar46 = '';
      legacy_s.eVar47 = '';
      legacy_s.eVar48 = '';
    }

    if (renderer) {
      renderer.display(currentHotspot, allHotspots, settings);
      currentContext.broadcast(name);
    }
  }

  function getTooltipPosition(x, y) {
    let buffer = 10,
        min,
        max,
        pos;

    min = buffer;
    max = 100 - buffer;

    if (x < min) {
      pos = 'right';
    } else if (x > max) {
      pos = 'left';
    } else if (y < min) {
      pos = 'bottom';
    } else {
      pos = 'top';
    }

    return pos;
  }

  /**
   * Adjusts hotspot container dimensions according to current content size.
   */
  function updateContainerSize(targetElement, container) {
    container.css({
      top: 0,
      width: targetElement.width(),
      height: targetElement.height()
    });
  }

  /**
   * Sets initial hotspot container size and updates config state.
   */
  function setSize(currentContext, updateName, width, height, currentElement, currentDom) {
    if (currentDom && currentDom.container) {
      updateContainerSize(currentElement, currentDom.container);
      currentContext.broadcast(updateName, {
        hotspotWidth: currentElement.width(),
        hotspotHeight: currentElement.height(),
        dom: currentDom
      });
    }
  }

  function getHref(hotspot) {
    let renderer = hotspot.renderer,
        href;

    if (renderer) {
      href = renderer.getHref(hotspot);
    } else {
      href = '#';
    }

    return href;
  }

  function getTooltip(hotspot) {
    let renderer = hotspot.renderer,
        title;

    if (renderer) {
      title = renderer.getTitle(hotspot);
    } else {
      title = '';
    }

    return title;
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  behavior = {

    messages: [defaults.hotspotMessages.dataUpdated, defaults.hotspotMessages.contentUpdated, defaults.hotspotMessages.viewportUpdated, defaults.hotspotMessages.hotspotsFound, defaults.hotspotMessages.hotspotsShown, defaults.hotspotMessages.hotspotsHidden, defaults.hotspotMessages.productModalShown],

    init: function() {
      config = Object.assign({}, defaults, context.getConfig('hotspots'));
      hotspotToggleConfig = Object.assign({}, hotspotToggleDefaults, context.getConfig('hotspot-toggle'));
      hotspotToggleConfig.newTemplate = context.getConfig().hasOwnProperty('newTemplate') ? context.getConfig().newTemplate : false;
      getServices(context);
      getGlobalVars(context);
      setDeviceType(context, defaults.hotspotMessages.dataUpdated, device);
      setPageTypes(context, defaults.hotspotMessages.dataUpdated);
      broadcastConfigChange(context, defaults.hotspotMessages.dataUpdated, {
        url: location.pathname
      });
    },

    getServices,
    getGlobalVars,
    setDeviceType,
    setPageTypes,
    updateObject,
    setHotspotConfig,
    setHotspotToggleConfig,
    broadcastConfigChange,
    getHotspots,
    getHref,
    getTooltip,
    getTooltipPosition,
    createContainer,
    appendContainer,
    setSize,
    appendElementHotspots,
    createHotspotToggle,
    updateToggleText,
    toggle,
    show,
    hide,
    attention,
    doClick,

    onmessage: function(name, data) {
      switch (name) {
        case defaults.hotspotMessages.dataUpdated:
          setHotspotConfig(config, data);
          break;
        case defaults.hotspotMessages.contentUpdated:
          broadcastConfigChange(context, defaults.hotspotMessages.dataUpdated, data);
          getHotspots(context, defaults.hotspotMessages.dataUpdated, defaults.hotspotMessages.hotspotsShown, defaults.hotspotMessages.hotspotsFound, hotspotService, config.url, config.imageUrl, config.initDeferred, data.element);
          break;
        // Adjusts hotspot container dimensions when device orientation is changed,
        // so hotspots will remain on the same place.
        case defaults.hotspotMessages.viewportUpdated:
          let container = data.element.next();
          if (container && container.hasClass(config.classContainer)) {
            updateContainerSize(data.element, container);
          }
          break;
        case defaults.hotspotMessages.hotspotsFound:
          createContainer(context, config.dom, config.classContainer, config.hotspotClass, 'absolute', defaults.hotspotMessages.dataUpdated);
          appendContainer(config.dom.container, data.elem);
          setSize(context, defaults.hotspotMessages.dataUpdated, config.hotspotWidth, config.hotspotHeight, data.elem, config.dom);
          appendElementHotspots(context, defaults.hotspotMessages.dataUpdated, config.dom, config.elementHotspots, getHref, getTooltip, getTooltipPosition, 'absolute');
          createHotspotToggle(config.hotspotShowHide, template, config.pageTypes.inline, config.isMobile, data.elem);
          config.initDeferred.resolve();
          break;
        case defaults.hotspotMessages.hotspotsShown:
          show(context, defaults.hotspotMessages.dataUpdated, config.dom, config.classHide, attentionCount, config.attentionMax, attention, config.attentionClass, config.attentionTimeout);
          break;
        case defaults.hotspotMessages.hotspotsHidden:
          hide(context, defaults.hotspotMessages.dataUpdated, config.dom, config.classHide);
          break;
        case defaults.hotspotMessages.productModalShown:
          break;
      }
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'toggle-hotspot':
          event.preventDefault();
          event.stopPropagation();
          let hotCon = config.isMobile ? $(element).siblings('.hotspotContainer') : $(element).parent('.pv-photo-wrapper').find('.hotspotContainer'); //$(element).parent().find('.hotspotContainer')
          hotCon = hotspotToggleConfig.newTemplate ? $(element).parent().find('.hotspotContainer') : hotCon;
          toggle(context, defaults.hotspotMessages.hotspotsShown, defaults.hotspotMessages.hotspotsHidden, hotCon, config.classHide);
          updateToggleText($(element).find('a'));
          break;
        case 'launch-product-modal':
          event.preventDefault();
          event.stopPropagation();
          doClick(context, defaults.hotspotMessages.productModalShown, $(element).data('hotspot'), check, config.collectionHotspots, config);
          break;
        case 'close-product-modal':
          break;
        case 'open-more-info':
          break;
        case 'show-all-products':
          break;
        case 'close-all-products':
          break;
      }
    }

  };

  return behavior;
});
