SNI.Application.addModule('footer', (context) => {
  'use strict';

  let defaults = {
    brazeApiUrl: '',
    brazeApiKey: '',
    brandSlug: ''
  };
  let debug = context.getService('logger').create('module.footer');
  let check = context.getService('check').new(debug);
  let scrollTracker = context.getService('scroll-tracker');
  let config = Object.assign({}, defaults, context.getConfig());
  let skimLinksLoaded = false;
  const mdManager = context.getGlobal('mdManager');


  const disableOptimizelyPopOut = () => {
    const performOptOut = () => {
      window.optimizely.push({
        type: 'optOut',
        isOptOut: true
      });
    };

    if (window.optimizely) {
      performOptOut();
      return;
    }

    // https://docs.developers.optimizely.com/web-experimentation/reference/add-listener#example-call-1
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'initialized'
      },
      handler: performOptOut
    });
  };
  /*  Simple module to handle scroll top link with animation  */

  const messageHandlers = {
    'optanon-loaded': data => {
      // When 'C0004' is in the active groups we consider that as OK to proceed.
      // When it is not present, then that is an opt out.
      let OnetrustActiveGroups = check.exists('OnetrustActiveGroups') ? context.getGlobal('OnetrustActiveGroups') : null;

      if (OnetrustActiveGroups && OnetrustActiveGroups.includes('C0004')) {
        const {disableAffiliation, permittedPage, skimId} = data;

        if (disableAffiliation === 'false' && permittedPage) {
          if (!skimLinksLoaded && skimId) {
            let skimLinksScript = document.createElement('script');
            skimLinksScript.src = `https://s.skimresources.com/js/${skimId}.skimlinks.js`;
            skimLinksScript.type = 'text/javascript';
            skimLinksScript.async = true;
            document.body.appendChild(skimLinksScript);
            skimLinksLoaded = true;
          }
        }
      } else {
        disableOptimizelyPopOut();
      }
    }
  }; //end messageHandlers

  return {
    behaviors: ['lazy-load', 'hover-intent', 'braze'],
    messages: Object.keys(messageHandlers),
    init: function() {
      if (
        mdManager.getPageType() !== 'articlepage' && 
        mdManager.getPageType() !== 'photogallerypage' &&
        typeof scrollTracker !== 'undefined'
      ) {
        let maxScrollDepth = 0;
        scrollTracker.setScrollHandler(event => {
          let scrollPercent = Math.ceil(($(window).scrollTop() / ($(document).height() - $(window).height())) * 100);
          if (scrollPercent >= 100 && maxScrollDepth < 100) {
            debug.log('BRAZE: 100% scroll depth');
            maxScrollDepth = 100;
            SNI.Application.broadcast('braze:scroll-depth', {scrollDepth: maxScrollDepth});
            scrollTracker.removeScrollHandler();
          } else if (scrollPercent >= 80 && maxScrollDepth < 80) {
            debug.log('BRAZE: 80% scroll depth');
            maxScrollDepth = 80;
            SNI.Application.broadcast('braze:scroll-depth', {scrollDepth: maxScrollDepth});
          } else if (scrollPercent >= 60 && maxScrollDepth < 60) {
            debug.log('BRAZE: 60% scroll depth');
            maxScrollDepth = 60;
            SNI.Application.broadcast('braze:scroll-depth', {scrollDepth: maxScrollDepth});
          }
        });
      }
    },
    onmessage(msg, data) {
      messageHandlers[msg](data);
    },
    onclick: function(event, element, elementType) {
      if (elementType==='scroll-top') {
        event.preventDefault();

        // https://stackoverflow.com/questions/4034659/is-it-possible-to-animate-scrolltop-with-jquery
        $('html, body').animate({ scrollTop: 0}, 200);
      }

      if (elementType === 'one-trust') {
        let Optanon = check.exists('Optanon') ? context.getGlobal('Optanon') : null;
        if (Optanon) {
          Optanon.ToggleInfoDisplay();
        } else {
          debug.error('Optanon not present');
        }
      }
    }
  };
});
