import '../services/truncate';
import '../services/logger';

SNI.Application.addBehavior('truncate', function(context) {

  let truncate = context.getService('truncate');

  const $element = $(context.getElement()),
        debug = context.getService('logger').create('behavior.truncate');

  const defaults = {
    attribName: 'truncate',
    suffixString: '...',
    suffixStringLess: ' ...Less',
    moreLessLinks: false,
    dotsWithLinks: '... ',
    truncateLength: 190,
    classMoreLink: 'more',
    classLessLink: 'less',
    classOriginalText: 'originalText',
    classTruncatedText: 'truncatedText',
    markAsDone: false,
    keepVerticalWhitespaces: false
  };

  let config = Object.assign({}, defaults, context.getConfig());

  function bindLinkBehavior($el) {
    let $links = $el.find(`.${config.classMoreLink}, .${config.classLessLink}`);
    $links.on('click' , function() {
      $el.find(`.${config.classTruncatedText}, .${config.classOriginalText}`).toggle();
      return false;
    });
  }

  const messageHandlers = {
    'behavior-truncate:truncate': (settings = {}) => {
      config = Object.assign(config, settings);
      init();
    }
  };

  const init = function() {
    let sel = `[data-${config.attribName}]`;
    debug.log('config', config);

    $element.find(sel).each(function() {
      const $this = $(this);
      let $nested = $this.find(sel);
      let markedAsDone = $this.data('truncateCompleted');
      if ($nested.length) {
        debug.warn('nested truncation not supported', $nested);
        return;
      }
      if (markedAsDone) {
        debug.log('Already truncated');
        return;
      }
      const maxLength = $this.data(config.attribName) ? $this.data(config.attribName) : config.truncateLength;
      const originalString = $this.html().trim();
      const dots = config.moreLessLinks ? `${config.dotsWithLinks ? config.dotsWithLinks : ''}<a href="#" class="${config.classMoreLink}">${config.suffixString}</a>` : config.suffixString;
      const truncateOptions = {
        keepVerticalWhitespaces: config.keepVerticalWhitespaces
      };

      const truncatedString = truncate.html(originalString, maxLength, dots.length ? ' ' + dots : '', truncateOptions);

      if (originalString !== truncatedString) {
        let truncatedHTML = `<span class="${config.classTruncatedText}">${truncatedString}</span>`;
        if (config.moreLessLinks) {
          const lessLink = `<a href="#" class="${config.classLessLink}">${config.suffixStringLess}</a>`;
          $this.html(`<span class="${config.classOriginalText}">${originalString} ${lessLink}</span>${truncatedHTML}`);
          $this.find(`.${config.classOriginalText}`).hide();
          bindLinkBehavior($this);
        } else {
          $this.html(truncatedHTML);
        }
        $this.data('truncateCompleted', true);
      }
    });

  };

  return {
    messages: Object.keys(messageHandlers),

    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },

    config,

    init,

  };

});
