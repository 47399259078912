
SNI.Application.addService('vote-service', (app) => {
  'use strict';
  const debug = app.getService('logger').create('service.vote-service'),
        check = app.getService('check').new(debug),
        mdManager = app.getGlobal('mdManager'),
        metadata = app.getService('metadata');

  // get pathname from url
  let getPathFromUrl = (url) => url.split('?')[0];
  
  // map key:value pairs into the mdManager
  let updateFromString = (str) => metadata.updateFromString(str);
  
  // get param-value from mdManager
  let getParameterString = (param) => {
    if (check.exists('mdManager')) {
      return mdManager.getParameterString(param);
    }
  };

  // set mdManager param
  let setParameter = (key, val) => {
    if (check.exists('mdManager')) {
      mdManager.setParameter(key, val);
    }
  };
  
  let searchJSONArray = (arr, key, val) => {
    var $jsonArray = arr;
    for (var i=0; i<$jsonArray.length; i++) {
      if ($jsonArray[i][key] === val) {
        return $jsonArray[i];
      }
    }
    return false;
  };

  // Save to local storage
  let setLStorItem = (key, val) => {
    if (check.supports('localStorage')) {
      localStorage.setItem(key, val);
    } else {
      debug.log('setLStorItem: no localstorage, exiting');
    }
  };

  // Fetch from local storage
  let getLStorItem = (key) => {
    if (check.supports('localStorage')) {
      return localStorage.getItem(key);
    } else {
      debug.log('getLStorItem: no localstorage, exiting');
    }
  };

  // Remove from local storage
  let removeLStorItem = (item) => {
    if (check.supports('localStorage')) {
      localStorage.removeItem(item);
    } else {
      debug.log('removeLStorItem: no localstorage, exiting');
    }
  };

  // Save vote to local storage
  let setLStor = (data) => {
    if (check.supports('localStorage')) {
      var midnight = new Date().setHours(23,59,59,999),
          state = 'vote.voted',
          voteData = {},
          votes = localStorage.getItem('votes') ? JSON.parse(localStorage.getItem('votes')) : [],
          currUrl = window.location.href;

      currUrl = getPathFromUrl(currUrl);
      localStorage.setItem('voteTime', midnight);
      localStorage.setItem('voteState', state);
      voteData.votedGallery = data.galTitle;
      voteData.votedGalleryId = data.assetId;
      voteData.voteURL = currUrl;
      if (data.campaignId) {
        voteData.votedCampaignId = data.campaignId;
      }
      votes.push(voteData);
      debug.log('setLStor: votes: ', votes);
      localStorage.setItem('votes', JSON.stringify(votes));
    } else {
      debug.log('setLStor: no localstorage, exiting');
    }
  };

  // Get voted gallery - 'title' (or) 'id' from local storage
  let getVotedGallery = (prop) => {
    let votes = getLStorItem('votes'),
        voteObj = votes ? searchJSONArray(JSON.parse(votes), 'voteURL', window.location.href.split('?')[0]) : false,
        val = null;
    
    if (prop === 'title') {
      val = (votes && voteObj && voteObj.votedGallery) ? voteObj.votedGallery : '';
    } else if (prop === 'id') {
      val = (votes && voteObj && voteObj.votedGalleryId) ? voteObj.votedGalleryId : '';
    }
    return val;
  };

  let isDuplicateVote = () => {
    var ls = getLStorItem('votes'),
        votes = ls ? JSON.parse(ls) : null,
        dupVote = ls ? voteService.searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false;

    return dupVote;
  };

  // Save vote
  let recordVote = (data) => {
    // submit vote only if all {data} attributes have values
    // -- this also checks for data.useInlineResults === true
    if (Object.keys(data).every((k) => data[k])) {
      let postURL = `${data.voteAPI}/${data.campaignId}/votes`,
          postData = {
            'asset_id': data.assetId,
            type: data.type // assetType - e.g GALLERY
          };
      postDataToURL(postData, postURL);
      

      // Save votes to user profile for logged-in users
      if (data.isLoggedIn && !$.isEmptyObject(data.user)) {
        //user = data.user;
        //postDataToURL(postData, postURL); /**commented temporarily */
      }
    }
    // Save vote to local storage
    setLStor(data);
  };
 
  // Get total votes per campaign
  let getVotes = (endpoint, campaign_id) => {
    if (!(endpoint && campaign_id)) return;
    let url = `${endpoint}/${campaign_id}/results`;
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      async: true,
      success: function(data) {
        debug.log(`vote-service: getVotes(): vote results - ${data}`);
        app.broadcast('vote-results-ready', data);
      },
      error: function(err) {
        debug.error(`vote-service: failed to fetch votes for campaign_id: ${campaign_id}`);
        app.broadcast('vote-results-failed', err);
      }
    });
  };
  

  // Post votes
  let postDataToURL = (postData, postURL) => {
    debug.log('postDataToURL: posting data to', postURL);
    $.ajax({
      url: postURL,
      type: 'POST',
      data: postData,
      contentType: 'application/json; charset=UTF-8',
      dataType: 'json'
    })
      .done((response) => { 
        debug.log('postDataToURL: response', response); 
        app.broadcast('vote-submitted', response);
      })
      .fail((xhr, err) => { 
        debug.error('postDataToURL: error', err );
        app.broadcast('vote-submit-failed', err);
      });
  };


  let voteService = {
    getVotes,
    recordVote,
    getVotedGallery,
    searchJSONArray,
    setLStorItem, // Save to local storage
    getLStorItem, // Fetch from local storage
    removeLStorItem, // Remove from local storage
    updateFromString,
    getParameterString,
    setParameter,
    isDuplicateVote
  };

  return voteService;

});