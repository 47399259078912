import '../services/url';
/**
 * This module is used on tv schedule pages
 */
SNI.Application.addModule('program-schedule', (context) => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  // let debug = context.getService('logger').create('module.program-schedule');
  let $element = $(context.getElement());
  const defaults = {  };
  let config = Object.assign({}, defaults, context.getConfig());
  const url = context.getService('url');

  const millisPerMinute = 1000 * 60;
  const tenMinutesBeforeInMillis = -10 * millisPerMinute;
  const twentyMinutesAfterInMillis  = 20 * millisPerMinute;


  /**
   * Removes the "Load Earlier Shows" button
   * @returns {void}
   */
  const removeLoadEarlierBtn = () =>
    $element.find('[data-type="btn-load-earlier"]').remove();


  /**
   * Gets the air time of the episode from the DOM
   * @param {JQueryElement} $episodeCard
   */
  const getEpisodeTime = $episodeCard =>
    new Date($episodeCard.attr('data-time'));


  /**
   * @return {Number}
   * @param {Date} present 
   * @param {Date} past 
   */
  const minutesElapsed = (present, past) =>
    Math.floor((present.getTime() - past.getTime()) / millisPerMinute);


  /**
   * @param {Date} currentTime 
   * @param {Date} airTime 
   */
  const isPastEpisode = (currentTime, airTime) =>
    currentTime.getTime() > airTime.getTime();


  /**
   * returns true if any elements have the episode-past class
   */
  const hasPastEpisodes = () =>
    $element.find('.episode-past').length > 0;


  /**
   * Ensures the last hour or the last two thirty minute episodes are revealed.
   * See COOKXP-138.
   * @param {JQueryElement} $episodeCard 
   */
  const revealPreviousEpisodes = ($episodeCard) => {
    const $lastEpisode = $episodeCard.prev();
    
    // No previous episode, skip out
    if (!$lastEpisode.length)
      return;

    $lastEpisode.removeClass('episode-past');

    if ( minutesElapsed(getEpisodeTime($episodeCard), getEpisodeTime($lastEpisode)) <= 30 ) {
      $lastEpisode.prev().removeClass('episode-past');
    }

  };


  // Note that unlike 'whatsOnTv' component, the data-time is correctly correct for all timezones, there is no need to shift.
  // This is because the date and timezone can optionally be specified to the server when the page render.
  // E.g. http://localhost:4502/content/hgtv-com/en/shows/tv-schedule.2013.06.06.EST.html
  function hidePastSchedule(now) {
    let notNow = true;
    const watchUrl = url.watchSiteOnRightRail();

    $element.find('.m-EpisodeCard').each((__, episodeCard) => {
      const $episodeCard = $(episodeCard);
      const airTime = getEpisodeTime($episodeCard);

      const diff = now.getTime() - airTime.getTime();
      const onNow = tenMinutesBeforeInMillis <= diff && diff < twentyMinutesAfterInMillis;

      if (onNow) {
        notNow = false;
        $episodeCard.find('.m-DateBlock__m-TextWrap').prepend('<span class="m-DateBlock__a-Label">On Now</span>');
        if (watchUrl) {
          $episodeCard.find('.m-MediaBlock__m-TextWrap .m-MediaBlock__a-Headline').after(`<a class="m-MediaBlock__a-Button" href="${watchUrl}">Watch Live TV</a>`);
        }
        revealPreviousEpisodes($episodeCard);
      } else if (isPastEpisode(now, airTime)) {
        $episodeCard.addClass('episode-past');
      }
    });

    if (notNow && watchUrl) {
      const $episodeCard = $element
        .find('.m-EpisodeCard:not(.episode-past)')
        .first();

      revealPreviousEpisodes($episodeCard);

      $episodeCard.find('.m-MediaBlock__m-TextWrap')
        .append(`<a class="m-MediaBlock__a-Button" href="${watchUrl}">Watch Live TV</a>`);
    }

    if (!hasPastEpisodes()) {
      removeLoadEarlierBtn();
    }

  }

  function init() {
    const now = new Date();
    const scheduleDate = new Date(parseInt(config.scheduleDate, 10));

    // scheduleDate came from UTC, so we need to adjust;
    scheduleDate.setMinutes(now.getTimezoneOffset());

    let isTodaySchedule = now.getDate() === scheduleDate.getDate() &&
      now.getMonth() === scheduleDate.getMonth() &&
      now.getFullYear() === scheduleDate.getFullYear();

    if (isTodaySchedule) {
      hidePastSchedule(now);
    } else {
      removeLoadEarlierBtn();
    }

  }

  function handleClick(evt, elt, eltType) {
    if (eltType === 'btn-load-earlier')  {
      $element.removeClass('episodes-hide-past');
      $(elt).parent().remove();
      evt.preventDefault();
    }
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {

    init,

    onclick: handleClick

  };

});
