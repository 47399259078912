SNI.Application.addModule('inline-gallery', function(context) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let module,
      $galleryElement,
      galleryContainer,
      detailsContainer,
      slider,
      config = {},
      deviceType,
      debug = context.getService('logger').create('module.inline-gallery'),
      check = context.getService('check').new(debug),
      pinSvc = context.getService('pinterest-button'),
      pinConfig = context.getConfig('social-pinterest'),
      contentInsights = context.getGlobal('Amzn_ci_v4'),
      adLib,
      template,
      metadata,
      analytics,
      mediaStream,
      router,
      shopping,
      coldLoad,
      instance,
      defaults;

  let ll = context.getService('lazy-load');

  if (!check.jqueryPlugin('royalSlider')) return {};

  const SniAds = context.getGlobal('SniAds');
  coldLoad = true;
  defaults = {
    galleryType: 'dynamic',
    galleryViewType: 'inline',
    newTemplate: false,
    isVoteGallery: false,
    preloadedImages: false,
    ajaxUrl: '',
    ajaxStayAhead: 3,
    ajaxFetchNumSlides: 10,
    ajaxPaging: false,
    ajaxImagesPerPage: 18,
    readyToFetchMore: true,
    adLibLoaded: false,
    coldLoad: true,
    pageTitle: '',
    pvHoverTime: '',
    galleryLoadingClass: 'loading',
    galleryAdSetup: {
      container: '.pv-content-wrapper',
      dismiss_elts: '.rsArrowIcn',
      disable_elts: '',
      blockDelay: 10
    },
    galleryContainer: '.slideshow-wrapper',
    detailsContainer: '.details-wrapper',
    nextClass: '[data-type="open-next-gallery"]',
    blurClass: 'm-NextAsset--blur',
    nextLink: '[data-type="open-next-gallery"] a',
    hotspot: true,
    previousPageNumber: true,
    previousArrowSelector: '.rsArrowLeft',
    nextArrowSelector: '.rsArrowRight',
    arrowDisabledSelector: '.rsArrowDisabled',
    rightDataType: 'increment-slide',
    leftDataType: 'decrement-slide',
    backNavPercent: 20,
    galleryStartingPosition: 0,
    overlayBigboxName: '',
    overlayPhotoIntName: '',
    overlayPhotoIntMobileName: '',
    interstitialShownClass: '.interstitial-show',
    interstitialClass: 'interstitial-shown',
    showTraySpan: '<span></span>',
    dynamicGalleryWrapper: '',
    onboardingTimeout: 5500,
    nextGalleryTimeout: 4000,
    hotspotUrl: window.location.pathname,
    hotspotElement: 'img.rsMainSlideImage',
    hotspotUrlName: 'hotspotUrl',
    defaultLoader: '.m-ContentWrap__a-Loader',
    rsOverflowCon         : '.rsOverflow',
    pvSlideWrapCon        : '.pv-slideshow-wrapper',
    pvContentWrapper      : '.pv-content-wrapper',
    pvPhotoWrapCon        : '.pv-photo-wrapper',
    pvDetailsWrapCon      : '.m-AssetDataWrap',
    pvProductsWrapCon     : '.m-ProductListWrap',
    pvSlideSelector       : '.m-Slide',
    pvDetailsSelector     : '.m-AssetData',
    pvProductListSelector : '.product-placeholder',
    pvProductListTarget   : '[data-target-curatedpl]',
    pvProductListSrc      : '[data-target-curatedsrc]',
    pvDetailsArray        : '',
    pvProductListArray    : '',
    pvDropdownItems       : '.pv-pl-nav-dropdown.dropdown-filter .dropdown-menu ul.inner li a',
    adWrapperElements     : {
      galleryContent      : '.o-Article__m-Content',
      galleryAside        : '.o-Article__m-Aside',
      stage               : '.o-Article__m-Body'
    },
    footerElement         : '.o-Article__m-Footer',
    nextUpElements        : {
      nextUpSelector      : '.o-Article__m-Footer .nextUp',
      nextUpBlock         : '.o-Article__m-Footer .nextUp .m-MediaBlock',
      nextUpContainer     : '.nextUp'
    },
    timers                : {},
    launchMeContainer     : '.rsContainer',
    launchMeWrapper       : '.o-PhotoGalleryPromo__m-VisualCta',
    ctaHeadline           : 'View The Gallery',
    launchMeText          : '.m-VisualCtaTextWrap',
    launchMePhotoCount    : '.a-CtaTextSecondary',
    xDown                 : null,
    yDown                 : null,
    swipeDir              : '',
    secondPass            : false,
    lastCloned            : false,
    allowPV               : true,
    mediaStream           : '',
    galleryTitle          : '',
    qString               : '',
    weRecommend           :'.weRecommend',
    weRecommendWidth      : 0,
    nextUp                :'.nextUp',
    sliderMessages        : {
      afterSlideName      : 'gallery.afterSlide',
      beforeAnimName      : 'gallery.beforeAnimation',
      beforeSizeName      : 'gallery.beforeSizeSet',
      afterContentSetName : 'gallery.afterContentSet'
    },
    originalGallery: 0,
    consecutiveGallery: 1,
    dynamicProductViewed: false,
    rsConfig: {
      autoHeight: false,
      autoScaleSlider: false,
      imageAlignCenter: false,
      globalCaption: false,
      fadeinLoadedSlide: false,
      arrowsNav: true,
      arrowsNavAutoHide: false,
      arrowsNavHideOnTouch: false,
      controlNavigation: 'none',
      controlsInside: true,
      imageScalePadding: 0,
      transitionSpeed: 0,
      transitionType: 'move',
      loop: true,
      addActiveClass: true,
      deeplinking: {
        change: false,
        enabled: true,
        prefix: 'photo-'
      },
      fullscreen: {
        enabled: false,
        buttonFS: false,
        nativeFS: true
      },
      keyboardNavEnabled: false,
      numImagesToPreload: 2,
      navigateByClick: false,
      imageScaleMode: 'fit-if-smaller',
      sliderDrag: false,
      sliderTouch: false,
      easeOut: 'linear',
      preloaderHTML: ''
    }
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setGalleryConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function setPreloaderHTML(markup) {
    config.rsConfig.preloaderHTML = markup;
  }

  function getServices(currentContext) {
    deviceType = currentContext.getService('device-type');
    template = currentContext.getService('template');
    metadata = currentContext.getService('metadata');
    analytics = currentContext.getService('analytics');
    mediaStream = currentContext.getService('media-stream');
    router = currentContext.getService('router');
    shopping = context.getService('track-shopping');
  }

  function updateConsecutiveGallery(currentContext, metadataService, data) {
    if (!isNaN(parseInt(data.consecutiveGallery)) && !isNaN(parseInt(data.originalGallery))) {
      debug.log('updateConsecutiveGallery: data: ', data);
      metadataService.setGalleryData(data);
      currentContext.broadcast('gallerydataupdated', data);
    }
  }

  function setOnboardingTimeout(currentContext, isMobile) {
    if (isMobile) {
      currentContext.broadcast('gallerydataupdated', {
        onboardingTimeout: 4000
      });
    }
  }

  function extractData(content, go = config.secondPass) {

    let src = $('img.rsMainSlideImage', content).attr('src');
    return {
      imageURL: src,
      proceed: go,
      element: $(content)
    };
  }

  function setAjaxConfig(currentContext, ajaxUrl, paging, isMobile, searchPage) {
    let url,
        page;

    if (paging) {
      if (isMobile) {
        page = searchPage || 1;
        currentContext.broadcast('gallerydataupdated', {
          ajaxPageWeAreOn: page
        });
      } else {
        url = ajaxUrl || window.location.href;
        page = url.substring(url.lastIndexOf('/p/')).split('/p/')[1] || '';
        page = page.split('?')[0];
        if (typeof(page) === 'undefined' || typeof parseInt(page) !== 'number' || page === '') {
          page = 1;
        }
        currentContext.broadcast('gallerydataupdated', {
          ajaxPageWeAreOn: page
        });
      }
    }
  }

  function updateCurrentPhotoSearchPage(currentContext, data) {
    currentContext.broadcast('gallerydataupdated', {
      currentSearchResultsPage: data.currentSearchResultsPage
    });
  }

  function cacheElements(elementContext, containerSelector, detailsSelector) {
    // self.$sniModule
    $galleryElement = $(elementContext.getElement());
    // self.$galleryContainer
    galleryContainer = $galleryElement.find(containerSelector);
    // self.$detailsContainer
    detailsContainer = $galleryElement.find(detailsSelector);
  }

  function updateDataAttributes(currentContext, target) {
    let title = target.data('galleryTitle'),
        ms = target.data('mediaStream');
    currentContext.broadcast('gallerydataupdated', {
      galleryTitle: title,
      mediaStream: ms
    });
  }

  function checkAdLibrary(currentContext, checkService) {
    currentContext.broadcast('gallerydataupdated', {
      adLibLoaded: checkService.exists(['SniAds.Gallery', 'SniAds.Event'])
    });
  }

  function checkStartSlide(currentContext, element) {


    if (typeof element.data('startSlide') !== 'undefined' || location.hash !== '') {
      var startPos = -1;

      // if deeplink hash exists get the start position from it
      // this overrides possible startposition comming from element data
      if (location.hash.indexOf('item-') > -1) {
        startPos = parseInt(location.hash.replace('#item-','')) - 1;
      } else {
        startPos = parseInt(element.data('startSlide'));
      }

      // set new starting position if valid start position found above
      if (!Number.isNaN(startPos) && startPos > -1) {
        currentContext.broadcast('gallerydataupdated', {
          galleryStartingPosition: startPos
        });
      }
    }
  }

  function checkGalleryType(currentContext, isCurated) {
    if (isCurated) {
      currentContext.broadcast('gallerydataupdated', {
        galleryType: 'curated'
      });
    }
  }

  function setSliderConfig(currentContext, sliderSettings, isMobile, isTablet, pos) {
    let settings;

    settings = {
      startSlideId: pos
    };

    if (isMobile || isTablet) {
      settings = updateObject(settings, {
        transitionSpeed: 0
      });
    }

    if (config.galleryType === 'curated' && !config.newTemplate) {
      settings = updateObject(settings, {
        loop: false,
        autoScaleSlider: false
      });
    }

    currentContext.broadcast('gallerydataupdated', {
      rsConfig: updateRoyalSliderConfig(sliderSettings, settings)
    });
  }

  function setInterstitialConfig(settings) {
    if (settings.adLibLoaded) {
      let currentSettings,
          galleryType;

      galleryType = settings.galleryViewType;

      switch (galleryType) {
        case 'inline':
          currentSettings = {
            desktop: {
              wrapper : 'photo_interstitial_wrapper',
              slot    : 'dfp_photo_interstitial',
              slotInst: 'overlayPhotoIntName',
              markup  : 'interstitialWrapper'
            },
            mobile: {
              wrapper : 'photo_interstitial_wrapper_mobile',
              slot    : 'dfp_smartphone_interstitial',
              slotInst: 'overlayPhotoIntMobileName',
              markup  : 'interstitialWrapperMobile'
            }
          };
          break;
      }

      context.broadcast('gallerydataupdated', {
        interstitialSettings: settings.isMobile ? currentSettings.mobile : currentSettings.desktop
      });
    }
  }

  function populateDetailsWrapper(currentContext, detailsItemSelector, currentDetailsContainer) {
    currentContext.broadcast('gallerydataupdated', {
      pvDetailsArray: currentDetailsContainer.find(detailsItemSelector).toArray()
    });
  }

  function stageContent(currentSlideWrapper, currentGalleryContainer) {
    if (config.preloadedImages) return;
    $(currentSlideWrapper).html('').append(currentGalleryContainer);
  }

  function createAdWrapper(adLib, container, templateService, config) {
    let {galleryType, adWrapperElements, newTemplate} = config;
    let adTemplate = newTemplate ? templateService.adWrapper() : templateService.adWrapperLite();
    let $ad;
    if (adLib) {
      if (galleryType === 'curated') {
        container.parents(adWrapperElements.galleryContent).siblings(adWrapperElements.galleryAside).prepend(adTemplate);
      } else {
        $ad = $(adWrapperElements.stage);
        $ad.append(templateService.adWrapper());
      }
    }
  }

  function setWeRecommendWidth(currentContext, target, type) {
    if (type === 'curated' && $(target).length > 0) {
      currentContext.broadcast('gallerydataupdated', {
        weRecommendWidth: $(target).outerWidth()
      });
    } else {
      debug.warn('setWeRecommendWidth: no target');
    }
  }

  function stageNextUp(type, isMobile, next, templateService) {
    if (type === 'curated' && !isMobile) {
      if (!next.nextUpBlock.length) {
        $(next.nextUpContainer).hide();
      }
    }
  }

  function setPreviousState(currentContext, type) {
    if (type === 'curated') {
      let timestamp,
          previousUrl;

      timestamp = new Date().getTime();
      previousUrl = window.location.pathname + window.location.search;

      currentContext.broadcast('previousUrlChanged', {
        previousURL: previousUrl
      });

      currentContext.broadcast('stateChanged', {
        gallery: timestamp,
        launcher: true,
        href: previousUrl
      });

      // why is stateChanged broadcast twice?

      timestamp = new Date().getTime();

      currentContext.broadcast('stateChanged', {
        gallery: timestamp,
        launcher: true,
        href: previousUrl
      });
    }
  }

  function setMediaStreamData(mediaStreamService, currentMediaStream, currentTitle, currentUrl) {
    mediaStreamService.recordCurrentAndPreviousSS({
      'title': currentTitle,
      'url': currentUrl
    });

    if ((currentMediaStream !== '') && (currentMediaStream.length > 0)) {
      mediaStreamService.setupNextSS(currentMediaStream);
    }
  }

  function createInterstitialContainer(settings, container, templateService) {
    if (settings.adLibLoaded) {

      if (settings.interstitialSettings.slotInst === '' || $('#' + settings.interstitialSettings.slotInst).length === 0) {
        container.before(templateService[settings.interstitialSettings.markup]());
        if (settings.isTablet || settings.isMobile) {
          container.before(templateService.interstitialMobileCloseBtn());
        }

        updateInterstitialSlotInstance(settings);

      } else {
        $( '#' + settings.interstitialSettings.slotInst + ', #' + settings.interstitialSettings.wrapper ).show();
        SniAds.Gallery.next();
      }

      createInterstitialOrientationHandler(settings, container);
    }
  }

  function updateInterstitialSlotInstance(settings) {
    debug.log('updateInterstitialSlotInstance: settings: ', settings);
    if (settings.adLibLoaded) {
      let newInterstitialSettings,
          newSlot;

      newSlot = SniAds.appendSlot(settings.interstitialSettings.wrapper, settings.interstitialSettings.slot, true);

      newInterstitialSettings = updateObject(settings.interstitialSettings, {
        slotInst: newSlot
      });

      context.broadcast('gallerydataupdated', {
        interstitialSettings: newInterstitialSettings
      });

      SniAds.Gallery.setInterstitialSlot(newInterstitialSettings.slotInst);
    }
  }

  function listenForOrientationChange(settings, container) {
    let orientationHandler = function() {
      if (isInterstitialShown(settings, container) && settings.overlayPhotoIntMobileName) {
        SniAds.Gallery.next();
      }
    };
    return orientationHandler;
  }

  function createInterstitialOrientationHandler(settings, container) {
    let handler = listenForOrientationChange(settings, container);
    if (settings.adLibLoaded && settings.isMobile && window.addEventListener && window.removeEventListener) {
      window.addEventListener('orientationchange', handler , false);
      context.broadcast('gallerydataupdated', {
        interstitialOrientationHandler: handler
      });
    }
  }

  function getInterstitial(interstitialShown, container) {
    return container.closest(interstitialShown);
  }

  function isInterstitialShown(settings, container) {
    let intShown = settings.interstitialShownClass;
    if (getInterstitial(intShown, container).length > 0) {
      $(settings.pvDetailsWrapCon).css('visibility', 'visible');
    }
    return getInterstitial(intShown, container).length > 0;
  }

  function prepareGallery(container, loading) {
    container.removeClass(loading);
  }

  function createCustomSliderEvents(currentContext, settings, proto, mods) {
    $.extend(proto, {
      _addAfterSlideEvent: function() {

        let self = this,
            sliderEvents = {
              rsAfterSlideChange: settings.afterSlideName,
              rsBeforeAnimStart: settings.beforeAnimName,
              rsBeforeSizeSet: settings.beforeSizeName
            },
            key;

        for (key in sliderEvents) {
          let name = key,
              val = sliderEvents[name];

          self.ev.on(name, function(event) {
            currentContext.broadcast(val, {
              event: event
            });
          });
        }

        self.ev.on('rsAfterContentSet', function(e, slideObject) {
          if (!self.sliderReady) {
            self.ev.trigger('rsSliderReady');
            currentContext.broadcast(settings.afterContentSetName, {
              event: e,
              slideObject: slideObject
            });
            self.sliderReady = true;
          }
          resizeWell(config.galleryType, config.rsOverflowCon, config.pvSlideWrapCon, config.weRecommend);
        });
      }
    });
    mods.addAfterSlideEvent = proto._addAfterSlideEvent;
  }

  function resizeWell(type, overflowContainer, wrapper, weRecommend) {
    if (type === 'curated') {

      let weRecommendOuterWidthStart   = $(weRecommend).width(),
          $blocksStart                 = $(weRecommend).find('.m-MediaBlock'),
          blockWidthStart              = $(weRecommend).find('.m-MediaBlock').outerWidth(),
          numBlocksStart               = Math.floor(weRecommendOuterWidthStart/blockWidthStart);

      if (!config.newTemplate) {
        $(`${overflowContainer}, ${wrapper}`).outerHeight(($(overflowContainer).outerWidth()-(parseInt($(overflowContainer).css('padding-left'))+parseInt($(overflowContainer).css('padding-right'))))/1.5);
      }
      $blocksStart.each(function() {
        $(this).hide();
      });
      $blocksStart.slice(0,numBlocksStart).each(function() {
        $(this).css('display', 'inline-block');
      });
      // hotspotResize();
    }
  }

  function setResizeHandler(type, overflowContainer, wrapper, resizeIt, center, currentSlider, isMobile, weRec) {
    var waitForFinalEvent = (function() {
      var timers = {};
      return function(callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = 'Don\'t call this twice without a uniqueId';
        }
        if (timers[uniqueId]) {
          clearTimeout (timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();
    $(window).off('resize.viewer');
    $(window).on('resize.viewer', function() {
      waitForFinalEvent(function() {
        resizeIt(type, overflowContainer, wrapper, weRec);
        if (!config.newTemplate) {
          let $slider = currentSlider.currSlide.content;
          center($slider, isMobile, type);
        }
      }, 4, 'resize.viewer');
    }).trigger('resize');
  }

  function centerImage($slide, isMobile, type) {
    let $photoWrapper   = $slide.find('.pv-photo-wrapper'),
        $photo          = $slide.find('.rsImg');

    if (!isMobile && type === 'curated' && !config.newTemplate) {
      if ($photo.height() < $photoWrapper.height()) {
        $photo.css('padding-top', ($photoWrapper.height()-$photo.height())/2);
      }
    }
  }

  function startSlider(currentContext, container, settings) {
    slider = container.royalSlider(settings).data('royalSlider');
    currentContext.broadcast('gallery.sliderInit', {
      slider: slider
    });
  }

  function insertCTA(type, container, templateService, isMobile, lMContainer, lMText, numSlides, newTemplate) {
    if (type === 'curated' && config.galleryStartingPosition === 0) {
      const $cta = newTemplate ? templateService.cta(config.ctaHeadline) : templateService.launchMeContent();

      if (!newTemplate) {
        container.append(templateService.clonedCredit());
      }

      if (!isMobile && config.originalGallery === 0 && config.consecutiveGallery === 1) {
        $(lMContainer).prepend($cta);
        if (!newTemplate) {
          $(lMText).find(config.launchMePhotoCount).remove();
          $(lMText).append(templateService.launchMeFiller(numSlides));
          $(lMContainer).addClass('cta-active');
        }
      }
    }
  }

  function transitionElements(slideContent, type, id, overflow, slideWrapper) {
    let $shareContainer = $(slideContent).find('.pv-photo-info'),
        $shareClone,
        creditText = '';

    if (type === 'curated' && !config.newTemplate) {
      if (id === 0) {
        $(`${overflow}, ${slideWrapper}`).outerHeight(($(overflow).outerWidth()-(parseInt($(overflow).css('padding-left'))+parseInt($(overflow).css('padding-right'))))/1.5);
      }
      $shareContainer.show();
      $shareClone = $shareContainer.clone();
      $shareClone.removeClass('pv-photo-info').addClass('clone-zone');
      $shareContainer.hide();
      $('.cloned-credit').html('').append($shareClone.html());
      $shareClone.remove();
    }

    if (type === 'dynamic') {
      creditText = $shareContainer.find('.pv-photo-credit').text().trim();
      if (creditText==='') $shareContainer.hide();
    }

    if ($('.pv-details-wrapper .list-item').length <= 6) {
      $('.pv-details-wrapper .list-item--show-all').css('display', 'none');
    }
  }

  // Verify that a DOM node exists for details or product
  function curatedNodeChecks(id, detailsType='') {
    let detailsArray = detailsType==='products'?config.pvProductListArray:config.pvDetailsArray;
    let nodeChecks = typeof detailsArray[id] !== 'undefined';
    return nodeChecks;
  }

  function curatedPL(id) {
    let detailsArray = config.pvProductListArray||[];
    let details = detailsArray[id]||'';
    let pl = $(details).find('.o-PhotoGalleryPromo__m-ProductsList');
    return pl;
  }

  function curatedLazyImages(id) {
    let detailsArray = config.pvDetailsArray;
    let $imageSet = $(detailsArray[id]).find('[data-src]');
    return $imageSet;
  }

  function setDetails(id) {
    let detailsWrapContainer = config.pvDetailsWrapCon;
    let detailsArray = config.pvDetailsArray;
    let nodeChecks = curatedNodeChecks(id);
    
    if (nodeChecks) {
      $(detailsWrapContainer).html(detailsArray[id]);
    }
  }

  function setProductsList(id){
    let nodeChecks = curatedNodeChecks(id, 'products');
    let plNodes = curatedPL(id);
    if (nodeChecks ) {
      if (plNodes.length===0) {
        $(config.pvProductListTarget).html('');
      } else {
        const $imageSet = curatedLazyImages(id);
        $(config.pvProductListTarget).html(config.pvProductListArray[id]);
        if ($imageSet.length > 0) {
          ll.loadImages($imageSet);
        }
      }
    }
  }

  function handleProductTracking(mdString) {
    const mdmData = JSON.parse(mdString);

    if (mdmData.products && mdmData.shopping === 'shopping') {
      shopping.trackProductImpression({
        partner: mdmData.partner,
        products: mdmData.products
      });
    }
  }
  
  function interstitialVisibleNotification(response) {
    if (response && response.showInterstitial) {
      let event = document.createEvent('Event');
      event.initEvent('showInterstitial', true, true);              
      document.dispatchEvent(event);      
    }
  }
  
  function refreshAds(currentContext, isMobile, adLibLoaded, container, bigboxName, templateService, structure, type) {
    if (!isMobile && adLibLoaded) {
      let adWrapper,
          newSlot,
          $overBB;

      if (type === 'curated') {
        adWrapper = container.parents(structure.galleryContent).siblings(structure.galleryAside).find('.bigbox-ad');
      } else {
        adWrapper = container.closest('[data-module]').find('.bigbox-ad');
      }

      if (bigboxName === '') {
        newSlot = bigboxName;
        adWrapper.append(templateService.adText());
        if (type === 'curated') {
          adWrapper.prepend(templateService.overlayBigbox());
        } else {
          adWrapper.append(templateService.overlayBigbox());
        }
        newSlot = SniAds.appendSlot('overlay_bigbox', 'dfp_bigbox');
        currentContext.broadcast('gallerydataupdated', {
          overlayBigboxName: newSlot
        });
        SniAds.Gallery.setSyncSlot(newSlot);
      } else {
        $overBB = $('#overlay_bigbox').show();
        if (!adWrapper.has('#overlay_bigbox')){
          $overBB.appendTo(adWrapper);
        } else {
          let sniAdsGalleryResponse = SniAds.Gallery.next();
          interstitialVisibleNotification(sniAdsGalleryResponse);          
        }
      }
    }
  }

  function handleInterstitial(currentContext, settings, currentSlider, container, checkService, fetch, interstitialShown, mediaStreamService, templateService) {
    if (checkService.exists(['SniAds.Gallery', 'SniAds.Event'])) {
      let isLastSlide = (container.find(`${config.nextArrowSelector}.rsArrowDisabled`).length > 0),
          isFirstSlide = (container.find(`${config.previousArrowSelector}.rsArrowDisabled`).length > 0);

      if (!settings.isMobile && interstitialShown(settings, container) && (isLastSlide || isFirstSlide || currentSlider.currSlideId === (currentSlider.numSlides-1) || currentSlider.currSlideId === 0)) {
        let arrows = container.find('.rsArrow');
        arrows.addClass('force-show').on('click.slidesend', function(e) {
          arrows.removeClass('force-show').off('click.slidesend');
          closeInterstitial(settings, fetch, container, checkService);
          if (isLastSlide || currentSlider.currSlideId === (currentSlider.numSlides-1)) {
            addNextGalleryLink(currentContext, settings, container, currentSlider, interstitialShown, mediaStreamService, templateService);
          }
          return false;
        });
      }
    }
  }

  function closeInterstitial(settings, fetchInterstitial, container, checkService) {
    if (settings.adLibLoaded && $('#ad-gallery-control').attr('rel') !== 'gallery-blocked') {
      let interstitial = fetchInterstitial(settings.interstitialShownClass, container),
          interSlot;

      $(settings.pvDetailsWrapCon).css('visibility', 'visible');
      $('.photo-viewer').removeClass(settings.interstitialClass);
      if (interstitial.hasClass('interstitial-show')) {
        interstitial.removeClass('interstitial-show');
        debug.log('Hide Interstitial');
        if (checkService.exists('googletag')) {
          interSlot = SniAds.getDefinedSlots()[SniAds.Gallery.getConfigData().interstitialSlot];
          context.getGlobal('googletag').pubads().clear([interSlot]);
        }
        return true;
      }
      return false;
    }
  }

  function addNextGalleryLink(currentContext, settings, container, currentSlider, interstitialShown, mediaStreamService, templateService) {
    if (!interstitialShown(settings, container)) {
      let $galleryOverflow = container.find('.rsOverflow'),
          nextGallery,
          nextGalleryTitle,
          nextGalleryUrl,
          nextGalleryLinkText = 'Next Up';

      if (settings.galleryType === 'curated') {
        nextGallery = mediaStreamService.getNextSS();
        nextGalleryTitle = nextGallery.title;
        nextGalleryUrl = nextGallery.url;
        if (nextGalleryTitle && window.location.href.lastIndexOf(nextGallery.url) <= 1) {
          currentSlider.slider.removeClass(settings.blurClass);

          // Blue next gallery button in the end of slideshow.
          if (config.newTemplate) {
            $galleryOverflow.find('.rsArrowRight').addClass('rsArrowPrimary');
          }

          $galleryOverflow.append(templateService.nextGalleryModal({
            link: nextGalleryUrl,
            subHeadline: nextGalleryLinkText,
            headline: nextGalleryTitle
          }));
        } else if (!nextGalleryTitle) {
          currentContext.broadcast('gallerydataupdated', {
            suppressNextGalleryEvent: true
          });
        }
      }
    }
  }

  function removeNextGalleryLink(container, classSelector) {
    container.find(classSelector).remove();

    // Remove blue color for next gallery arrow.
    if (config.newTemplate) {
      container.find('.rsArrowRight').removeClass('rsArrowPrimary');
    }
  }

  function updateRoyalSliderConfig(sliderSettings, data) {
    let updatedRSConfig,
        newSettings;

    newSettings = data;
    updatedRSConfig = updateObject(sliderSettings, newSettings);

    return updatedRSConfig;
  }

  function goNextClick(container, threshold, clickX) {
    let sliderWidth = container.width(),
        clickPercentage = ((clickX - container.offset().left) / sliderWidth) * 100;

    if (clickPercentage > threshold) {
      return true;
    } else {
      return false;
    }
  }

  function delegateArrowEvents(container, leftSelector, leftType, rightSelector, rightType) {
    let left = container.find(leftSelector),
        right = container.find(rightSelector);

    if (left.length && right.length) {
      left.attr('data-type', leftType);
      right.attr('data-type', rightType);
    }
  }

  function removeLaunchMe(cta) {
    if ($(cta).length > 0) {
      $(cta).parent().removeClass('cta-active');
      $(cta).remove();
      ll.forceScroll();
    }
  }

  function traverseSlideshow(container, settings, pageX, currentSlider, checkService, fetch, go) {
    let ad = closeInterstitial(settings, fetch, container, checkService);

    if (!settings.isMobile && !ad) {
      if (go(container, settings.backNavPercent, pageX)) {
        currentSlider.next();
      } else {
        currentSlider.prev();
      }
    }
  }

  function createCustomPrevNextHandling(currentSlider) {
    currentSlider.lastSlideId = -1;
    currentSlider._arrowLeft.off();
    currentSlider._arrowRight.off();

    $(document).on('keydown.photoGalleryModalKeyFilter', function(e) {
      switch (e.which) {
        case 37:
          if (closeInterstitial(config, getInterstitial, galleryContainer, check)) {
            return false;
          }
          currentSlider.prev();
          e.preventDefault();
          break;
        case 39:
          if ($(config.launchMeWrapper).length > 0) {
            removeLaunchMe(config.launchMeWrapper);
            setProductsList(slider.currSlideId);
            let $slider = slider.currSlide.content;
            handleProductTracking($slider.attr('data-mdm'));
            e.preventDefault();
          } else {
            if (closeInterstitial(config, getInterstitial, galleryContainer, check)) {
              return false;
            }
            currentSlider.next();
            e.preventDefault();
          }
          break;
      }
    });
  }

  function updateShopElement(currentContext, currentSlider) {
    // Get curated products count to determine if STL should show
    let cProds = curatedPL(currentSlider.currSlideId);
    currentContext.broadcast('shopElementChanged', {
      type: config.galleryType,
      newElement: currentSlider.currSlide.content,
      cProds
    });
  }

  function areWeThereYet(currentContext, currentSlider) {
    currentSlider.ev.one('rsTryingToAdvancePastLastSlide', function(event) {
      currentContext.broadcast('gallery.lastSlideReached', {
        currentEvent: event
      });
    });
  }

  function updateURL(currentContext, type, checkService, routingService, currentSlider, wrap) {
    if (checkService.exists('history.pushState') && type !== 'curated') {
      let slideContent = currentSlider.currSlide.content,
          assetURL = slideContent.data('assetUrl') || '',
          $slideContainer  = $(slideContent).find(wrap),
          socialShare = $slideContainer.data('social-share') === true,
          assetURLMatch = assetURL.match(/^https?:\/\/[^\/]*/),
          assetURLOrigin = '',
          timestamp,
          slideID,
          sObj;

      if (assetURLMatch) {
        assetURLOrigin = assetURLMatch[0];
      }

      if ((document.location.origin === assetURLOrigin) || assetURL.indexOf('//') === -1 ) {

        if (!socialShare) {
          assetURL = routingService.getPreviousURL();
        }

        timestamp = new Date().getTime(),
        slideID = currentSlider.currSlideId;
        sObj = { sID: slideID, gallery: timestamp, href: assetURL };

        if (window.location.href.indexOf(assetURL) === -1) {
          currentContext.broadcast('stateChanged', sObj);
        }
      }

    }
  }

  function fetchNextGallery(currentContext, currentSlider, settings, container) {
    let normalizedSlideNumber = currentSlider.currSlideId+1,
        totalSlides = currentSlider.numSlides,
        loop = settings.rsConfig.loop || false,
        lastCloned = settings.lastCloned || false,
        type = settings.galleryType || false,
        $slider,
        $clonedSlide;

    if (normalizedSlideNumber === totalSlides && !loop && type === 'curated') {
      $(`${config.nextArrowSelector}.rsArrowDisabled`).removeClass('rsArrowDisabled');
      if (lastCloned) {
        currentContext.broadcast('gallery.nextGalleryFetched');
        currentContext.broadcast('gallerydataupdated', {
          allowPV: false
        });
      } else {
        $slider = currentSlider.currSlide.content;
        $clonedSlide = $slider.clone();
        currentSlider.appendSlide($clonedSlide);
        currentContext.broadcast('gallerydataupdated', {
          lastCloned: true,
          allowPV: true
        });
      }
    } else {
      currentContext.broadcast('gallery.noNextGallery');
      currentContext.broadcast('gallerydataupdated', {
        allowPV: true
      });
    }
  }

  function getHotspotInstance(currentContext, currentUrl, hasHotSpot, currentImg, hotspotElement, defaultUrl, single) {
    let img,
        hotspotUrl,
        elementUrl,
        deferredHotspots;

    img = $(currentImg);
    
    if (!hasHotSpot) return;

    if (!img.data(hotspotElement)) {
      debug.log('Get hotspot for slide');
      hotspotUrl = currentUrl;
      deferredHotspots = $.Deferred();
      img.data(hotspotElement, deferredHotspots.promise());
      elementUrl = img.attr('src') || '';
      elementUrl = elementUrl.replace(/\.rend.*/, '');
      currentContext.broadcast('elementUpdated',{
        element: img,
        url: hotspotUrl || defaultUrl,
        imageUrl: elementUrl,
        hotspotShowAll: !single,
        initDeferred: deferredHotspots
      });
    }
  }

  function updateLastItem(currentSlider) {
    currentSlider.lastSlideId = currentSlider.currSlideId;
  }

  function setSecondPass(currentContext, currentSlider) {
    if (currentSlider > 0) {
      currentContext.broadcast('gallerydataupdated', {
        secondPass: true
      });
    }
  }

  function setupFilterListeners() {
    let pvHoverTimer;

    if (config.isTablet) {
      $(config.pvDropdownItems).addClass('tablet');
    }

    $('.list-item--show-all a').on('click', function(){
      let $this = $(this);
      $this.parent().parent().addClass('tags--all-visible');
    });

    $('.list-inline-expandable').each(function(){
      let $this = $(this),
          tags_count =  $this.children('.list-item').length;
      if (tags_count <= 5) {
        $('.list-item--show-all').remove();
      }
    });

    $('.pv-pl-nav-dropdown.dropdown-filter')
      .on('mouseenter', function() {
        let self = this;
        pvHoverTimer = setTimeout(function() {
          $(self).closest('.pv-pl-nav-dropdown').addClass('open');
        }, 175);
      })
      .on('mouseleave', function() {
        clearTimeout(pvHoverTimer);
        $(this).removeClass('open');
      });
  }

  function setupHistoryListeners() {
    window.onpopstate = function(event) {
      if (check.exists('history.pushState')) {
        slider.goTo(event.state.sID);
        setDetails(slider.currSlideId);
        setProductsList(slider.currSlideId);
        transitionElements(slider.currSlide.content, config.galleryType, slider.currSlideId, config.rsOverflowCon, config.pvSlideWrapCon);
        refreshAds(context, config.isMobile, check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, config.overlayBigboxName, template, config.adWrapperElements, config.galleryType);
        handleInterstitial(context, config, slider, galleryContainer, check, getInterstitial, isInterstitialShown, mediaStream, template);
      }
    };
  }

  function incrementOriginalGallery(currentContext, metadataService, settings) {
    if (settings.allowPV) {
      settings.originalGallery++;
      updateConsecutiveGallery(currentContext, metadataService, {
        originalGallery: settings.originalGallery,
        consecutiveGallery: settings.consecutiveGallery
      });
    }
  }

  function processMetadata(target, options) {
    debug.log('processMetadata: instance: ', $(context.getElement()).attr('id'));
    let mdmData = (typeof target.data('mdm') === 'object' ? target.data('mdm'): JSON.parse(target.data('mdm'))) || false,
        title = target.data('assetTitle') || false,
        currentURL = mdmData.URL || false,
        isPhotoLibrary = /photolibrary/.test(mdmData.UniqueId) || false,
        checkService = options.checkService || false,
        metadataService = options.metadataService || false,
        analyticsService = options.analyticsService || false,
        incrementValue = options.incrementValue || false,
        updateValues = options.updateValues || false,
        currentContext = options.context | false,
        previousPageNumber = options.previousPageNumber || false,
        mdmUniqueParameter = options.mdmUniqueParameter || 'Overlay_UniqueId',
        type = options.galleryType || false,
        transfer,
        currentMDM,
        currentPageNumber,
        behavioralInteraction,
        uniqueIDPrime;

    if (checkService) {
      let mdManager = context.getGlobal('mdManager');
      currentMDM = checkService.exists('mdManager') ? mdManager : false;
      if (currentMDM) {
        if (title) {
          mdManager.setParameter('CurrentRoom', title);
        }
      }
    }

    if (metadataService) {
      behavioralInteraction = metadataService.getMediaStreamNumber(mdmData, incrementValue) || false;
      uniqueIDPrime = metadataService.updateConsecutiveViewCount(mdmData) || false;
      if (mdmData) {
        if (isPhotoLibrary && currentURL) {
          delete mdmData.URL;
        }
        if (updateValues) {
          if (uniqueIDPrime) {
            transfer = {};
            if (typeof mdmUniqueParameter === 'object') {
              for (const key in mdmUniqueParameter) {
                transfer[key] = uniqueIDPrime;
              }
            } else {
              transfer[mdmUniqueParameter] = uniqueIDPrime;
            }
            mdmData = updateValues(mdmData, transfer);
          }
          if (behavioralInteraction) {
            mdmData = updateValues(mdmData, {
              behavioralInteraction: behavioralInteraction
            });
          }
        }
        metadataService.updateFromJSON(mdmData);
      } else {
        debug.warn('processMetadata: MDM Data Missing: Cannot Update Metadata');
      }
    } else {
      debug.warn('processMetadata: No Metadata Service: Cannot Update Metadata');
    }
    
    if (mdmData && mdmData.shopping && mdmData.shopping === 'shopping') {
      handleProductTracking(JSON.stringify(mdmData));
    }

    if (analyticsService) {
      analyticsService.callDynamicPageview();
      if (currentMDM && type) {
        currentPageNumber = currentMDM.getPageNumber() || false;
        if (currentPageNumber !== previousPageNumber && type === 'curated') {
          currentContext.broadcast('gallerydataupdated', {
            previousPageNumber: currentPageNumber
          });
        }
      }
    } else {
      debug.warn('processMetadata: No Analytics Service: Cannot Fire Pageview');
    }
  }

  function setupTouchListeners() {
    $(config.pvSlideWrapCon).on('touchstart.gallery', function(event) {
      config.xDown = event.originalEvent.touches[0].clientX;
      config.yDown = event.originalEvent.touches[0].clientY;
    });

    $(config.pvSlideWrapCon).on('touchmove.gallery', function(event) {
      if (!config.xDown || !config.yDown) {
        return;
      }

      let xUp = event.originalEvent.touches[0].clientX;
      let yUp = event.originalEvent.touches[0].clientY;

      let xDiff = config.xDown - xUp;
      let yDiff = config.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // left swipe
          refreshAds(context, config.isMobile, check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, config.overlayBigboxName, template, config.adWrapperElements, config.galleryType);
          handleInterstitial(context, config, slider, galleryContainer, check, getInterstitial, isInterstitialShown, mediaStream, template);
          slider.next();
        } else {
          // right swipe
          if (slider.currSlideId !== 0) {
            refreshAds(context, config.isMobile, check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, config.overlayBigboxName, template, config.adWrapperElements, config.galleryType);
            handleInterstitial(context, config, slider, galleryContainer, check, getInterstitial, isInterstitialShown, mediaStream, template);
          }
          slider.prev();
        }
        setDetails(slider.currSlideId);
        setProductsList(slider.currSlideId);
        transitionElements(slider.currSlide.content, config.galleryType, slider.currSlideId, config.rsOverflowCon, config.pvSlideWrapCon);
      }
      config.xDown = null;
      config.yDown = null;
    });
  }

  function setQString(type = 'curated') {
    if (type === 'curated' && location.search) {
      config.qString = setQueryToJSON();
      return true;
    } else {
      return false;
    }
  }

  function setQueryToJSON() {
    let pairs = location.search.slice(1).split('&'),
        result = {};

    pairs.forEach(function(pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
  }

  function setDeepLink(currentContext, type) {
    if (type === 'curated') {
      let timestamp;
      if (setQString(type) && config.qString.im) {
        config.qString.im = config.qString.im.replace(/[^0-9]/gim,'').trim();
        if (config.qString.im !== '') {
          slider.goTo(parseInt(config.qString.im));
        }
        timestamp = new Date().getTime();
        currentContext.broadcast('stateChanged', {
          gallery: timestamp,
          href: window.location.pathname+window.location.search.replace(/\??\&?im\=[^\&]+/gim,'')
        });
      }
    }
  }

  function addSlideNumber() {
    if (config.galleryType === 'curated') {
      galleryContainer.find('.rsCurr').text(slider.currSlideId + 1).end().find('.rsLength').text(slider.numSlides);
    }
  }

  function createSlideNumbering(container, count, add, type) {
    if (type === 'curated') {
      container.append(count);
      add();
    }
  }

  function setupAjaxHandler(type, currentSlider, currentContext, stayAheadAmount, readyToFetch) {
    if (type === 'dynamic') {
      let currentSlide = currentSlider.currSlideId + 1,
          numberOfSlides = currentSlider.numSlides;

      if (numberOfSlides - currentSlide <= stayAheadAmount) {
        if (readyToFetch) {
          currentContext.broadcast('gallery.loadingAdditionalContent');
        }
      } else {
        currentContext.broadcast('gallerydataupdated', {
          readyToFetchMore: true
        });
      }
    }
  }

  function getAdditionalContent(currentContext, ajaxUrl, paging, currentAjaxPage, currentSlider, ajaxFetchNumSlides, slideClass, detailsClass = false, detailsArray = false) {
    let finalAjaxUrl = ajaxUrl,
        countOfHTML,
        lastHTML,
        nextPage,
        $slideData;

    if (finalAjaxUrl !== '') {
      countOfHTML = finalAjaxUrl.match(/.html/g) || 0;

      if (countOfHTML.length > 1) {
        lastHTML = finalAjaxUrl.lastIndexOf('.html');
        finalAjaxUrl = finalAjaxUrl.substring(0, lastHTML);
      }

      if (paging) {
        nextPage = ++currentAjaxPage;

        currentContext.broadcast('gallerydataupdated', {
          ajaxPageWeAreOn: nextPage
        });

        if (finalAjaxUrl.lastIndexOf('/p/') !== -1) {
          finalAjaxUrl = finalAjaxUrl.substring(0, finalAjaxUrl.lastIndexOf('/p/'));
        }
        finalAjaxUrl += '/p/' + nextPage;
      } else {
        finalAjaxUrl += '/o/' + currentSlider.numSlides + '/n/' + ajaxFetchNumSlides;
      }

      finalAjaxUrl = finalAjaxUrl + '.html';

      if (check.isLocalhost() || check.isAuthor()) {
        finalAjaxUrl += '?wcmmode=disabled';
      }

      currentContext.broadcast('gallerydataupdated', {
        readyToFetchMore: false
      });

      $.get(finalAjaxUrl, function(data) {
        $slideData = $('<div>').append(data);

        appendSlideData($slideData, currentSlider, slideClass, currentContext, detailsClass, detailsArray);
      });
    }
  }

  function appendSlideData($data, currentSlider, newContentClass, currentContext, newDetailsClass, detailsArray) {
    let transfer;
    $data.find(newContentClass).each(function() {
      currentSlider.appendSlide(this);
      currentContext.broadcast('contentUpdated', {
        newContent: this
      });
    });
    if (newDetailsClass && detailsArray) {
      transfer = detailsArray;
      $data.find(newDetailsClass).each(function() {
        transfer.push(this);
      });
      currentContext.broadcast('gallerydataupdated', {
        pvDetailsArray: transfer
      });
    }
  }

  function killItWithFire() {
    // $(document).unbind('keydown.photoGalleryModalKeyFilter');
    $(window).off('resize.viewer');
    $(config.pvSlideWrapCon).off('touchstart.gallery');
    $(config.pvSlideWrapCon).off('touchmove.gallery');
    $('.rsArrow').off('click.slidesend');
    $('.list-item--show-all a').off('click');
    $('.pv-pl-nav-dropdown.dropdown-filter').off('hover');
  }

  const messageHandlers = {
    'gallery.sliderInit': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        debug.log('gallery.sliderInit: instance: ', $(context.getElement()).attr('id'), mediaStream.getInstance());
        config.dynamicProductViewed = $('[data-contains-STL]').data('containsStl') ? $('[data-contains-STL]').data('containsStl') : false;
        insertCTA(config.galleryType, galleryContainer, template, config.isMobile, config.launchMeContainer, config.launchMeText, slider.numSlides, config.newTemplate);
        setDetails(slider.currSlideId);
        if (config.newTemplate) {
          setProductsList(slider.currSlideId);
          $(config.defaultLoader).remove();
        }
        transitionElements(slider.currSlide.content, config.galleryType, slider.currSlideId, config.rsOverflowCon, config.pvSlideWrapCon);
        if (check.exists(['SniAds.Gallery', 'SniAds.Event'])) {
          SniAds.ready(function() {
            refreshAds(context, config.isMobile, check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, config.overlayBigboxName, template, config.adWrapperElements, config.galleryType);
            handleInterstitial(context, config, slider, galleryContainer, check, getInterstitial, isInterstitialShown, mediaStream, template);
          });
        }
        createCustomPrevNextHandling(slider);
        areWeThereYet(context, slider);
        setupFilterListeners();
        setupHistoryListeners();
        setupTouchListeners();
        setDeepLink(context, config.galleryType);
        createSlideNumbering(galleryContainer, template.slideCount(), addSlideNumber, config.galleryType);
        let $element = slider.currSlide.content;
        delegateArrowEvents(galleryContainer, config.previousArrowSelector, config.leftDataType, config.nextArrowSelector, config.rightDataType);
        if ($element.length === 1) {
          getHotspotInstance(context, $element.data(config.hotspotUrlName), $element.data('hotspot'), slider.currSlide.content.find(config.hotspotElement), config.hotspotElement, config.hotspotUrlName, window.location.pathname, false);
        }
        updateShopElement(context, slider);
        // setupAjaxHandler(config.galleryType, slider, context, config.ajaxStayAhead, config.readyToFetchMore);
        // a11y attributes on nav:
        $(context.getElement()).find(config.nextArrowSelector).attr({'role': 'button', 'aria-label': 'Next photo'});
        $(context.getElement()).find(config.previousArrowSelector).attr({'role': 'button', 'aria-label': 'Previous photo'});
        // Royal Slider hack for alt attr on lazy-loaded image: http://jsfiddle.net/DmitrySemenov/p2h7dsho/
        slider.ev.on('rsAfterContentSet', function(e, slideObject) {
          let img = slideObject.holder.find('img').eq(0);
          if (img && img.length && slideObject.caption) {
            // sometimes the caption is a string and sometimes it is a text node; we can choose the correct option here
            let caption = typeof slideObject.caption === 'string' ? slideObject.caption : (slideObject.caption.text && slideObject.caption.text())||'';
            img.attr('alt', caption);
          }
        });
      }
    },
    'gallery.afterSlide': function() {
      if (config.isVoteGallery) {
        debug.log('Exiting After Slide');
        return;
      }
      debug.log('gallery.afterSlide', slider.currSlide.content);

      if ($(context.getElement()).attr('id') === mediaStream.getInstance() && !config.inProgressOfLastSlideAction) {
        let $element = slider.currSlide.content;
        setupAjaxHandler(config.galleryType, slider, context, config.ajaxStayAhead, config.readyToFetchMore);
        centerImage($element, config.isMobile, config.galleryType);
        updateURL(context, config.galleryType, check, router, slider, config.pvPhotoWrapCon);
        fetchNextGallery(context, slider, config, galleryContainer);
        updateLastItem(slider);
        setProductsList(slider.currSlideId);
        setSecondPass(context, slider.currSlideId);
        incrementOriginalGallery(context, metadata, config);
        if (config.secondPass && config.allowPV) {
          processMetadata($element, {
            checkService: check,
            metadataService: metadata,
            analyticsService: analytics,
            incrementValue: 1,
            updateValues: updateObject,
            currentContext: context,
            previousPageNumber: config.previousPageNumber,
            mdmUniqueParameter: {UniqueID: false, Overlay_UniqueId: false},
            type: config.galleryType
          });
        }
        setDetails(slider.currSlideId);
        transitionElements(slider.currSlide.content, config.galleryType, slider.currSlideId, config.rsOverflowCon, config.pvSlideWrapCon);
        updateShopElement(context, slider);
        contentInsights('.photo-viewer');
        pinSvc.setPinButton({name,  $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content, true)});
        refreshAds(context, config.isMobile, check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, config.overlayBigboxName, template, config.adWrapperElements, config.galleryType);
        handleInterstitial(context, config, slider, galleryContainer, check, getInterstitial, isInterstitialShown, mediaStream, template);
        if ($element.length === 1) {  // Make sure we have a slide to check
          getHotspotInstance(context, $element.data(config.hotspotUrlName), $element.data('hotspot'), slider.currSlide.content.find(config.hotspotElement), config.hotspotElement, config.hotspotUrlName, window.location.pathname, false);
        }
        ll.forceScroll();
        // context.broadcast('contentUpdated', {
        //   newContent: $(slider.currSlide.content)
        // });
      } else {
        pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content, true)});
      }

    },
    'gallery.beforeAnimation': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        addSlideNumber();
        ll.forceScroll();
      }
    },
    'gallery.beforeSizeSet': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        debug.log('gallery.beforeSizeSet');
      }
    },
    'gallery.afterContentSet': () => {
      debug.log('gallery.afterContentSet', slider.currSlide.content);
      pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content, true)});

      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        setResizeHandler(config.galleryType, config.rsOverflowCon, config.pvSlideWrapCon, resizeWell, centerImage, slider, config.isMobile, config.weRecommend);
        updateShopElement(context, slider);
      }
    },
    'gallery.lastSlideReached': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        if (isInterstitialShown(config, galleryContainer)) {
          closeInterstitial(config, getInterstitial, galleryContainer, check);
          addNextGalleryLink(context, config, galleryContainer, slider, isInterstitialShown, mediaStream, template);
        }
        context.broadcast('gallerydataupdated', {
          inProgressOfLastSlideAction: true
        });
        if (!config.suppressNextGalleryEvent) {
          context.broadcast('mediaStream.readyForNextSS');
        }
        if ($(config.nextLink).length) {
          $(config.nextLink).html(template.loadingHTML());
          slider.slider.removeClass(config.blurClass);
        }
      }
    },
    'test.gallery.showNextAssetModal': ({link='#', subHeadline='Next Up', headline='Gallery Title'}) => {
      let $galleryOverflow = $('.rsOverflow');
      $galleryOverflow.append(template.nextGalleryModal({
        link,
        subHeadline,
        headline
      }));
    },
    'gallery.nextGalleryFetched': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        addNextGalleryLink(context, config, galleryContainer, slider, isInterstitialShown, mediaStream, template);
      }
    },
    'gallery.noNextGallery': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        removeNextGalleryLink(galleryContainer, config.nextClass);
      }
    },
    'gallery.loadingAdditionalContent': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        getAdditionalContent(context, config.ajaxUrl, config.ajaxPaging, config.ajaxPageWeAreOn, slider, config.ajaxFetchNumSlides, `${config.galleryContainer} ${config.pvSlideSelector}`, `${config.detailsContainer} ${config.pvDetailsSelector}`, config.pvDetailsArray);
      }
    },
    'gallerydataupdated': (data) => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        setGalleryConfig(config, data);
      }
    },
    'photos.searchPageUpdated': (data) => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        updateCurrentPhotoSearchPage(context, data);
      }
    },
    'mediaStream.consecutiveGalleryUpdated': (data) => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        updateConsecutiveGallery(context, metadata, {
          originalGallery: config.originalGallery,
          consecutiveGallery: data.consecutiveGallery
        });
      }
    },
    'mediaStream.headingToPreviousSS': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        killItWithFire();
      }
    },
    'mediaStream.headingToNextSS': () => {
      if ($(context.getElement()).attr('id') === mediaStream.getInstance()) {
        killItWithFire();
      }
    },
    'ajax.areaContentLoaded': () => {
      setProductsList(slider.currSlideId);
    }
  };

  function doProcessMetadata() {
    if (config.secondPass && config.allowPV) {
      let $slider = slider.currSlide.content;
      processMetadata($slider, {
        checkService: check,
        metadataService: metadata,
        analyticsService: analytics,
        incrementValue: 1,
        updateValues: updateObject,
        currentContext: context,
        previousPageNumber: config.previousPageNumber,
        mdmUniqueParameter: {UniqueID: false, Overlay_UniqueId: false},
        type: config.galleryType
      });
    }
  }

  // remove CTA and set up first image
  function doLaunch() {
    let $element = slider.currSlide.content;
    removeLaunchMe(config.launchMeWrapper);
    setProductsList(slider.currSlideId);
    handleProductTracking($element.attr('data-mdm'));
    pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content, true)});
    doProcessMetadata();
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  module = {
    behaviors: ['shop-this-look', 'hotspot', 'lazy-load', 'affiliate'],

    messages: Object.keys(messageHandlers),

    init: function() {
      getServices(context);
      instance = $(context.getElement()).attr('id');
      mediaStream.setInstance(instance);
      setGalleryConfig(defaults, context.getConfig());
      config.pvProductListArray = $(config.pvProductListSelector).toArray();
      checkAdLibrary(context, check);
      if (check.exists(['SniAds.Gallery', 'SniAds.Event'])) {
        adLib = context.getService('ads');
        SniAds.Gallery.init({
          galleryCfg: {
            container: '.pv-content-wrapper',
            dismiss_elts: '.rsArrowIcn',
            disable_elts: '',
            blockDelay: 10
          }
        });
        adLib.event.subscribe('slotRenderComplete',slot => {
          let id = slot.slot.getSlotElementId();
          if (!slot.isEmpty && /dfp_photo_interstitial/.test(id)) {
            $('.photo-viewer').addClass(config.interstitialClass);
          }
        });
        if (!coldLoad) {
          SniAds.Gallery.reset();
        }
        coldLoad = false;
      }
      checkGalleryType(context, $('.container-site').hasClass('inline-horizontal'));
      let viewCountData = metadata.getGalleryData();
      if (viewCountData) {
        if (typeof viewCountData.originalGallery !== 'undefined') {
          config.originalGallery = viewCountData.originalGallery;
        }
        if (typeof viewCountData.consecutiveGallery !== 'undefined') {
          config.consecutiveGallery = viewCountData.consecutiveGallery;
        }
        updateConsecutiveGallery(context, metadata, {
          originalGallery: config.originalGallery,
          consecutiveGallery: config.consecutiveGallery
        });
      } else {
        updateConsecutiveGallery(context, metadata, {
          originalGallery: config.originalGallery,
          consecutiveGallery: config.consecutiveGallery
        });
      }
      checkStartSlide(context, $(context.getElement()));
      setGalleryConfig(config, { isMobile: deviceType.isMobile, isTablet: deviceType.isTablet });
      setSliderConfig(context, config.rsConfig, config.isMobile, config.isTablet, config.galleryStartingPosition);
      if (config.galleryType === 'curated') {
        context.broadcast('gallerydataupdated', {
          adWrapper: config.newTemplate ? template.adWrapper() : template.adWrapperLite()
        });
      }
      let $footer = $(config.footerElement);
      if ($footer.text().trim().length === 0) {
        $footer.hide();
      } else {
        setWeRecommendWidth(context, config.weRecommend, config.galleryType);
        stageNextUp(config.galleryType, config.isMobile, config.nextUpElements, template);
      }
      setPreviousState(context, config.galleryType);
      updateDataAttributes(context, $(context.getElement()));
      if (config.galleryType === 'curated') {
        setMediaStreamData(mediaStream, config.mediaStream, config.galleryTitle, window.location.href);
      }
      setPreloaderHTML(template.preloaderHTML());
      cacheElements(context, config.galleryContainer, config.detailsContainer);
      populateDetailsWrapper(context, config.pvDetailsSelector, detailsContainer);
      stageContent(config.pvSlideWrapCon, galleryContainer);
      createAdWrapper(check.exists(['SniAds.Gallery', 'SniAds.Event']), galleryContainer, template, config);
      setInterstitialConfig(config);
      if (check.exists(['SniAds.Gallery', 'SniAds.Event'])) {
        SniAds.ready(function() {
          createInterstitialContainer(config, galleryContainer, template);
        });
      }
      prepareGallery(galleryContainer, config.galleryLoadingClass);
      createCustomSliderEvents(context, config.sliderMessages, $.rsProto, $.rsModules);
      setAjaxConfig(context, config.ajaxUrl, config.ajaxPaging, config.isMobile, config.currentSearchResultsPage);
      startSlider(context, galleryContainer, config.rsConfig);
      updateDataAttributes(context, $galleryElement);
      setOnboardingTimeout(context, config.isMobile);
      // set md for first image if it is visible and active
      if ($(config.launchMeWrapper).length === 0) {
        doProcessMetadata();
      }
    },

    destroy: function() {
      killItWithFire();
    },

    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'open-next-gallery':
          event.preventDefault();
          slider.next();
          setDetails(slider.currSlideId);
          setProductsList(slider.currSlideId);
          transitionElements(slider.currSlide.content, config.galleryType, slider.currSlideId, config.rsOverflowCon, config.pvSlideWrapCon);
          break;
        case 'close-gallery-cta':
          doLaunch();
          break;
        case 'contain-gallery':
          if (config.newTemplate && $(config.launchMeWrapper).length > 0) {
            doLaunch();
          } else {
            traverseSlideshow(galleryContainer, config, event.pageX, slider, check, getInterstitial, goNextClick);
          }
          break;
        case 'increment-slide':
          if ($(config.launchMeWrapper).length > 0) {
            doLaunch();
          } else {
            traverseSlideshow(galleryContainer, config, event.pageX, slider, check, getInterstitial, goNextClick);
          }
          break;
        case 'decrement-slide':
          traverseSlideshow(galleryContainer, config, event.pageX, slider, check, getInterstitial, goNextClick);
          break;
        case 'product-link':
          const productTitle = $(element).closest('[data-product-title]').data('product-title');
          let $slider = slider.currSlide.content;
          const mdmData = $slider.data('mdm');

          shopping.trackProductClick({
            partner: mdmData.partner,
            title: productTitle
          });
          break;
        default:
          break;
      }
    }
  };

  return module;
});
