SNI.Application.addService('start-modules', function(application) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let service;

  function start(currentContext) {
    application.start(currentContext);
  }

  function startAll(currentContext) {
    application.startAll(currentContext);
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  service = {
    start,
    startAll
  };

  return service;
});
