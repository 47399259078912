SNI.Application.addModule('user/ratings', (context) => {
  const debug = context.getService('logger').create('module.user/ratings');

  function addRatingListener() {
    $('.composebox-rating-value').on('mouseleave', function() {
      if ($('.selected-star').length) {
        let selectedStar = $('.selected-star')[0].getAttribute('data-value');

        $('.composebox-rating-value').children().each(function() {
          if ($(this)[0].hasAttribute('data-value')) {
            let dataValue = $(this)[0].getAttribute('data-value');
            if (dataValue <= selectedStar) {
              $(this).addClass('rating-star-full');
            } else {
              if ($(this).hasClass('rating-star-full')) $(this).removeClass('rating-star-full');
            }
          }
        });
      } else {
        $('.composebox-rating-value').children().each(function() {
          if ($(this).hasClass('rating-star-full')){
            $(this).removeClass('rating-star-full');
          }
        });
      }
    });
  }

  // module entry point
  function init() {
    debug.log('loading ratings');
    addRatingListener();
  }

  return {
    init,
  };
});
