SNI.Application.addModule('on-tv-full-width', (context) => {
//-----------------------------------------------------------
// Private
//-----------------------------------------------------------

  const debug     = context.getService('logger').create('module.on-tv-full-width'),
        template  = context.getService('template'),
        utility   = context.getService('utility'),
        schedule  = context.getService('schedule'),
        url       = context.getService('url'),
        defaults  = {
          parentClass: 'm-ScheduleCard',
          listSelector: 'ul',
          isCard: false,
          isWhatsHotOn: false,
          removeHiddenElements: true,
          onTonightTimeGMT: '0:0',
          display: {
            onNow: true,
            upNext: true,
            onTonight: true,
            watchLive: true
          }
        };

  let element,
      settings;

  function watchButton(watchUrl) {
    return template.button({
      url: watchUrl,
      parentClass: settings.parentClass,
      text: 'Watch Live TV'
    });
  }

  function showWhatsOnNow(showNode, watchUrl) {
    const { watchLive = true } = settings.display;
    const $whatOnNow = $(showNode),
          onNowLabel      = template.subHeadline({
            parentClass: settings.parentClass,
            text: 'On Now',
            modifier: 'now'
          });

    $whatOnNow.addClass('m-ScheduleCard__m-ScheduleItem--OnNow');

    if (watchLive && watchUrl) {
      $whatOnNow.append(watchButton(watchUrl));
    }
    $whatOnNow.removeAttr('data-timesearch');
    $whatOnNow.prepend(onNowLabel);
    requestAnimationFrame(() => $whatOnNow.show());
  }

  function showWhatsOnNext(showNode) {
    const $whatsOnNext = $(showNode),
          nextLabel      = template.subHeadline({
            parentClass: settings.parentClass,
            text: 'Up Next'
          });
    $whatsOnNext.removeAttr('data-timesearch').prepend(nextLabel);
    
    requestAnimationFrame(() => $whatsOnNext.show());
  }

  function showWhatsOnTv(dateTime, $element) {
    let whatOnNow,
        whatsOnNext,
        whatsOnTonight;

    const watchUrl = url.watchSiteOnRightRail();
    const isWhatsHotOn = settings.isWhatsHotOn;
    const { removeHiddenElements } = settings;
    const { onNow = true, upNext = true, onTonight = true, watchLive = true } = settings.display;

    // This could be optimized, currently search is Big O n.
    $element.find('[data-time]').each((indexInArray, showNode) => {
      const airTime = new Date($(showNode).attr('data-time')),
            airTimeHourMin =`${airTime.getUTCHours()}:${airTime.getUTCMinutes()}`,
            comparison = schedule.compareDateTimeNowAndAirtime(dateTime, airTime, utility.getZone(dateTime));
      $(showNode).attr('data-timesearch');  // tag this element for possible removal later

      if (onNow && comparison === 0) {
        whatOnNow = showNode;
      } else if (upNext && comparison < 0 && !whatsOnNext) {
        whatsOnNext = showNode;
      }

      //if element's airTime equals onTonightTimeGMT then it is whatsOnTonight
      //if OnNow is not present then show OnTonight
      if (onTonight && airTimeHourMin === settings.onTonightTimeGMT && (!whatOnNow || !isWhatsHotOn)) {
        whatsOnTonight = showNode;
      }
    });

    showWhatsOnNow(whatOnNow, watchUrl);

    if (watchLive && watchUrl && !whatOnNow) {
      $(whatsOnNext).append(watchButton(watchUrl));
    }

    showWhatsOnNext(whatsOnNext, watchUrl);

    if (whatsOnTonight) {
      let $wTonight = $(whatsOnTonight);
      $wTonight.removeAttr('data-timesearch');
      requestAnimationFrame(() => $wTonight.show());
    }

    if (removeHiddenElements) {
      $element.find('[data-timesearch]').remove();
    }
  }

  function createListItem(data) {
    return `
      <li data-time="${data.airTime}" style="display: none;">
        <div class="m-SchedulePromo__a-SubHeadline" style="display: ${data.labelAtom.text ? 'block' : 'none'};">${data.labelAtom.text}</div>
        <span class="m-SchedulePromo__a-Headline">
          <a href="${data.headlineAtom.link}">
            <span class="m-SchedulePromo__a-HeadlineText">${data.headlineAtom.title}</span>
          </a>
        </span>   
        <div class="m-SchedulePromo__a-Description">${data.descriptionAtom.description}</div>
      </li>
    `;
  }

  function createCardItem(data) {
    return `
      <div data-time="${data.airTime}" style="display: none;">
        <h4 class="m-ScheduleCard__a-SubHeadline" style="display: ${data.labelAtom.text ? 'inline-block' : 'none'};">${data.labelAtom.text}</h4>
        <h5 class="m-ScheduleCard__a-Headline" style="display: ${data.headlineAtom.link ? 'block' : 'none'};">
          <a href="${data.headlineAtom.link}">
            <span class="m-ScheduleCard__a-HeadlineText">${data.headlineAtom.title}</span>
          </a>
        </h5>                  
        <div class="m-ScheduleCard__a-Description">${data.descriptionAtom.description}</div>
      </div>
    `;
  }

  function scheduleRender($element) {
    const { isCard, listSelector } = settings;
    const $list = $element.find(listSelector);

    const markup = settings.schedule?.map((data) => {
      return isCard ? createCardItem(data): createListItem(data);
    }).join('\n');

    $list.append(markup);
  }

  function init() {
    const now = new Date();

    element = context.getElement();

    debug.log('On-TV Module: initialized');

    settings = Object.assign({}, defaults, context.getConfig());

    scheduleRender($(element));

    requestAnimationFrame(() => showWhatsOnTv(now, $(element)));

  }

  return {
    init
  };
});
