SNI.Application.addBehavior('popover', function(context) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let debug = context.getService('logger').create('behavior.popover');
  let check = context.getService('check').new(debug);
  if (!check.jqueryPlugin('popover')) return {};

  let $element = $(context.getElement());

  const defaults = {
    name: 'popover',
    placement: 'right',
    trigger: 'focus',
    content: '',
    title: 'test',
    container: $element,
    target: false,
    html: true,
    template: 'Popover template is not defined'
  };

  let config = Object.assign({}, defaults, context.getConfig('popover'));
  let target = config.target ? $element.find(config.target) : $element;

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {

    init() {
      debug.log('Initialize', $element, config);

      let template = $element.find(`#${config.name}-template`);
      let content = $element.find(`#${config.name}-content`);

      if (template.length) {
        config.template = template.html();
      }

      if (content.length) {
        config.content = content.html();
      }

      debug.log(config);
      target.popover(config);
    },

    onclick: function(event, element, elementType) {

      switch (elementType) {

        case 'toggle-popover':
          target.popover('toggle');
          break;

        case 'close-popover':
          target.popover('hide');
          break;

        default:
          debug.log(event, element, elementType);
          break;
      }
    }

  };

});
