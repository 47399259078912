SNI.Application.addModule('full-width-strip', context => {
  let behaviors = ['lazy-load', 'horizontal-scroll'];
  let config = context.getConfig();
  if (config && config.hasDropdown) {
    behaviors.push('replace-content');
  }
  return {
    behaviors
  };
});
