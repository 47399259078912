/** Newsletter Component
 **  3 required settings to pass to newsletter service:
 **  reminderBrand , reminderSourceId, reminderListId
 ** Newsletter ID needed for newsletter
 */

import '../services/logger';

SNI.Application.addModule('newsletter', (context) => {

  let moduleEl,
      $element,
      mdManager,
      service,
      debug,
      defaults,
      config;

  const brazeNewsletterEvent = (params) => {
    SNI.Application.broadcast('braze:newsletter', params);
  };

  return {
    onclick(event, element, elementType) {
      if (elementType !== '' && elementType !=='checkbox') {
        event.preventDefault();
      }

      switch (elementType) {
        case 'subscribe-initial':
          this.state1Submit();
          break;
        case 'subscribe-more':
          this.state2Submit();
          break;
        case 'cancel':
          this.state2Cancel();
          break;
        case 'subscribe-fw':
          this.state2Submit();
          break;
        case 'checkbox':
          this.toggleCheck(element);
          break;
        case 'checkbox-label':
          this.handleCheckbox(element);
          break;
        case 'close':
          this.hideNewsletter(element);
          break;
        default:
          // do nothing
      }
    },

    init() {
      moduleEl = context.getElement();

      $element = $(moduleEl);

      mdManager = context.getGlobal('mdManager');

      service = context.getService('newsletter-subscribe');

      debug = context.getService('logger').create('module.newsletter');

      defaults = {
        // This one gets set in the CMS
        newsletterId: '',
        newsletterName: '',
        newsletterSource:  '',
        newsletterBrand: 'hgtv',
        newsletterCategory: '',
        newsletterShowMore: true,
        newsletterSite: '',
        subscribeBrand: 'hgtv',
        title: '',
        description: '',
        subscriptionEndpoint: '',
        subscribeType: 'reminder',
        subscribeId: '',
        isShopping: false,
        isFWNewsletter: false,
        // Various message templates that the widget uses
        msg: {
          state2Thanks:'<strong>Thanks for subscribing to the {{newsletterName}} newsletter.</strong> Check out all our other great newsletters from {{newsletterSite}}.',
          state2ThanksReminder: '<strong>Thank You, </strong> You have successfully registered to receive email reminders to enter the giveaway.',
          state3Thanks:'<strong>Thanks,</strong> we have added your selections to your subscriptions.',
          state3NoThanks:'Sure, no problem. You\'re now subscribed to the {{newsletterName}} newsletter.',
          thankYouMsgFW:'<strong>Thanks for subscribing!</strong>'
        },
        // Various error messages that the widget uses
        err: {
          emailEmpty: 'Please enter your email address.',
          emailInvalid: 'Your email address is invalid.',
          noCheckbox: 'Please select at least one newsletter from the list above.',
          serviceError: 'A problem occurred, please try again later or contact customer service.'
        },
        selectors: {
          $emailInput: '',
          state1: '.ns-state1',
          state2: '.ns-state2',
          state3: '.ns-state3',
          statePrefix: '.ns-',
          stateNamePrefix: 'state',
          errors: '.ns-errors',
          description: '.ns-description',
          // Full Width Newsletter Selectors
          submit: '',
          thankYouState: '',
          placeholder: '',
          invalidEmail: ''
        },
        //  Used for calculated source values!
        customSource: false
      };

      // In the JSP, anything with a type of "text/x-config" will be passed in here
      // config = Object.assign({}, defaults, context.getConfig());
      // need a deep merge if we are overriding one "msg":
      config = $.extend(true, {}, defaults, context.getConfig());

      // Check if subscription is newsletter and if so, change subscribe type
      if (config.newsletterId) {
        config.subscribeType = 'newsletter';
      }

      if (config.isFWNewsletter) {
        this.initPlaceholder();
      } else {
        this.initTitle();
        this.initDescription();
        this.initThanks();
      }

      this.setEmailSelector();

    },

    destroy() {
      moduleEl = null;
      $element = null;
      mdManager = null;
      service = null;
      debug = null;
      defaults = null;
      config = null;
    },

    initPlaceholder() {
      if (config.placeholder) {
        $element.find(config.selectors.submit + ' ' + config.selectors.placeholder).html(config.placeholder);
      }
    },

    initTitle() {
      const self = this;

      if (config.title) {
        $element.find('h2').html( self.substitute(config.title) );
      }
    },

    initDescription() {
      const self = this;

      if (config.description) {
        $element.find(config.selectors.state1 + ' ' + config.selectors.description).html( self.substitute(config.description) );
      }
    },

    initThanks() {
      let msg;

      // Update the thanks message text so it includes the newsletter name
      if (config.subscribeType === 'newsletter') {
        msg = this.substitute(config.msg.state2Thanks);
      } else {
        msg = this.substitute(config.msg.state2ThanksReminder);
      }

      $element.find(config.selectors.state2 + ' ' + config.selectors.description).html(msg);

      // Also update the thanks message on the state3 page in case we jump directly there
      msg = this.substitute(config.msg.state3Thanks);

      $element.find(config.selectors.state3 + ' ' + config.selectors.description).html(msg);
    },

    state1Submit() {
      const self = this;

      if (!self.state1Validate()) {
        return;
      }

      self.showLoading();

      //set API url and parameters depending on subscription type
      if (config.subscribeType === 'newsletter') {
        config.subscribeBrand = config.newsletterBrand;
        config.subscribeId = config.newsletterId;
        config.subscribeSource = config.newsletterSource;
      } else {
        if (config.customSource) {
          config.subscribeSource = config.newsletterSource;
        } else {
          config.subscribeSource = mdManager.getPageTitle().replace(/\ /g, '_');
        }
        config.subscribeBrand = mdManager.getParameter('site');
      }

      service.subscribe({
        email: self.getEmail(),
        nl: config.subscribeId,
        listId: config.listId,
        source: config.subscribeSource,
        nlbrand: config.subscribeBrand,
        subscribeType: config.subscribeType,
        subscriptionEndpoint: config.subscriptionEndpoint
      }).done(() => {

        brazeNewsletterEvent({
          utm: config.subscribeSource,
          [config.subscribeId + '_joined_date']: new Date()
        });

        // Service call successfully subscribed
        if (config.isShopping) {
          self.showState(config.selectors.statePrefix + '2Promo');
        } else {
          self.state2Show();
        }

      }).fail(() => {
        // Service call did not subscribe
        self.showLoading(false);
        self.setError(config.selectors.stateNamePrefix + '1', config.err.serviceError);
        debug.error(arguments);
      });
    },

    state1Validate() {
      const email = this.getEmail();
      this.setError(config.selectors.statePrefix + '1', '');

      if (email.length === 0) {
        this.setError(config.selectors.statePrefix + '1', config.err.emailEmpty);
        return false;
      }

      if (! this.isValidEmail(email)) {
        this.setError(config.selectors.statePrefix + '1', config.err.emailInvalid);
        return false;
      }

      return true;
    },

    state2Show() {
      if (!config.newsletterShowMore && config.subscribeType === 'newsletter') {
        // Skip state2 and jump immediately to state3 to show a thank you message.
        this.showState(config.selectors.statePrefix + '3');
      } else {
        this.showState(config.selectors.statePrefix + '2');
        $element.find(config.selectors.state2 + ' :checkbox').eq(0).focus();
      }
    },

    state2Submit() {
      //At this point, state2Submit is exclusively for the newsletter
      const self = this,
            nl = [];

      // Make sure the form is ready to submit
      if (! self.state2Validate()) {
        return;
      }

      if (config.isFWNewsletter) {
        if (config.subscribeType === 'newsletter') {
          config.subscribeBrand = config.newsletterBrand;
          config.subscribeSource = config.newsletterSource;
        } else {
          if (config.customSource) {
            config.subscribeSource = config.newsletterSource;
          } else {
            config.subscribeSource = mdManager.getPageTitle().replace(/\ /g, '_');
          }
          config.subscribeBrand = mdManager.getParameter('site');
        }

        // Get array of newsletter ids that were checked
        $element.find(config.selectors.submit + ' :checkbox').filter(':checked').each((index, element) => {
          nl.push( element.value );
        });
      } else {
        self.showLoading();

        // Get array of newsletter ids that were checked
        $element.find(config.selectors.state2 + ' :checkbox').filter(':checked').each((index, element) => {
          nl.push( element.value );
        });
      }

      service.subscribe({
        email: self.getEmail(),
        firstname: self.getName(),
        nl: nl,
        source: config.newsletterSource,
        listId: config.listId,
        nlbrand: config.newsletterBrand,
        subscribeType: config.subscribeType,
        subscriptionEndpoint: config.subscriptionEndpoint
      }).done(() => {
        const newsletters = {};
        for (let i = 0; i < nl.length; i++) {
          newsletters[`${nl[i]}_joined_date`] = new Date();
        }
        brazeNewsletterEvent({
          ...newsletters,
          utm: config.newsletterSource
        });

        // Service call successfully subscribed
        if (config.isFWNewsletter) {
          self.showState(config.selectors.thankYouState);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).html(config.msg.thankYouMsgFW);
        } else {
          self.showState(config.selectors.statePrefix + '3');
        }
      }).fail(() => {
        // Service call did not subscribe
        if (config.isFWNewsletter) {
          self.setError(config.selectors.thankYouState, config.err.serviceError);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).html(config.err.serviceError);
        } else {
          self.showLoading(false);
          self.setError(config.selectors.statePrefix + '2', config.err.serviceError);
        }
        debug.error(arguments);
      });
    },

    state2Cancel() {
      $element.find(config.selectors.state3 + ' ' + config.selectors.description).html( this.substitute(config.msg.state3NoThanks) );
      this.showState(config.selectors.state3);
    },

    state2Validate() {
      if (config.isFWNewsletter) {
        debug.log('full width newsletter: successfuly validated');
        this.setError(config.selectors.submit, '');

        $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).html('');

        if (! $element.find(config.selectors.submit + ' :checkbox').is(':checked')) {
          this.setError(config.selectors.thankYouState, config.err.noCheckbox);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).show();
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).html(config.err.noCheckbox);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.invalidEmail).hide();
          return false;
        }

        // Verify email is provided
        const email = this.getEmail();
        this.setError(config.selectors.submit);

        if (email.length === 0) {
          this.setError(config.selectors.thankYouState, config.err.emailEmpty);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).show();
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).html(config.err.emailEmpty);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.invalidEmail).hide();
          debug.log('full width newsletter: Returned from here empty email');
          return false;
        }

        if (! this.isValidEmail(email)) {
          this.setError(config.selectors.thankYouState, config.err.emailInvalid);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.invalidEmail).show();
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.invalidEmail).html(config.err.emailInvalid);
          $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).hide();
          debug.log('full width newsletter: Returned from here invalid email');
          return false;
        }

        $element.find(config.selectors.thankYouState + ' ' + config.selectors.placeholder).show();
        $element.find(config.selectors.thankYouState + ' ' + config.selectors.invalidEmail).hide();
        debug.log('full width newsletter: Returned true from newsletter validate');
        return true;
      } else {
        // Clear any existing errors
        this.setError(config.selectors.statePrefix + '2', '');

        // Verify that at least one checkbox was checked
        let $ckboxes = $element.find(config.selectors.state2 + ' :checkbox');
        if (! $ckboxes.is(':checked')) {
          this.setError(config.selectors.statePrefix + '2', config.err.noCheckbox);
          $ckboxes.eq(0).focus();
          return false;
        }

        return true;
      }
    },

    setError(state, msg) {
      if (config.isFWNewsletter) {
        const errorClass = `${state}`;
        $element.find(errorClass).show();
      } else {
        const errorClass = `${state} ${config.selectors.errors}`;
        let show = Boolean(msg);
        let $errorElem = $element.find(errorClass);
        $errorElem.html( msg || '' ).toggle(show).toggleClass('is-Shown', show);
        if (show) {
          $errorElem.attr('role', 'alert');
          config.selectors.$emailInput.focus();
        } else {
          $errorElem.removeAttr('role');
        }
        config.selectors.$emailInput.attr('aria-invalid', show);
      }
    },

    showState(state) {
      const showClass = `${state}`;
      if (config.isFWNewsletter) {
        $element.find(config.selectors.submit).hide();
      } else {
        // *The initial show/hide of these divs is set in the newsletter CSS
        this.showLoading(false);

        $element.find(config.selectors.state1).hide();
        $element.find(config.selectors.state2).hide();
      }
      $element.find(showClass).show().focus();
    },

    showLoading(show) {
      const $loader = $element.find('[data-ui-loader]');
      $loader.toggle( show !== false );
      if (show !== false) $loader.focus();
    },

    setEmailSelector() {
      var selector = config.selectors.state1;
      if (config.isFWNewsletter) {
        selector = config.selectors.submit;
      }
      config.selectors.$emailInput = $element.find(selector + ' input[name=email]');

    },

    getEmail() {
      var email;
      email = String(config.selectors.$emailInput.val() || '').trim();
      email = (email || '').toLowerCase();

      return email;
    },

    getName() {
      return String($element.find(config.selectors.submit + ' input[name=first_name]').val() || '').trim();
    },

    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);

    },

    toggleCheck(element) {
      const $checkbox = $(element);
      if ($checkbox) {
        if ($checkbox.is(':checked')){
          $checkbox.siblings('.input-check').show();
        } else {
          $checkbox.siblings('.input-check').hide();
        }
      }
    },

    handleCheckbox(element) {
      const $label = $(element);
      if ($label) {
        const $checkbox = $label.find('input[type=checkbox]');
        if ($checkbox) {
          if ($checkbox.is(':checked')) {
            $checkbox.prop('checked',false);
            $checkbox.siblings('.input-check').hide();
          } else {
            $checkbox.prop('checked',true);
            $checkbox.siblings('.input-check').show();
          }
        }
      }
    },

    hideNewsletter(element) {
      const $button = $(element);
      if ($button) {
        const newsletter = $($button.attr('data-parent-id'));
        if (newsletter) {
          newsletter.hide();
        }
      }
    },

    substitute(s) {
      return s.replace( /\{\{([^{}]*)\}\}/g,
        (a, b) => {
          const r = config[b];
          return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
      );
    }
  };

});
