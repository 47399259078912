SNI.Application.addModule('virtual-tour', context => {

  let ads,
      side_check,
      side_check_touch,
      $element = $(context.getElement()),
      bodySel = '.header, .footer, #site, .container-site',
      deviceType = context.getService('device-type'),
      debug = context.getService('logger').create('virtual-tour'),
      check = context.getService('check').new(debug),
      analytics = context.getService('analytics'),
      modal = context.getService('modal'),
      metadata = context.getService('metadata'),
      template = context.getService('template'),
      productHS = context.getService('product-hotspots'),
      mdManager = check.exists('mdManager')
        ? context.getGlobal('mdManager')
        : false,
      modUtil = context.getService('utility');

  let defaults = {
    roomName: 'default',
    tourWrapSelector: '.virtual-tour-outer-wrapper',
    tourLaunchSelector: '.virtual-tour-handler',
    overlayBigboxName: '',
    basePageType: ''
  };
  let settings = Object.assign({}, defaults, context.getConfig());

  let messageHandlers = {
    'modal.hidden': () => {}
  };

  const createTour = (embedpano) => {
    let VirtualTour = function(element) {
      //overlayMarkup contains what should go into the modal to serve as the container for both the tour and ad-wrapper
      var self = this;
      settings.roomName = settings.roomName.replace(/\\/gi, '');
      settings.basePageType =
        mdManager && mdManager.getPageType
          ? mdManager.getPageType()
          : 'articlepage';
      self.bindTourHandler($element);
    };

    VirtualTour.prototype.launchTour = function(clickElement) {
      var tourModal,
          self = this,
          seamRefresh = true;

      //To avoid any positioning issues with the modal by scrolling to top before launching the overlay.
      //Afterwards, hide all other content on the page that is not the virtual tour module.
      self.scrollTop = $(window).scrollTop() || 0;
      $(bodySel).hide();

      //content string used to be wrapped in $($.parseHTML()) .. was that needed?
      tourModal = modal.open(
        'tour-overlay',
        template.tourOverlay({
          roomName: settings.roomName
        })
      );

      function _refreshBigBox() {
        if (!deviceType.isMobile && ads) {
          // only need to append the overlay bb slot once, but will refresh each time
          if (settings.overlayBigboxName === '') {
            // can't depend on the name of the slot, so... this returns the slot name
            settings.overlayBigboxName = ads.appendSlot(
              'dfp_overlay_bigbox',
              'dfp_bigbox'
            );
            // tell adserver which slot to sync with
            ads.Gallery.setSyncSlot(settings.overlayBigboxName);
          } else {
            /*  closing the overlay then opening it again w/o reloading page results in no ad
            * detangling this looks complex since the overlay isn't destroyed, but OVERWRITTEN
            * the next time the overlay promo is clicked to launch the overlay */
            ads.refreshSlot(settings.overlayBigboxName);
          }
        }
      }

      if (!deviceType.isMobile && ads) {
        settings.overlayBigboxName = ads.appendSlot(
          'dfp_overlay_bigbox',
          'dfp_bigbox'
        );
        ads.Gallery.setSyncSlot(settings.overlayBigboxName);
      }

      //Create krpano object inside of the modal
      embedpano({
        swf: '/etc/clientlibs/assets/tour/tour.swf',
        xml:
          settings.componentUrl.replace('jcr:content', '_jcr_content') +
          '.tourConfig.xml',
        target: 'virtual-tour-embed-container',
        html5: 'prefer',
        passQueryParameters: true
      });

      productHS.init(settings);

      self.setLevelSix();

      var krpano = document.getElementById('krpanoSWFObject');

      var lookat_interval, touch_interval, last_side, last_side_touch;

      (side_check = mdManager.getParameterString('TourPosition')),
      (side_check_touch = mdManager.getParameterString('TourPosition'));

      //left: -90
      //front: 0/-0
      //right: 90
      //back: 180/-180
      //top: -45
      //bottom: 45

      $(krpano)
        .mousedown(function() {
          clearInterval(lookat_interval);
          lookat_interval = setInterval(function() {
            if (krpano && krpano.get && seamRefresh) {
              krpano.call(
                'screentosphere(mouse.x, mouse.y, mouseath, mouseatv)'
              );
              var mouseHorizontal = krpano.get('mouseath'),
                  mouseVertical = krpano.get('mouseatv'),
                  roundedH = Math.round(mouseHorizontal),
                  roundedV = Math.round(mouseVertical);
              if (
                Math.round(mouseHorizontal) % 90 === 0 ||
                (Math.round(mouseVertical) % 45 === 0 &&
                  Math.round(mouseVertical) !== 0)
              ) {
                if (roundedV <= -45) {
                  if (last_side !== 'top') {
                    //self.seamAnalyticsRefresh('top');
                    //debug.log('top');
                    last_side = 'top';
                  }
                } else if (roundedV >= 45) {
                  if (last_side !== 'bottom') {
                    //self.seamAnalyticsRefresh('bottom');
                    //debug.log('bottom');
                    last_side = 'bottom';
                  }
                } else if (roundedH === -90) {
                  if (last_side !== 'left') {
                    //self.seamAnalyticsRefresh('left');
                    //debug.log('left');
                    last_side = 'left';
                  }
                } else if (roundedH === 0) {
                  if (last_side !== 'front') {
                    //self.seamAnalyticsRefresh('front');
                    //debug.log('front');
                    last_side = 'front';
                  }
                } else if (roundedH === 90) {
                  if (last_side !== 'right') {
                    //self.seamAnalyticsRefresh('right');
                    //debug.log('right');
                    last_side = 'right';
                  }
                } else if (roundedH === 180 || roundedH === -180) {
                  if (last_side !== 'back') {
                    //self.seamAnalyticsRefresh('back');
                    //debug.log('back');
                    last_side = 'back';
                  }
                }
                //clearInterval(lookat_interval);
                //_refreshBigBox();
              }
            }
          }, 20);
        })
        .mouseup(function() {
          clearInterval(lookat_interval);
          if (last_side !== side_check && typeof last_side !== 'undefined') {
            self.seamAnalyticsRefresh(last_side);
            _refreshBigBox();
          }
          side_check = last_side;
        });

      $(krpano)
        .on('touchstart', function() {
          clearInterval(touch_interval);
          touch_interval = setInterval(function() {
            if (krpano && krpano.get && seamRefresh) {
              var touchHorizontal = krpano.get('view.hlookat'),
                  touchVertical = krpano.get('view.vlookat'),
                  roundedHo = Math.round(touchHorizontal),
                  roundedVe = Math.round(touchVertical);
              if (
                Math.round(touchHorizontal) % 90 === 0 ||
                (Math.round(touchVertical) % 45 === 0 &&
                  Math.round(touchVertical) !== 0)
              ) {
                if (roundedVe <= -45) {
                  if (last_side_touch !== 'top') {
                    //self.seamAnalyticsRefresh('top');
                    //debug.log('top');
                    last_side_touch = 'top';
                  }
                } else if (roundedVe >= 45) {
                  if (last_side_touch !== 'bottom') {
                    //self.seamAnalyticsRefresh('bottom');
                    //debug.log('bottom');
                    last_side_touch = 'bottom';
                  }
                } else if (roundedHo > -135 && roundedHo <= -45) {
                  if (last_side_touch !== 'left') {
                    //self.seamAnalyticsRefresh('left');
                    //debug.log('left');
                    last_side_touch = 'left';
                  }
                } else if (roundedHo > -45 && roundedHo <= 45) {
                  if (last_side_touch !== 'front') {
                    //self.seamAnalyticsRefresh('front');
                    //debug.log('front');
                    last_side_touch = 'front';
                  }
                } else if (roundedHo > 45 && roundedHo <= 135) {
                  if (last_side_touch !== 'right') {
                    //self.seamAnalyticsRefresh('right');
                    //debug.log('right');
                    last_side_touch = 'right';
                  }
                } else if (roundedHo > 135 && roundedHo <= -135) {
                  if (last_side_touch !== 'back') {
                    //self.seamAnalyticsRefresh('back');
                    //debug.log('back');
                    last_side_touch = 'back';
                  }
                }
                //debug.log(roundedHo, roundedVe);
                //clearInterval(touch_interval);
                //_refreshBigBox();
              }
            }
          }, 20);
        })
        .on('touchend', function() {
          clearInterval(touch_interval);
          if (
            last_side_touch !== side_check_touch &&
            typeof last_side !== 'undefined'
          ) {
            self.seamAnalyticsRefresh(last_side_touch);
            _refreshBigBox();
          }
          side_check_touch = last_side_touch;
        });

      $(
        document
      ).on(
        'webkitfullscreenchange mozfullscreenchange fullscreenchange',
        function(e) {
          seamRefresh = !seamRefresh;
          if (seamRefresh) {
            setTimeout(function() {
              $('body').on(
                'keyup.modaldismiss',
                $.proxy(function(e) {
                  e.which === 27 &&
                    context.broadcast('modal.hidden', { modal: tourModal });
                }, self)
              );
            }, 1000);
          } else {
            $('body').off('keyup.modaldismiss');
          }
        }
      );

      function closeTour(obj) {
        let modalsExist =
          check.exists('modal.$container', obj) &&
          check.exists('$container', tourModal);
        if (
          modalsExist &&
          obj.modal.$container.attr('id') === tourModal.$container.attr('id')
        ) {
          analytics.setProp(2, '');
          analytics.setProp(46, '');
          analytics.setProp(47, '');
          analytics.setProp(48, '');
          analytics.setValue('events', '');

          if (check.exists('mdManager.setParameter')) {
            metadata.updateFromString({ Type: settings.basePageType });
          }

          $('#product-overlay, #tooltip').remove();
          $(krpano).remove();
          $(bodySel).show();
          debug.log(
            `hiding/removing tour elements, including: #${obj.modal.$container.attr(
              'id'
            )}`
          );

          if (self.scrollTop) {
            scrollTo(0, self.scrollTop);
          }
          messageHandlers['modal.hidden'] = () => {};
        }
      }

      messageHandlers['modal.hidden'] = closeTour;

      return false;
    };

    //Wiring for seam refresh analytics
    VirtualTour.prototype.seamAnalyticsRefresh = function(side) {
      var levelSixNode, nodePattern;
      if (check.exists(['s', 'mdManager'])) {
        if (side === undefined) {
          side = mdManager.getParameterString('TourPosition');
        }
        analytics.setValue('events', 'event1');
        nodePattern = /(front|left|back|right|top|bottom)/gi;

        //debug.log('Pre-refreshCall ' + mdManager.getParameterString('TourPosition'));
        levelSixNode = mdManager.getParameterString('TourPosition');
        levelSixNode = levelSixNode.replace(nodePattern, side);
        levelSixNode = levelSixNode.trim();
        //debug.log('levelSixNode' + levelSixNode);
        mdManager.setParameter('TourPosition', levelSixNode);
        analytics.setProp(68, levelSixNode);
        //debug.log('Post-refreshCall ' + mdManager.getParameterString('TourPosition'));
        analytics.legacyPV();
        analytics.setValue('events', '');
        //analytics.callDynamicPageview();
      }
    };

    //Wiring for initial tour load analytics
    VirtualTour.prototype.setLevelSix = function() {
      //var levelSixNode;

      function setAnalyticsEvents() {
        if (check.exists('mdManager.setParameter')) {
          metadata.updateFromString({ Type: 'virtualtour' });
        }
        analytics.setProp(68, analytics.getProp(68).trim());
        analytics.setValue('events', 'event1');
        analytics.callDynamicPageview();
        analytics.setValue('events', '');
      }

      if (check.exists(['s', 'mdManager'])) {
        //levelSixNode = mdManager.getParameterString('SubSctnL2');
        //levelSixNode += 'front';
        setTimeout(function() {
          var panocake = document.getElementById('krpanoSWFObject');
          if (panocake && panocake.get) {
            var touchHorizontal2 = panocake.get('view.hlookat'),
                touchVertical2 = panocake.get('view.vlookat'),
                roundedHot = Math.round(touchHorizontal2),
                roundedVet = Math.round(touchVertical2);
            mdManager.setParameter('TourPosition', '');

            let prop68 = '';
            analytics.setProp(68, prop68);
            if (roundedVet <= -45) {
              mdManager.addParameter('TourPosition', 'top');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'top';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else if (roundedVet >= 45) {
              mdManager.addParameter('TourPosition', 'bottom');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'bottom';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else if (roundedHot > -135 && roundedHot <= -45) {
              mdManager.addParameter('TourPosition', 'left');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'left';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else if (roundedHot > -45 && roundedHot <= 45) {
              mdManager.addParameter('TourPosition', 'front');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'front';
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else if (roundedHot > 45 && roundedHot <= 135) {
              mdManager.addParameter('TourPosition', 'right');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'right';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else if (roundedHot > 135 && roundedHot <= -135) {
              mdManager.addParameter('TourPosition', 'back');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'back';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log(mdManager.getParameterString('TourPosition'));
            } else {
              mdManager.addParameter('TourPosition', 'front');
              side_check = mdManager.getParameterString('TourPosition');
              side_check_touch = mdManager.getParameterString('TourPosition');
              prop68 += 'front';
              analytics.setProp(68, prop68);
              setAnalyticsEvents();
              //debug.log('set analytics default');
              //debug.log(mdManager.getParameterString('TourPosition'));
            }
          }
        }, 500);
      }
    };

    VirtualTour.prototype.bindTourHandler = function($element) {
      var self = this;

      $element
        .closest(settings.tourWrapSelector)
        .find(settings.tourLaunchSelector)
        .on('click', function(e) {
          e.preventDefault();
          return self.launchTour(this);
        });
    };

    return VirtualTour;
  };

  return {
    init: async () => {
      await import('../vendor/assets/tour/tour' /* webpackChunkName: "tour" */);

      let embedpano = context.getGlobal('embedpano');
      let VirtualTour = createTour(embedpano);

      ads = check.exists('SniAds.Gallery')
        ? context.getGlobal('SniAds')
        : false;
      modUtil.registerjQueryPlugin('virtualTour', VirtualTour);
      $element.virtualTour();
    },

    messages: Object.keys(messageHandlers),
    onmessage(msg, data) {
      messageHandlers[msg](data);
    }
  };
});
