SNI.Application.addService('product-hotspots', function(context) {
  'use strict';

  let x,
      y,
      ads,
      config,
      mdManager,
      isFullScreen  = false,
      debug         = context.getService('logger').create('service.product-hotspots'),
      check         = context.getService('check').new(debug),
      deviceType    = context.getService('device-type'),
      analytics     = context.getService('analytics'),
      template      = context.getService('template');

  let showOverlay = function(hotspotId) {
    var settings = checkConfig(config);
    if (!settings) { return; }

    var tourProducts = settings.tourProducts,
        tpName = settings.tpName.replace(/[^a-z0-9\s]/gi, ''),
        sName = settings.sName.replace(/[^a-z0-9\s]/gi, '');

    hideTooltip();
    var previous=(hotspotId-1);
    var next=hotspotId;
    next++;

    //if at end of list, loop back to first for next
    if (next>(tourProducts.length-1)) {
      next=0;
    }

    //if at beginning of list, loop back to last for previous
    if (previous < 0 ) {
      previous = (tourProducts.length-1);
    }

    var overlay = document.getElementById('product-overlay');

    if (overlay === null) {
      // create overlay and append to page
      overlay = document.createElement('div');
      overlay.setAttribute('id','product-overlay');
      overlay.setAttribute('class', 'product-overlay');
    }

    //IE11 cannot layer the modal on top of the fullscreen WebGL component, so exit fullscreen
    if (!!document.msExitFullscreen && (document.msFullscreenElement !== null)) {
      document.msExitFullscreen();
    }

    if (isFullScreen) {
      var windowWidth = $(window).width();

      setTimeout(function() {
        $('#product-overlay').css('cssText', 'z-index: 2000000000000 !important' )
          .css('width', windowWidth );
      }, 1);
    }

    overlay.innerHTML = template.productHotSpot({
      product: tourProducts[hotspotId],
      currentProduct: parseInt(hotspotId) + 1,
      totalProducts: tourProducts.length,
      componentUrl: settings.componentUrl,
      previous: previous,
      next: next,
    });

    document.body.appendChild(overlay);

    //Wiring for hotspot click pageviews
    var levelSixNode, currentSide;
    if (mdManager)  {
      var compName = tourProducts[hotspotId].company_name;
      var titleName = tourProducts[hotspotId].title;
      compName = compName.replace(/[^a-z0-9\s]/gi, '');
      titleName = titleName.replace(/[^a-z0-9\s]/gi, '');
      analytics.setValue('events', 'event1,event47');
      analytics.setEvar(46, tpName);
      analytics.setEvar(47, tpName + ':' + sName + ':' + titleName);
      analytics.setEvar(48, tpName + ':' + 'tour' + ':' + sName + ':' + compName + ':' + titleName);
      levelSixNode = mdManager.getParameterString('TourPosition');
      currentSide = mdManager.getParameterString('TourPosition');
      levelSixNode += '|' + titleName + '|' + compName;
      mdManager.setParameter('TourPosition', levelSixNode);
      levelSixNode = levelSixNode.trim();
      currentSide = currentSide.trim();
      analytics.setProp(68, levelSixNode);
      analytics.legacyPV();
      mdManager.setParameter('TourPosition', currentSide);
      analytics.setProp(68, currentSide);
    }

    //Wiring for more info clicks
    //Double check that this doesn't interfere with link opening in new tab
    $('.more-info, .product-left img, .product-right img')
      .off('click')
      .on('click', function() {
        if (mdManager)  {
          var comp = tourProducts[hotspotId].company_name;
          var titleName2 = tourProducts[hotspotId].title;
          comp = comp.replace(/[^a-z0-9\s]/gi, '');
          titleName2 = titleName2.replace(/[^a-z0-9\s]/gi, '');
          analytics.setValue('linkTrackVars', 'prop1,prop2,eVar46,eVar47,eVar48,events');
          analytics.setValue('linkTrackEvents', 'event48');
          analytics.setValue('events', 'event48');
          analytics.setProp(2, tpName + ':Sponsor Site Click');
          analytics.setEvar(46, tpName);
          analytics.setEvar(47, tpName + ':' + sName + ':' + titleName2);
          analytics.setEvar(48, tpName + ':' + 'tour' + ':' + sName + ':' + comp + ':' + titleName2);
        }
      });

    if ( deviceType.isMobile && ads ) {
      ads.refreshSlot( $('#dfp_overlay_bigbox :first-child').attr('id') );
    }

  };

  let overlayRestore = function() {
    document.body.removeChild(document.getElementById('product-overlay'));
  };

  let showTooltip = function(hotspotId) {
    var settings = checkConfig(config);
    if (!settings) { return; }

    var krpano = document.getElementById('krpanoSWFObject'),
        xCoord, yCoord, athCo, atvCo;

    if (krpano && krpano.get) {
      krpano.call('spheretoscreen(hotspot[' + hotspotId + '].ath, hotspot[' + hotspotId + '].atv, screenH, screenV)');
      window.setTimeout(function() {
        athCo = krpano.get('hotspot[' + hotspotId + '].ath');
        atvCo = krpano.get('hotspot[' + hotspotId + '].atv');
        xCoord = krpano.get('screenH');
        yCoord = krpano.get('screenV');
        debug.log('showTooltip', hotspotId, athCo, atvCo, xCoord, yCoord);

        var tooltip = document.getElementById('tooltip');

        if (tooltip === null) {
          // create tooltip and append to page
          tooltip = document.createElement('div');
          tooltip.setAttribute('id','tooltip');
          tooltip.setAttribute('class', 'tooltip top in');
        }

        tooltip.innerHTML = '<div class="tooltip-inner">' + settings.tourProducts[hotspotId].title.replace(/&amp;quot;/ig, '\"') + '</div>';

        var $cont = $('#virtual-tour-embed-container'),
            positronix = $cont.position(),
            marLeft = $cont.css('margin-left'),
            marTop = $cont.css('margin-top');

        marLeft = marLeft.replace(/px/ig, '');
        marTop = marTop.replace(/px/ig, '');

        tooltip.style.position = 'absolute';
        tooltip.style.left = (xCoord+positronix.left+Math.round(marLeft)+20) + 'px';
        tooltip.style.top = (yCoord+positronix.top+Math.round(marTop)) + 'px';
        document.body.appendChild(tooltip);

        if (isFullScreen) {
          $('#tooltip').css('cssText', 'z-index: 2000000000000 !important' )
            .css('left', (xCoord + 20) + 'px')
            .css('top', yCoord + 'px');
        }
      }, 20);
    }

  };

  let hideTooltip = function() {
    if ($('#tooltip').length) {
      document.body.removeChild(document.getElementById('tooltip'));
    }
  };

  let placeHotspot = function(hspotName, atHorizontal, atVertical) {
    var updateCoordinate = function(inputClass, newVal) {
          var coordinateElement = '';

          function findCoordinateElement(els){
            var el = '';
            if (els.length > 0) {
              el = els.pop();
              if (hspotName === $(el).val()) {
                return el;
              } else {
                return findCoordinateElement(els);
              }
            } else {
              debug.error('placeHotspot: updateCoordinate: findCoordinateElement: Hotspot ' + hspotName + ' not found.');
              return false;
            }
          }

          coordinateElement = findCoordinateElement([].slice.call(document.getElementsByClassName('dialog-hotspot-name')));

          if (coordinateElement) {
            return $(coordinateElement).closest('.dialog-products-fieldset').find('input.' + inputClass).val(newVal).val();
          } else {
            return false;
          }
        },

        newX = updateCoordinate('dialog-hotspot-x-coordinate', Math.round(atHorizontal)),
        newY = updateCoordinate('dialog-hotspot-y-coordinate', Math.round(atVertical));

    // log and update the coordinates
    debug.log('Hotspot ' + hspotName
      + ' Coordinates are now (X: '
      + newX
      + ', Y: '
      + newY
      + ')');
  };

  function checkConfig(config) {
    if (!config || !config.tourProducts || !config.tpName || !config.sName) {
      debug.error('a proper config was not found. config passed was:', config);
      return false;
    } else {
      return config;
    }
  }

  return {

    init: function(passedSettings) {
      config = checkConfig(passedSettings);
      ads = check.exists('SniAds') ? context.getGlobal('SniAds') : false;
      mdManager = check.exists('mdManager') ? context.getGlobal('mdManager') : false;

      x; y; //weird thing to make linter pass...

      document.addEventListener('mousemove', function(e) {
        x = e.clientX;
        y = e.clientY;
      }, false);

      $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e) {
        isFullScreen = !isFullScreen;
      });

      //export needed methods to the global scope
      SNI.ProductOverlay = {
        showOverlay:    showOverlay,
        showTooltip:    showTooltip,
        hideTooltip:    hideTooltip,
        placeHotspot:   placeHotspot,
        overlayRestore: overlayRestore
      };
    }

  };

});
