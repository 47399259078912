SNI.Application.addModule('base-inline-gallery', function(context) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let gModule,
      el = context.getElement(),
      $el = $(el),
      detailsSelector = '[data-descriptions]',
      $RS,
      $RSContainer = $el.find('.slideshow-wrapper'),
      deviceType = context.getService('device-type'),
      debug = context.getService('logger').create('module.base-inline-gallery'),
      check = context.getService('check').new(debug),
      galleryManager = context.getService('gallery-manager'),
      defaults,
      config,
      fromResultsPage = false; // true: if user navigated to vote gallery from results page

  if (!check.jqueryPlugin('royalSlider')) return {};
  defaults = galleryManager.getDefaults();

  // Manage the gallery config: main config and Royal slider config
  function setGalleryConfig(oldSettings, newSettings) {
    let config = galleryManager.updateObject(oldSettings, newSettings);
    return galleryManager.setConfig(config);
  }

  // Waits and then preloads some images
  function cacheElements() {
    // add the set of images to a hidden queue
    let target = $('[data-slideshow-preload]');
    let images = galleryManager.getAllGalleries();
    let firstImages = images.map(function(el){
      return el.firstImage;
    });
    galleryManager.preloadImages(target, firstImages);
  }

  // Shared the existing code and moved to gallery manager
  function createCustomSliderEvents() {
    let settings = config.sliderMessages,
        proto = $.rsProto,
        mods = $.rsModules;
    $.extend(proto, {
      _addAfterSlideEvent: function() {

        let self = this,
            sliderEvents = {
              rsAfterSlideChange: settings.afterSlideName,
              rsBeforeAnimStart: settings.beforeAnimName,
              rsBeforeSizeSet: settings.beforeSizeName
            },
            key;

        for (key in sliderEvents) {
          let name = key,
              val = sliderEvents[name];

          self.ev.on(name, function(event) {
            context.broadcast(val, {
              event: event
            });
          });
        }

        self.ev.on('rsAfterContentSet', function(e, slideObject) {
          if (!self.sliderReady) {
            self.ev.trigger('rsSliderReady');
            context.broadcast(settings.afterContentSetName, {
              event: e,
              slideObject: slideObject
            });
            self.sliderReady = true;
          }
        });

        self.ev.on('rsTryingToAdvancePastLastSlide', function(event) {
          context.broadcast('gallery.lastSlideReached', {
            currentEvent: event
          });
        });
      }
    });
    mods.addAfterSlideEvent = proto._addAfterSlideEvent;
  }

  // Create the markup and update the numbers
  function createSlideNumbering() {
    galleryManager.createSlideNumbering($RSContainer);
    updateSlideNumber();
  }

  // run ad init
  function setUpAds() {
    galleryManager.setUpAds();
  }

  // Slider can start right away or it can start from the top carousel
  // init: true is set when its the first on-page carousel
  function startSlider(data, init) {
    let slides = init && !fromResultsPage ? data.images.slice(1) : data.images;
    let slidesMarkup = galleryManager.buildDesktopSlides(slides);
    debug.log('slidesMarkup: ' + slidesMarkup);
    let RSConfig = config.rsConfig;

    if (init) {
      debug.log('startSlider init');
      if (fromResultsPage) {
        // empty slider
        $RS = $RSContainer.empty().royalSlider(RSConfig).data('royalSlider');
      } else {
        $RS = $RSContainer.royalSlider(RSConfig).data('royalSlider');
      }
      slidesMarkup.forEach(function(slide){
        $RS.appendSlide($(slide));
      });


    } else {
      debug.log('startSlider NOT init');
      RSConfig.slides = slidesMarkup && slidesMarkup.length>0? slidesMarkup.slice().join('') :'';
      debug.log('RSConfig.slides ', RSConfig.slides);
      $RS = $RSContainer.data('royalSlider');
      $RS.destroy();
      $RS = $RSContainer.empty().royalSlider(RSConfig).data('royalSlider');
      setDetails(slides[0]);
      galleryManager.setActiveSlide(0);
      doProcessMetadata(true, data.images[0]);
      config.preventFurtherClicks = false;
    }
    context.broadcast('gallery.sliderInit', {
      slider: $RS
    });
  }

  // Re-uses existing overlay methods to build the descriptions
  // Shared code was moved to gallery manager
  function setDetails(data) {
    if (!data) return;
    let options = {
      $galleryElement: $el,
      descSel: detailsSelector,
      creditsSel: '[data-credit-target]',
      attrSel: '[data-attributions]',
      creditVoteLayout: true,
      data
    };
    galleryManager.buildSlideDesc(options);
  }

  // This is not active yet but we will need to update in order to allow
  // Auto move into a different slide
  function changeGallery(change) {
    // reset Royal slider with new slides
    if (change === 'next') {
      config.preventFurtherClicks = true;
      context.broadcast('stream-next-gallery',{type: 'desktop'});
      debug.log('Auto advancing to the next gallery');
    } else {
      debug.log('Prev gallery');
    }

  }

  // The slide change listener will call to run slide related change
  // Ads, MDM, descriptions
  function changeSlide() {
    let currentIndex = $RS.currSlideId;
    let update = galleryManager.changeSlide(currentIndex); // advance
    let {message, data}  = update;
    // Allow streaming to the next gallery
    if (message === 'gallery:next') {
      $el.find(config.nextArrowSelector).removeClass('rsArrowDisabled');
    }
    doProcessMetadata(false, data);
    setDetails(data);
    galleryManager.refreshAds();
  }

  // Update the slide numbers
  function updateSlideNumber() {
    // target the slide container and set the values
    $RSContainer.find('.rsCurr').text($RS.currSlideId + 1).end().find('.rsLength').text($RS.numSlides);
  }

  // Destroy the module
  function killItWithFire() {
    // $(document).unbind('keydown.photoGalleryModalKeyFilter');
    $(window).off('resize.viewer');
    $(config.pvSlideWrapCon).off('touchstart.gallery');
    $(config.pvSlideWrapCon).off('touchmove.gallery');
    $('.rsArrow').off('click.slidesend');
  }

  // read metadata and prepare it for analytics
  function doProcessMetadata(init, slideData) {
    debug.log('Do slide  or gallery analytics; update consecutive gallery');
    galleryManager.processMetadata(init, slideData);
  }

  // Uses the data.index to know which gallery to retrieve from gallery-manager
  function setUpNewSlider(data) {
    galleryManager.setCurrentGallery(data.index);
    let gallery = galleryManager.getGalleryData(data.index);
    debug.log('Set up new slider:', data, gallery);
    if (data.init) {
      // update slider config to show no fade in?
      cacheElements();
    }
    startSlider(gallery, data.init);
    createSlideNumbering();
    galleryManager.areWeThereYet($RS);
    //  resetAds();
  }

  // Listens to a variety of slider events
  // Not all events are in use but leaving here for now
  const messageHandlers = {
    'vote.carousel.slide.clicked': (data) => {
      debug.log('Reset Royal Slider');
      setUpNewSlider(data);
      if (!deviceType.isMobile) {
        galleryManager.refreshAds();
      }
    },
    // Triggered when navigating to vote gallery from results page
    'results.carousel.clicked': (data) => {
      debug.log('Display selected results gallery on Royal Slider');
      sessionStorage.removeItem('resultsSelectedGalId');
      let updatedConfig = galleryManager.updateObject(defaults, context.getConfig());
      config = setGalleryConfig(updatedConfig, { isMobile: deviceType.isMobile, isTablet: deviceType.isTablet });
      setUpAds();
      createCustomSliderEvents(context, config.sliderMessages, $.rsProto, $.rsModules);
      setUpNewSlider(data);
    },
    // 'gallery.sliderInit': () => {
    //   // fire off initial slide view
    //   // update gallery manager - reset when new gallery
    // },
    'gallery.afterSlide': () => {
      // Advance top carousel
      // get data from gallery manager;
      debug.log('Change slide!');
      changeSlide();
    },
    'gallery.beforeAnimation': () => {
      updateSlideNumber();
    },
    // 'gallery.beforeSizeSet': () => {
    //   // Not needed?
    // },
    // 'gallery.afterContentSet': () => {
    //   // Set pinterest button
    // },
    'gallery.lastSlideReached': () => {
      // gallery manager ready new gallery
      debug.log('Last Slide reached!!');
      if (config.preventFurtherClicks) {
        debug.log('Prevented further clicks while gallery advances');
      } else {
        changeGallery('next');
      }
    }
  };

  // Init the first slider
  function doLaunch(data) {
    //startSlider(context, galleryContainer, config.rsConfig); <<< ?????
    setUpNewSlider(data);
    //setUpAds();
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  gModule = {
    behaviors: ['lazy-load'], // No products or affiliate links here yet

    messages: Object.keys(messageHandlers),

    init: function() {
      // true: if user navigated to vote gallery from results page ('true' -- only for desktop)
      fromResultsPage = sessionStorage.getItem('resultsSelectedGalId') && sessionStorage.getItem('resultsSelectedGalId').length ? true : false;

      if (!fromResultsPage) {
        let updatedConfig = galleryManager.updateObject(defaults, context.getConfig());
        config = setGalleryConfig(updatedConfig, { isMobile: deviceType.isMobile, isTablet: deviceType.isTablet });
        setUpAds();
        createCustomSliderEvents(context, config.sliderMessages, $.rsProto, $.rsModules);
        // updateDataAttributes(context, $galleryElement);
        doLaunch({index: 0, init: true});
      }
    },

    destroy: function() {
      killItWithFire();
    },

    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'increment-slide':
          debug.log('Custom event handlers to change gallery');
          break;
        case 'decrement-slide':
          debug.log('Go to previous slide show??');
          break;
        default:
          break;
      }
    }
  };

  return gModule;
});
