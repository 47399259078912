SNI.Application.addModule('nutrition-info', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let debug = context.getService('logger').create('module.nutrition-info');

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  const defaults = {};

  let settings = Object.assign({}, defaults, context.getConfig());

  let init = function() {
    debug.log('Module started', settings);
  };

  return {
    behaviors: ['popover'],
    init
  }; //end return

});
