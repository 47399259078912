/**
 * @fileoverview Asset Navigation Module
 * @author Jonathan Kemp
 * @author Igor Alpert
 */

/*
 * Handles the disabling and re-enabling links in certain cases.
 * Adds the affix behavior to the module.
 */
SNI.Application.addModule('asset-navigation', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------


  const ASSET_NAVIGATION_PREV_ITEMS = 'ASSET_PREV';

  const $el   = $(context.getElement());
  const sS    = context.getGlobal('sessionStorage');
  const debug = context.getService('logger').create('module.asset-navigation');

  const defaults = {
    disabledClass: 'is-Disabled'
  };

  const {disabledClass, urls, path} = Object.assign({}, defaults, context.getConfig());

  let prevLinks = JSON.parse(sS.getItem(ASSET_NAVIGATION_PREV_ITEMS));


  if (!Array.isArray(prevLinks)) {
    prevLinks = [];
  }


  const buildLinks = ({urls = [], path = ''}) => {
    debug.log({urls, path});

    const previousUrl = $(prevLinks).get(-1);
    const nextUrl     = urls.find((url) => prevLinks.indexOf(url) === -1);

    let $previous = $el.find('[data-type="previous"]');
    let $next     = $el.find('[data-type="next"]');

    if (previousUrl && path !== previousUrl) { // Trying to avoid loops
      $previous.attr('href', previousUrl);
    } else if ($(urls).get(1)) {                  // In case we dont have prev link (most likely its first recipe we try to use 2nd item from feed as prev)
      $previous.attr('href', $(urls).get(1));
    } else {                                      // We dont have any links to use, just disable button
      $previous.addClass(disabledClass);
    }


    if (nextUrl) {
      $next.attr('href', nextUrl);
    } else {
      $next.addClass(disabledClass);
    }


  };


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    behaviors: ['affix'],

    onclick(event, element, type) {
      if ($(element).hasClass(disabledClass)) {
        event.preventDefault();
      }

      switch (type) {

        case 'previous':
          prevLinks.pop();
          break;

        // Before we go mark current page as 'seen'
        case 'next':
          if (prevLinks.indexOf(path) === -1) {
            prevLinks.push(path);
          }
          break;
      }

      sS.setItem(ASSET_NAVIGATION_PREV_ITEMS, JSON.stringify(prevLinks));

    },

    init() {
      buildLinks({urls, path});
    },

    buildLinks
  };
});
