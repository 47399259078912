// Replace tile content with new content from ajax call based on data-type in link

SNI.Application.addBehavior('invoke-tiles', context => {

  const debug = context.getService('logger').create('behavior.invoke-tiles');
  const device = context.getService('device-type');
  const startModules = context.getService('start-modules');
  const utility = context.getService('utility');
  let $element = $(context.getElement());
  let lazy = context.getService('lazy-load');
  let loadedTiles = [];

  function updateActiveState(selector){
    var targetElement = $(`[data-type=${selector}]`);

    targetElement.addClass('a-Tab--Active');
    targetElement.siblings().removeClass('a-Tab--Active');
  }

  function loadTilesToElement(url, container, targetElement, selector) {
    $.ajax(url).then(function(responseBody, code) {
      var addContainer;
      /**
       * If the content returns with an html tag that means something went wrong.
       * We're expecting to receive a fragment of html representing the new tiles
       * only.
       */
      if (code === 'success' && responseBody && !responseBody.includes('<html')) {
        debug.log('Load new tiles successful');

        // Append markup to the container
        container.find(targetElement).append(responseBody);
        
        // Toggle elements to show the group currently selected
        $element.find('.o-Recommendations__TileContainer').hide();
        $element.find(`.o-Recommendations__TileContainer--${selector}`).show();

        // If there are no recipe tiles for this selection, show 'not found' message
        if ($element.find(`.o-Recommendations__TileContainer--${selector} .m-MediaBlock`).length < 1) {
          $element.find(`.o-Recommendations__TileContainer--${selector}`).removeClass('l-Columns l-Columns--4up');
          $element.find(`.o-Recommendations__TileContainer--${selector}`).html('<p class="o-Recommendations__TileMessage"><span class="o-Recommendations__TileMessageContainer">Hmm… we couldn’t find any related recipes for that topic. <span class="o-Recommendations__TileMessageNewLine">Try a different topic above for more related recipes.</span></p>');
        }
        
        // Restart the module with new content
        setTimeout(() => {
          startModules.startAll($element.find('[data-module="multi-content-stream"]'));
        }, 250);
        // Make images load
        lazy.addImages({
          settings: {
            usePlaceholder: false
          },
          container: container
        });

      } else {
        debug.log('Load new tiles unsuccessful!');
        $element.find('.o-Recommendations__TileContainer').hide();
        addContainer = `<div class="o-Recommendations__TileContainer o-Recommendations__TileContainer--${selector}"><p class="o-Recommendations__TileMessage"><span class="o-Recommendations__TileMessageContainer">Hmm… we couldn’t find any related recipes for that topic. <span class="o-Recommendations__TileMessageNewLine">Try a different topic above for more related recipes.</span></p></div>`;
        container.find(targetElement).append(addContainer);

        $element.find(`.o-Recommendations__TileContainer--${selector}`).show();
      }
    }, function() {
      debug.log('Load tiles AJAX request failed');
      $element.find('.o-Recommendations__TileContainer').hide();
      var addContainer = `<div class="o-Recommendations__TileContainer o-Recommendations__TileContainer--${selector}"><p class="o-Recommendations__TileMessage"><span class="o-Recommendations__TileMessageContainer">Hmm… we couldn’t find any related recipes for that topic. <span class="o-Recommendations__TileMessageNewLine">Try a different topic above for more related recipes.</span></p></div>`;
      container.find(targetElement).append(addContainer);

      $element.find(`.o-Recommendations__TileContainer--${selector}`).show();
    });
  }

  return {   
    onclick: function(event, element, selector) {
      if ($(event.target).hasClass('a-Tab')) {
        event.preventDefault();

        const url = utility.buildUrlWithSelector(location, device.isMobile, selector);
        if (loadedTiles.indexOf(selector) === -1) {
          loadedTiles.push(selector);
          loadTilesToElement(url, $element, '.m-Content .m-ScrollContainer', selector);          
        } else {          
          $element.find('.o-Recommendations__TileContainer').hide();
          $element.find(`.o-Recommendations__TileContainer--${selector}`).show();
          
        }
        updateActiveState(selector);
      }
    }
  };
});
