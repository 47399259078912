SNI.Application.addModule('photo-gallery', function(context) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let module,
      galleryElement,
      $galleryElement,
      galleryContainer,
      galleryModal,
      $slideToolbar,
      slider,
      config,
      receivedId,
      deviceType,
      SniAds,
      mdManager,
      debug = context.getService('logger').create('module.photo-gallery'),
      check = context.getService('check').new(debug),
      pinSvc = context.getService('pinterest-button'),
      shopping = context.getService('track-shopping'),
      pinConfig = context.getConfig('social-pinterest'),
      galleryManager = context.getService('gallery-manager'),
      template,
      utility = context.getService('utility'),
      ads = context.getService('ads'),
      metadata,
      analytics,
      router,
      defaults;

  if (!check.jqueryPlugin('royalSlider')) return {};

  defaults = {
    galleryType: 'dynamic',
    galleryViewType: 'overlay',
    galleryPageTypes: {
      singleImage: false
    },
    galleryCount        : 0,
    numGalleriesViewed  : 0,
    galleryParent       : '',
    galleryURL          : '',
    galleryIndex        : '',
    totalPlace          : 0,
    nextGalleryIndex    : false,
    loadedState         : '',
    lastGallery         : false,
    viewedAllGalleries  : false,
    galleryContainerSelector: '.slideshow-wrapper',
    ajaxUrl            : '',
    ajaxStayAhead      : 3,
    ajaxFetchNumSlides : 10,
    ajaxPaging         : false,
    ajaxImagesPerPage  : 18,
    readyToFetchMore   : true,
    adLibLoaded: false,
    coldLoad: true,
    galleryAdSetup: {
      container: '#photo-gallery',
      dismiss_elts: '.rsArrowIcn',
      disable_elts: '',
      blockDelay: 10
    },
    hash: '',
    overlayBigboxName: '',
    overlayPhotoIntName: '',
    overlayPhotoIntMobileName: '',
    interstitialShownClass: '.interstitial-show',
    galleryLoadingClass: 'loading',
    galleryStartingPosition: 0,
    photoTraySelector: '.m-SlideContainer__m-PhotoTray',
    trayActionSelector: '.m-SlideContainer__a-Title',
    hiddenTrayClass: 'tray-hidden',
    visibleTrayClass: 'tray-showing',
    currentTrayVisiblity: 'tray-hidden',
    hoverRightClass: 'hoverRight',
    hoverLeftClass: 'hoverLeft',
    slideClassSelector: '.slide',
    rightDataType: 'increment-slide',
    leftDataType: 'decrement-slide',
    showTraySpan: '<span></span>',
    backNavPercent: 20,
    afterSlideName: 'gallery.afterSlide',
    beforeAnimName: 'gallery.beforeAnimation',
    beforeSizeName: 'gallery.beforeSizeSet',
    lastSlideName : 'gallery.lastSlide',
    afterContentSetName: 'gallery.afterContentSet',
    previousArrowSelector: '.rsArrowLeft',
    nextArrowSelector: '.rsArrowRight',
    hotspotElement: 'img.rsMainSlideImage',
    hotspotUrlName: 'hotspotUrl',
    originalGallery: 0,
    consecutiveGallery: 1,
    overLoop: false,
    voteModalId        : 'voteModalOnboarding',
    nextGallerySel     : '[data-type="open-next-gallery"]',
    nextGalleryLinkSel : '[data-next-gallery-link]',
    voteReadyBtnSel    : '[data-vote-ready-btn]',
    voteElement        : '[data-vote-gallery-thumb]',
    voteWrapper        : '[data-vote-module]',
    voteEnabled        : false,
    voteAllowed        : false,
    readyForNextVoteCat: true,
    resetScroll        : false,
    modalSelector      : 'photo-gallery',
    rsConfig: {
      imageAlignCenter     : false,
      globalCaption        : false,
      fadeinLoadedSlide    : false,
      arrowsNav            : true,
      arrowsNavAutoHide    : false,
      arrowsNavHideOnTouch : false,
      controlNavigation    : 'none',
      controlsInside       : true,
      imageScalePadding    : 0,
      transitionSpeed      : 0,
      transitionType       : 'fade',
      minSlideOffset       : 50,
      loop               : true,
      addActiveClass     : true,
      deeplinking: {
        change  :  false,
        enabled : true,
        prefix  : 'photo-'
      },
      fullscreen: {
        enabled: true,
        buttonFS: false,
        nativeFS: true
      },
      allowCSS3          : true,
      keyboardNavEnabled : false,
      numImagesToPreload : 2,
      navigateByClick    : false,
      imageScaleMode     : 'fit-if-smaller',
      sliderDrag         : false,
      preloaderHTML      : ''
    },
    newTemplate: false
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setGalleryConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function setGalleryPageTypes(currentContext, currentPageTypes) {
    let pages,
        updatedPages;

    pages = currentPageTypes;
    updatedPages = updateObject(pages, {
      singleImage: $('body').hasClass('singleImagePage')
    });

    currentContext.broadcast('gallerydataupdated', {
      galleryPageTypes: updatedPages
    });
  }

  function setPreloaderHTML(markup) {
    config.rsConfig.preloaderHTML = markup;
  }

  function getServices(currentContext) {
    deviceType = currentContext.getService('device-type');
    template = currentContext.getService('template');
    metadata = currentContext.getService('metadata');
    analytics = currentContext.getService('analytics');
    router = currentContext.getService('router');
    galleryModal = currentContext.getService('modal');
    debug.log('getServices: router: ', router);
  }

  function checkAdLibrary(currentContext, checkService) {
    currentContext.broadcast('gallerydataupdated', {
      adLibLoaded: checkService.exists(['SniAds.Gallery', 'SniAds.Event'])
    });
  }

  function setAdConfig(currentContext, adLibExists, galleryAdSetup, coldLoad) {
    if (adLibExists) {
      ads.adsGallery('init', galleryAdSetup);
      if (!coldLoad) {
        ads.adsGallery['reset'];
      }
      currentContext.broadcast('gallerydataupdated', {
        coldLoad: false
      });
    }
  }

  function checkStartSlide(currentContext, element, freshStart = 0) {
    let startPosition;
    if (typeof element.data('startSlide') !== 'undefined' || freshStart > 0) {
      startPosition = (typeof element.data('startSlide') !== 'undefined') ? element.data('startSlide') : freshStart;
      currentContext.broadcast('gallerydataupdated', {
        galleryStartingPosition: startPosition
      });
    }
  }

  function updateRoyalSliderConfig(sliderSettings, data) {
    let updatedRSConfig,
        newSettings;

    newSettings = data;
    updatedRSConfig = updateObject(sliderSettings, newSettings);

    return updatedRSConfig;
  }

  function setSliderConfig(currentContext, sliderSettings, isMobile, isTablet, pos) {
    let settings;

    settings = {
      startSlideId: pos,
      autoHeight: isMobile
    };

    if (isMobile || isTablet) {
      settings = updateObject(settings, {
        transitionSpeed: 300,
        sliderTouch: true,
        transitionType: 'move'
      });
    }

    if (config.galleryType === 'curated') {
      if (config.overLoop) {
        settings = updateObject(settings, {
          loop: true
        });
      } else {
        settings = updateObject(settings, {
          loop: false
        });
      }
    }

    // need to add GAC preloader check

    currentContext.broadcast('gallerydataupdated', {
      rsConfig: updateRoyalSliderConfig(sliderSettings, settings)
    });
  }

  function cacheElements(elementContext, containerSelector) {
    galleryElement = elementContext.getElement();
    $galleryElement = $(galleryElement);
    galleryContainer = $galleryElement.find(containerSelector);

    if (typeof galleryModal === 'undefined') {
      galleryModal = false;
    }

    if (typeof slider === 'undefined') {
      slider = false;
    }

    if (typeof $slideToolbar === 'undefined') {
      $slideToolbar = false;
    }
  }

  function prepareGallery(container, loading) {
    container.removeClass(loading);
  }

  function createCustomSliderEvents(currentContext, settings, proto, mods) {
    $.extend(proto, {
      _addAfterSlideEvent: function() {

        let self = this,
            sliderEvents = {
              rsAfterSlideChange: settings.afterSlideName,
              rsBeforeAnimStart: settings.beforeAnimName,
              rsBeforeSizeSet: settings.beforeSizeName,
              rsTryingToAdvancePastLastSlide: settings.lastSlideName
            },
            key;

        for (key in sliderEvents) {
          let name = key,
              val = sliderEvents[name];

          self.ev.on(name, function(event) {
            currentContext.broadcast(val, {
              event: event
            });
          });
        }

        self.ev.on('rsAfterContentSet', function(e, slideObject) {
          if (!self.sliderReady) {
            self.ev.trigger('rsSliderReady');
            currentContext.broadcast(settings.afterContentSetName, {
              event: e,
              slideObject: slideObject
            });
            self.sliderReady = true;
          }
        });
      }
    });
    mods.addAfterSlideEvent = proto._addAfterSlideEvent;
  }

  function createCustomPrevNextHandling(currentSlider) {
    currentSlider.lastSlideId = -1;

    $(document).on('keydown.photoGalleryModalKeyFilter', function(e) {
      if (!$('#voteModalOnboarding').is(':visible')) {
        switch (e.which) {
          case 37:
            currentSlider.prev();
            e.preventDefault();
            break;
          case 39:
            currentSlider.next();
            e.preventDefault();
            break;
        }
      }
    });
  }

  function goNextClick(container, threshold, clickX) {
    let sliderWidth = container.width(),
        clickPercentage = (clickX / sliderWidth) * 100;

    if (clickPercentage > threshold) {
      return true;
    } else {
      return false;
    }
  }

  function updateConsecutiveGallery(currentContext, metadataService, data) {
    metadataService.setGalleryData(data);
    currentContext.broadcast('gallerydataupdated', data);
  }

  function incrementOriginalGallery(currentContext, metadataService, settings) {
    settings.originalGallery++;
    updateConsecutiveGallery(currentContext, metadataService, {
      originalGallery: settings.originalGallery,
      consecutiveGallery: settings.consecutiveGallery
    });
  }

  function startSlider(currentContext, container, settings) {
    slider = container.royalSlider(settings).data('royalSlider');
    currentContext.broadcast('gallery.sliderInit', {
      slider: slider
    });
  }

  function removeGalleryModal(element, currentContext, galleryID) {
    debug.log('removeGalleryModal: element: ', element);
    $(element).removeClass('full-overlay-modal-open');

    if (deviceType.isMobile) {
      $('body, html').removeAttr('style');
    }

    currentContext.broadcast('galleryModal.closed', {
      origin: galleryID
    });
  }

  function createSlideToolbar(isMobile, container) {
    if (isMobile) {
      return $('<div>').addClass('slide-toolbar').prependTo(container);
    } else {
      return false;
    }
  }

  function setTrayVisibility(currentContext, element, hidden, visible, isMobile) {
    if (!isMobile) {
      let tray = $(element),
          hide = hidden,
          show = visible,
          newState;

      tray.toggleClass(`${hide} ${show}`);

      newState = tray.hasClass(show) ? show : hide;

      currentContext.broadcast('gallerydataupdated', {
        currentTrayVisiblity: newState
      });
    }
  }

  function maintainTrayVisibility(element, hidden, visible, state, isMobile) {
    if (!isMobile) {
      $(element).toggleClass(`${hidden} ${visible}`, false).addClass(state);
    }
  }

  function addSlideNumber(currentSlider, target) {
    if (target && target.length>0) {
      target.find('.rsCurr').text(currentSlider.currSlideId + 1);
      target.find('.rsLength').text(currentSlider.numSlides);
    }
  }

  function createSlideNumbering(type, templateService, currentSlider, tray) {
    if (type === 'curated') {
      let countMarkup = templateService.slideCount();
      let target = config.newTemplate ? $galleryElement.find(tray) : currentSlider.currSlide.content.find(tray);
      $(countMarkup).appendTo(target);
      addSlideNumber(currentSlider, target);
    }
  }

  function getSlideTitle($slide) {
    let $ele = $slide.find('[data-og-title]');
    return $ele.text();
  }

  function processSlideMetadata($slide, metadataService, analyticsService) {
    let mdmData = $slide.data('mdm') || false,
        behavioralInteraction,
        uniqueIDPrime,
        earl;

    if (mdManager) {
      mdManager.setParameter('CurrentRoom', getSlideTitle($slide));
    }

    uniqueIDPrime = metadataService.updateConsecutiveViewCount(mdmData);
    behavioralInteraction = metadataService.getMediaStreamNumber(mdmData, 1);

    if (config.voteEnabled) {
      metadataService.updateFromString({Url: config.galleryURL});
    }

    if (mdmData) {
      metadataService.updateFromJSON(mdmData);
      if (uniqueIDPrime) {
        metadataService.updateFromJSON({Overlay_UniqueId: uniqueIDPrime});
      }
      if (behavioralInteraction){
        metadataService.updateFromJSON({behavioralInteraction: behavioralInteraction});
      }
    } else {
      debug.log('Slide MDM data missing');
    }

    //Add the #hash if one is defined (e.g. #comparison)
    if (mdManager && typeof config.hash !== 'undefined' && config.hash.length) {
      earl = mdManager.getParameterString('Url');
      //Remove any previous hash
      if (earl.includes('#')) {
        earl = earl.substring(0, earl.indexOf('#'));
      }
      earl = `${earl}${config.hash}`;
      metadataService.updateFromJSON({'Url':earl});
    }

    analyticsService.callDynamicPageview();
  }

  function refreshAds(currentContext, isMobile, adLibLoaded, container, bigboxName, templateService) {
    if (!isMobile && adLibLoaded) {
      let adWrapper = config.newTemplate ? $galleryElement.find('[data-target-adcontainer]') : container.closest('[data-module]').find('.bigbox-ad'),
          $overBB;
      if (bigboxName === '') {
        if (!config.newTemplate) adWrapper.append(templateService.overlayBigbox());
        bigboxName = SniAds.appendSlot('overlay_bigbox', 'dfp_bigbox');
        currentContext.broadcast('gallerydataupdated', {
          overlayBigboxName: bigboxName
        });
        ads.adsGallery('setSyncSlot', bigboxName);
      } else {
        $overBB = $('#overlay_bigbox').show();

        if (!adWrapper.has('#overlay_bigbox')){
          $overBB.appendTo(adWrapper);
        } else {
          ads.adsGallery('next');
        }
      }
    }
  }

  function createAdWrapper(adLib, container, templateService) {
    debug.log('Creating ad wrapper');
    let adContainer = config.newTemplate? templateService.overlayBigbox() : templateService.adWrapper();
    let adText = templateService.adText();
    if (adLib) {
      if (!config.newTemplate) {
        container.after(adContainer);
      } else {
        let bb = container.find('#overlay_bigbox');
        if (bb.length === 0) {
          container.find('.bigbox-ad').append([adContainer, adText]);
        }
      }
    }
  }

  function setInterstitialConfig(settings) {
    if (settings.adLibLoaded) {
      let currentSettings,
          galleryType;

      galleryType = settings.galleryViewType;

      switch (galleryType) {
        case 'overlay':
          currentSettings = {
            desktop: {
              wrapper : 'photo_interstitial_wrapper',
              slot    : 'dfp_photo_interstitial',
              slotInst: 'overlayPhotoIntName',
              markup  : 'interstitialWrapper'
            },
            mobile: {
              wrapper : 'photo_interstitial_wrapper_mobile',
              slot    : 'dfp_smartphone_interstitial',
              slotInst: 'overlayPhotoIntMobileName',
              markup  : 'interstitialWrapperMobile'
            }
          };
          break;
      }

      context.broadcast('gallerydataupdated', {
        interstitialSettings: settings.isMobile ? currentSettings.mobile : currentSettings.desktop
      });
    }
  }

  function createInterstitialContainer(settings, container, templateService) {
    if (settings.adLibLoaded) {

      if (settings.interstitialSettings.slotInst === '' || $('#' + settings.interstitialSettings.slotInst).length === 0) {
        container.before(templateService[settings.interstitialSettings.markup]());
        if (settings.isTablet || settings.isMobile) {
          container.before(templateService.interstitialMobileCloseBtn());
        }

        updateInterstitialSlotInstance(settings);

        ads.adsGallery('setInterstitialSlot',settings[settings.interstitialSettings.slotInst]);
      } else {
        $( '#' + settings.interstitialSettings.slotInst + ', #' + settings.interstitialSettings.wrapper ).show();
        ads.adsGallery('next');
      }

      createInterstitialOrientationHandler(settings, container);
    }
  }

  function updateInterstitialSlotInstance(settings) {
    if (settings.adLibLoaded) {
      let updatedSlotInst,
          newInterstitialSettings;

      updatedSlotInst = SniAds.appendSlot(settings.interstitialSettings.wrapper, settings.interstitialSettings.slot, true);

      newInterstitialSettings = updateObject(settings.interstitialSettings, {
        slotInst: updatedSlotInst
      });

      context.broadcast('gallerydataupdated', {
        interstitialSettings: newInterstitialSettings
      });
    }
  }

  function listenForOrientationChange(settings, container) {
    let orientationHandler = function() {
      if (isInterstitialShown(settings, container) && settings.overlayPhotoIntMobileName) {
        ads.adsGallery('next');
      }
    };
    return orientationHandler;
  }

  function createInterstitialOrientationHandler(settings, container) {
    let handler = listenForOrientationChange(settings, container);
    if (settings.adLibLoaded && settings.isMobile && window.addEventListener && window.removeEventListener) {
      window.addEventListener('orientationchange', handler , false);
      context.broadcast('gallerydataupdated', {
        interstitialOrientationHandler: handler
      });
    }
  }

  function getInterstitial(interstitialShown, container) {
    if (interstitialShown) {
      return container.closest(interstitialShown);
    }
  }

  function isInterstitialShown(settings, container) {
    let intShown = settings.interstitialShownClass;
    return getInterstitial(intShown, container).length > 0;
  }

  function handleInterstitial(settings, currentSlider, container, checkService) {
    if (settings.adLibLoaded) {

      if (isInterstitialShown(settings, container) && (currentSlider.currSlideId === (currentSlider.numSlides-1) || currentSlider.currSlideId !== 0)) {
        let arrows = container.find('.rsArrow');
        arrows.addClass('force-show').on('click.slidesend', function(e) {
          arrows.removeClass('force-show').off('click.slidesend');
          closeInterstitial(settings, container, checkService);
          return false;
        });
      }

    }
  }

  function closeInterstitial(settings, container, checkService) {
    if (settings.adLibLoaded) {
      let interstitial,
          interSlot,
          interstitialBlurPrevent;

      if ( $('#ad-gallery-control').length > 0 && $('#ad-gallery-control').attr('rel') === 'gallery-blocked' ){
        return false;
      }

      interstitial = getInterstitial(settings.interstitialShownClass, container);
      if (interstitial.hasClass('interstitial-show')) {
        interstitial.removeClass('interstitial-show');

        if (checkService.exists('googletag')) {
          interSlot = SniAds.getDefinedSlots()[SniAds.Gallery.getConfigData().interstitialSlot];
          context.getGlobal('googletag').pubads().clear([interSlot]);
        }

        interstitialBlurPrevent = setInterval(function() {
          if (container.hasClass('m-NextAsset--blur') || container.hasClass('slider-blur')) {
            clearInterval(interstitialBlurPrevent);
            container.removeClass('m-NextAsset--blur slider-blur');
          }
        }, 10);

        return true;
      }
      return false;
    }
  }

  function updateShopElement(currentContext, currentSlider) {
    currentContext.broadcast('shopElementChanged', {
      newElement: currentSlider.currSlide.content
    });
  }

  function updateDescriptions(currentSlider) {
    /*
      If we are on a new template on overlay
      Then get JSON data for descriptions and load it to the DOM
    */
    if (!config.newTemplate) return false;
    let $slide = currentSlider.currSlide.content;
    let options;
    let data = utility.getElementJSON($slide);

    options = {
      $galleryElement,
      data,
      descSel: '[data-overlay-descriptions]',
      creditsSel: '[data-credit-target]',
      attrSel: '[data-overlay-attributions]'
    };

    galleryManager.buildSlideDesc(options);
  }

  function setReceivedId(transmitter) {
    receivedId = transmitter;
  }

  function traverseSlideshow(container, settings, pageX, currentSlider, checkService) {
    let ad = closeInterstitial(settings, container, checkService);

    if (!settings.isMobile && !ad) {
      if (goNextClick(container, settings.backNavPercent, pageX)) {
        currentSlider.next();
      } else {
        currentSlider.prev();
      }
    }
  }

  function delegateArrowEvents(container, leftSelector, leftType, rightSelector, rightType) {
    let left = container.find(leftSelector),
        right = container.find(rightSelector);

    if (left.length && right.length) {
      left.attr('data-type', leftType);
      right.attr('data-type', rightType);
    }
  }

  function setArrowHoverState(container, leftClass, rightClass, hoverRight) {
    if (hoverRight) {
      container.addClass(rightClass).removeClass(leftClass);
    } else {
      container.addClass(leftClass).removeClass(rightClass);
    }
  }

  function removeArrowHoverState(container, left, right) {
    container.removeClass(left).removeClass(right);
  }

  function getAdditionalContent(currentContext, ajaxUrl, paging, currentAjaxPage, currentSlider, ajaxFetchNumSlides, slideClass) {
    let finalAjaxUrl = ajaxUrl,
        countOfHTML,
        lastHTML,
        nextPage,
        $slideData,
        $voteSlides;

    if (finalAjaxUrl !== '') {
      countOfHTML = finalAjaxUrl.match(/.html/g) || 0;

      if (countOfHTML.length > 1) {
        lastHTML = finalAjaxUrl.lastIndexOf('.html');
        finalAjaxUrl = finalAjaxUrl.substring(0, lastHTML);
      }

      if (!config.voteEnabled) {
        if (paging) {
          nextPage = ++currentAjaxPage;

          currentContext.broadcast('gallerydataupdated', {
            ajaxPageWeAreOn: nextPage
          });

          finalAjaxUrl = finalAjaxUrl.substring(0, finalAjaxUrl.lastIndexOf('/p/'));
          finalAjaxUrl += /p/ + nextPage;
        } else {
          finalAjaxUrl += '/o/' + currentSlider.numSlides + '/n/' + ajaxFetchNumSlides;
        }
        finalAjaxUrl = finalAjaxUrl + '.html';
      }

      if (check.isLocalhost() || check.isAuthor()) {
        finalAjaxUrl += '?wcmmode=disabled';
      }

      //if on mobile author
      if (deviceType.isMobile && check.isAuthor()) {
        finalAjaxUrl = finalAjaxUrl.replace(/\.slideshow\.html/, '.mobile.slideshow.html');
        finalAjaxUrl = finalAjaxUrl.replace(/\.galleryoverlay\.html/, '.mobile.galleryoverlay.html');
      }

      currentContext.broadcast('gallerydataupdated', {
        readyToFetchMore: false
      });

      debug.log('getAdditionalContent: finalAjaxUrl: ', finalAjaxUrl);

      $.get(finalAjaxUrl, function(data) {
        debug.log('getAdditionalContent: data: ', data);
        $slideData = $('<div>').append(data);
        debug.log('getAdditionalContent: $slideData: ', $slideData, config.galleryContainerSelector, $slideData.find(config.galleryContainerSelector));

        if (config.voteEnabled) {
          $voteSlides = $('<div>').append($slideData.find(config.galleryContainerSelector));
          debug.log('getAdditionalContent: $voteSlides: ', $voteSlides);
          $slideData = $voteSlides;
        }
        appendSlideData($slideData, currentSlider, slideClass, currentContext);
      });
    }
  }

  function appendSlideData($data, currentSlider, newContentClass, currentContext) {
    debug.log('appendSlideData: ', {
      $data: $data,
      currentSlide: currentSlider,
      newContentClass: newContentClass,
      currentContext: currentContext
    });
    debug.log('appendSlideData: newContent: ', $data.find(newContentClass));
    $data.find(newContentClass).each(function() {
      debug.log('appendSlideData: new slide: ', this);
      currentSlider.appendSlide(this);
      currentContext.broadcast('contentUpdated', {
        newContent: this
      });
    });
  }

  function setupAjaxHandler(type, currentSlider, currentContext, stayAheadAmount, readyToFetch) {
    if (type === 'dynamic') {
      let currentSlide = currentSlider.currSlideId + 1,
          numberOfSlides = currentSlider.numSlides;

      if (numberOfSlides - currentSlide <= stayAheadAmount) {
        if (readyToFetch) {
          currentContext.broadcast('gallery.loadingAdditionalContent');
        }
      } else {
        currentContext.broadcast('gallerydataupdated', {
          readyToFetchMore: true
        });
      }
    }
  }

  function updateCurrentPhotoSearchPage(currentContext, data) {
    currentContext.broadcast('gallerydataupdated', {
      currentSearchResultsPage: data.currentSearchResultsPage
    });
  }

  function setAjaxConfig(currentContext, ajaxUrl, paging, isMobile, searchPage) {
    let url,
        page;

    if (paging) {
      if (isMobile) {
        page = searchPage || 1;
        currentContext.broadcast('gallerydataupdated', {
          ajaxPageWeAreOn: page
        });
      } else {
        url = ajaxUrl || window.location.href;
        page = url.substring(url.lastIndexOf('/p/')).split('/p/')[1] || '';
        page = page.split('?')[0];
        if (typeof(page) === 'undefined' || typeof parseInt(page) !== 'number' || page === '') {
          page = 1;
        }
        currentContext.broadcast('gallerydataupdated', {
          ajaxPageWeAreOn: page
        });
      }
    }
  }

  function getHotspotInstance(currentContext, currentUrl, currentImg, hotspotElement, defaultUrl, single) {
    let img,
        hotspotUrl,
        elementUrl,
        deferredHotspots;

    img = $(currentImg);

    if (!img.data(hotspotElement)) {
      hotspotUrl = currentUrl;
      deferredHotspots = $.Deferred();
      img.data(hotspotElement, deferredHotspots.promise());
      elementUrl = img.attr('src') || '';
      elementUrl = elementUrl.replace(/\.rend.*/, '');
      currentContext.broadcast('elementUpdated',{
        element: img,
        url: hotspotUrl || defaultUrl,
        imageUrl: elementUrl,
        hotspotShowAll: !single,
        initDeferred: deferredHotspots
      });
    }
  }

  function killItWithFire() {
    $('#overlay_bigbox, #photo_interstitial_wrapper').hide().appendTo('body');
    if (config.interstitialOrientationHandler) {
      window.removeEventListener('orientationchange', config.interstitialOrientationHandler);
    }
    $(document).off('keydown.photoGalleryModalKeyFilter');
    $(config.voteReadyBtnSel).off('click.vote-link');
    $(config.nextGalleryLinkSel).off('click.next-gallery-plus-vote');
    $('.nextCat').off('click.vote-link');
    $('[data-type="modal-close"]').off('click.onboard');
    $(document).off('keydown.vote.nextCategory');
    galleryContainer.off('click.vote.nextCategory');
    closeInterstitial(config, galleryContainer, check);
    galleryContainer.find('.rsArrow').off();
    galleryContainer.off();
    slider.destroy();
  }

  function extractData(content) {
    let src = $(content).find('img.rsMainSlideImage').attr('src');
    return {
      imageURL: src,
      element: $(content)
    };
  }

  function setupVote() {
    if ($('body').hasClass('votePage')) {
      config.voteEnabled = true;
      debug.log('setupVote: config.voteElement: ', config.voteElement);
      config.galleryCount = $(config.voteElement).length;
      config.galleryIndex = $galleryElement.data('voteIndex');
      config.galleryParent = $(config.voteElement + '[data-vote-index="' + config.galleryIndex + '"]');
      debug.log('setupVote: galleryParent: ', $(config.voteElement), config.galleryCount, config.galleryParent);
      config.galleryURL = config.galleryParent.data('galleryUrl');
      // config.nextGalleryIndex = config.galleryParent.data('galleryLast') ? 0 : config.galleryIndex+1;
      if ($(config.voteWrapper).filter('[data-vote-voting-allowed]').length) {
        config.voteAllowed = true;
        config.nextGalleryTimeout = 5000;
      }
      if (check.supports('localStorage')) {
        config.rsConfig.loop = JSON.parse(localStorage.getItem('voteLoop'));
        config.loadedState = localStorage.getItem('loadedState');
      }
      if ($(config.voteWrapper).data('nextCategory')) {
        config.rsConfig.loop = false;
      }
      if (config.loadedState === 'vote.ballotState') {
        config.rsConfig.loop = true;
      }
      if (!config.voteAllowed && !$(config.voteWrapper).data('nextCategory')) {
        config.rsConfig.loop = true;
      }
      config.suppressNextGalleryEvent = config.rsConfig.loop;
    }
  }

  function checkAllGalleries() {
    if (config.voteEnabled) {
      $(config.voteElement).each(function() {
        if ($(this).attr('data-gallery-viewed') !== 'true') {
          config.totalPlace++;
          config.nextGalleryIndex = $(this).data('voteIndex');
          return false;
        }
      });
      if (config.totalPlace === $(config.voteElement).length) {
        config.viewedAllGalleries = true;
      }
    }
  }

  function nextVoteGallery(data) {
    debug.log('nextVoteGallery: data: ', data);
    if (data.$currentParent.attr('data-gallery-viewed') !== 'true') {
      config.numGalleriesViewed++;
      data.$currentParent.data('galleryViewed', true);
      data.$currentParent.attr('data-gallery-viewed', true);
    }
    checkAllGalleries();
    if (config.viewedAllGalleries) {
      context.broadcast('vote.nextCategory', {
        nextCat: data.nextCatUrl
      });
    } else {
      $(config.voteElement + '[data-vote-index="' + data.currentIndex + '"]').attr('data-gallery-viewed', true);
      if (config.ajaxUrl !== $($(config.voteElement + '[data-vote-index="' + config.nextGalleryIndex + '"]')[0]).data('slideshowUrl')) {
        config.ajaxUrl = $($(config.voteElement + '[data-vote-index="' + config.nextGalleryIndex + '"]')[0]).data('slideshowUrl');
        debug.log('nextVoteGallery: loading more content');
        getAdditionalContent(context, config.ajaxUrl, config.ajaxPaging, config.ajaxPageWeAreOn, slider, config.ajaxFetchNumSlides, config.slideClassSelector);
        config.galleryIndex = config.nextGalleryIndex;
        config.galleryParent = $(config.voteElement + '[data-vote-index="' + config.galleryIndex + '"]');
        config.galleryURL = config.galleryParent.data('galleryUrl');
      } else {
        config.viewedAllGalleries = true;
        context.broadcast('vote.nextCategory', {
          nextCat: data.nextCatUrl
        });
      }
    }
  }

  function searchJSONArray(arr, key, val) {
    let $jsonArray = arr;

    for (let i=0; i<$jsonArray.length; i++) {
      if ($jsonArray[i][key] === val) {
        return $jsonArray[i];
      }
    }

    return false;
  }

  function nextVoteCategory(data) {
    let votes = localStorage.getItem('votes') ? JSON.parse(localStorage.getItem('votes')) : null,
        dupVote = votes !== null ? searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false,
        headline = $('.assetTitle').find('.headline').text(),
        message,
        endFrame;

    if (config.voteEnabled && config.voteAllowed && (votes === null || !dupVote)) {
      message = 'Choose Your Favorite';
    } else {
      message = 'Continue to Next Category';
    }
    endFrame = template.voteEndframe(headline, message);
    if (!config.voteAllowed && !$(config.voteWrapper).data('nextCategory')) {
      config.rsConfig.loop = true;
    } else {
      slider.appendSlide(endFrame);
      if (config.voteEnabled && config.voteAllowed && (votes === null || !dupVote)) {
        context.broadcast('vote.ballotState');
        galleryContainer.on('click.vote.nextCategory', function(e) {
          e.preventDefault();
          e.stopPropagation();
          galleryModal.close(config.modalSelector);
          removeGalleryModal('body', context, receivedId);
        });
      } else if (config.voteEnabled && config.voteAllowed && dupVote) {
        galleryContainer.on('click.vote.nextCategory', function(e) {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = $(config.voteWrapper).data('nextCategory');
        });
      }
    }
  }

  function votingModal() {
    if (check.supports('localStorage') && config.voteAllowed) {
      if (!deviceType.isMobile) {
        if (!localStorage.getItem('voteModalShown') || localStorage.getItem('voteModalAlwaysShow')) {
          let onboardMarkup = template.modal({
            id: config.voteModalId,
            heading: 'Please Note',
            body: 'Be sure to view all nominees before casting your vote.',
            actionText: 'Ok, Let\'s Start'
          });
          if (onboardMarkup) {
            $($.parseHTML(onboardMarkup)).appendTo(`#${config.modalSelector}`);
            localStorage.setItem('voteModalShown', true);

            $('#' + config.voteModalId).find('[data-button-primary], [data-type="modal-close"]').on('click.onboard', function(e) {
              $('#' + config.voteModalId).remove();
              e.preventDefault();
            });
          } else {
            debug.error('There was a problem fetching the onboarding template markup.');
          }
        }
      }
    }
  }

  function removeNextGalleryLink() {
    galleryContainer.find(config.nextGallerySel).remove();
  }

  function bindNextClickEvents() {
    $(config.voteReadyBtnSel).on('click.vote-link', function(e) {
      e.preventDefault();
      e.stopPropagation();
      let votes = localStorage.getItem('votes') ? JSON.parse(localStorage.getItem('votes')) : null,
          dupVote = votes ? searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false;


      if (config.voteEnabled && config.voteAllowed && (votes === null || !dupVote)) {
        context.broadcast('vote.ballotState');
      }
      galleryModal.close(config.modalSelector);
      removeGalleryModal('body', context, receivedId);
    });
    $(config.nextGalleryLinkSel).on('click.next-gallery-plus-vote', function(e) {
      e.preventDefault();
    });
  }

  function _searchJSONArray(arr, key, val) {
    var $jsonArray = arr;
    for (var i=0; i<$jsonArray.length; i++) {
      if ($jsonArray[i][key] === val) {
        return $jsonArray[i];
      }
    }
    return false;
  }

  function addNextGalleryLink() {
    debug.log('addNextGalleryLink: 3');
    if (isInterstitialShown(config, galleryContainer)) {
      return false;
    }

    let isVoting = config.voteEnabled,
        $galleryOverflow = galleryContainer.find('.rsOverflow'),
        $currentGalleryParent,
        currentGallery,
        currentGalleryCollectionIndex,
        nextGalleryCollectionIndex,
        galleryCollectionTotal,
        $nextGalleryParent,
        nextGalleryTitle,
        nextGalleryUrl,
        nextGalleryLinkText = 'Next';

    debug.log(currentGallery, currentGalleryCollectionIndex, galleryCollectionTotal);

    if (isVoting) {
      debug.log('addNextGalleryLink: 3: a');
      $currentGalleryParent = config.galleryParent,
      currentGallery = (isVoting ? $currentGalleryParent.data('slideshowUrl') : window.location.href),
      currentGalleryCollectionIndex = $currentGalleryParent.data('voteIndex'),
      nextGalleryCollectionIndex = config.nextGalleryIndex,
      galleryCollectionTotal = $(config.voteElement).length;
      config.lastGallery = $currentGalleryParent.data('galleryLast');
      if (!config.viewedAllGalleries) {
        debug.log('addNextGalleryLink: 3: b');
        $nextGalleryParent = $(config.voteElement + '[data-vote-index="' + nextGalleryCollectionIndex + '"]');
        nextGalleryTitle = $nextGalleryParent.data('galleryTitle');
        nextGalleryUrl = $nextGalleryParent.data('slideshowUrl');
      }
      if (!nextGalleryTitle) {
        debug.log('addNextGalleryLink: 3: c');
        config.suppressNextGalleryEvent = true;
        return;
      } else {
        debug.log('addNextGalleryLink: 3: d');

        slider.slider.removeClass('m-NextAsset--blur');
        let votes = localStorage.getItem('votes') ? JSON.parse(localStorage.getItem('votes')) : null,
            dupVote = votes ? _searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false;

        $galleryOverflow.append(template.nextGalleryModal({
          headline      : nextGalleryTitle,
          link          : nextGalleryUrl,
          subHeadline   : nextGalleryLinkText,
          voting        : (config.voteEnabled && config.voteAllowed && (votes === null || !dupVote))
        }));

        bindNextClickEvents();
      }
    }
  }

  function prepNextVoteGallery() {
    if (config.voteEnabled) {
      debug.log('addNextGalleryLink: 1');
      let $currentGalleryParent,
          voteGalleryCollectionIndex,
          voteGalleryCollectionTotal,
          voteNextCategoryUrl;

      $currentGalleryParent = config.galleryParent,
      voteGalleryCollectionIndex = $currentGalleryParent.data('voteIndex'),
      voteGalleryCollectionTotal = config.galleryCount,
      voteNextCategoryUrl = $currentGalleryParent.parents(config.voteWrapper).data('nextCategory');

      config.lastGallery = $currentGalleryParent.data('galleryLast');
      config.galleryURL = $currentGalleryParent.data('galleryUrl');

      if (isInterstitialShown(config, galleryContainer)) {
        closeInterstitial(config, galleryContainer, check);
        slider.remove(slider.currSlideId);
        context.broadcast('vote.nextGallery', {
          $currentParent  : $currentGalleryParent,
          nextCatUrl      : voteNextCategoryUrl,
          totalGalleries  : voteGalleryCollectionTotal,
          currentIndex    : voteGalleryCollectionIndex,
          nextIndex       : voteGalleryCollectionIndex + 1
        });
        addNextGalleryLink();
        return false;
      } else {
        if (!config.voteEnabled) {
          slider.ev.off('rsTryingToAdvancePastLastSlide');
        }
      }
      config.inProgressOfLastSlideAction = config.voteEnabled ? false : true;

      if (config.suppressNextGalleryEvent) {
        return false;
      }

      if (config.voteEnabled) {
        context.broadcast('vote.nextGallery', {
          $currentParent  : $currentGalleryParent,
          nextCatUrl      : voteNextCategoryUrl,
          totalGalleries  : voteGalleryCollectionTotal,
          currentIndex    : voteGalleryCollectionIndex,
          nextIndex       : voteGalleryCollectionIndex + 1
        });
        removeNextGalleryLink();
      }

      if ($(config.nextGalleryLinkSel).length) {
        $(config.nextGallerySel).html(template.loadingHTML());
        slider.slider.removeClass('m-NextAsset--blur');
      }
    }
  }

  function voteAfterSlide() {
    if (config.voteEnabled) {
      debug.log('addNextGalleryLink: 2');
      if ((slider.currSlideId + 1) === slider.numSlides && !config.rsConfig.loop) {
        $('.rsArrowRight.rsArrowDisabled').removeClass('rsArrowDisabled');
        let $currentGalleryParent = config.galleryParent,
            voteGalleryCollectionIndex = $currentGalleryParent.data('voteIndex'),
            voteGalleryCollectionTotal = config.galleryCount,
            voteNextCategoryUrl = $currentGalleryParent.parents(config.voteWrapper).data('nextCategory');

        config.lastGallery = $currentGalleryParent.data('galleryLast');
        config.galleryURL = $currentGalleryParent.data('galleryUrl');
        context.broadcast('vote.nextGallery', {
          $currentParent  : $currentGalleryParent,
          nextCatUrl      : voteNextCategoryUrl,
          totalGalleries  : voteGalleryCollectionTotal,
          currentIndex    : voteGalleryCollectionIndex,
          nextIndex       : voteGalleryCollectionIndex + 1
        });
        debug.log('addNextGalleryLink: 2: a');
        addNextGalleryLink();
      } else {
        removeNextGalleryLink();
      }
    }
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  module = {

    behaviors: ['shop-this-look', 'hotspot', 'affiliate'],

    messages: ['galleryModal.opened', 'gallery.sliderInit', defaults.lastSlideName, defaults.afterSlideName, defaults.beforeAnimName, defaults.beforeSizeName, defaults.afterContentSetName, 'gallery.loadingAdditionalContent', 'gallerydataupdated', 'modal.hidden', 'photos.searchPageUpdated', 'vote.nextGallery', 'vote.nextCategory', 'productList.generated'],

    init: function() {
      mdManager = check.exists('mdManager') ? context.getGlobal('mdManager') : false;
      SniAds = context.getGlobal('SniAds');
      setGalleryConfig(defaults, context.getConfig());
      getServices(context);
      setPreloaderHTML(template.preloaderHTML());
      updateConsecutiveGallery(context, metadata, {
        originalGallery: config.originalGallery,
        consecutiveGallery: config.consecutiveGallery
      });
      setGalleryConfig(config, { isMobile: deviceType.isMobile, isTablet: deviceType.isTablet });
      setGalleryPageTypes(context, config.galleryPageTypes);
      setAjaxConfig(context, config.ajaxUrl, config.ajaxPaging, config.isMobile, config.currentSearchResultsPage);
    },

    destroy: function() {
      killItWithFire();
    },

    onmessage: function(name, data) {
      let adTargetContainer;
      switch (name) {
        case 'galleryModal.opened':
          cacheElements(context, config.galleryContainerSelector);
          checkAdLibrary(context, check);
          setAdConfig(context, config.adLibLoaded, config.galleryAdSetup, config.coldLoad);
          checkStartSlide(context, $galleryElement, data.startingSlide);
          setSliderConfig(context, config.rsConfig, config.isMobile, config.isTablet, config.galleryStartingPosition);
          setReceivedId(data.origin);
          setInterstitialConfig(config);
          setupVote();
          adTargetContainer = config.newTemplate ? $galleryElement.find('[data-target-adcontainer]') : galleryContainer;
          createAdWrapper(config.adLibLoaded, adTargetContainer, template);
          createInterstitialContainer(config, galleryContainer, template);
          prepareGallery(galleryContainer, config.galleryLoadingClass);
          createCustomSliderEvents(context, defaults, $.rsProto, $.rsModules);
          startSlider(context, galleryContainer, config.rsConfig);
          break;
        case 'gallery.sliderInit':
          createCustomPrevNextHandling(slider);
          processSlideMetadata($(slider.currSlide.content.context), metadata, analytics);
          refreshAds(context, config.isMobile, config.adLibLoaded, galleryContainer, config.overlayBigboxName, template);
          handleInterstitial(config, slider, galleryContainer, check);
          $slideToolbar = createSlideToolbar(config.isMobile, galleryContainer);
          delegateArrowEvents(galleryContainer, config.previousArrowSelector, config.leftDataType, config.nextArrowSelector, config.rightDataType);
          if (config.voteEnabled) {
            votingModal();
          }
          getHotspotInstance(context, $(slider.currSlide.content.context).data(config.hotspotUrlName), slider.currSlide.content.find(config.hotspotElement), config.hotspotElement, config.hotspotUrlName, window.location.pathname, config.galleryPageTypes.singleImage);
          pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content)});
          break;
        case defaults.afterSlideName:
          processSlideMetadata($(slider.currSlide.content.context), metadata, analytics);
          setupAjaxHandler(config.galleryType, slider, context, config.ajaxStayAhead, config.readyToFetchMore);
          refreshAds(context, config.isMobile, config.adLibLoaded, galleryContainer, config.overlayBigboxName, template);
          voteAfterSlide();
          handleInterstitial(config, slider, galleryContainer, check);
          incrementOriginalGallery(context, metadata, config);
          updateShopElement(context, slider);
          updateDescriptions(slider);
          getHotspotInstance(context, $(slider.currSlide.content.context).data(config.hotspotUrlName), slider.currSlide.content.find(config.hotspotElement), config.hotspotElement, config.hotspotUrlName, window.location.pathname, config.galleryPageTypes.singleImage);
          pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content)});
          break;
        case defaults.beforeAnimName:
          if (config.resetScroll) {
            $(config.galleryAdSetup.container).scrollTop(0);
          }
          removeNextGalleryLink();
          if (config.newTemplate) {
            addSlideNumber(slider, $galleryElement);
          } else {
            createSlideNumbering(config.galleryType, template, slider, config.photoTraySelector);
          }
          maintainTrayVisibility(slider.currSlide.content.find(config.photoTraySelector), config.hiddenTrayClass, config.visibleTrayClass, config.currentTrayVisiblity, config.isMobile);
          break;
        case defaults.beforeSizeName:
          // setTimeout(function() {
          //   context.broadcast('gallery.sizeSet', {
          //     targetElement: slider.currSlide.content
          //   });
          // }, 200);
          break;
        case defaults.afterContentSetName:
          setTimeout(function() {
            pinSvc.setPinButton({name, $element: $galleryElement, itemConfig: pinConfig, data: extractData(slider.currSlide.content)});
            createSlideNumbering(config.galleryType, template, slider, config.photoTraySelector);
            updateShopElement(context, slider);
            updateDescriptions(slider);
          }, 200);
          break;
        case defaults.lastSlideName:
          prepNextVoteGallery();
          break;
        case 'gallery.loadingAdditionalContent':
          getAdditionalContent(context, config.ajaxUrl, config.ajaxPaging, config.ajaxPageWeAreOn, slider, config.ajaxFetchNumSlides, config.slideClassSelector);
          break;
        case 'gallerydataupdated':
          setGalleryConfig(config, data);
          break;
        case 'modal.hidden':
          removeGalleryModal('body', context, receivedId);
          break;
        case 'photos.searchPageUpdated':
          updateCurrentPhotoSearchPage(context, data);
          break;
        case 'vote.nextGallery':
          nextVoteGallery(data);
          break;
        case 'vote.nextCategory':
          if (config.readyForNextVoteCat) {
            config.readyForNextVoteCat = false;
            nextVoteCategory(data);
          }
          break;
        case 'productList.generated':
          if (slider) {
            slider.updateSliderSize(true);
          }
          break;
      }
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'modal-close':
          debug.log('modal-close');
          removeGalleryModal('body', context, receivedId);
          break;
        case 'close-mobile-interstitial':
          closeInterstitial(config, galleryContainer, check);
          break;
        case 'toggle-photo-tray':
          setTrayVisibility(context, element, config.hiddenTrayClass, config.visibleTrayClass, config.isMobile);
          break;
        case 'open-next-gallery':
          slider.next();
          break;
        case 'contain-gallery':
          traverseSlideshow(galleryContainer, config, event.pageX, slider, check);
          break;
        case 'product-link':
          let dynamicSTL = config['shop-this-look'].dynamicSTL,
              partner = config['shop-this-look'].vendor,
              productTitle = $(element).closest('div[data-product-title]').data('product-title');

          let metadata = dynamicSTL ? {
            partner:          partner,
            title:            partner+'|'+productTitle,
            componentname:    'photogalleryoverlay',
            imagecount:       'n/a',
            productcount:     'n/a'
          } : {
            title: productTitle
          };

          shopping.trackProductClick(metadata);
          break;
        default:
          break;
      }
    },

    onmouseover: function(event, element, elementType) {
      switch (elementType) {
        case 'increment-slide':
          setArrowHoverState(galleryContainer, config.hoverLeftClass, config.hoverRightClass, true);
          break;
        case 'decrement-slide':
          setArrowHoverState(galleryContainer, config.hoverLeftClass, config.hoverRightClass, false);
          break;
      }
    },

    onmouseout: function(event, element, elementType) {
      switch (elementType) {
        case 'increment-slide':
          removeArrowHoverState(galleryContainer, config.hoverLeftClass, config.hoverRightClass);
          break;
        case 'decrement-slide':
          removeArrowHoverState(galleryContainer, config.hoverLeftClass, config.hoverRightClass);
          break;
      }
    }

  };

  return module;

});
