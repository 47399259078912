SNI.Application.addService('kd-player', (application) => {
  'use strict';
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const debug = application.getService('logger').create('service.kd-player');
  let util = application.getService('utility');
  let cloudfrontBaseURL = '';
  let useDevFW = util.getUrlParam(window.location.href, 'useDevFW') === 'true';
  let useFw = util.getUrlParam(window.location.href, 'forceFW');
  const cookie = application.getService('cookie');
  // To enable Freewheel logs use `?showFwDebug` with a value between 0-2 to go from quiet logs to more verbose
  let showFwDebug = util.getUrlParam(window.location.href, 'showFwDebug')||false;
  let deviceType = application.getService('device-type');
  let isMobile = deviceType.isMobile;
  let sectionRand = util.getRandomInteger(1, 2048);
  const userInterface = SNI.Config.useDaltonLogin ? application.getService('user/user-data') : null;

  if (SNI.Config && SNI.Config.kdpVideoPlayerUri) {
    cloudfrontBaseURL = SNI.Config.kdpVideoPlayerUri;
  } else {
    debug.error('Could not find KDP url in SNI.Config.kdpVideoPlayerUri, will not be able to load player for GVP videos');
  }
  // Ad slots configuration
  let fwBaseConfig = {
    networkId: null, // always the same for all CORE sites
    serverUrl: '',
    profileId: '', // always the same for all CORE sites
    fwURL: '',
    useFw: useFw||false,
    useFwMobile: true,
    showFwDebug,
    sectionRand
  };

  let boundListeners = false;

  let sniAdsConfig = (SNI && SNI.Config && SNI.Config.fwDefaults) || {};
  // The final config updated with the page values
  let adsConfig = Object.assign({}, fwBaseConfig, sniAdsConfig);
  // Turn off mobile with FF
  if (isMobile) adsConfig.useFw = adsConfig.useFwMobile;
  // For setting it to the player dev env for dds-core
  // cloudfrontBaseURL = 'https://d1eqi0jbfp4zhc.cloudfront.net';

  const KDPScriptUrl = cloudfrontBaseURL + '/dtc-player.js';
  const KDPStylesUrl = cloudfrontBaseURL + '/dtc-player.css';
  const KDPScriptId = 'kd-player-script';
  const KDPStylesId = 'kd-player-styles';
  const PLAYBACK_ENDPOINT = SNI.Config && SNI.Config.playbackEndpoint;
  const mdManager = application.getGlobal('mdManager');
  // This is where we store all instances of active players, which are used for things like programmatically making it play, tearing it down, etc
  const playersOnPage = {
  };
  // This is helpful for troubleshooting
  window.playersOnPage = playersOnPage;

  /*********************************************************
   * Table of Contents
   *
   * Functions for loading the KDP Library
   * Player Instance Functions
   * Utility Functions
   * Accessibility Functions
   * Functions for digesting metadata and loading and destroying videos
   * Functions for the end card
   * Functions for the error screen
   * Functions for the replay video screen
   * Functions for binding elements (tiles, poster, end card)
   * Functions for updating and hiding/showing tile labels, poster image, etc
   * Functions controlling video pause/playback
   * Functions for getting the actual video url from its endpoint
   *********************************************************/

  /*********************************************************
   * Functions for loading the KDP Library
   *********************************************************/

  function onKDPScriptLoad({playerId, videoIndex = 0 }) {
    application.broadcast('kdp-library-loaded', { playerId, videoIndex });
  }

  function onKDPScriptError(e) {
    debug.log('script load error', e);
  }

  function onKDPStylesLoad({ playerId, videoIndex = 0 }) {
    loadKDPScript({ playerId, videoIndex, KDPScriptUrl, onKDPScriptLoad, onKDPScriptError });
  }

  function onKDPStylesError(e) {
    debug.log('styles load error', e);
  }

  function loadKDPStyles({ playerId, videoIndex = 0, KDPStylesUrl, onKDPStylesLoad, onKDPStylesError }) {
    const linkTag = document.createElement('link');
    linkTag.id = KDPStylesId;
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.href = KDPStylesUrl;
    linkTag.addEventListener('load', () => onKDPStylesLoad({ playerId, videoIndex }));
    linkTag.addEventListener('error', (e) => onKDPStylesError(e));
    document.getElementsByTagName('head')[0].appendChild(linkTag);
  }

  function loadKDPScript({ playerId, videoIndex = 0, KDPScriptUrl, onKDPScriptLoad, onKDPScriptError }) {
    const script = document.createElement('script');
    script.id = KDPScriptId;
    script.src = KDPScriptUrl;
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', () => onKDPScriptLoad({ playerId, videoIndex}));
    script.addEventListener('error', (e) => onKDPScriptError(e));
  }

  /**
   * Confirm that the script and the stylesheet have been loaded, and that the library has added the dtcPlayer object to window
   */
  function checkForKDPLibrary() {
    return $(`#${KDPStylesId}`).length > 0 && $(`#${KDPScriptId}`).length > 0 && window && window.dtcPlayer;
  }

  /**
   * Main logic for async loading of the KD Player library JS and CSS files
   */
  function loadKDPLibrary({ playerId, videoIndex = 0 }) {
    if ($(`#${KDPStylesId}`).length > 0) {
      debug.log('KDP styles already there, try script');
      if ($(`#${KDPScriptId}`).length === 0) {
        debug.log('KDP styles there but script is not, load it');
        loadKDPScript({ playerId, videoIndex, KDPScriptUrl, onKDPScriptLoad, onKDPScriptError });
      }
    } else {
      debug.log('KDP styles not there, load them first');
      loadKDPStyles({ playerId, videoIndex, KDPStylesUrl, onKDPStylesLoad, onKDPStylesError });
    }
  }

  /*********************************************************
   * Player Instance Functions
   * When a KDP is instantialized, it returns an object with useful features. The following functions are for storing those in this services 'state', so they can be accessed as needed by modules.
   *********************************************************/

  /**
   * Update the attribute on the container so it's easy to see which video in the playlist is loaded
   */
  function updateDataAttrVideoIndex({playerId, videoIndex}) {
    const $videoParent = getVideoParentFromId({playerId});
    $videoParent.attr('data-video-index', videoIndex);
  }

  /**
   * Add a player on page to the object.
   */
  function addPlayerOnPage({playerId, playerInstance, videoIndex}) {
    playersOnPage[playerId] = {
      playerInstance,
      activeVideoIndex: videoIndex
    };
    updateDataAttrVideoIndex({playerId, videoIndex});
  }

  /**
   * Update an existing player on page with a new video/index
   */
  function updatePlayerOnPage({playerId, playerInstance, videoIndex}) {
    if (playersOnPage[playerId]) {
      playersOnPage[playerId].instance = playerInstance;
      playersOnPage[playerId] = {
        playerInstance,
        activeVideoIndex: videoIndex
      };
      updateDataAttrVideoIndex({playerId, videoIndex});
    } else {
      debug.error('Cannot update playerOnPage, does not exist');
    }
  }

  /**
   * Remove just the instance of a player on a page
   */
  function removePlayerInstanceOnPage({playerId}) {
    if (playerId && playersOnPage[playerId]) {
      debug.log('Removing player instance', playerId);
      playersOnPage[playerId].instance = null;
      return playersOnPage[playerId].playerInstance = null;
    }
  }

  /**
   * Get a player on page from the object
   */
  function getPlayerOnPage({playerId = ''}) {
    if (playerId && playersOnPage[playerId]) {
      return playersOnPage[playerId];
    } else {
      null;
    }
  }

  /**
   * Get all players on the page, aka the object itself.
   */
  function getAllPlayersOnPage() {
    return playersOnPage;
  }

  /*********************************************************
   * Utility Functions
   *********************************************************/

  /**
   * Gets the overall parent container for the video
   */
  function getVideoParentFromId({playerId}) {
    const $videoEl = $(`[data-player-id='${playerId}']`);
    const $videoParent = $videoEl.closest('.kdp');
    return $videoParent;
  }

  /**
     * Grabs the config needed from the main T3 config for the component
     */
  function getVideoInfoFromT3Config({config, videoIndex}) {
    const { webPlayer } = config;
    const { channels } = webPlayer;
    const videoInfo = channels[0] && channels[0].videos[videoIndex];
    return videoInfo;
  }

  /*********************************************************
   * Accessibility Functions
   *********************************************************/

  /**
   * If you click somewhere on the site, we can assume you don't need the keyboard accessibility outlines
   */
  function removeFocusIfNeeded(e){
    const activeClass = 'kdp--tab-focus';
    $(activeClass).removeClass(activeClass);
  }

  /**
   * This makes is so that when a user is tabbing through the site, when they get to the GVP component it will apply
   * outlines to show what they have selected.
   */
  function addFocustabListener(e){
    const activeClass = 'kdp--tab-focus';
    if (e.keyCode === 9) {
      const activeElement = document.activeElement;
      const $activeElement = $(activeElement);
      const $parent = $activeElement.closest('.kdp');
      if ($parent.length > 0) {
        $('.kdp').removeClass(activeClass);
        $parent.addClass(activeClass);
      }
    }
  }

  document.addEventListener('keyup', addFocustabListener);
  document.addEventListener('click', removeFocusIfNeeded);

  /*********************************************************
   * Functions for digesting metadata and loading and destroying videos
   *********************************************************/

  /**
   * The KD Players needs a newer version of Adobe Heartbeat than the old MPX player in order to work. If we don't have that and still pass in metadata, the player breaks, so we check that here.
   */
  function checkForCorrectHeartbeatVersion(){
    const correctVersion = 'js-extn-2.2.1.229-1bf77a';
    // disable
    if (useDevFW) return false;
    if (window && window.ADB && window.ADB.MediaHeartbeat && window.ADB.MediaHeartbeat.version && typeof window.ADB.MediaHeartbeat.version === 'function' && window.ADB.MediaHeartbeat.version() === correctVersion) {
      debug.log('Has correct media heartbeat version, enable analytics', window.ADB.MediaHeartbeat.version());
      return true;
    } else {
      debug.log('Incorrect media heartbeat version, disable analytics');
      return false;
    }
  }


  /**
   * Due to adobe analytics issues, when making a new player in an existing one it must be destroyed completely, including it's elements. This does that.
   */
  function destoryCurrentKDPVideo({playerId}){
    if (!playerId) return;
    const playerOnPage = getPlayerOnPage({playerId});
    const playerInstance = playerOnPage && playerOnPage.playerInstance;
    if (playerInstance && playerInstance.webControls){
      playerInstance.webControls.destroyAndResetPlayer();
      removePlayerInstanceOnPage({playerId});
    } else {
      application.broadcast('kdp-remove-module-player', { playerId });
    }
  }

  /**
   * Main function that will check if the library is already loaded. If it's not, it will load it, and if it is, it will fire off the message that gets fired when it is loaded.
   */
  function initKDPLibrary({ playerId, videoIndex = 0 }) {
    debug.log('initKDPLibrary');
    if (checkForKDPLibrary()) {
      debug.log('initKDPLibrary - already there, fire event');
      application.broadcast('kdp-library-loaded', { playerId, videoIndex });
    } else {
      debug.log('initKDPLibrary - not found, loadKDPLibrary');
      loadKDPLibrary({ playerId, videoIndex });
    }
  }

  /**
   * Take the provided config from the component and output the metadata needed to init the player
   * The videoIndex is which video config object from the videos array to use (see video-playlist module for example)
   */
  function compilePlayerConfig({config, videoIndex = 0, playType = 'manual'}) {
    const cloneDeep = window.dtcPlayer.util.cloneDeep;
    const sampleConfig = window.dtcPlayer.sampleConfig;
    if (!sampleConfig) {
      debug.error('Cannot find window.dtcPlayer.sampleConfig, means KD Player isn\'t loaded');
      return;
    }
    const playerConfigClone = cloneDeep(sampleConfig.webPlayer);
    const { webPlayer } = config;
    const { channels, playerId, videoDigitalIdOverride } = webPlayer;
    const videoInfo = channels[0] && channels[0].videos[videoIndex] || {};
    const { metaData, mux } = videoInfo;
    let durationNumber;

    if (videoInfo && videoInfo.displayMetadata && videoInfo.displayMetadata.duration) {
      durationNumber = parseInt(videoInfo.displayMetadata.durationInSeconds);
      // Adjust this to change when the end card appears
      const secondsBeforeVideoEnds = 5;
      const durationNumberMs = (durationNumber - secondsBeforeVideoEnds) * 1000;
      playerConfigClone.videoCreditsTime = durationNumberMs;
    }
    const newId = playerId.replace('video-', '');

    // Set up Mux analytics
    if (mux && SNI && SNI.Config && SNI.Config.muxKey) {
      playerConfigClone.mux.key = SNI.Config.muxKey;
      playerConfigClone.mux.muxPlayerName = 'DDS Web Player';
      if (videoInfo && videoInfo.mux && videoInfo.mux.metaData) {
        playerConfigClone.mux.metaData = videoInfo.mux.metaData;
      }
    }

    // Set up Adobe heartbeat analytics
    if (checkForCorrectHeartbeatVersion()){
      playerConfigClone.metaData = metaData;
      playerConfigClone.metaData.heartbeatTracking.loginStatus = 'unauthenticated';
      playerConfigClone.metaData.heartbeatTracking.platform = 'web';
      playerConfigClone.metaData.heartbeatTracking.userType = 'avod';
      playerConfigClone.metaData.heartbeatTracking.showName = playerConfigClone.metaData.heartbeatTracking.videoShowTitle;
      playerConfigClone.metaData.heartbeatTracking.videoChannelName = webPlayer.videoChannelName;
      playerConfigClone.metaData.heartbeatTracking.videoPlayType = playType;
      if (SNI.Config.useDaltonLogin && userInterface.getLoginStatus()) {
        playerConfigClone.metaData.heartbeatTracking.loginStatus = 'authenticated';
      }
      const siteSection = mdManager.getParameterString('CategoryDspName') && mdManager.getParameterString('CategoryDspName').toLowerCase() || '';
      playerConfigClone.metaData.heartbeatTracking.videoSiteSection = siteSection;
      // Turn off chromecast sender app button
      playerConfigClone.chromecastReceiverId = null;
      // Check if video is fullscreen
      const fullscreenElement = document.fullscreenElement;
      const fullscreenState = fullscreenElement && fullscreenElement.hasClass('player') ? 'full screen' : 'normal' ;
      playerConfigClone.metaData.heartbeatTracking.videoScreenType = fullscreenState;
      // Find and set the adobeECID (Experience Cloud ID) - used to ID visitors
      // https://experienceleague.adobe.com/docs/experience-platform/identity/ecid.html
      const _satellite = application.getGlobal('_satellite');
      if (_satellite && typeof _satellite.getVisitorId === 'function') {
        const visitorId = _satellite.getVisitorId();
        if (visitorId && typeof visitorId.getMarketingCloudVisitorID === 'function') {
          const adobeECID = visitorId.getMarketingCloudVisitorID();
          playerConfigClone.metaData.heartbeatTracking.adobeECID = adobeECID;
        }
      }
    } else {
      playerConfigClone.metaData = null;
    }

    // Set up ad stuff (freewheel)
    function formatAdVal(val) {
      return val.replace(/\s/g, '_').replace(/&amp;/g, '&').replace(/&/g, 'and').toLowerCase();
    }

    const brand = formatAdVal(mdManager.getParameterString('site'));
    const category = formatAdVal(mdManager.getParameterString('CategoryDspName'));
    const theSiteSectionId = brand + '-' + category;
    debug.log('theSiteSectionId', theSiteSectionId);
    const theVideoAssetId = videoDigitalIdOverride || ((metaData && metaData.heartbeatTracking && metaData.heartbeatTracking.videoDigitalID) ? metaData.heartbeatTracking.videoDigitalID + '-oo' : '-oo');

    const amcv = decodeURIComponent(cookie.get('AMCV_BC501253513148ED0A490D45%40AdobeOrg'));
    const amcvArr = (amcv) ? amcv.split(/\|/) : [];
    const mcmidIdx = amcvArr.indexOf('MCMID');
    debug.log('amcv: ', amcv, ', amcvArr', amcvArr, ', mcmidIdx: ', mcmidIdx);

    const $videoParent = getVideoParentFromId({playerId});
    const $videoWrapper = $videoParent.find('.kdp__player-container');

    // Note - all of these values must be strings
    const adKeyValues = {
      adkey1: formatAdVal(mdManager.getParameterString('AdKey1')),
      adkey2: formatAdVal(mdManager.getParameterString('AdKey2')),
      category: formatAdVal(mdManager.getParameterString('CategoryDspName')),
      externalCustomVisitor: cookie.get('aam_did') || '',
      pagetype: formatAdVal(mdManager.getParameterString('Type')),
      playertype: formatAdVal(mdManager.getParameterString('PlayerType')),
      topic: formatAdVal(mdManager.getParameterString('Sponsorship')),
      subsection: formatAdVal(mdManager.getParameterString('SctnDspName')),
      uniqueid: formatAdVal(mdManager.getParameterString('UniqueId')),
      vgncontent: formatAdVal(mdManager.getParameterString('SctnDspName')),
      page_url: formatAdVal(window.location.href),
      pw: util.convertNumberToIntegerThenString($videoWrapper.outerWidth()),
      ph: util.convertNumberToIntegerThenString($videoWrapper.outerHeight()),
      mcmid: mcmidIdx === -1 ? '' : amcvArr[mcmidIdx + 1],
      metr: '0'
    };
    debug.log('adKeyValues', adKeyValues);

    const consentStates = ['data-store', 'ads-contextual', 'ads-person-prof', 'ads-person', 'vendor'];
    let _fw_cookie_consent = 1;

    if (window.WM && window.WM.UserConsent) {
      if (window.WM.UserConsent.inUserConsentState(consentStates, {id: 'kdp-video-player'}) === false) {
        _fw_cookie_consent = 0;
      }
    }

    debug.log('_fw_cookie_consent', _fw_cookie_consent);

    if (_fw_cookie_consent) {
      const wmukCookie = cookie.get('datid') || cookie.get('WMUKID_STABLE');
      if (wmukCookie) {
        adsConfig.visitorId = wmukCookie;
        adsConfig.additionalParams = {
          wmuk: wmukCookie
        };
      }
    }

    debug.log('adsConfig', adsConfig);

    // Demo ad account values, for testing as sometimes you won't get ads back with the real config
    const videoDurationDefault = 500;
    if (useDevFW) {
      adsConfig = {
        networkId: 42015,
        serverUrl: '//demo.v.fwmrm.net/ad/g/1',
        profileId: '42015:js_allinone_profile',
        theVideoAssetId: 'js_allinone_demo_video',
        theSiteSectionId: 'js_allinone_demo_site_section',
        theVideoDuration: durationNumber || videoDurationDefault,
        fwUrl: '//mssl.fwmrm.net/libs/adm/6.52.0/AdManager.js',
        useFw: true,
        additionalParams: {
          ...adKeyValues,
          _fw_cookie_consent
        }
      };
      playerConfigClone.adsConfig = {
        ...adsConfig
      };
    } else {
      // Real ad values
      playerConfigClone.adsConfig = {
        ...adsConfig,
        theVideoAssetId,
        theSiteSectionId,
        theVideoDuration: durationNumber || videoDurationDefault,
        additionalParams: {
          ...(adsConfig.additionalParams || {}),
          ...adKeyValues,
          _fw_cookie_consent
        }
      };
    }

    if (useFw) playerConfigClone.adsConfig.useFw = true;
    if (showFwDebug) playerConfigClone.adsConfig.showFwDebug = true;

    debug.log('Ads player config', playerConfigClone.adsConfig);

    // Set general player values
    playerConfigClone.autoplay = true;
    playerConfigClone.fullscreenContainer = '.kdp';
    playerConfigClone.playerId = newId;
    playerConfigClone.path = videoInfo.path;
    playerConfigClone.displayMetadata = videoInfo.displayMetadata;

    debug.log('Final player config', playerConfigClone);
    return playerConfigClone;
  }

  /**
   * This is used when clicking a tile or an end card to help set some things for loading a new video
   */
  function prepareForNewVideo({playerId, videoIndex, scrollToVideo = true, playType = 'manual'}) {
    const $videoParent = getVideoParentFromId({playerId});
    const $videoEl = $videoParent.find(`[data-player-id='${playerId}']`);
    const $videoButtonPoster = $videoParent.find('.kdp-poster');
    const $tile = $videoParent.find(`[data-vid-num="${videoIndex}"]`);

    // Check if a video is already playing
    const hasVideo = $videoEl.hasClass('player--unlocked');

    // Reset endcards and error screens
    toggleEndcard({playerId, showEndCard: false});
    toggleErrorScreen({playerId, showErrorScreen: false});
    toggleReplayVideoScreen({playerId, showReplayVideoScreen: false});

    // If we're showing playlist tiles, we have more to do
    if ($tile.length > 0) {
      const isAlreadyActive = $tile.hasClass('kdp__tile--is-active');
      const $tilesContainer = $tile.closest('.kdp__tiles-container');
      const $tilesContainerInner = $tile.closest('.kdp__tiles-inner-container');

      // Scroll to selection
      const isBottom = $tilesContainer.hasClass('kdp__tiles-container--bottom');
      if (isBottom) {
        $tilesContainerInner.animate({
          scrollLeft: $tilesContainerInner.scrollLeft() + ($tile.offset().left - $tilesContainer.offset().left)
        }, 500);
      } else {
        $tilesContainer.animate({
          scrollTop: $tilesContainer.scrollTop() + ($tile.offset().top - $tilesContainer.offset().top - 12)
        }, 500);
      }

      if (scrollToVideo) {
        $('html, body').animate({
          scrollTop: $videoEl.offset().top
        }, 500);
      }

      setNowPlaying({playerId, activeVidIndex: videoIndex});

      // If this tile is already playing, keep playing it instead of loading a new one
      if (isAlreadyActive) {
        const playerOnPage = getPlayerOnPage({playerId});
        if (playerOnPage) {
          const { playerInstance } = playerOnPage;
          playerInstance.webControls.play();
          return;
        }
      }
    }

    if (hasVideo) {
      destoryCurrentKDPVideo({playerId, videoIndex});
      application.broadcast('kdp-library-play-video', {playerId, videoIndex, playType});
    } else {
      showPosterLoader(playerId);
      initKDPLibrary({ playerId, $videoButtonPoster, videoIndex });
    }
  }

  /**
   * Load a video into the KD Player based on the provided config and playerId
   * @param   {String}    playerId   The id for KDP component
   * @param   {Object}    config   The config object for this video
   * @param   {Number}    videoIndex   The index of this video in the playlist array in the config
   * @param   {String}    playType   Should be 'manual' or 'autoplay'
   */
  async function loadNewKDPVideo({playerId, config, videoIndex, playType = 'manual'}) {
    const playerInterface = window.dtcPlayer.playerInterface;
    if (!playerInterface) {
      debug.error('Cannot find window.dtcPlayer.playerInterface, means KD Player isn\'t loaded');
      return;
    }

    let playerInstance;
    const $videoParent = getVideoParentFromId({playerId});
    const $videoButtonPoster = $videoParent.find('.kdp-poster');
    const playerOnPage = getPlayerOnPage({playerId});
    let playerError = false;

    // We only want to set this up for the very first video loaded on the page
    if (!boundListeners) {
      bindVideoListeners();
      boundListeners = true;
    }

    // Check to see if the player is already active,
    // and then if we're loading a new video in the playlist
    let newVideoInExistingPlayer = false;
    if (playerOnPage) {
      const { activeVideoIndex } = getPlayerOnPage({playerId});
      if (activeVideoIndex !== videoIndex) {
        newVideoInExistingPlayer = true;
      }
    }

    // Should only load a video if it's a new one in the playlist in an already activated player,
    // or a player that hasn't loaded a video at all yet
    if (newVideoInExistingPlayer || !playerOnPage){
      const playerConfig = compilePlayerConfig({config, videoIndex, playType});
      debug.log('final playerConfig', playerConfig);
      // Get the url from AEM service
      const urlFromService = await kdPlayer.getPlaybackUrl(playerConfig);
      debug.log('urlFromService', urlFromService);
      if (!urlFromService) {
        debug.error('urlFromService not found!');
        toggleErrorScreen({playerId, showErrorScreen: true});
        playerError = true;
      } else {
        playerConfig.videoUrl = urlFromService;
        debug.log('playerConfig inside newVideoInExisting', playerConfig);
        playerInstance = playerInterface(playerConfig);
      }
    }

    if (playerError) {
      debug.log('Error loading video in playlist, still update the metadata and plaerOnPage object though');
      debug.log('Clear out the player - ?');
      let playerInstance = null;
      updateMetadataElements({playerId, config, videoIndex});
      updatePlayerOnPage({playerId, playerInstance, videoIndex});
    } else if (newVideoInExistingPlayer) {
      debug.log('New videoIndex doesnt match current one, load new video in playlist');
      updatePlayerOnPage({playerId, playerInstance, videoIndex});
      updateMetadataElements({playerId, config, videoIndex});
    } else if (!playerOnPage) {
      debug.log('Player not loaded yet, load a video for the first time');
      addPlayerOnPage({playerId, playerInstance, videoIndex});
      // We don't need to update the metadata if it's the first time the player loads a video
      // and the video being loaded is the first in the playlist
      if (videoIndex !== 0) {
        updateMetadataElements({playerId, config, videoIndex});
      }
      if ($videoButtonPoster.length === 1) {
        hidePosterLoader(playerId);
      }
    }

    return playerInstance;
  }

  /*********************************************************
   * Functions for setting up listeners
   *********************************************************/

  /**
   * The KDP emits events using the PubSub library, which are listened for here.
   */
  function bindVideoListeners(){
    if (window.PubSub && window.dtcPlayer && window.dtcPlayer.util && window.dtcPlayer.util.PlayerEvents) {
      const playerEvents = window.dtcPlayer.util.PlayerEvents;
      const PubSub = window.PubSub;
      PubSub.subscribe(playerEvents.PAUSE, function(msg, data){
        const idFromData = data.id;
        if (idFromData) {
          updateActiveLabelText({playerId: idFromData, newText: 'Resume'});
        }
      });
      PubSub.subscribe(playerEvents.PLAY, function(msg, data){
        const idFromData = data.id;
        if (idFromData) {
          updateActiveLabelText({playerId: idFromData, newText: 'Now Playing'});
          // Pause all KDP videos except this one
          pauseAllVideosExceptThisOne({playerId: idFromData});
          // Pause non KDP videos (MPX ones)
          // pauseAllVideos();
          // If the replay button is showing, remove that
          toggleReplayVideoScreen({playerId: idFromData, showReplayVideoScreen: false});
        }
      });
      // For showing the end card
      PubSub.subscribe(playerEvents.IS_SHOWING_CREDITS, function(msg, data){
        const idFromData = data.id;
        if (idFromData) {
          toggleEndcard({playerId: idFromData, showEndCard: data.isShowingCredits});
        }
      });
      let END_EVENT = playerEvents.MEDIA_END || playerEvents.END;
      // For when the video in a playlist ends
      PubSub.subscribe(END_EVENT, function(msg, data){
        const { id:idFromData, adPlaying } = data;
        debug.log('Video end event', END_EVENT);
        if (idFromData) {
          // See if it's an ad ending, if so we don't want to show the replay option
          if (adPlaying !== 'ad-playing') {
            const $videoParent = getVideoParentFromId({playerId: idFromData});
            const currentIndex = $videoParent.attr('data-video-index');
            const $endCardLink = $videoParent.find('.kdp__end-card-link');
            const hasEndCardLink = $endCardLink.length > 0;
            if (!hasEndCardLink) {
              const playType = 'autoplay';
              application.broadcast('kdp-library-end-card-click', {playerId: idFromData, currentIndex, playType});
            } else {
              // For single video pages, they have a link they can click in the end card
              // If the video goes to the end, just take them there
              const newLink = $endCardLink.attr('href');
              window.location.replace(newLink);
            }
          }
        }
      });
    }
  }

  /*********************************************************
   * Functions for the end card
   *********************************************************/

  /**
   * For hiding and showing endcard, based on the showEndCard argument
   */
  function toggleEndcard({playerId, showEndCard}) {
    const $videoParent = getVideoParentFromId({playerId});
    const activeClass = 'kdp--show-end-card';
    if (showEndCard) {
      $videoParent.addClass(activeClass);
    } else {
      $videoParent.removeClass(activeClass);
    }
  }

  /**
   * For updating the endcard's title and thumbnail when a new video is loaded in the playlist
   */
  function updateEndCard({playerId, nextVideo}) {
    const { nextVideoTitle, nextVideoThumbnail } = nextVideo;
    const $videoParent = getVideoParentFromId({playerId});
    const $thumbnail = $videoParent.find('.kdp__end-card-thumbnail');
    const $title = $videoParent.find('.kdp__end-card-title');
    $title.text('');
    if (nextVideoTitle.length > 0) {
      $title.text(nextVideoTitle);
    }
    if (nextVideoThumbnail.length > 0) {
      $thumbnail.attr('src', nextVideoThumbnail);
    } else {
      $thumbnail.attr('src', '');
    }
  }

  /**
   * For handling clicks on the end card
   */
  function bindEndCardClick({playerId}) {
    const $videoParent = getVideoParentFromId({playerId});
    const $endCardButton = $videoParent.find('.kdp__end-card-button');
    $endCardButton.on('click', function(){
      const currentIndex = $videoParent.attr('data-video-index');
      const playType = 'manual';
      application.broadcast('kdp-library-end-card-click', {playerId, currentIndex, playType});
    });
  }

  /*********************************************************
   * Functions for the error screen
   *********************************************************/

  /**
   * For hiding and showing the error screen, based on the showErrorScreen argument
   */
  function toggleErrorScreen({playerId, showErrorScreen}) {
    const $videoParent = getVideoParentFromId({playerId});
    const activeClass = 'kdp--show-error-screen';
    const errorContainer = $videoParent.find('.kdp__error-screen-container');

    if (showErrorScreen) {
      errorContainer.attr('aria-hidden', false);
      $videoParent.addClass(activeClass);
    } else {
      errorContainer.attr('aria-hidden', true);
      $videoParent.removeClass(activeClass);
    }
  }

  /*********************************************************
   * Functions for the replay video screen
   *********************************************************/

  /**
   * For hiding and showing the error screen, based on the showReplayVideoScreen argument
   */
  function toggleReplayVideoScreen({playerId, showReplayVideoScreen}) {
    const $videoParent = getVideoParentFromId({playerId});
    const activeClass = 'kdp--show-replay';
    if (showReplayVideoScreen) {
      $videoParent.addClass(activeClass);
    } else {
      $videoParent.removeClass(activeClass);
    }
  }

  /**
   * For handling clicks on the replay button
   */
  function bindReplayVideoClick({playerId}) {
    const $videoParent = getVideoParentFromId({playerId});
    const $replayButton = $videoParent.find('.kdp__replay-btn');
    $replayButton.on('click', function(){
      const playerOnPage = getPlayerOnPage({playerId});
      const { playerInstance } = playerOnPage;
      playerInstance.webControls.skipToPoint(0);
      playerInstance.webControls.play();
      toggleReplayVideoScreen({playerId, showReplayVideoScreen: false});
    });
  }

  /*********************************************************
   * Functions for binding elements (tiles, poster, end card)
   *********************************************************/

  /**
   * One function for all the things that need to be bound
   */
  function bindVideoElements({ playerId = '', scrollToVideo = true }) {
    const $videoParent = getVideoParentFromId({playerId});
    const $videoButtonPoster = $videoParent.find('.kdp-poster');
    const $videoTiles = $videoParent.find('.kdp__tile');
    bindVideoPosterClick({ playerId, $videoButtonPoster });
    bindVideoTilesClick({playerId, $videoTiles, scrollToVideo });
    bindEndCardClick({playerId});
    bindReplayVideoClick({playerId});
  }

  /**
   * Binds the main video player poster image button (not the extra tiles below or to the right of it for other videos) so that when you click it, it makes sure the library is loaded and then tries to play it.
   */
  function bindVideoPosterClick({ playerId = '', $videoButtonPoster }) {
    if ($videoButtonPoster.length !== 1) {
      debug.error('There should only be one videoButtonPoster!');
      return;
    }
    $videoButtonPoster.on('click', function() {
      posterClick({ playerId, $videoButtonPoster });
    });
  }

  /**
   * What happens when you click the video poster, shows the loader aniamtion and starts up the library to init the video
   */
  function posterClick({ playerId = '', $videoButtonPoster }){
    showPosterLoader(playerId);
    initKDPLibrary({ playerId, $videoButtonPoster, videoIndex: 0 });
  }

  /**
   * Binds the extra tiles below or to the right of the main player so that when you click it, it makes sure the library is loaded and then tries to play it. Also will destroy existing player if a video is already playing from the set of tiles.
   */
  function bindVideoTilesClick({playerId, $videoTiles, scrollToVideo }) {
    $videoTiles.on('click', function(e) {
      e.preventDefault();
      let $tile = $(this);
      let currentIndex = parseInt($tile.attr('data-vid-num'));
      prepareForNewVideo({playerId, videoIndex: currentIndex, scrollToVideo});
    });
  }

  /*********************************************************
   * Functions for updating and hiding/showing tile labels, poster image, etc
   *********************************************************/

  /**
   * Update the title, duration and description of the video
   */
  function updateTitleAndDescription({playerId, displayMetadata}) {
    const $videoParent = getVideoParentFromId({playerId});
    const $title = $videoParent.find('.kdp__metadata-video-title');
    const $duration = $videoParent.find('.kdp__metadata-title-duration');
    const $description = $videoParent.find('.kdp__metadata-video-description');
    const { description, duration, showEpisodeTitle } = displayMetadata;
    const descriptionClass = 'kdp__metadata-video-description--has-description';

    if (!description) {
      $description.removeClass(descriptionClass);
      $description.text('');
    } else {
      $description.html(description);
      $description.addClass(descriptionClass);
    }
    $title.text(showEpisodeTitle);
    $duration.text(duration);
  }

  /**
   * Update the call to action
   */
  function updateCta({playerId, newCtaText, newCtaLink}){
    const $videoParent = getVideoParentFromId({playerId});
    const $ctaContainer = $videoParent.find('.kdp__metadata-cta-container');
    const $ctaLink = $videoParent.find('.kdp__metadata-cta');
    const activeClass = 'kdp__metadata-cta-container--has-cta';
    if (newCtaText && newCtaLink) {
      $ctaContainer.addClass(activeClass);
      $ctaLink.text(newCtaText);
      $ctaLink.attr('href', newCtaLink);
    } else {
      $ctaContainer.removeClass(activeClass);
    }
  }

  /**
   * Update the attribution (the part that says something like "From: Food Network Kitchen...")
   */
  function updateAttributions({playerId, newAttributions}){
    const $videoParent = getVideoParentFromId({playerId});
    const $attributionsContainer = $videoParent.find('.kdp__metadata-video-attribution');
    const $currentAttributions = $videoParent.find('.kdp__metadata-video-attribution-link');
    const $currentPrefixes = $videoParent.find('.kdp__metadata-video-attribution-prefix');
    $currentPrefixes.remove();
    $currentAttributions.remove();

    const activeClass = 'kdp__metadata-video-attribution-container--has-attributions';
    if (newAttributions.length > 0){
      $attributionsContainer.addClass(activeClass);
      newAttributions.forEach(function(el, i) {
        let prefixes = ['From: ', ' with ', ' and '];
        const newPrefix = `<span class="kdp__metadata-video-attribution-prefix">${prefixes[i]}</span>`;
        const newLink = `<a class="kdp__metadata-video-attribution-link" href="${el.path}">${el.title}</a>`;
        $attributionsContainer.append(newPrefix);
        $attributionsContainer.append(newLink);
      });
    } else {
      $attributionsContainer.removeClass(activeClass);
    }
  }

  /**
   * Update the tags under the video
   */
  function updateTags({playerId, newTags}){
    const $videoParent = getVideoParentFromId({playerId});
    const $tagContainer = $videoParent.find('.kdp__metadata-tag-container');
    const $currentTags = $videoParent.find('.kdp__metadata-tag');
    $currentTags.remove();

    const activeClass = 'kdp__metadata-tag-container--has-tags';
    if (newTags.length > 0){
      $tagContainer.addClass(activeClass);
      newTags.forEach(function(el, i) {
        const newMarkup = `<a class="kdp__metadata-tag" href="${el.path}">${el.title}</a>`;
        $tagContainer.append(newMarkup);
      });
    } else {
      $tagContainer.removeClass(activeClass);
    }
  }

  /**
   * Combines all of the different functions needed to update the metadata for a video
   */
  function updateMetadataElements({playerId, config, videoIndex}) {
    const currentConfig = getVideoInfoFromT3Config({config, videoIndex});

    const {
      attribution,
      ctaText,
      ctaLink,
      displayMetadata,
      nextVideo,
      tags
    } = currentConfig;
    updateTitleAndDescription({playerId, displayMetadata});
    updateAttributions({playerId, newAttributions: attribution});
    updateTags({playerId, newTags: tags});
    updateCta({playerId, newCtaText: ctaText, newCtaLink: ctaLink});
    updateEndCard({playerId, nextVideo});
  }

  /**
   * Sets the label for the video tiles
   */
  function setNowPlaying({playerId, activeVidIndex, alreadyPlaying = false}) {
    const activeClass = 'kdp__tile--is-active';
    const $videoParent = getVideoParentFromId({playerId});
    const $newActiveVideo = $videoParent.find(`[data-vid-num='${activeVidIndex}']`);
    const $newVideoLabel = $newActiveVideo.find('.kdp__tile-status');
    const $allTiles = $videoParent.find('.kdp__tile');
    const $allLabels = $videoParent.find('.kdp__tile-status');

    $allLabels.text('');
    $newVideoLabel.text('Now Playing');
    $allTiles.removeClass(activeClass);
    $newActiveVideo.addClass(activeClass);
  }

  /**
   * Updates the text in the label for the video tiles
   */
  function updateActiveLabelText({playerId, newText = ''}){
    const $videoParent = getVideoParentFromId({playerId});
    const $activeLabel = $videoParent.find('.kdp__tile--is-active .kdp__tile-status');
    $activeLabel.text(newText);
  }

  /**
   * Shows the loader part of the overlay, used while the KDP library is loading or loading a video
   */
  function showPosterLoader(playerId) {
    const $videoParent = getVideoParentFromId({playerId});
    if ($videoParent.length !== 1) {
      debug.error('There should only be one videoParent!!!');
      return;
    }
    $videoParent.addClass('kdp--show-loader');
  }

  /**
   * Hides the loader part of the overlay, once the KDP library is done loading or loading a video
   */
  function hidePosterLoader(playerId) {
    const $videoParent = getVideoParentFromId({playerId});
    if ($videoParent.length !== 1) {
      debug.error('There should only be one videoParent!!!');
      return;
    }
    $videoParent.removeClass('kdp--show-loader');
    $videoParent.addClass('kdp--show-video');
  }

  /*********************************************************
   * Functions controlling video pause/playback
   *********************************************************/

  function pauseAllKDPVideos() {
    const playersOnPage = getAllPlayersOnPage();

    for (const player in playersOnPage) {
      const playerValues = playersOnPage[player];
      playerValues.playerInstance.webControls.pause();
    }
  }

  /**
   * Pause all videos on the page, except the one specified
   */
  function pauseAllVideosExceptThisOne({playerId}) {
    const playersOnPage = getAllPlayersOnPage();

    for (const player in playersOnPage) {
      if (player !== playerId) {
        const playerValues = playersOnPage[player];
        playerValues.playerInstance.webControls.pause();
      }
    }
  }

  /*********************************************************
   * Functions for getting the actual video url from its endpoint
   *********************************************************/

  async function getPlaybackUrl(config) {
    const path = config.path;
    const url = `${window.location.origin}${PLAYBACK_ENDPOINT}?path=${path}`;
    const playbackUrl = await fetch(url)
      .then (response => {
        let returnValue = response.status === 200 ? response.url : null;
        return returnValue;
      })
      .catch(function(error) {
        // Worth noting that even when the router fails, it still returns a url,
        // so this code doesn't come into play unless it can't hit the router at all
        debug.error(error);
      });
    return playbackUrl;
  }

  const kdPlayer = {
    bindVideoElements,
    getAllPlayersOnPage,
    getPlaybackUrl,
    getPlayerOnPage,
    loadNewKDPVideo,
    pauseAllKDPVideos,
    prepareForNewVideo,
    posterClick,
    toggleReplayVideoScreen
  };

  return kdPlayer;
});
