import './check';

SNI.Application.addService('affiliate', application => {
  const check = application.getService('check');
  const window = application.getGlobal('window');
  let amazon;
    
  const resetAffiliateSpec = () => {
    // Checking on function invocation rather than during service setup b/c the amazon affiliate script loads long after T3
    amazon = check.exists(['window.amzn_assoc_ad', 'window.amzn_assoc_ad_spec']);
    if (amazon) {
      window.amzn_assoc_ad.render(window.amzn_assoc_ad_spec);
      window.amzn_assoc_ad_spec.reset();
    }
  };

  return {
    resetAffiliateSpec
  };
});