/**
 * @fileoverview Popup Behavior
 * @author Jonathan Kemp
 */

/*
 * Opens up a new window on click
 */
SNI.Application.addBehavior('popup', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let windowObjectReference = null;

  const defaults = {
    popupWidth: '1100',
    popupHeight: '700'
  };
  const config = Object.assign({}, defaults, context.getConfig());

  function openPopup(href) {
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(href, 'SNIWindowName', 'resizable,scrollbars,width=' + config.popupWidth + ',height=' + config.popupHeight);
    } else {
      windowObjectReference.focus();
    }
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    onclick(event, element, elementType) {
      if (elementType === 'popup') {
        event.preventDefault();

        openPopup(element.href);
      }
    }
  };
});
