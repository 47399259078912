import '../services/lazy-load';

SNI.Application.addModule('vote-vertical-gallery', (context) => {
  let config,
      coldLoad = true;

  const debug = context.getService('logger').create('module.vote-vertical-gallery'),
        check = context.getService('check').new(debug),
        modUtil = context.getService('utility'),
        template = context.getService('template'),
        galleryManager = context.getService('gallery-manager'),
        lazyLoad = context.getService('lazy-load'),
        SniAds = context.getGlobal('SniAds'),
        adLibLoaded = check.exists('SniAds.Gallery'),
        behaviors = ['lazy-load', 'truncate'],
        defaults = {
          adFreq: 3,
          topClass: '[data-type="scroll-top"]',
          cardTextSelector: '[data-text]', // Container for card text
          cardSelector: '[data-card]',
          previousCard: $(),
          currentCard: $('[data-card]').first(),
          watchScroll: true,
          showingFirstGallery: true,
          previousScrollPosition: 0
        };
  let $window = $(window),
      scrollEvent = 'scroll',
      resizeEvent = 'resize',
      cardsContainer = '.photocards-inner',
      $cardsContainer = $(cardsContainer);
  
  // Determine if a message should be sent to the carousel
  function changeGallery(check, index) {
    if (check.changed && !(galleryManager.suspendChecks())) {
      context.broadcast('switch-to-gallery',{type: 'mobile', check, to: index});
    }
  }
  function checkScrollDirection() {
    let currentScrollPosition = $(window).scrollTop(),
        direction;
    if (currentScrollPosition > config.previousScrollPosition){
      direction = 'down';
    } else {
      direction = 'up';
    }
    config.previousScrollPosition = currentScrollPosition;
    return direction;
  }
  function checkProgress($card) {
    if (galleryManager.suspendChecks()) {
      debug.log('Check scroll - suspend Checks: true');
    }
    let index = Number($card.data('card-index'))||0;
    let gIndex = Number($card.data('gallery-index')||0);
    let percent = galleryManager.percentViewed(gIndex, index);
    let nextUp = galleryManager.getNextUpIndex(gIndex);
    let direction = checkScrollDirection();
    let hasStreamed = galleryManager.getHasStreamed(gIndex);
    
    if (galleryManager.hasBeenPrefetched(gIndex, 'has been rendered')) {
      let check = galleryManager.didGalleryChange(gIndex);
      changeGallery(check, gIndex);
    } 
    if (hasStreamed) return;
    if (!(galleryManager.hasBeenPrefetched(nextUp, 'Ready to render')) && percent>80 && percent <100 && direction==='down' && nextUp !== false) {
      galleryManager.setHasStreamed(gIndex);
      renderVerticalGallery({index:nextUp}, true);
    }
  }

  function handleScroll(event) {
    $(config.cardSelector).each(function(i) {
      let $photoCard = $(this),
          textElem = $photoCard.find(config.cardTextSelector),
          visibleElem = $photoCard,
          visibleCheck = 'partial',
          isInFullView = false,
          hasBeenSeen = $photoCard.data('hasBeenSeen'),
          topContainer = config.topClass;

      // Add class 'currentcard' to 'photocard(s)' visible on the viewport
      if (modUtil.isInViewport(visibleElem[0], visibleCheck)) {
        $photoCard.addClass('currentCard');
        config.currentCard = $photoCard;
        isInFullView = modUtil.isInViewport(textElem[0], 'full');

        // Add 'lazy-loaded' image
        lazyLoad.addImages({
          container: $photoCard,
          settings: {
            usePlaceholder: false
          }
        });

        // Set opacity - 1
        if (i >= 2) {
          $photoCard.find('.m-MediaBlock__a-Image').addClass('fade-in');
        }
        
        if (!config.currentCard.is(config.previousCard) && config.watchScroll && !galleryManager.suspendChecks() && isInFullView) {
          fullMonty({
            card: $photoCard,
            hasBeenSeen
          });
          config.previousCard = config.currentCard;
          checkProgress($photoCard);
        }
      } else {
        $photoCard.removeClass('currentCard');
      }

      if ($(this).scrollTop() >= config.topScrollOffset) {
        $(topContainer).removeClass('smooth-hide').addClass('smooth-move');
      } else {
        $(topContainer).removeClass('smooth-move').addClass('smooth-hide');
      }
      
      // Load ads
      if (adLibLoaded) {
        $('.bigbox-trigger').each(function() {
          if (modUtil.isInViewport($(this)[0], visibleCheck)) {
            $(this).addClass('currentBigBox');
            if (check.exists('SniAds.appendSlot')) {
              let bigboxID = SniAds.appendSlot('currentBigBox','dfp_bigbox',{},true);
              $(this).removeClass('currentBigBox bigbox-trigger');
              //smooth the ad load experience...
              let $bb = $('#' + bigboxID);
              $bb.after(template.loadingHTML('is-Subtle'));
            }
          }
        });
      }
    });
  }

  let setupScrollHandler = () => {
    let handler = modUtil.throttle(handleScroll, 60);
    $window.on(scrollEvent + ' ' + resizeEvent, handler);
  };

  function doProcessMetadata(init, data) {
    galleryManager.processMetadata(init, data);
  }

  let renderVerticalGallery = (refData, prefetch) => {
    debug.log('renderVerticalGallery', refData);

    let cardsContainer = '.photocards-inner',
        data = galleryManager.getGalleryData(refData.index),
        images = data.images,
        initialize = refData.init,
        renderIndex = galleryManager.getRenderIndex(),
        adFreq = config.adFreq,
        $ad = '<p class="ad-text">More photos after this Ad</p><div class="bigbox-ad bigbox-trigger lazyAd"></div>',
        $cardsContainer = $(cardsContainer),
        attrSel = '[data-attributions]',
        galleryInner = $(`<div class="gallery-inner" data-render-index="${renderIndex}" data-container-index="${refData.index}"></div>`),
        $attr;
    
    // Gallery switch
    if (initialize && adLibLoaded) {
      let adsEvent = SniAds && SniAds.Event;
      if (adsEvent.unsubscribe && adsEvent.subscribe){
        SniAds.Event.unsubscribe('slotRenderComplete', slotRenderCallback);
        SniAds.Event.subscribe('slotRenderComplete', slotRenderCallback);
      }
    }

    // Set galleryManager config
    galleryManager.setConfig(config);
    // Append cards
    $.each(images, (i, img) => {
      let $card;

      if (i===0 && initialize) return; // skip append of the first card
      $card = $(template.renderVerticalGallery(i+1, {image: img.image, renderIndex, galleryIndex: refData.index, description: img.description, title: img.title||'', attributionAllowed: config.attributionAllowed}));
      // CSS transition style: set 'opacity - 0'  
      if (i <= 1) {
        $card.find('.m-MediaBlock__a-Image').addClass('fade-in');
      }
      
      if (config.attributionAllowed) {
        $attr = $card.find(attrSel);
        galleryManager.updateAttributions($attr, img);
      }
      
      galleryInner.append($card);
      
      if (i!==0 && ((i+1)%adFreq===0)) {
        galleryInner.append($ad);
      }
    });

    $cardsContainer.append(galleryInner);
    if (refData.scrollTo && !initialize) {
      // scroll on append on streams
      let pos = galleryInner.offset();
      galleryManager.suspendChecks(true);
      if (window.scrollTo) {
        window.scrollTo(0, pos.top - 90);
        setTimeout(function(){
          galleryManager.suspendChecks(false);
        }, 550);
      } else {
        galleryManager.suspendChecks(false);
      }
    }
    if (!prefetch) {
      galleryManager.setCurrentGallery(refData.index);
      galleryManager.setPrefetch(refData.index, true);  // don't allow a prefetch later
    } else {
      galleryManager.setPrefetch(refData.index);
    }
    
    if (initialize) {
      setupScrollHandler();
    } else {
      if (!prefetch) {
        //galleryInner.animate({scrollTop:0});
        galleryManager.setActiveSlide(0);
        config.showingFirstGallery = false;
      }
    }
    
    // Lazy load images
    let $images = $cardsContainer.find('.m-MediaBlock__m-MediaWrap img');
    lazyLoad.loadImages($images);

    // truncate
    context.broadcast('behavior-truncate:truncate',{
      moreLessLinks: true,
      suffixString: 'more',
      dotsWithLinks: '... ',
      keepVerticalWhitespaces: true
    });
  };


  // Hide slot
  // let hideSlot = (id) => {
  //   debug.log('hiding slot', id);
  //   var $ele = $('#' + id);
  //   if (!$ele) return;
  //   $ele = $ele.closest('.bigbox-ad');
  //   $ele.css({
  //     padding: 0,
  //     margin: 0,
  //     transition: '0.2s all ease-out',
  //   });
  // };

  // Remove loader, hide empty ad slots
  let slotRenderCallback = (slot) => {
    let id = slot.slot.getSlotElementId();
    if (!id) return;
    let $ele = $('#' + id);
    //debug.log('vote-vertical-gallery: slotRenderComplete', id);
    $ele.siblings('.is-Subtle.a-LoaderWrap').remove();
    $ele.parent().removeClass('lazyAd');
    if (slot.isEmpty) {
      debug.log('vote-vertical-gallery: slot.isEmpty is true for id:', id);
      //hideSlot(id);
    }
  };


  let updateObject = (existing, updated) => {
    return Object.assign({}, existing, updated);
  };
  

  let setConfig = (oldSettings, newSettings) => {
    config = updateObject(oldSettings, newSettings);
  };

  /* Analytics */
  // VOTE Analytics
  let fullMonty = (data) => {
    // Update sharebar
    // Update analytics
    let { card, hasBeenSeen }  = data;
    let index = Number(card.data('card-index'));
    let gIndex = Number(card.data('gallery-index'));
    let rIndex = Number(card.data('render-index'));
    let gallery, cardData;
    if (gIndex>0) config.showingFirstGallery = false;
    if (!hasBeenSeen) {
      card.data('hasBeenSeen', true);
      if (config.showingFirstGallery && (index-1) === 0) return; // do not fire first slide event
      gallery = galleryManager.getImage(gIndex, index - 1);
      cardData = gallery.data;
      cardData.useCustom = true;
      cardData.gIndex = gIndex + 1;
      cardData.rIndex = rIndex;
      cardData.index = index;
      if (cardData) {
        doProcessMetadata(false, cardData);
      }
    }
  };
  

  const messageHandlers = {
    'mobile.carousel.slide.clicked': (data) => {
      data.scrollTo = true;
      renderVerticalGallery(data);
    },
    'mobile.carousel.slide.advance': (data) => {
      let voteIndex = data.index;
      let parentPos = $(`[data-container-index="${voteIndex}"]`).offset();
      if (parentPos) {
        galleryManager.suspendChecks(true);
        if (window.scrollTo) {
          window.scrollTo(0, parentPos.top - 90);
          setTimeout(function(){
            galleryManager.suspendChecks(false);
          }, 350);
        } else {
          galleryManager.suspendChecks(false);
        }
      }
    }
  };


  return {
    behaviors,
    messages: Object.keys(messageHandlers),

    onmessage: (msg, data) => {
      messageHandlers[msg](data);
    },
    destroy: () => {
      $window.off(scrollEvent + ' ' + resizeEvent, handleScroll);
      $cardsContainer.empty();
    },
    init: () => {
      setConfig(defaults, context.getConfig());
      if (adLibLoaded) {
        if (!coldLoad) {
          debug.log('Not resetting the gallery');
          //SniAds.Gallery.reset();
        }
        coldLoad = false;
      }
      if (check.exists('SniAds.Event')) {
        SniAds.Event.subscribe('slotRenderComplete', slotRenderCallback);
      }
    }
  };
});
