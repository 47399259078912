SNI.Application.addModule('recipe-info', context => {

  return {
    behaviors: ['toggle-on-message'],
    init() {
      //console.log('recipeInfo init');
    }
  };

});
