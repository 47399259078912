SNI.Application.addBehavior('multi-component-dynamic-ad', function(context) {
  const ads = context.getService('ads');

  return {
    init: () => {
      ads.getDynamicSlots( $(context.getElement()).parent() ).forEach(function(slot) {
        ads.lazyLoadAd(slot);
      });
    }
  };

});
