/**
 * Module is managing the list of selected ingredients
 * API (broadcasted events)
 *  'recipeIngredients:selected'    - some ingredient got selected
 *  'recipeIngredients:unselected'  - some ingredient got un-selected
 *  'recipeIngredients:none'        - all ingredients got un-selected
 *  'recipeIngredients:all'         - all ingredients got selected
 *  'recipeIngredients:submit'      - ingredients is submited to brand (recipebox, walmart, instacart etc.)
 *
 */
SNI.Application.addModule('recipe-ingredients', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const debug = context.getService('logger').create('module.recipe-ingredients');
  let utility = context.getService('utility');
  const mdManager = context.getGlobal('mdManager');

  const dalton = SNI.Config.useDaltonLogin ? context.getService('dalton/dalton-interface') : null;
  const userInterface = SNI.Config.useDaltonLogin ? context.getService('user/user-data') : null;

  // KD - Remove/refactor after shopping list launch successful
  const newSLVersion = context.getGlobal('isShoppingListEnabled');
  let checkedAgainForNewSLVersion = false;

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  const defaults = {
    item: {
      selectorAllIngredients: '.o-Ingredients__m-Body'
    },
    section: {
      footer: '.o-Ingredients__m-Footer'
    }
  };

  const settings = $.extend(true, {}, defaults, context.getConfig());
  const { graphQLEndpoint, shoppingListUrl, ingredientSubstitutionsUrl } = settings;

  const $element = $(context.getElement());
  // Ingredients/Checkboxes
  const $selectAll = $('.o-Ingredients__a-Ingredient--SelectAll');
  const $selectAllCheckBox = $selectAll.find('.o-Ingredients__a-Ingredient--Checkbox');
  const $selectAllLabel = $selectAll.find('.o-Ingredients__a-Ingredient--CheckboxLabel');
  const $ingredientsCheckboxes = $('.o-Ingredients__a-Ingredient:not(.o-Ingredients__a-Ingredient--SelectAll) .o-Ingredients__a-Ingredient--Checkbox');
  const $allCheckboxes = $('.o-Ingredients__a-Ingredient--Checkbox');
  //Buttons
  const $addToShoppingList = $('.a-Button--AddToShoppingList');
  const $viewShoppingList = $('.a-Button--ViewShoppingList');
  const $textIngredients = $('.a-Button--TextIngredients');
  const $ingredientSubstitutions = $('.a-Button--IngredientSubstitutions');

  // KD - Remove/refactor after shopping list launch successful
  function toggleFunctionalityForShoppingList() {
    debug.log('Shopping List: newSLVersion: ', newSLVersion);
    if (newSLVersion === null) {
      if (!checkedAgainForNewSLVersion) {
        debug.log('Shopping List: var not set, try again in a couple seconds');
        checkedAgainForNewSLVersion = true;
        setTimeout(() => {
          toggleFunctionalityForShoppingList();
        }, 3000);
      } else {
        debug.log('Shopping List: var not set, giving up');
      }
    } else if (newSLVersion === true) {
      debug.log('Shopping List: ENABLED');
      // Show shopping list buttons
      $addToShoppingList.css('display', 'flex');
      $viewShoppingList.css('display', 'flex');
    } else if (newSLVersion === false) {
      debug.log('Shopping List: NOT ENABLED');
    }
  }

  // Manage ingredients
  function isEverythingChecked() {
    let everythingChecked = true;
    $ingredientsCheckboxes.each(function() {
      if (!$(this).prop('checked')) {
        everythingChecked = false;
        return false;
      }
    });
    return everythingChecked;
  }

  function getCheckedIngredients() {
    const checkedIngredients = [];
    $ingredientsCheckboxes.each(function() {
      if ($(this).prop('checked')) {
        checkedIngredients.push($(this).attr('value'));
      }
    });
    return checkedIngredients;
  }

  function checkAllIngredients() {
    $ingredientsCheckboxes.prop('checked', true);
    changeToDeselectAll();
  }

  function uncheckAllIngredients() {
    $ingredientsCheckboxes.prop('checked', false);
    changeToSelectAll();
    $selectAllCheckBox.prop('checked', false);
  }

  function changeToSelectAll() {
    $selectAllCheckBox.prop('value', 'Select All');
    $selectAllCheckBox.prop('checked', false);
    $selectAllLabel.text('Select All');
  }

  function changeToDeselectAll() {
    $selectAllCheckBox.prop('value', 'Deselect All');
    $selectAllCheckBox.prop('checked', true);
    $selectAllLabel.text('Deselect All');
  }

  // Manages Select All and button states
  function updateShoppingElements() {
    const disabledClass = 'o-Ingredients__a-Button--Disabled';
    const checked = getCheckedIngredients();
    // If no ingredients are checked, disable the text/add to list buttons.
    if (checked.length < 1) {
      $addToShoppingList.addClass(disabledClass);
      $textIngredients.addClass(disabledClass);
      $addToShoppingList.prop('disabled', true);
      $textIngredients.prop('disabled', true);
      changeToSelectAll();
    } else {
      $addToShoppingList.removeClass(disabledClass);
      $textIngredients.removeClass(disabledClass);
      $addToShoppingList.prop('disabled', false);
      $textIngredients.prop('disabled', false);
      // If everything is checked, it should read deselect all. Otherwise, select all.
      if (checked.length === $ingredientsCheckboxes.length) {
        changeToDeselectAll();
      } else {
        changeToSelectAll();
      }
    }
  }

  const addItemsQuery = `
    mutation addItems(
      $items: [String!]!
      $assetID: ID!
      $assetType: String = "class"
    ) {
      shoppingList {
        addItems(
          items: $items
          asset: { id: $assetID, brand: "food", type: $assetType }
        ) {
          items {
            text
            measure
          }
        }
      }
    }
  `;

  function fetchToken() {
    let token;
    if (SNI.Config.useDaltonLogin) {
      token = userInterface.getUserToken();
    }
    return token;
  }

  // Add ingredients to FNK shopping list
  function addIngredientsToShoppingList() {
    const checkedIngredients = getCheckedIngredients();
    const recipeId = mdManager.getDetailId();
    const authToken = fetchToken();
    if (checkedIngredients.length > 0 && recipeId && authToken) {
      const requestVariables = {
        'items': checkedIngredients,
        'assetID': recipeId,
        'assetType': 'recipe'
      };
      utility.postGraphQLRequest(graphQLEndpoint, authToken, addItemsQuery, requestVariables);
      showNotificationBar(checkedIngredients.length);
      clickTracking(shoppingListUrl, 'shopping list', 'shopping list add item from recipe');
    }
  }

  // Texting Ingredients
  function generateTextMessageAndSend(ingredients) {
    const recipeTitle = mdManager.getParameter('Title');
    const author = $('.m-RecipeSummary > .attribution .o-Attribution__m-Author').text().replace(/\s+/g, ' ').trim();
    const messageHeader = `${utility.toTitleCase(recipeTitle)} \n${author} \n${window.location.href} \n\n`;
    const ingredientsParsed = ingredients.reduce((text, ingredient) => {
      return text += `${ingredient.trim()}\n`;
    }, 'INGREDIENTS:\n');
    const textMessage = messageHeader + ingredientsParsed;
    debug.log('textMessage: ', textMessage);
    textIngredients(textMessage);
  }

  function textIngredients(ingredients) {
    window.open(`sms:?&body=${encodeURIComponent(ingredients)}`, '_system');
  }

  // 'Snackbar' Notification bar
  function showNotificationBar(numberIngredients = 1) {
    if (isNaN(numberIngredients)) {
      return;
    }

    const showNotificationBarClass = 'fnk-notification-bar--show';
    let $notificationBar = $element.find('.fnk-notification-bar');
    const modalExists = $notificationBar.length !== 0;

    if (!modalExists) {
      // Create and add notification bar
      const text = numberIngredients > 1 ? `${numberIngredients} ingredients added.` : `${numberIngredients} ingredient added.`;
      const markup = `
        <div class="fnk-notification-bar fnk-notification-bar--show">
          <p class="fnk-notification-bar__text">
            ${text}
          </p>
          <a href="${shoppingListUrl}" class="fnk-notification-bar__button">View List</a>
        </div>
      `;
      $element.append(markup);

      // Add class for animation
      $notificationBar = $element.find('.fnk-notification-bar');
      let $notificationLink = $element.find('.fnk-notification-bar__button');
      $notificationBar.addClass(showNotificationBarClass);
      $notificationLink.on('click', function() {
        clickTracking(shoppingListUrl, 'shopping list', 'view shopping list');
      });
      setTimeout(() => {
        $notificationBar.removeClass(showNotificationBarClass);
      }, 3000);
    } else {
      // Update text in notification bar
      const $notificationBarText = $notificationBar.find('.fnk-notification-bar__text');
      const newText = numberIngredients > 1 ? `${numberIngredients} ingredients added.` : `${numberIngredients} ingredient added.`;
      $notificationBarText.text(newText);

      // Add class for animation
      $notificationBar.addClass(showNotificationBarClass);
      setTimeout(() => {
        $notificationBar.removeClass(showNotificationBarClass);
      }, 3000);
    }
  }

  // Listeners
  function attachListeners() {
    if (SNI.Config.useDaltonLogin) {
      $addToShoppingList.on('click', function(e) {
        if (userInterface.getLoginStatus()) {
          addIngredientsToShoppingList();
        } else {
          dalton.login();
        }
      });
    }

    $textIngredients.on('click', function(e) {
      e.preventDefault();
      const checkedIngredients = getCheckedIngredients();
      generateTextMessageAndSend(checkedIngredients);
    });

    // Update elements when clicking ingredients
    $allCheckboxes.on('click', function(e) {
      // If they're clicking Select/Deselect All we need to toggle all the checkboxes
      if ($(e.target).parent().parent().hasClass('o-Ingredients__a-Ingredient--SelectAll')) {
        const everythingChecked = isEverythingChecked();
        if (everythingChecked) {
          uncheckAllIngredients();
        } else {
          checkAllIngredients();
        }
      }

      updateShoppingElements();
    });

    //Analytics Tracking Calls when clicking View Shopping List
    $viewShoppingList.on('click', function(){
      clickTracking(shoppingListUrl, 'shopping list', 'view shopping list');
    });

    $ingredientSubstitutions.on('click', function(){
      clickTracking(ingredientSubstitutionsUrl, 'Ingredient Substitutions', 'view ingredient substitutions');
    });
  }

  function clickTracking(href, module, action){
    if (window && window.moduleTrack) {
      const currentPage = window.location.href;
      // https://discoveryinc.atlassian.net/wiki/spaces/ANY/pages/282074519/Discovery+Standard+Module+Click+Tracking
      window.moduleTrack(this, module, action, '0', currentPage, href, 'click');
    }
  }

  let init = function() {
    debug.log('Module started', settings);
    attachListeners();
    // KD - Remove/refactor after shopping list launch successful
    toggleFunctionalityForShoppingList();
  };

  return {
    init
  };

});