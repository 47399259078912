SNI.Application.addBehavior('region-toggle', context => {
  let el = context.getElement();
  let $el = $(el);
  let debug = context.getService('logger').create('behavior.region-toggle');

  const defaults = {
    'regionToggle': {
      'regions': {
        'A': '.bodyLeft',
        'B': '.bodyRight, .o-RelatedContent, .m-ShowAttribution, .o-Tags, .o-PackagePromo, .o-ReadMore',
      },
      'initOff': 'B',
    },
  };

  const config = Object.assign({}, defaults, context.getConfig());

  let IBtnTempl = `<div class="m-ButtonToggle">
      <span class="a-Tab a-Tab--Active">Ingredients</span>
      <a class="a-Tab" data-type="region-select" data-target-region="B">Directions</a>
    </div>`;
  let DBtnTempl = `<div class="m-ButtonToggle">
      <a class="a-Tab" data-type="region-select" data-target-region="A">Ingredients</a>
      <span class="a-Tab a-Tab--Active">Directions</span>
    </div>`;
 
  const rs = config.regionToggle.regions;
    
  function getRegion(reg) {
    return rs[reg];
  }  

  function getRegionNot(reg) {
    return Object.keys(rs).filter(r => r !== reg).map(r => rs[r]).reduce((a,b) => a+b);
  }

  function doInit() {
    if ($el.find('.bodyLeft').length > 0) {
      debug.log('init');
      $('.o-Ingredients__m-Header').replaceWith(IBtnTempl);
      $('.o-Method__m-Header').replaceWith(DBtnTempl);
      $(getRegion(config.regionToggle.initOff)).hide();
      context.broadcast('read-more-off');
    }
  }

  function onclick(evt, elt, eltType) {
    debug.log('clicked ' + eltType);
    switch (eltType) {
      case 'region-select':
        evt.preventDefault();
        let region = $(elt).attr('data-target-region');
        debug.log('target-region ' + region);
        debug.log('getRegion(region)) ' + getRegion(region));
        debug.log('getRegionNot(region)) ' + getRegionNot(region));
        $(getRegionNot(region)).hide();
        $(getRegion(region)).show();
        if (region === config.regionToggle.initOff) 
          context.broadcast('read-more-on');
        else   
          context.broadcast('read-more-off');
        break;
    }
  }

  return {
    onclick,
    onmessage: {
      'enable-region-toggle': () => doInit(),
    },
  };

});
