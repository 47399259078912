/**
 * @Announcements Module
 */

SNI.Application.addModule('announcements', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------


  const $el   = $(context.getElement());
  const $popover = $el.clone();
  const elementOffsetTop = $el.offset().top + 300;
  const $closeBtn = '<a class="o-Announcements--Close" data-type="popover-dismiss">x</a>';
  const debug = context.getService('logger').create('module.announcements');
  const cookie = context.getService('cookie');
  const cookieTime = 10;
  let check = context.getService('check').new(debug);

  const BETA_COOKIE = 'recipeBeta2018';  

  const deviceType = context.getService('device-type');
  const isMobile = deviceType.isMobile;
  let config;

  const defaults = {
    restrictToPages: ['recipePage'],
    elementSelector: '.rightRailTop .o-FreeFormHTMLArea',
    popoverClass: 'o-Announcements--Fixed'
  };

  const hoursToExpire = 48;

  function setNewExpireTime(hours) {
    const currentTime = new Date().getTime();
    const newTime = currentTime + (hours * 3600000);
    debug.log('New expire time:', new Date(newTime));
    return newTime;
  }

  function showPopover() {
    $popover.show();
  }

  function hidePopover() {
    $popover.hide();
  }

  function checkExpireDate() {
    if (check.supports('localStorage')) {
      const savedTime = localStorage.getItem('hideSurveyTimer');

      if (savedTime !== null) {
        debug.log('Storage value \'hideSurveyTimer\' is set');

        debug.log('savedTime', new Date(JSON.parse(savedTime)));

        let currentTime = new Date().getTime();
        debug.log('currentTime', new Date(currentTime));

        if (savedTime < currentTime) {
          debug.log('Saved time less than current time, clear storage value \'hideSurveyTimer\' and show modal');
          localStorage.removeItem('hideSurveyTimer');
          return true;
        } else {
          debug.log('Saved time greater than current time, extend expire time and don\'t show modal');
          localStorage.setItem(
            'hideSurveyTimer',
            setNewExpireTime(hoursToExpire)
          );
          return false;
        }
      } else {
        debug.log('Storage value \'hideSurveyTimer\' not set, show modal');
        return true;
      }
    } else {
      debug.log('localStorage not supported, show modal');
      return true;
    }
  }

  function configurePopover() {
    // Do not render popover on mobile.
    if (isMobile) return;

    // Add Close Btn
    $popover.prepend($closeBtn);

    // Update Popover ID for survey button.
    $popover.find('#betasurveyid').attr('id','betasurveyid_popover');

    // On close btn click
    $popover.find('[data-type="popover-dismiss"]').on('click', function() {
      $(document).off('scroll.announcements');
      hidePopover();
      // Set time value so the modal won't show again until that's met
      if (check.supports('localStorage')) {
        localStorage.setItem(
          'hideSurveyTimer',
          setNewExpireTime(hoursToExpire)
        );
      }
    });

    // On delete-cookie btn click
    $popover.on('click', '[data-type="delete-cookie"]', () => {
      setCookie(BETA_COOKIE, 'off', cookieTime);
    });
    
    // On set-cookie btn click
    $popover.on('click', '[data-type="set-cookie"]', () => {
      setCookie(BETA_COOKIE, 'on', cookieTime);
    });

    // Hide Popover
    hidePopover();

    // Append popover to the body
    $('body').append($popover);

    // Add Fixed class to popover
    $popover.addClass(config.popoverClass);

    // Toggle popover on scroll
    toggleOnScroll();
  }

  // Toggle popover on scroll
  function toggleOnScroll() {
    $(document).on('scroll.announcements', function() {
      let currPosition = $(this).scrollTop();

      (currPosition > elementOffsetTop) 
        ? showPopover() 
        : hidePopover();

    });
  }

  // Test whether to render component on current page.
  function renderOnCurrentPage() {
    // Get class that represents the page name
    let isPageClass = (pageClass) => pageClass.toLowerCase().includes('page');

    // Get Current Page class
    let currentPage = $('body').attr('class').split(' ').filter(isPageClass)[0];

    return config.restrictToPages
      .map(page => page.toLowerCase())
      .includes(currentPage.toLowerCase());
  }

  // Remove `config.elementSelector` element from the DOM if not a root element.
  function removeElement() {
    // Do not remove Root Free Form HTML element.
    if (config.elementSelector.replace(/\./g, '') === 'freeFormHTMLArea') return;

    $(config.elementSelector).has('.o-Announcements').remove();
  }

  // Set Cookie
  function setCookie(c_name, value, exdays = 7) { 
    if (value === 'off') {
      cookie.remove(c_name);
      debug.log(`toggling beta cookie to ${value}`);
      window.location.reload();
    } else if (value === 'on') {
      cookie.set(c_name, value, exdays);
      debug.log(`toggling beta cookie to ${value}`);
      window.location.reload();
    }
  }


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {

    init() {
      debug.log('init');

      config = Object.assign({}, defaults, context.getConfig());

      // Do not render on pages that are not defined in config.restrictToPages
      if (!renderOnCurrentPage()) {
        removeElement();
        return;
      }

      // Check to see if they closed it already and if enough time has passed to show it again
      if (checkExpireDate()) {
        // Clone component and create a popover
        configurePopover();
      }      
    },

    onclick(event, element, elementType) {

      switch (elementType) {
        case 'delete-cookie':

          setCookie(BETA_COOKIE, 'off', cookieTime);

          break;

        case 'set-cookie':

          setCookie(BETA_COOKIE, 'on', cookieTime);

          break;

        default:
          break;
      }
    },

  };
});
