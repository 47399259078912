export const pauseAllVideos = (players) => {
  if (players || SNI && SNI.Video && SNI.Video.Players) {
    Object.values(players || SNI.Video.Players)
      .filter(player => !player.isPaused())
      .forEach(player => player.pause());
  }
};

export default class Player {
  constructor(
    configOrId,
    { player = SNI.Video.Player, playerCollection = SNI.Video.Players } = {}
  ) {
    player = player || SNI.Video.Player;
    playerCollection = playerCollection || SNI.Video.Players;

    this.playerCollection = playerCollection;
    this._listeners = [];

    // Player exists, providing containerId
    if (typeof configOrId === 'string') {
      this._player = playerCollection[configOrId];

      // Player exists, provided config but id gotten off of player collection
    } else if (configOrId.player && configOrId.player.containerId in playerCollection) {
      this._player = playerCollection[configOrId.player.containerId];

      // Initialize new player
    } else {
      const finalConfig = Object.assign({}, configOrId, {
        freewheel: {
          enabled: !window.location.href.match(/[?|&]nopreroll/)
        }
      });

      this._player = new player(finalConfig);
    }
    this._parent = $(this._player.containerId).parent();
  }

  play(videoNum, delay = 500) {
    setTimeout(() => {
      this._player.play(videoNum, true, true);
    }, delay);
  }

  pause() {
    // Pause is a toggle by default (bad juju)
    this._player.pause(true);
  }

  resume() {
    this._player.pause(false);
  }

  pauseAllVideos() {
    pauseAllVideos(this.playerCollection);
  }

  _addEventListener(event, callback) {
    const listener = e => callback(this._player.currentVideoIdx || 0, e);
    this._listeners.push([event, listener]);

    SNI.Video.Events.addEventListener(
      event,
      listener,
      this._player.config.player.containerId
    );
  }

  OnPlayerLoaded(callback) {
    this._addEventListener('OnPlayerLoaded', callback);
  }

  onPreroll(callback) {
    this._addEventListener('OnMediaStart', (vidNum, event) => {
      let clip = event.data;

      if (clip.baseClip.isAd) {
        callback(vidNum, event);
      }
    });
  }

  onVideoStart(callback) {
    this._addEventListener('OnMediaStart', callback);
  }

  OnLoadReleaseUrl(callback) {
    this._addEventListener('OnLoadReleaseUrl', callback);
  }

  onContentStart(callback) {
    this._addEventListener('OnMediaStart', (vidNum, event) => {
      let clip = event.data;

      if (!clip.baseClip.isAd) {
        callback(vidNum, event);
      }
    });
  }

  OnMediaLoadStart(callback) {
    this._addEventListener('OnMediaLoadStart', callback);
  }

  onVideoPause(callback) {
    this._addEventListener('OnMediaPause', callback);
  }

  onVideoUnpause(callback) {
    this._addEventListener('OnMediaUnpause', callback);
  }

  clearListeners() {
    this._listeners.forEach(([event, listener]) =>
      SNI.Video.Events.removeEventListener(event, listener)
    );
  }
}
