SNI.Application.addService('watch-button', function(application) {

  let debug                   = application.getService('logger').create('service.watch-button');
  let templateService         = application.getService('template');
  let getBrand                = application.getService('getBrand');

  const brand = getBrand.basic();

  // Used in replaceWatchButton below.
  const waitForElementToDisplay = (selector, time, callback) => {
    if (document.querySelector(selector) !== null) {
      return callback();
    } else {
      setTimeout(function() {
        waitForElementToDisplay(selector, time, callback);
      }, time);
    }
  };

  const replaceWatchButton = (oldBtnSelector, newBtnSelector, title = 'WATCH') => {
    waitForElementToDisplay(oldBtnSelector, 250, () => {
      const $oldWatchBtn = $(oldBtnSelector);

      $oldWatchBtn.html(`<div class="a-Button ${newBtnSelector}">${templateService.watchButton(title, isDiscovery('discovery', brand))}</div>`);
    });
  };

  const addWatchButton = (to, newBtnSelector = 'a-Button--WatchBtn', title = 'Watch Now') => {
    const $to = $(to);
    const watchBtnTemplate = `<button class="customPlayButton" title="Play video" aria-label="Play video">
                                <div class="a-Button ${newBtnSelector}">
                                  ${templateService.watchButton(title, isDiscovery('discovery', brand))}
                                </div>
                              </button>`;

    return $to.append(watchBtnTemplate);
  };

  const isDiscovery = (trueCondition = true, falseCondition = false) => (brand === 'discovery' || brand === '') ? trueCondition : falseCondition;

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */
  return {

    replaceWatchButton,
    isDiscovery,
    addWatchButton,

    init: function() {
      debug.warn('.init() is called.');
    }

  };

});
