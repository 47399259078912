SNI.Application.addModule('calendar', function(context) {

  const MSEC_PER_DAY = 24 * 60 * 60 * 1000;

  let $element   = $(context.getElement()),
      debug      = context.getService('logger').create('module.calendar');

  let defaults = {
    format: 'YYYY.MM.DD',
    futureDays: '1000',
  };

  let config = Object.assign({}, defaults, context.getConfig());
  
  const maxDate = new Date(Date.now() + config.futureDays * MSEC_PER_DAY);

  function getDateFromPath() {
    let d = document.location.pathname.match(/.*tv-schedule[\.\-](\d\d\d\d\.\d\d\.\d\d)\..*/);
    return d === null ? d : d[1];
  }

  return {

    config,

    init: async function() {

      const { default: Pikaday } = await import('pikaday' /* webpackChunkName: "dateTime" */);
      const { default: moment } = await import('moment' /* webpackChunkName: "dateTime" */);

      debug.log('calendar init');
      let picker;

      picker = new Pikaday({
        firstDay: 0,
        field: $element[0],
        showDaysInNextAndPreviousMonths: true,
        maxDate,
        onSelect: function() {
          // change path to selected date--if it isn't that already
          let path = $element.data('path'),
              pattern = $element.data('pattern'),
              url = '';
          let dateFromPath = getDateFromPath();
          let formatedDate = this.getMoment().format(config.format);
          debug.log('user selected date: ' + formatedDate);
          debug.log('current schedule date: ' + dateFromPath);
          // rewrite location only if different (today is null)
          if ((formatedDate === dateFromPath) || (dateFromPath === null && formatedDate === moment().format(config.format)))
            debug.log('selected date already loaded');
          else {
            debug.log('loading schedule for: ' + formatedDate);
            if (path !==undefined && pattern !==undefined){
              url = path.replace(pattern, formatedDate);
            }
            //update input value with formatted date
            $element.val(formatedDate);
            //if path and pattern are provided then trigger url;
            if (url !== ''){
              document.location = url;
            }
          }
        }
      });
      // setting the selected date class allows styling
      let seldate = picker.getMoment();   // selected date is today if not explicit in path
      let dateFromPath = getDateFromPath();
      if (dateFromPath !== null)
        seldate = moment(dateFromPath, 'YYYY.MM.DD');
      debug.log('selected date ' + seldate.format('YYYY-MM-DD'));
      if (seldate <= maxDate)
        picker.setMoment(seldate, true);
      debug.log(picker);
    }

  };

});
