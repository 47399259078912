SNI.Application.addModule('vine-embed', (context) => {

  const debug = context.getService('logger').create('module.vine-embed');

  let module,
      element,
      defaults,
      instance,
      config;

  defaults = {
    jsURL: '//platform.vine.co/static/scripts/embed.js',
    embedURL : 'https://vine.co/oembed.json',
    embedName: 'vine'
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setEmbedConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function loadEmbed() {
    let url = config.apiPath,
        vineFormat =  config.format || 'simple',
        dimension;

    if (typeof url !== 'undefined' && typeof vineFormat !== 'undefined') {
      dimension = element.outerWidth();
      url = `${url}/embed/${vineFormat}`;
      element.html(`<iframe src="${url}" width="${dimension}" height="${dimension}" frameborder="0"></iframe>`);
    } else {
      debug.warn('loadEmbed: invalid embed parameters: unable to load vine embed!');
    }
  }

  module = {
    init() {
      setEmbedConfig(defaults, context.getConfig());
      element = $(context.getElement());
      instance = element.attr('id');
      loadEmbed();
      $(window).off(`resize.vine.${instance}`).on(`resize.vine.${instance}`, function() {
        element.find('iframe').attr('width', element.outerWidth());
        element.find('iframe').attr('height', element.outerWidth());
      });
    },

    updateObject,
    setEmbedConfig
  };

  return module;

});
