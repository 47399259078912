SNI.Application.addModule('itk-activation', (context) => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const debug = context.getService('logger').create('module.itk-activation'),
        gigya = context.getGlobal('gigya'),
        gigyaService = context.getService('gigya/account'),
        tracking = context.getService('gigya/tracking');

  let verifiedToken = false,
      finalTokenString,
      baseUrl = context.getConfig('baseUrl'),
      upsellUrl = context.getConfig('upsellUrl'),
      customGigyaMsg = '.itk',
      isFnk = context.getConfig('appUser') === 'fnk' ? true : false,
      isSubscribe = context.getConfig('appUser') === 'subscribe';

  const getAccountInfo = () => {
    debug.log('getAccountInfo called');
    gigyaService.isLoggedIn(customGigyaMsg);
  };

  const processGigyaUserInfo = (data) => {
    if (isFnk) {
      tracking.trackOnProfile('fnk');
    }
    gigya.accounts.getJWT({ callback: ({ id_token }) => {
      var xhr = new XMLHttpRequest();
      var url = baseUrl + 'v1/link?code=' + finalTokenString;

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('Authorization', 'Bearer ' + id_token);

      xhr.onload = function() {
        debug.log('onload ' + isSubscribe);
        if (xhr.status === 204){
          debug.log('Success : User profile is successfully linked to device id.' + xhr.status);
          if (isSubscribe) {
            debug.log('subscribe success');
            window.location = upsellUrl+'?affiliate=comcast';
          } else {
            debug.log('toggle success ' + isSubscribe);
            //Hide Input Section and Display Thank you message.
            $('.activation-token-container').toggle();
            $('.success').toggle();
          }
         
        }

        if (xhr.status === 404){
          debug.log('404 error while associating user profle to device id.');
        }
      };

      xhr.onerror = function(jqXHR) {
        debug.log('Error while associating user profle to device id.');
      };
      xhr.send();
    }});
  };

  const messageHandlers = {
    'gigya.isloggedIn': (response) => {
      if (verifiedToken === false) {
        return;
      }
      processGigyaUserInfo(response);
    },
    'gigya.isloggedIn.itk': (response) => {
      processGigyaUserInfo(response);
    },
    'gigya.isloggedOut.itk': () => {
      gigyaService.showLoginScreen(true,false,'', function(){});
    }
  };

  return {
    behaviors:  ['gigya/profile'],
    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },
    init(){
      //focus on first text field
      $('.activation-token-container input')[0].focus();

      //move focus to next text field
      $('.activation-token-container input').on('input', function() {
        if ($(this).val().length === parseInt($(this).attr('maxlength'))) {
          $(this).val($(this).val().toUpperCase());
          $(this).next().focus();
        }
      });

      //Enable active button after entering all tokens
      $('#token6').on('keyup', function() {
        if ($(this).val().length === parseInt($(this).attr('maxlength'))){
          $(this).val($(this).val().toUpperCase());
          $('#submit-activation').removeAttr('disabled');
        }
      });
      //submit entered token
      $('#submit-activation').on('click', function() {
        finalTokenString = $('#token1').val()+$('#token2').val()+$('#token3').val()+$('#token4').val()+$('#token5').val()+$('#token6').val();

        var xhr = new XMLHttpRequest();
        var url = baseUrl + 'v1/codes/'+ finalTokenString;

        xhr.open('GET', url, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        xhr.onload = function() {
          if (xhr.status === 204){
            verifiedToken = true;
            getAccountInfo();
          }

          if (xhr.status === 404){
            alert('\'' + finalTokenString + '\' is invalid token. Please enter a valid token.');
            debug.log(finalTokenString + ' is invalid token. Please enter a valid token.');
          }
        };

        xhr.onerror = function(jqXHR) {
          debug.log('Error while validating entered code' + xhr.response);
        };
        xhr.send();
      });
    }
  }; //end return
});
