SNI.Application.addModule('breadcrumb', context => {
/**
  * for lengthy breadcrumbs on mobile viewports
  *   (ULPs where terminal breadcrumb -- the page title -- would be offscreen),
  *   scroll to the (right) end
  */

  const HIDDEN_CLASS = 'masked';

  const debug = context.getService('logger').create('module.breadcrumb');

  return {
    init() {
      debug.log('init ');
      const bcElt = context.getElement();
      const scrollAmt = bcElt.scrollWidth - bcElt.clientWidth;
      debug.log('amt ' + scrollAmt);
      if (scrollAmt > 0) {
        bcElt.scrollBy({ top: 0, left: scrollAmt, behavior: 'instant' });
        debug.log('scrolled ' + scrollAmt);
      }
      bcElt.classList.remove(HIDDEN_CLASS);
    },
  };
});
