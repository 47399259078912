SNI.Application.addService('model', application => {

  return {

    createModel(modelConfig) {
      let data = Object.assign({}, modelConfig.data);
      let model = Object.assign({}, modelConfig.methods);

      Object.keys(data).forEach(key => Object.defineProperty(model, key, {
        get: () => data[key],
        set(val) {
          data[key] = val;
          if (modelConfig.watch && modelConfig.watch[key]) {
            modelConfig.watch[key](val);
          }
          if (modelConfig.watchAll) {
            modelConfig.watchAll(key, val);
          }
        },
        enumberable: true,
        configurable: true
      }));
      return model;
    }

  };

});
