SNI.Application.addModule('single-image', context => {
  const debug = context.getService('logger').create('module.single-image');
  const pinSvc = context.getService('pinterest-button');
  const pinConfig = context.getConfig('social-pinterest');
  const util = context.getService('utility');

  let defaults = {
    pinterestEnabled: true,
  };

  let config = Object.assign({}, defaults, context.getConfig());

  const module = {

    behaviors: ['shop-this-look', 'lazy-load'],

    init() {
      const $imageElement = $(context.getElement());

      if (config.pinterestEnabled) {
        
        let protocol = window.location.protocol + '//';
        let pageHost = window.location.host;

        if (util.isDebug()) {
          // When using a lower environment, Pinterest will not have the SSL cert needed to complete the request
          pinConfig.pageURL = util.getProdUrl(window.location.href);
          // A prod page host is needed to since Pinterest will otherwise make https requests to a lower environment 
          pageHost = util.getProdUrl(window.location.host);
        }
        if (pinConfig.imageURL) {
          // A CORS issue occurs when we keep the sndimg domain for the images, so we use the page's domain instead
          let pinImg = pinConfig.imageURL;
          pinImg = ((pinImg) ||'').replace(/.+\.sndimg\.com/, pageHost);
          pinImg = /https?\:\/\//.test(pinImg) ? pinImg : protocol + pinImg;
          pinConfig.imageURL = pinImg;
        }
        debug.log('Updating pin config', pinConfig);
        pinSvc.setPinButton({
          $element: $imageElement,
          itemConfig: pinConfig,
          data: {}
        });
      }
    }
  };

  return module;
});
