SNI.Application.addModule('recipe-shopping-list', (context) => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const debug = context.getService('logger').create('module.recipe-box'),
        mdManager = context.getGlobal('mdManager'),
        gigyaService = context.getService('gigya/account'),
        detailId = mdManager.getDetailId();
  let gigyaObject,
      gigyaUserId,
      signatureTimestamp,
      getAccountInfo,
      uidSignature,
      shoppingList,
      customGigyaMsg = '.shopping-list';

  //let config = Object.assign({}, defaults, context.getConfig());
  //const $element = $(context.getElement());

  getAccountInfo = () => {
    debug.log('getAccountInfo called');
    gigyaService.isLoggedIn(customGigyaMsg);
  };

  function processGigyaUserInfo(eventObj) {
    gigyaObject = eventObj;
    gigyaUserId = gigyaObject.UID;
    signatureTimestamp = gigyaObject.signatureTimestamp;
    uidSignature = encodeURIComponent(gigyaObject.UIDSignature);

    let baseUrl = `//recipes.foodnetwork.com/api/shoppingList/add?recipeId=${detailId}&gigyaUserId=${gigyaUserId}&signatureTimestamp=${signatureTimestamp}&uidSignature=${uidSignature}`;

    //Do Ajax post
    $.ajax({
      type: 'POST',
      url: baseUrl,
      data: {'ingredients[]': shoppingList.items},
      success: function(data){
        context.broadcast('hide-dialog-uncheck-all', 'Extra data');
      }
    });
  }

  const messageHandlers = {
    'recipeIngredients:submit': ( list ) => {
      shoppingList = list;
      getAccountInfo();
    },
    'gigya.isloggedIn.shopping-list': (response) => {
      processGigyaUserInfo(response);
    },
    'gigya.isloggedOut.shopping-list': () => {
      gigyaService.showLoginScreen('',true,false,'');
    }
  };

  return {
    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    }
  }; //end return

});
