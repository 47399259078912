import '../services/modal';
import '../services/utility';
import '../services/template';
import '../services/device-type';

SNI.Application.addModule('photo-gallery-launcher', context => {
  let modal = context.getService('modal');
  let deviceType = context.getService('device-type');
  let pinSvc = context.getService('pinterest-button');
  let pinConfig = context.getConfig('social-pinterest');
  let module;
  let launcherElement;
  let $launcherElement;
  let launcherId;
  let galleryModal;
  let defaults;
  let config;

  defaults = {
    assetPageSelector: '.photo-gallery-collection',
    launchIndex: false,
    galleryOffset: 0,
    queueSelector: 'slideshow-queue',
    currentScrollPosition: 0,
    launchIndexUpdated: false,
    updatedIndex: 0,
    startSlide: 0,
    modalId: 'photo-gallery',
    variant: false,
    closeButtonParent: '.o-PhotoGalleryPromo',
    hasTransition: false,
    skipFade: false,
    overrides: {
      backdropAlternate: '',
      noScroll: false,
      deferStop: false,
      clearContents: true
    }
  };

  function setLaunchIndex(newIndex) {
    config.updatedIndex = parseInt(newIndex);
  }

  function launchGalleryModal(element) {
    let $clickElement = $(element);
    let $slideshowQueue;
    let $gallery;
    let index;

    if (config.launchIndexUpdated) {
      index = config.updatedIndex + config.galleryOffset;
    } else {
      index = ($clickElement.closest(config.launchIndex).index() || 0) + config.galleryOffset;
    }

    $slideshowQueue = config.galleryQueue || $launcherElement.find('.slideshow-queue');

    if ($slideshowQueue.length > 0) {
      if ($clickElement.data('queue-selector')) {
        $slideshowQueue = $clickElement.find($clickElement.data('queue-selector'));
      }

      $gallery = $slideshowQueue.clone();
      $gallery.removeClass(config.queueSelector);

      if (index !== -1) {
        $gallery.data('startSlide', index);
        config.startSlide = index;
      }
      if (config.variant && deviceType.isMobile) {
        $gallery.append('<div id="photo-gallery"></div>');
      }
      setGalleryModal(config.modalId, $gallery);
    }
  }

  function setGalleryModal(id, gal) {
    let containerSelector = `#${id}`;
    if (typeof galleryModal === 'undefined') {
      galleryModal = false;
    }

    if (config.variant) {
      galleryModal = modal.create({
        modalId: config.modalId,
        content: gal,
        // closeButtonParent: config.closeButtonParent,
        hasTransition: config.hasTransition,
        skipFade: (deviceType && deviceType.isMobile),
        clearContents: true,
        overrides: {
          backdropAlternate: config.overrides.backdropAlternate,
          noScroll: config.overrides.noScroll,
          deferStop: false,
          clearContents: true,
        }
      });

      galleryModal.show();
    } else {
      $('body').addClass('full-overlay-modal-open');
      if (deviceType.isMobile) {
        $('body').height($(window).height());
        $('html').css({
          overflow: 'hidden'
        });
      }
      galleryModal = modal.open(id, gal);
    }

    context.broadcast('galleryModal.opened', {
      container: containerSelector,
      origin: launcherId,
      startingSlide: config.startSlide
    });
  }

  function insertPosterImage() {
    let photoHash = window.location.hash;
    let $queueSlide = $(config.assetPageSelector).find('.slideshow-queue .slide');
    let cleanString = photoHash.toLowerCase().replace('#photo-', '');
    let parsedNumber = 0;
    let slideImage;

    if (cleanString && parseInt(cleanString, 10) > 0) {
      parsedNumber = parseInt(cleanString, 10) - 1;
    }

    slideImage = $queueSlide.eq(parsedNumber).find('.rsImg').attr('data-is-18');

    if (slideImage === undefined) {
      slideImage = $queueSlide.eq(0).find('.rsImg').attr('data-is-18');
    }

    $(config.assetPageSelector).find('.gallery-poster-image').remove().end().prepend($('<img />', {
      'src': slideImage,
      'class': 'gallery-poster-image photo-gallery-launch'
    }));
  }

  function setupPromoPinterest(event) {
    event.stopPropagation();

    let options = {
      media: config.imageURL,
      url: config.pageURL,
      description: config.description
    };

    pinSvc.pinOne(options);
  }

  function recordScrollPosition() {
    config.currentScrollPosition = $(document).scrollTop();
  }

  function restoreScrollPosition(originalLauncher) {
    if (originalLauncher === launcherId) {
      window.scrollTo(0, config.currentScrollPosition);
    }
  }

  module = {

    behaviors: ['lazy-load'],

    messages: ['galleryModal.closed', 'carousel.indexUpdated'],

    init() {
      launcherElement = context.getElement();
      $launcherElement = $(launcherElement);
      launcherId = $launcherElement.attr('id');
      config = Object.assign({}, defaults, context.getConfig());
      if ($(config.assetPageSelector).length) {
        insertPosterImage();
      }
      context.broadcast('gallery.ImageUpdate', {});
      pinSvc.setPinButton({
        $element: $launcherElement,
        itemConfig: pinConfig,
        data: {}
      });
    },

    onmessage(name, {origin, updatedIndex}) {
      switch (name) {
        case 'galleryModal.closed':
          restoreScrollPosition(origin);
          break;
        case 'carousel.indexUpdated':
          config.launchIndexUpdated = true;
          setLaunchIndex(updatedIndex);
          break;
      }
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'launch-gallery-modal':
          event.preventDefault();
          recordScrollPosition();
          launchGalleryModal(element);
          break;
        case 'promo-social-pinit':
          setupPromoPinterest(event);
          break;
      }
    }

  };

  return module;
});
