/*
  Workaround for using touch UI and classic UI in tandem in CQ/AEM 6.1.
  This was put in place because Adobe prefers not to fix the issue in the CQ codebase. See DayCare ticket:
  https://daycare.day.com/content/home/scripps/scripps_us/sn_digital/76555.html

           .          .
 .          .                  .          .              .
       +.           _____  .        .        + .                    .
   .        .   ,-~"     "~-.                                +
              ,^ ___         ^. +                  .    .       .
             / .^   ^.         \         .      _ .
            Y  l  o  !          Y  .         __CL\H--.
    .       l_ `.___.'        _,[           L__/_\H' \\--_-          +
            |^~"-----------""~ ^|       +    __L_(=): ]-_ _-- -
  +       . !                   !     .     T__\ /H. //---- -       .
         .   \                 /               ~^-H--'
              ^.             .^            .      "       +.
                "-.._____.,-" .                    .
         +           .                .   +                       .
  +          .             +                                  .
*/

(function() {
  /*
   * code adapted from http://www.quirksmode.org/js/cookies.html
   */
  var cookie = {
    get: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
          c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length,c.length);
        }
      }
      return null;
    },
    // leave 'days' blank to expire at end of session
    set: function(name, value, days, path, domain) {
      var expires = "",
          sharedDomain = window.location.hostname.match(/[^\.]*\.*[a-z]*$/g),
          domain = (domain && sharedDomain && sharedDomain[0]) ? "; domain=." + sharedDomain[0] : "";

      if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
      }
      if (!path) {
        path = '/';
      }
      document.cookie = name + "=" + value + expires + "; path=" + path + domain;
    }
  };

  var modeCookie = cookie.get('cq-authoring-mode');
  if (!!modeCookie && (window.top === window.self)) {
    var touchSelector = (window.location.pathname.indexOf('/editor.html/') === 0),
        touchCookie = (modeCookie === 'TOUCH');

    if (!touchSelector && touchCookie) {
      cookie.set('cq-authoring-mode', 'CLASSIC');
      window.location.reload();
    }
  }
})();
