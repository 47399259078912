SNI.Application.addModule('vote', (context) => {

  let $voteElements,
      $element           = $(context.getElement()),
      debug              = context.getService('logger').create('module.vote'),
      check              = context.getService('check').new(debug),
      device             = context.getService('device-type'),
      startModule        = context.getService('start-modules').start,
      voteService        = context.getService('vote-service'),
      analytics          = context.getService('analytics'),
      template           = context.getService('template'),
      loadingHTML        = template.loadingHTML(),
      modalVote          = context.getService('modal'),
      voteError          = false,
      voteModuleSel      = '[data-vote-module]',
      voteLabelSel       = '[data-vote-voted-label]',
      afterVoteHideSel   = '[data-vote-hide-after]',
      initialHideSel     = '[data-vote-hide-on-initial]',
      resourceLabelSel   = '[data-vote-resource-label]',
      voteElementSel     = '[data-vote-gallery-thumb]',
      voteButtonSel      = '[data-vote-cast-button]',
      voteHideSel        = '[data-vote-hide-whilst]',
      viewPhotoSel       = '[data-vote-view-photos-link]',
      voteInstructSel    = '[data-vote-instruction-copy]',
      voteHeaderSel      = '[data-voting-header]',
      voteResultsHeaderSel =  '[data-voting-refreshTitle]',
      voteResultsMsgContainer = '[data-voting-refreshTitle] .o-AssetTitle__a-HeadlineText',
      ssQueueSel         = '.slideshow-queue',
      loadingCls         = 'loading',
      hiddenCls          = 'is-Hidden',
      shownCls           = 'is-Shown',
      subNavSel          = '.o-SubNavigation',
      voteGridSel        = '.l-Columns',
      voteResultsContainer = '[data-vote-results]',
      isLoggedIn = false,
      user = {};


  let defaults = {
    voteType              : 'gallery',
    cid                   : '',
    barId                 : '',
    voteIndex             : 0,
    nextCategoryUrl       : '',
    baseVoteUrl           : '',
    sweepsTitle           : '',
    sweepsUrl             : '',
    totalVoteElements     : 0,
    numVoteElementsLoaded : 0,
    currentIndex          : '',
    previousIndex         : '',
    freshVote             : '',
    voteReactions         : [],
    elementsLoaded        : false,
    ballotState           : false,
    useInlineResults      : false,
    componentTitle        : 'results-carousel'
  };

  let settings = Object.assign({}, defaults, context.getConfig());
  
  function toggleStateClass(state) {
    var $el = $(voteModuleSel);
    var classes = $el[0].className.split(' ');
    var newClasses = [];
    for (var i=0; i < classes.length; i++) {
      var cls = classes[i].trim();
      if (cls.substring(0, 3) !== 'is-') {
        newClasses.push(cls);
      }
    }
    $el[0].className = newClasses.join(' ');
    $el.addClass('is-' + state.charAt(0).toUpperCase() + state.slice(1));
  }

  function getColumnsClass(count) {
    return 'l-Columns--' + count.toString() + 'up';
  }

  function fetchSlideshowQueue() {
    $(voteElementSel).each(function(){
      var slideshowUrl,
          countOfHTML,
          lastHTML,
          $voteBlock;

      if ($(this).data('slideshowUrl') === '') {
        debug.log('No SLIDESHOW URL specified, cannot AJAX');
        return false;
      }

      $(this).find(voteElementSel).prepend(loadingHTML);
      slideshowUrl = $(this).data('slideshowUrl');
      countOfHTML = slideshowUrl.match(/.html/g) || 0;

      //if on mobile author
      if (device.isMobile && check.isAuthor()) {
        slideshowUrl = slideshowUrl.replace(/\.slideshow\.html/, '.mobile.slideshow.html');
      }

      if (countOfHTML.length > 1) {
        lastHTML = slideshowUrl.lastIndexOf('.html');
        slideshowUrl = slideshowUrl.substring(0,lastHTML);
      }

      if (check.isLocalhost() || check.isAuthor()) {
        slideshowUrl += '?wcmmode=disabled';
      }

      $voteBlock = $(this);
      $.get(slideshowUrl, function(data){
        var $queue = $('<div>').append(data);
        appendSlideshowQueue($queue, $voteBlock);
      }).done(function(data){
        $voteBlock.find(ssQueueSel).attr('data-vote-index', $voteBlock.data('voteIndex'));
        $voteBlock.removeClass(loadingCls);
        $voteBlock.find('[data-ui-loader]').remove();
        settings.numVoteElementsLoaded++;
        if (settings.numVoteElementsLoaded === settings.totalVoteElements) {
          context.broadcast('vote.elementsLoaded', {
            items: $voteElements
          });
        }
      });
    });
  }

  function appendSlideshowQueue($slideshowQueue, $voteElement) {
    $voteElement.append($slideshowQueue.html());
  }

  function preventVoteClick() {
    $(voteElementSel).each(function() {
      var $currBlock = $(this);
      $currBlock.find('a').on('click', function(e){
        if ($(this).hasClass(loadingCls)) {
          e.preventDefault();
        }
      });
    });
  }

  //wat? need data- attribs instead of tags
  function setColor() {
    $(voteInstructSel).find('b, strong').css('color', $(subNavSel).css('background-color'));
  }

  function preventGalleryLaunch() {
    $voteElements.each(function() {
      var $currVoteElement = $(this),
          $currVoteImage = $(this).find(voteElementSel),
          $viewLink = $(this).find(viewPhotoSel);
      $currVoteImage.on('click.vote', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).find(voteButtonSel).trigger('click.vote');
      });
      debug.log($viewLink, $currVoteElement);
    });
  }

  function titleGalleryLaunch() {
    $voteElements.each(function() {
      var $currVoteElement = $(this),
          $galleryTitleLink = $currVoteElement.find('[data-vote-gallery-title]');
      debug.log($galleryTitleLink);
    });
  }

  function removeVote() {
    $voteElements.each(function() {
      var $currVoteImage = $(this).find(voteElementSel),
          $viewLink = $(this).find(viewPhotoSel);
      $currVoteImage.off('click.vote');
      $viewLink.off('click.vote').hide();
    });
  }

  function setupVote() {
    preventGalleryLaunch();

    $(voteButtonSel).each(function() {
      $(this).on('click.vote', function(e) {
        e.preventDefault();
        e.stopPropagation();
        
        var $currentElement= $(this).parents(voteElementSel),
            currentButton = $currentElement.data('galleryTitle'),
            largeImage = $currentElement.find('[data-vote-gallery-launch] img').attr('data-large-rendition'),
            currentImage = $currentElement.find('[data-vote-gallery-launch] img').attr('src');

        if (!voteError) {
          context.broadcast('vote.voted', {
            galTitle: currentButton,
            galImage: currentImage,
            largeImage: largeImage
          });
        } else {
          errorModal();
        }
      });

      var galleryID = $(this).parents(voteElementSel).data('galleryTitle');
      galleryID = galleryID.replace(/,/g, '');

      var reactionObj = {
        text: galleryID,
        ID: galleryID
      };

      settings.voteReactions.push(reactionObj);

    });

  }

  /* Note: DS-2027 removed printReactions since it was no longer available after Gigya removal */

  function hideElements(hideIt, group) {
    $(group).each(function(){
      $(this).removeClass(hiddenCls + ' ' + shownCls).addClass(hideIt ? hiddenCls : shownCls);
    });
  }

  function transform(newLayout, oldLayout) {
    $(voteGridSel).addClass(newLayout).removeClass(oldLayout);
  }

  function errorModal() {
    var id = 'vote-modal-error',
        eM = modalVote.create({ modalId : id }),
        errorContent;

    errorContent = {
      modalId : id,
      body : 'We experienced a technical glitch and we\'re very sorry about that. Please close this message and try again.',
      heading : 'Sorry, your vote was not cast.',
      actionText : 'Close & Try Again'
    };

    eM.loadContent(errorContent);
    eM.show();

    $('#' + id + ' [data-button-primary]').off().on('click', function() {
      eM.hide();
    });
  }

  function votingModal(data) {
    var id = 'vote-modal',
        m = modalVote.create({ modalId : id });

    m.loadContent({
      modalId : id,
      body : {
        html : template.voteResultModalBody({
          mobile: device.isMobile,
          title: data.galTitle,
          image: device.isMobile ? data.largeImage : data.galImage
        })
      },
      header : {
        title : 'Got It, Thanks!',
        subtitle : 'Be sure to come back and vote again tomorrow.'
      },
      footer : {
        actionText : settings.nextCategoryUrl ? 'Continue to Next Category' : 'Close',
        cancelText : settings.sweepsTitle ? 'Enter our '+settings.sweepsTitle : ''
      }
    });
    m.show();

    addSocialShare(data, { mobileIcons: true, shareSource: 'ogDataTags' }, $('#' + id + ' [vote-share-container]'));

    if (!settings.nextCategoryUrl) {
      $('#' + id + ' [data-button-primary]').off().on('click', function() {
        m.hide();
      });
    }

    $('[data-modal-footer] [data-button-primary]').on('click.nextCategory', function(e) {
      if (settings.nextCategoryUrl) {
        e.preventDefault();
        window.location.href = settings.nextCategoryUrl;
      }
    });

    if (!settings.sweepsUrl) {
      $('[data-modal-footer] span').remove();
    } else {
      $('[data-modal-footer] [data-button-secondary]').on('click.sweeps', function(e) {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = settings.sweepsUrl;
      });
    }
  }

  function retrieveSocialDataFromGallery(data) {
    var galleryURL = window.location.href,
        siteURL = galleryURL.replace(/https?:\/\//i, ''),
        slideImage = data.galImage,
        hubText = settings.cid ? ' in '+settings.cid : '',
        pageTitle = voteService.getParameterString('Title');
    
    function toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }

    pageTitle = toTitleCase(pageTitle);

    var shareValues = {
      title:        'I voted for ' + data.galTitle + hubText,
      description:  'Visit '+siteURL+' to vote for your favorite '+pageTitle,
      url:          galleryURL,
      image:        slideImage
    };

    return shareValues;
  }

  function addSocialShare(data, config, $shareContainer) {
    context.broadcast('socialShare.requested', {
      target: $shareContainer,
      data: retrieveSocialDataFromGallery(data),
      config: config
    });
  }

  function checkStorage() {
    var ls = voteService.getLStorItem('votes'),
        votes = ls ? JSON.parse(ls) : null,
        dupVote = ls ? voteService.searchJSONArray(votes, 'voteURL', window.location.href.split('?')[0]) : false;

    debug.log('dupVote', dupVote);

    if (check.supports('localStorage')) {
      var now = new Date().getTime(),
          voteTime = voteService.getLStorItem('voteTime');
      // If no vote has been registered
      if (voteTime === null) {
        settings.freshVote = true;
        if (settings.useInlineResults) {
          hideElements(false, voteModuleSel);
        }
        debug.log('checkStorage: freshVote');
      } else if (voteTime-now > 0 && votes !== null) { // If user already voted today
        // Gallery already voted - duplicate
        if (dupVote) {
          settings.freshVote = false;
          debug.log('checkStorage: oldVote');
          context.broadcast('vote.voted');
          if (settings.useInlineResults) {
            hideElements(false, voteResultsContainer);
          }
        // Not a duplicate - gallery never voted
        } else if (settings.useInlineResults) {
          hideElements(false, voteModuleSel);
        }
      } else if (voteTime-now <= 0) { // Clear old votes prior to today from user's localstorage
        debug.log('checkStorage: resetVote');
        voteService.removeLStorItem('voteTime');
        voteService.removeLStorItem('voteState');
        voteService.removeLStorItem('votes');
        if (settings.useInlineResults) {
          hideElements(false, voteModuleSel);
        }
      }
    } else {
      if (settings.useInlineResults) {
        hideElements(false, voteModuleSel);
      }
      debug.log('checkStorage: no localstorage, exiting');
    }
  }

  function disableLoop() {
    voteService.setLStorItem('voteLoop', false);
  }

  function showVotedLabel(galleryText) {
    $(voteLabelSel)
      .addClass(hiddenCls).remove(shownCls);

    if (galleryText.length) {
      $(voteModuleSel + ' [data-gallery-title="'+galleryText+'"] ' + voteLabelSel)
        .removeClass(hiddenCls).addClass(shownCls);
    }
  }

  function renderVoteResultGalleryItemText(gallery, votedGalleryTitle) {
    let $gallery = $(gallery);
    let largeImg = $gallery.find('img').data('large-rendition');
    let galleryTitle = $gallery.data('gallery-title');
    let customTitle = $gallery.data('custom-title');
    let $voteImg = $gallery.find('[data-vote-img]');
    let href = ($voteImg.length>0 && $voteImg.attr('href')) || ($gallery.data('external-url')||'');
    $gallery.find('a').prop('onclick', null).removeAttr('onclick').off('click');
    if (largeImg) {
      $gallery.find('img').attr('src','').attr('src', largeImg);
    }
    
    let isWinner = galleryTitle === votedGalleryTitle ? template.voteWinnerTitle() : '';
    
    if (!device.isMobile) {
      let galleryAttribute = {
        title: (customTitle||galleryTitle),
        isWinner,
        href,
        componentTitle: settings.componentTitle
      };
      $gallery.append(template.voteResultGalleryItemTextWrap(galleryAttribute));
    } else {
      if (isWinner !== '') {
        $gallery.append(isWinner);
      }
    }
  }

  function updateImageTitle($collection) {
    $.each($collection, function(key, value){
      let title = $(this).data('custom-title');
      $(this).attr('title', title);
    });
  }

  function showVoteResults(votedGalleryTitle) {
    // STOR-329: Get total votes to display on results page
    // Fetch votes using campaign ID?
    //totalVotes = voteService.getVotes(campaign_id);
    // update results by +1 using data from 'totalVotes'
    
    if (votedGalleryTitle.length) {
      let $resultsContainer = $(voteResultsContainer);
      $(voteLabelSel).addClass(hiddenCls).remove(shownCls);
      $(voteHeaderSel).addClass(hiddenCls);
      $(voteResultsMsgContainer).html(settings.voteResultsMessage);
      $(voteResultsHeaderSel).removeClass(hiddenCls);
      $(voteModuleSel).find('.l-Columns.l-Columns--2up > [data-vote-index]').clone().prependTo($resultsContainer);
      $(voteModuleSel).detach();
      $resultsContainer.find('[data-gallery-title="'+votedGalleryTitle+'"]').addClass('winner');
      $resultsContainer.find(' [data-gallery-title] .m-MediaBlock__m-TextWrap ').remove();
      $.each($resultsContainer.find('[data-gallery-title]'), function( key, value ) {
        renderVoteResultGalleryItemText(this, votedGalleryTitle);
      });
      updateImageTitle($resultsContainer.find('[data-vote-img]'));  // Make anchor and image titles the same for analytics click tracking
      $resultsContainer.attr('data-module','vote-carousel');
      startModule($resultsContainer.get(0));
    }
  }

  function removeBorder(border, type) {
    $(voteGridSel).css(border, type);
  }

  function elementsLoaded() {
    voteService.setLStorItem('loadedState', 'vote.elementsLoaded');
    setColor();
    titleGalleryLaunch();
  }

  function startBallot() {
    if (!device.isMobile) {
      transform(getColumnsClass(3), getColumnsClass(2));
    }
    voteService.setLStorItem('loadedState', 'vote.ballotState');

    setupVote();
    toggleStateClass('voteEnabled');
    hideElements(false, initialHideSel);
    hideElements(true, voteHideSel);
    $(viewPhotoSel).show();
    //metadata.updateFromString(JSON.stringify({Type:'ballotPage', UniqueID:mdManager.getParameterString('UniqueID').replace(/voteOverlay/g, 'ballotPage'), Url:settings.baseVoteUrl}));
    voteService.updateFromString(JSON.stringify({Type:'ballotPage', UniqueID: voteService.getParameterString('UniqueID').replace(/voteOverlay/g, 'ballotPage'), Url:settings.baseVoteUrl}));
    // analytics.setProp(9, analytics.getProp(9).replace(/voteOverlay/g, 'ballotPage'));
    analytics.callDynamicPageview();
    analytics.setProp(9, '');
    analytics.setProp(10, '');
    debug.log('startBallot: Type: ', voteService.getParameterString('Type'));
    setTimeout(function() {
      $(window).scrollTop($(voteInstructSel + ':first').offset().top);
    }, 100);
  }

  function registerVote(data) {
    debug.log('registerVote: votedState entered');
    var galleryText = null;
    voteService.setLStorItem('loadedState', 'vote.voted');
    voteService.setLStorItem('voteLoop', true);
    transform(getColumnsClass(2), getColumnsClass(3));
    hideElements(false, voteHideSel);
    hideElements(true, afterVoteHideSel);
    hideElements(true, resourceLabelSel);
    toggleStateClass('voted');

    if (!data) {
      galleryText = voteService.getLStorItem('votes')? voteService.getVotedGallery('title') : null;
    } 

    // Fresh vote
    if (data) {
      // Pass user information for logged-in user
      if (isLoggedIn && !$.isEmptyObject(user)) {
        data.isLoggedIn = true;
        data.user = user;
      }
      // If true, voteService will save votes to user profile
      if (settings.useInlineResults) {
        data.useInlineResults = true;
      }
      // Submit vote
      voteService.recordVote(data);

      // Show results
      if (settings.useInlineResults) {
        showVoteResults(data.galTitle);
      } else {
        votingModal(data);
        showVotedLabel(data.galTitle);
      }
    } else if (settings.useInlineResults && !settings.freshVote) { // If on a campaign already voted, redirect user to the results page
      showVoteResults(galleryText);
    } else {
      showVotedLabel(galleryText);
    }
    removeVote();
    removeBorder('border-top', 'none');
  }

  function hiddenState() {
    var currentState = 'votePage',
        stateObject = {};
    if (voteService.getLStorItem('loadedState') === 'vote.elementsLoaded') {
      currentState = 'votePage';
    } else if (voteService.getLStorItem('loadedState') === 'vote.ballotState') {
      currentState = 'ballotPage';
    }
    stateObject.Type = currentState;
    voteService.updateFromString(JSON.stringify(stateObject));
    voteService.setParameter('Url', settings.baseVoteUrl);
    setTimeout(function() {
      $(window).scrollTop($(voteGridSel).offset().top);
    }, 100);
  }

  return {
    messages: ['galleryModal.closed', 'vote.elementsLoaded', 'vote.ballotState', 'vote.voted', 'modal.hidden'],
    init() {
      let $modules = $(`[data-module="${$element.attr('data-module')}"]`);
      if (($modules.length > 1) && !$element.is($modules.eq(0))) {
        debug.log('init: Only one vote module per page allowed, exiting');
        return;
      }

      settings.cid               = $element.data('cid');
      settings.barId             = $element.data('barId');
      settings.nextCategoryUrl   = $element.data('nextCategory');
      settings.sweepsTitle       = $element.data('sweepstakes-title');
      settings.sweepsUrl         = $element.data('sweepstakes-url');
      settings.baseVoteUrl       = check.exists('mdManager') ? voteService.getParameterString('Url') : window.location.href.split('?')[0];
      debug.log('init: sweeps {}: ', settings.sweepsTitle, settings.sweepsUrl);

      $voteElements              = $element.find(voteElementSel);
      settings.totalVoteElements = $(voteElementSel).length;

      preventVoteClick($voteElements);

      fetchSlideshowQueue();
      disableLoop();
      hideElements(true, initialHideSel);
      checkStorage();
    },
    onmessage: function(name, data) {
      switch (name) {
        case 'galleryModal.closed':
          break;
        case 'vote.elementsLoaded':
          if (!settings.elementsLoaded) {
            settings.elementsLoaded = true;
            elementsLoaded();
          }
          break;
        case 'vote.ballotState':
          if (!settings.ballotState) {
            settings.ballotState = true;
            startBallot();
          }
          break;
        case 'vote.voted':
          registerVote(data);
          break;
        case 'modal.hidden':
          hiddenState();
          break;
      }
    }
  };

});
