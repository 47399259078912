import '../services/logger';
import '../services/check';
import '../services/device-type';
import '../vendor/owl-carousel';

SNI.Application.addModule('carousel', (context) => {

  // Private

  let debug = context.getService('logger').create('module.carousel');
  let check = context.getService('check').new(debug);
  let util = context.getService('utility');
  let percentVisible = util.percentVisible;
  let deviceType = context.getService('device-type');
  if (!check.jqueryPlugin('owlCarousel')) return {};

  const defaults = {
    startPosition: 0,
    loop: false,
    items: 1,
    margin: 0,
    lazyLoad: true,
    navigation: false,
    pagination: false,
    mouseDrag: false,
    thumbnails: false,
    superTheme: false,
    videoEmbed: false,
    autoplay: false,
    smartSpeed: 500,
    itemsPerPage: 3,
    totalItems: null, // the total number of items (not necessarily slides) in the carousel
    slidesPerPage: 3, // the number of slides that fit in a "page" when doing pagination
    checkFinalSlide: false,
    selectors: {
      carouselContainer: '.carousel-container',
      slideCount: '.m-Carousel__a-SlideCount',
      moduleSelector: '[data-module="carousel"]',
      playListContainer: '.o-VideoPlaylistPromo__m-Carousel',
      slide: '[data-slide]',
      slideText: '.slide-text',
      activeClass: 'active',
      activeSlide: '.block.active, .block-text-right.active',
      thumbEmbedClass: 'thumbnail-embed',
      thumbImgClass: 'thumbnail-img',
      pagination: {
        all: '.playlist-pagination, .custom-pagination',
        custom: '.custom-pagination',
        count: '.pagination-count span',
        superLeadThemed: {
          navItem: '.super-lead-theme-pagination .block'
        },
        buttons: {
          next: '.nav-next',
          prev: '.nav-prev',
          both: '.nav-prev, .nav-next'
        }
      }
    }
  };
  let settings = context.getConfig();
  let config = $.extend(true, {}, defaults, settings);
  let { selectors } = config;

  // set the current context element as the carousel Wrapper
  let $carouselWrapper = $(context.getElement());
  let $carouselContainer = $carouselWrapper.find(selectors.carouselContainer);
  let $playListContainer = $carouselWrapper.closest(selectors.playListContainer);
  let $navNext = $carouselWrapper.find(selectors.pagination.buttons.next);
  let $navPrev = $carouselWrapper.find(selectors.pagination.buttons.prev);
  let $slideText = $carouselWrapper.find(selectors.slideText);
  let $pagination = $carouselWrapper.find(selectors.pagination.custom);
  if ($pagination.length === 0) $pagination = $carouselWrapper.next(selectors.pagination.custom);

  if ($playListContainer.length && !$navNext.length) {
    $pagination = $playListContainer.find(selectors.pagination.all).first();
    $navNext = $pagination.find(selectors.pagination.buttons.next);
    $navPrev = $pagination.find(selectors.pagination.buttons.prev);
  }

  function isStarted(element) {
    return context.application.isStarted(element);
  }

  function otherModules() {
    let $modules = $(config.selectors.moduleSelector).filter((i, e) => {
      return isStarted(e);
    });
    return $modules;
  }

  function isMostVisible($modules, $carousel, $wrapper) {
    let openModal = $modules.parents('.o-Modal').length;
    let parentModal = $carousel.parents('.o-Modal').length;
    if (openModal > 0) {
      return parentModal > 0; // We are in a modal - we are the most visible
    } else {
      let wrapper = $wrapper.get(0);
      let $otherMods = $modules.not(wrapper);
      return visiblity(wrapper, $otherMods); // compare current visibility to other modules visibility
    }
  }

  function highestVisibility(arr) {
    arr.sort((a, b) => a - b);
    return arr.shift();
  }

  function visiblity(carousel, $modules) {
    let currentPct = percentVisible(carousel);

    let isHigher = false;
    let pcts = [];
    $modules.each((i, el) => {
      let elPct = percentVisible(el);
      pcts.push(elPct);
    });
    let highest = highestVisibility(pcts); // Get the most visible element from all the other carousels on the page (which can be many, hence the array)
    isHigher = currentPct > highest;
    return isHigher;
  }

  function bindOwlInit() {

    setOwlDots();

    $carouselContainer.on('initialized.owl.carousel', (event) => {
      debug.log('initialized.owl.carousel fired');

      let slidePageIndex = event.page.index;
      let currentId = $(context.getElement()).attr('id');
      $(context.getElement()).addClass('is-Loaded');
      updatePagination(slidePageIndex, config.totalItems);
      updateSlideCount();
      context.broadcast('carousel.loaded', {
        carouselId: currentId
      });
    });

  }

  function setOwlDots() {
    if (config.itemsPerPage && config.totalItems !== null) {
      if (config.totalItems <= config.itemsPerPage) {
        config.dots = false;
      }
    }
  }

  function updateSlideCount() {
    const $slideCount = $carouselWrapper.find(config.selectors.slideCount).first();
    if ($slideCount.length > 0) {
      $carouselContainer.on('translated.owl.carousel', (event) => {
        let max = event.page.count;
        let cur = event.page.index + 1;
        $slideCount.text(`${cur} / ${max}`);
        $slideCount.addClass('is-Seen');
      });
    }
  }

  function broadcastOwlTranslation() {
    $carouselContainer.on('change.owl.carousel', (event) => {
      let currentId = $(context.getElement()).attr('id');
      context.broadcast('carousel.changing', {
        carouselId: currentId
      });
    });

    $carouselContainer.on('changed.owl.carousel', (event) => {
      let currentIndex = event.item.index;
      context.broadcast('carousel.indexUpdated', {
        updatedIndex: currentIndex
      });
    });

    $carouselContainer.on('translated.owl.carousel', (event) => {
      let currentSlide = $carouselContainer.find(`.${selectors.activeClass}`).find(selectors.slide).data('slide');
      let currentId = $(context.getElement()).attr('id');
      context.broadcast('carousel.slideUpdated', {
        updatedSlide: currentSlide,
        carouselId: currentId
      });
    });
  }

  function updatePagination(slidePageIndex, itemCount) {
    let itemsPerPage, totalItems, pageRangeStart, pageRangeEnd;

    itemsPerPage = config.itemsPerPage;
    totalItems = config.totalItems;

    // calculates numbers to do things like: "showing 3 of 6"
    // pageRangeEnd ternary is used to prevent from exceeding the maximum length
    pageRangeStart = (slidePageIndex * itemsPerPage + 1);
    pageRangeEnd = (slidePageIndex * itemsPerPage + itemsPerPage < totalItems) ? slidePageIndex * itemsPerPage + itemsPerPage : totalItems;
    $pagination.find('> div > a').data('slide-index', slidePageIndex);
    $pagination.find(selectors.pagination.count).eq(0).text(pageRangeStart + ' - ' + pageRangeEnd);

    if (config.loop === false) {
      // clear current disabled attributes
      $carouselWrapper.find(selectors.pagination.buttons.both).removeAttr('disabled');

      // disable next or previous links when appropriate
      if (slidePageIndex === 0) {
        $carouselWrapper.find(selectors.pagination.buttons.prev).attr('disabled', 'disabled');
      } else if (slidePageIndex === (itemCount - 1)) {
        $carouselWrapper.find(selectors.pagination.buttons.next).attr('disabled', 'disabled');
      }
    }
  }

  // TODO: Verifiy this is working correctly
  // Seems to work correctly based on my initial smoke test
  function initThumbnails() {
    debug.log('initThumbnails started');

    $carouselWrapper.addClass(selectors.thumbEmbedClass);

    $.each($carouselContainer.find('.owl-item'), (i, element) => {

      $carouselContainer.find('.owl-controls .owl-dot').eq(i).append(`<div class="${selectors.thumbImgClass}" />`).find(`.${selectors.thumbImgClass}`)
        .css({
          'background': 'url(' + $(element).find('img').attr('data-src') + ') center center no-repeat',
          '-webkit-background-size': 'cover',
          '-moz-background-size': 'cover',
          '-o-background-size': 'cover',
          'background-size': 'cover'
        });
    });

  }

  // TODO: Verifiy this is working correctly
  // Seems to work correctly based on my initial smoke test
  function initSuperTheme() {
    const target = selectors.pagination.superLeadThemed;

    // on change pagination gets active class
    $carouselContainer.on('changed.owl.carousel', (event) => {
      let slideIndex = event.page.index;
      $carouselWrapper.find(target.navItem).removeClass(selectors.activeClass).eq(slideIndex).addClass(selectors.activeClass);
    });

    let timer;

    // on mouseenter change slide
    $carouselWrapper.find(target.navItem).on('mouseenter', (event) => {
      let $this = $(event.currentTarget);

      timer = setTimeout(() => {
        $this.siblings().removeClass(selectors.activeClass).eq($this.index()).addClass(selectors.activeClass);
        goToSlide($this.index());
        stopAutoPlay();
      }, 500);
    });

    $carouselWrapper.find(target.navItem).on('mouseleave', (event) => {
      clearTimeout(timer);
    });
  }

  function removePlaylistGradient(event, initCheck) {
    if (initCheck) {
      if (config && config.totalItems && config.itemsPerPage && config.totalItems === config.itemsPerPage) {
        $carouselContainer.addClass('is-FinalSlide');
      }
      return;
    }
    if (config.checkFinalSlide) {
      if (event.item && (event.item.index +1 === event.item.count)) {
        $carouselContainer.addClass('is-FinalSlide');
      } else {
        $carouselContainer.removeClass('is-FinalSlide');
      }
    }
  }

  function bindVideoEmbedPagination() {

    let slidePageIndex = 0;

    $carouselWrapper.on('changed.owl.carousel', (event) => {

      slidePageIndex = event.page.index;

      updatePagination(slidePageIndex, event.item.count);
      removePlaylistGradient(event);
    });

    $pagination.find(selectors.pagination.buttons.both).on('click', (event) => {
      let $this = $(event.currentTarget);
      if ($this.is(selectors.pagination.buttons.next)) {
        nextSlide();
      } else if ($this.is(selectors.pagination.buttons.prev)) {
        prevSlide();
      }
    });

  }

  function bindArrowKeys(e) {
    let keyCode = e.keyCode;
    // handle cursor keys
    if (keyCode === 37) {
      // go left
      prevSlide(e);
    } else if (keyCode === 39) {
      // go right
      nextSlide(e);
    }
  }

  function bindEvents() {
    debug.log('bindEvents Start');

    if (deviceType.isMobile) {

      $slideText.on('touchend', () => {
        setHeight($slideText);
      });

      window.addEventListener('orientationchange', () => {

        setTimeout(() => {
          setHeight($slideText);
        }, 200);

      });
    } else {
      $navNext.off('click').on('click', () => {
        nextSlide();
      });
      $navPrev.off('click').on('click', () => {
        prevSlide();
      });

      $(document.documentElement).on('keyup', bindArrowKeys);
    }

    debug.log('Events Bound');
  }

  /**
   * Determine if there are other competing keyboard event handlers and skip current event or allow it to continue with slide event
   */
  function handleSlideEvent(e, eventType) {
    if (e) { // When no event is passed then we are handling a click and we can bypass these checks
      let $otherModules = otherModules();
      if ($otherModules.length > 1) { // If there's other modules present then we have to determine if we are the most visible
        let isMost = isMostVisible($otherModules, $carouselContainer, $carouselWrapper);
        if (isMost) {
          e.stopImmediatePropagation();
        } else {
          return false;
        }
      }
    }
    $carouselContainer.trigger(eventType);
  }

  function nextSlide(e) { // e = keyboardEvent
    debug.log('nextSlide() called');
    handleSlideEvent(e, 'next.owl.carousel'); //  If this carousel should not i
  }

  function prevSlide(e) { // e = keyboardEvent
    debug.log('prevSlide() called');
    handleSlideEvent(e, 'prev.owl.carousel');
  }

  function goToSlide(pos) {
    $carouselContainer.trigger('to.owl.carousel', pos);
  }

  function stopAutoPlay() {
    $carouselContainer.trigger('stop.owl.autoplay');
  }

  function goToActiveSlide() {
    // when meta data updates its trigger a slide change
    let slideIndex = $carouselContainer.find(selectors.activeSlide).parents('.owl-item').index();
    if (slideIndex > -1) {
      goToSlide(slideIndex);
    }
  }

  function setHeight($sText) {
    let tallest = 0;

    $sText.each((i, element) => {
      let $this = $(element);

      $this.outerHeight('auto');
      if ($this.outerHeight() > tallest) {
        tallest = $this.outerHeight();
      }
    });

    $sText.each((i, element) => {
      if (tallest !== 0) {
        $(element).outerHeight(tallest);
      }
    });
  }

  function setNowPlaying(videoNum) {
    debug.log(`Setting video ${videoNum} as the one playing`);
    $carouselWrapper.find('[data-vid-num]').removeClass('is-Active');
    $carouselWrapper.find(`[data-vid-num=${videoNum}]`).addClass('is-Active');
  }

  // public

  let module = {

    config,

    init: () => {
      debug.log('Init Start');

      bindOwlInit();
      debug.log('Owl Init Bound');

      // initialize the carousel
      $carouselContainer.owlCarousel(config);
      debug.log('Owl Carousel initialized');

      //
      if (config.thumbnails) {
        initThumbnails();
        debug.log('Thumbnail Initialized');
      }

      // custom for super theme lead implementation
      // desktop only
      if (config.superTheme) {
        initSuperTheme($carouselWrapper);
        debug.log('Super Theme Initialized');
      }

      // if video embed or video overlay
      if (config.videoEmbed) {
        bindVideoEmbedPagination();
        debug.log('Video Embed Events Bound');
      }

      if (deviceType.isMobile) {
        setHeight($slideText);
        debug.log('Mobile Initialized');
      }

      bindEvents();
      broadcastOwlTranslation();
      removePlaylistGradient({}, true);
      debug.log('Carousel Module: Events Bound');
      debug.log('Init End');

    },

    destroy: () => {
      if ($carouselContainer && $carouselContainer.owlCarousel) {
        debug.log('Clean up of owl carousel');
        $carouselContainer.trigger('destroy.owl.carousel');
      }
      $(document.documentElement).off('keyup', bindArrowKeys);
    },

    messages: ['update.metadata', 'carousel.update.slide'],

    behaviors: ['truncate'],

    onmessage: {
      'update.metadata': data => {
        goToActiveSlide();
        setNowPlaying(data);
      },
      'carousel.update.slide': data => {
        if (data.carouselId && data.carouselId === $(context.getElement()).attr('id')) {
          if (data.index) {
            goToSlide(data.index);
          }
        }
      }
    },

    onclick(event, element, elementType) {
      debug.log(`clicked on a ${elementType}`);
      switch (elementType) {
        case 'slide':
          context.broadcast('carousel.slide.clicked', {
            id: $carouselWrapper.attr('id'),
            slide: element
          });
          break;
        case 'next-item':
          if (deviceType.isMobile) {
            nextSlide();
          }
          break;
        case 'previous-item':
          if (deviceType.isMobile) {
            prevSlide();
          }
          break;
      }
    }

  };

  return module;

});
