SNI.Application.addModule('episode-feed', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const defaults = {
    dynamicPanelId: '#facets1',
    defaultPanelId: '#facets2',
    tabsSel: '.m-TabbedContent__m-TabNav',
    epCardSel: '.m-EpisodeCard'
  };

  let config = Object.assign({}, defaults, context.getConfig());
  let $element = $(context.getElement());

  /** Returns the time zone of the client. */
  let getZone = function(dateTime) {
    let dst = isDaylightSavings(dateTime);
    let zone = 'eastern';
    switch (dateTime.getTimezoneOffset()) {
      default:
        zone = 'eastern'; // eastern|central|mountain|pacific
        break;
      case 240 : // GMT -4:00
        zone = 'eastern';
        break;
      case 300 : // GMT -5:00
        zone = dst ? 'central' : 'eastern';
        break;
      case 360 : // GMT -6:00
        zone = dst ? 'mountain' : 'central';
        break;
      case 420 : // GMT -7:00
        zone = dst ? 'pacific' : 'mountain';
        break;
      case 480 : // GMT -8:00
        zone = 'pacific';
        break;
    }
    return zone;
  };

  /** Returns true if the client is on day light savings. */
  let isDaylightSavings = function(date) {
    return date.getTimezoneOffset() < stdTimezoneOffset(date);
  };

  let stdTimezoneOffset = function(date) {
    let jan = new Date(date.getFullYear(), 0, 1);
    let jul = new Date(date.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  /** extract future epidsodes from Recent tab and put them in Upcoming tab **/
  let makeUpcomingTab = function($element) {
    let now = new Date();
    let zone = getZone(now);
    let upcomingShows = $('<div/>', {'class': 'load-more-container'});
    let upcomingShowCount = 0;
    let loadMoreShows = $('<div/>');
    let loadMoreList = $('<div>', {'class':'list-load-more'});

    // If the client is in the west cost, either mountain of pacific, adj the time before doing the comparison.
    if (zone === 'mountain' || zone === 'pacific') {
      now.setHours(now.getHours() - 3);
    }

    $element.find('[data-time]').each(function(indexInArray, elt) {
      let $elt = $(elt);
      let timestamp = $elt.attr('data-time');
      let airTime = new Date(timestamp);

      const millisPerMinute            = 1000 * 60;
      const tenMinutesBeforeInMillis   = -10 * millisPerMinute;
      const twentyMinutesAfterInMillis = 20 * millisPerMinute;

      let diff = now.getTime() - airTime.getTime();
      let onNow = tenMinutesBeforeInMillis <= diff && diff < twentyMinutesAfterInMillis;

      if (onNow || now.getTime() < airTime.getTime()) {
        if (upcomingShowCount < 3) {
          upcomingShows.append($elt.clone());
        } else {
          loadMoreShows.append($elt.clone());
        }

        $elt.remove();
        upcomingShowCount++;
      }
    });

    // facet 2
    // this will remove Recently on TV tab if there are 0 results left in the .load-more-container
    if ($(config.defaultPanelId + ' .load-more-container').children().length <= 0 ) {
      $(config.tabsSel).children().eq(1).remove();
    }

    // if there are upcoming shows add them to facet1
    if ($(upcomingShows).children().length > 0) {
      $(config.dynamicPanelId).prepend(upcomingShows);
    }

    if ($(loadMoreShows).children().length > 0 ) {
      let appendedList = loadMoreList.append(loadMoreShows.children() );
      $(config.dynamicPanelId).append(appendedList);
      $(config.dynamicPanelId).find('.load-more').show();
    }

  };


  // reverse Recent Episodes order: most to least recent
  let reverseRecent = function() {
    let $recentCont = $(config.defaultPanelId);
    let $recentEps = $recentCont.find(config.epCardSel);
    let $newLoadMoreContainer = $('<div class="load-more-container"></div>');
    let $newListLoadMore = $('<div class="list-load-more"></div>');
    let i;
    let lim = Math.max($recentEps.length-3, 0);
    // up to 3 shown initially
    for (i = $recentEps.length-1; i >= lim; i--) {
      $newLoadMoreContainer.append($recentEps.eq(i));
    }
    // any additional initially hidden then shown on demand
    while (i >= 0) {
      $newListLoadMore.append($recentEps.eq(i));
      i--;
    }
    $recentCont.find('.load-more-container, .list-load-more').remove();
    $recentCont.prepend($newLoadMoreContainer).prepend($newListLoadMore);
    if ($recentEps.length > 3)  $(config.defaultPanelId).find('.load-more').show();
  };

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  let init = function() {

    makeUpcomingTab($element);

    // existing impl doesn't work at all-- not even a ul -- new impl reverseRecent() method above
    // Reverse the order of the li for facet2, the recently aired shows.
    // let ul = $(settings.defaultPanelId).find('ul');
    // ul.children('li').each(function(i, li) { ul.prepend(li); });
    reverseRecent();

  };


  return {

    init,

    behaviors: ['tab']

  };

});
