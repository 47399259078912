import { getPrivateNotes, savePrivateNotes, deletePrivateNotes, setApiEndpoint, setSavesToken } from '../libs/saves-api';

SNI.Application.addModule('recipe-private-notes', (context) => {

  const mdManager = context.getGlobal('mdManager');
  const detailId = mdManager.getDetailId();
  const mName = 'recipe-private-notes';
  const debug = context.getService('logger').create('module.' + mName);
  const dalton = context.getService('dalton/dalton-interface');
  const userInterface = context.getService('user/user-data');

  let config, $notesTextarea, $rootElement, $rootHeader, $clearButton, privateNotes, state;

  let defaults = {
    recipeBoxUrl: 'https://api.foodnetwork.com/fn-web/v1',
    notesContainer: '.private-notes-body',
    notesHeader: '.o-Capsule__m-Header',
    notesLength: 190,
  };

  function oninput(e, container, type) {
    if (type === 'private-notes') {
      let notes = $notesTextarea.val();

      if (notes.length === 0) {
        $clearButton.attr('disabled', 'disabled');
        return;
      }

      $clearButton.removeAttr('disabled');

      e.stopPropagation();
    }
  }

  function hidePrivateNotes() {
    privateNotes = '';
    $rootElement.html(addNotesButtonTemplate());
  }

  function escapeHtml(text) {
    'use strict';
    return text.replace(/[\"&<>]/g, function(a) {
      return { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;' }[a];
    });
  }

  // Render Notes HTML
  function renderNotesHtml(notes) {

    // Render Notes
    $rootElement.html(getNotesTemplate(notes)).text();
    if ($('.o-Capsule__m-TextWrap.print-recipe').length === 0) {
      $rootHeader.append(getNotesPrintHeader).text();
    }

    // Truncate Notes With Options
    SNI.Application.broadcast('behavior-truncate:truncate', {
      moreLessLinks: true,
      suffixString: '...More',
      dotsWithLinks: '',
      keepVerticalWhitespaces: true
    });
  }

  function saveNotes() {
    if (!$notesTextarea) {
      $notesTextarea = $('[data-type="private-notes"]');
    }

    // If notes did not change don't make an API call
    if (privateNotes && privateNotes.trim() && privateNotes === escapeHtml($notesTextarea.val())) {
      renderNotesHtml(privateNotes);
      return;
    }

    privateNotes = escapeHtml($notesTextarea.val());

    // Don't save notes if blank.
    if (privateNotes.trim().length === 0) {
      deletePrivateNotes(detailId, function(resp) {
        debug.log('Delete Private notes Success', resp);
        hidePrivateNotes();
      }, function(err) {
        debug.log('Unable to DELETE private notes.', arguments);
        if (arguments[2].status === 204) {
          hidePrivateNotes();
        }
      });
      return;
    }

    savePrivateNotes(detailId, privateNotes, function(resp) {
      debug.log('Save Private note Success', resp);

      // Render notes HTML
      renderNotesHtml(privateNotes);

    }, function(err) {
      debug.log('Unable to SAVE private notes.', arguments);
    });
  }

  function clearPrivateNotes() {
    if (!$notesTextarea) {
      $notesTextarea = $('[data-type="private-notes"]');
    }

    privateNotes = '';
    $notesTextarea.val(privateNotes);
    $clearButton.attr('disabled', 'disabled');
  }

  function onAddNoteClick() {
    $rootElement.html(getNewNotesTemplate());

    $notesTextarea = $('[data-type="private-notes"]');
    // Set PN in text area if exist
    privateNotes ? $notesTextarea.val(privateNotes).text() : false;
    $clearButton = $('[data-type="clear-private-notes"]');

    if (privateNotes && privateNotes.length > 0) {
      $clearButton.removeAttr('disabled');
    }
  }

  function getNotes() {
    getPrivateNotes(detailId, function(notes) {
      debug.log('NOTES RESPONSE: ', notes);
      if (!notes) return;

      privateNotes = notes.note;

      // Render notes HTML
      renderNotesHtml(notes.note);

    }, function(err) {
      debug.log('Unable to GET private notes.', arguments);
    });
  }

  function getNewNotesTemplate() {
    return `<div class='private-notes__form'>
              <textarea name="private-notes" rows="5" placeholder="Start typing..." class="private-notes__textarea" data-type="private-notes" aria-labelledby="private-notes-label"></textarea>
              <div class='private-notes__form__actions'>
                <button class="private-notes__a-Button a-Button--Clear" disabled="disabled" data-type="clear-private-notes"><span class="a-Button--Clear__a-TextWrap">Clear</span></button>
                <button class="private-notes__a-Button a-Button--Done" data-type="save-private-notes"><span class="a-Button--Done__a-TextWrap">Done</span></button>
              </div>
            </div>`;
  }

  function getNotesPrintHeader() {
    return `<div class="o-Capsule__m-TextWrap print-recipe">
              <h3 class="o-Capsule__a-Headline">
                <span class="o-Capsule__a-HeadlineText">My Private Notes</span>
              </h3>
            </div>`;
  }

  function getNotesTemplate(notes, truncate = true) {
    return `<div class='private-notes__notes'>
              <p class="private-notes__note-content" data-truncate="${config.notesLength}">${notes}</p>
              <p class="private-notes__note-content print-recipe">${notes}</p>
              <button class="private-notes__notes--edit" data-type="edit-private-notes">Edit</button>
            </div>`;
  }

  function addNotesButtonTemplate() {
    return `<button class="add-note" data-type="add-note">
              <svg class="a-Button__a-Icon--Add a-Button__a-Icon a-Icon--Add a-Icon" role="img" aria-hidden="true">
                <symbol id="icon-add" viewBox="0 0 20 20">
                  <path d="M8.3 8.3V1.7C8.3.7 9.1 0 10 0c.9 0 1.7.7 1.7 1.7v6.7h6.7c.9 0 1.7.7 1.7 1.7 0 .9-.7 1.7-1.7 1.7h-6.7v6.7c0 .9-.7 1.7-1.7 1.7-.9 0-1.7-.7-1.7-1.7v-6.7H1.7C.7 11.7 0 10.9 0 10s.7-1.7 1.7-1.7h6.6z"></path>
                </symbol>
                <use xlink:href="#icon-add"></use>
              </svg>
              <span class="add-note__title">Add a Note</span>
            </button>`;
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      setSavesToken(userInterface.getUserToken());
      if (state === 'INIT') {
        debug.log('logged in INIT ' + userInterface.getUserToken());
        getNotes();
      } else if (state === 'EDIT_NOTE') {
        debug.log('logged in EDIT_NOTE');
        getNotes();
      }
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      hidePrivateNotes();
      if (state === 'INIT') {
        debug.log('logged out INIT');
      } else if (state === 'EDIT_NOTE') {
        debug.log('logged out EDIT_NOTE');
        state = 'INIT';
      }
    },
  };

  let onclick = (event, element, elementType) => {

    switch (elementType) {
      case 'add-note':
        debug.log('add-note clicked');
        event.preventDefault();
        if (!userInterface.getLoginStatus()) {
          debug.log('pop Dalton login');
          state = 'EDIT_NOTE';
          dalton.login();
        }
        if (userInterface.getLoginStatus()) {
          state = 'EDIT_NOTE';
          onAddNoteClick();
        }
        break;

      case 'clear-private-notes':
        event.preventDefault();
        clearPrivateNotes();
        break;

      case 'save-private-notes':
        event.preventDefault();
        saveNotes();
        break;

      case 'edit-private-notes':
        event.preventDefault();
        onAddNoteClick();
        break;

      default:
        break;
    }
  };

  function init() {
    debug.log('init');
    config = Object.assign({}, defaults, context.getConfig());
    $rootElement = $(context.getElement()).find(config.notesContainer);
    $rootHeader = $(context.getElement()).find(config.notesHeader);
    setApiEndpoint(config.recipeBoxUrl);
    debug.log('setting endpoint ' + config.recipeBoxUrl);
    state = 'INIT';
  }

  return {

    init,

    behaviors: ['truncate'],

    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },

    onclick,

    oninput,

    getNotes,
  };

});