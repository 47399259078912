SNI.Application.addModule('cook-mode', context => {
/**
  * toggle for "cook mode" (keep screen alive) toggle
  *   at top of directions on mobile & tablet recipe pages
  */

  const INACTIVE_CLASS = 'collapsed';
  const TOGGLE_TEMPL_CLASS = 'cookMode-markup';
  const TOOLTIP_TEMPL_CLASS = 'cookMode-toolTip';
  const TOOLTIP_CLASS = 'o-CookMode__a-ToolTip';
  const TT_LOCSTORE_KEY = 'cookmode_ttdone';

  const deviceType = context.getService('device-type');
  const debug = context.getService('logger').create('module.cook-mode');

  const cmpn = context.getElement();

  const {isMobile, isTablet} = deviceType;

  let screenLock,
      ckbox;

  const insertCmpn = () => {
    cmpn.append(cmpn.getElementsByClassName(TOGGLE_TEMPL_CLASS)[0].content.cloneNode(true));
    ckbox = cmpn.querySelector('input[data-type="toggle"]');
    cmpn.classList.remove(INACTIVE_CLASS);
  };

  // check tooltip status and insert if never dismissed
  const ckInsertTooltip = () => {
    if (!localStorage.getItem(TT_LOCSTORE_KEY)) {
      debug.log('inserting tooltip');
      cmpn.append(cmpn.getElementsByClassName(TOOLTIP_TEMPL_CLASS)[0].content.cloneNode(true));
    }
  };

  // dismiss the tooltip & note that it has been seen
  const closeTooltip = () => {
    cmpn.getElementsByClassName(TOOLTIP_CLASS)[0]?.remove();
    localStorage.setItem(TT_LOCSTORE_KEY, true);
  };

  // if lock is released other than by user, update the UI
  //   (this can happen if user switches away from the browser)
  const setUIupdate = (theLock) => {
    theLock.addEventListener('release', () => { ckbox.checked = false; });
  };

  const getLock = () => {
    debug.log('getLock()');
    navigator.wakeLock.request('screen').then(
      resp => {
        debug.log('getLock resolve');
        screenLock = resp;
        setUIupdate(screenLock);
        debug.log('cook mode activated!');
      })
    .catch (err => { debug.log(`COOK MODE: failed: ${err.name}, ${err.message}`); });
  };

  const releaseLock = () => {
    debug.log('release lock');
    screenLock.release().then(() => { screenLock = null; });
  };


  return {

    oninput: (evt, tgt, type) => {
      if (type === 'toggle') {
        // dismiss tooltip if active
        if (cmpn.getElementsByClassName(TOOLTIP_CLASS).length) {
          closeTooltip();
        }
        if (tgt.checked) {
          getLock();
          debug.log('activate!');
        } else {
          releaseLock();
          debug.log('DEactivate!');
        }
      }
    },

    onclick: (evt, tgt, type) => {
      if (type === 'dismiss') {
        closeTooltip();
      }
    },

    init() {
      // activate only if feature is available in browser, and only on phones & tablets
      debug.log('init: mobile? ' + isMobile + ' tablet? ' + isTablet);
      debug.log('feature ck ' + ('wakeLock' in navigator));
      if ((isMobile || isTablet) && 'wakeLock' in navigator) {
        debug.log('activating');
        insertCmpn();
        ckInsertTooltip();
      }
    }
  };
});
