SNI.Application.addModule('immersive-lead', context => {
  //const utility = context.getService('utility');

  let $moduleEl;
  let $overlay;
  let $promoList;
  let $promoListScroll;
  let $promoListWrapper;
  let mouseEnterTime;
  let initialPromoListHeight;
  let promoListHoverHeight;
  let scaleFactor;
  let leftSideScrollingOffset = 0;

  return {
    behaviors: ['lazy-load', 'horizontal-scroll'],
    messages: ['hoverHeader', 'outHeader', 'openSearch', 'closeSearch'],

    init: () => {
      $moduleEl = $(context.getElement());
      $overlay = $moduleEl.find('#opacityLayer');
      $promoList = $moduleEl.find('.o-ImmersiveLead__m-PromoList');
      $promoListScroll = $moduleEl.find('.o-ImmersiveLead__m-PromoListScroll');
      $promoListWrapper = $moduleEl.find('.o-ImmersiveLead__m-PromoListWrapper');
      initialPromoListHeight = $promoListWrapper.height();

      const onMouseEnter = (event) => {
        $moduleEl.addClass('hovered');

        if (scaleFactor === undefined) {
          promoListHoverHeight = $promoListWrapper.height();
          scaleFactor = promoListHoverHeight / initialPromoListHeight;
        }

        if (scaleFactor > 1) {
          mouseEnterTime = Date.now();

          // calculate origin point to scale promo list relative to the mouse pointer
          const originX = event.clientX + $promoListScroll[0].scrollLeft - $promoList[0].offsetLeft;

          // manually allocate some room on the left side to make it possible to scroll
          // to the very left of the promo list when it's growing beyond container's left edge
          // (the browser does that properly only for elements growing to the right)
          leftSideScrollingOffset = Math.round(originX * (scaleFactor - 1));

          if (leftSideScrollingOffset < 0) {
            leftSideScrollingOffset = 0;
          }

          $promoList.css({
            'transform-origin': `${originX}px 0`,
            'transform': `scale(${scaleFactor})`,

            // "margin-left" property is used here to avoid animation and to make the change unnoticeable to the user
            'margin-left': `${leftSideScrollingOffset}px`
          });
          $promoListScroll[0].scrollLeft += leftSideScrollingOffset;

          // change scrolling container height
          $promoListScroll.css({
            height: `${promoListHoverHeight}px`
          });
        }
      };

      const onMouseLeave = () => {
        if (scaleFactor > 1) {
          let correctScrolling = $promoListScroll[0].scrollLeft - leftSideScrollingOffset;
          let correctPosition = 0;
          if (correctScrolling < 0) {
            correctPosition = -correctScrolling;
            correctScrolling = 0;
          }

          // remove extra scrolling width on the left that we previously allocated
          $promoList.css({
            // make sure the user's hovered long enough to avoid annoying animations
            'transition': Date.now() - mouseEnterTime > 500 ? 'none' : '',

            // pass a value from "margin-left" property to "transform:translateX" to make animation smoother
            'transform': `translateX(${correctPosition}px) scale(${scaleFactor})`,
            'margin-left': 0
          });
          $promoListScroll[0].scrollLeft = correctScrolling;

          // reset scrolling container height
          $promoListScroll.css({
            height: ''
          });

          // reset css to apply theming and start animation
          $promoList.css({
            'transition': '',
            'transform': ''
          });
        }
        $moduleEl.removeClass('hovered');
      };

      const onScroll = () => {
        const $leftScrollingButton = $promoListWrapper.find('.a-ScrollButton--Left');

        // make sure we only display left scrolling button when it's necessary
        // (due to the room for scrolling on the left that we allocated before)
        const isFirstPromoItemVisible = $promoListScroll[0].scrollLeft <= $promoList[0].offsetLeft - leftSideScrollingOffset;
        $leftScrollingButton.css('visibility', isFirstPromoItemVisible ? 'hidden' : '');
      };

      $promoListScroll.on('scroll', onScroll);
      $promoListWrapper.on('mouseenter', onMouseEnter);
      $promoListWrapper.on('mouseleave', onMouseLeave);
      $overlay.css('height', $(document).height());
    },

    destroy: () => {
      $moduleEl = null;
      $overlay = null;
    },

    onmessage: function(name, data) {
      switch (name) {
        case 'hoverHeader':
          this.addOverlay('hoverHeader');
          break;
        case 'openSearch':
          this.addOverlay('openSearch');
          break;
        case 'outHeader':
          this.removeOverlay('hoverHeader');
          break;
        case 'closeSearch':
          this.removeOverlay('openSearch');
          break;
      }
    },

    onclick: function(event, element, elementType) {
      if (elementType === 'feature-item') {
        if (event.target.classList.contains('o-ImmersiveLead__a-ScrollDownButton')) {
          event.preventDefault();
          $('html, body').animate({scrollTop: $(window).height()}, 600);
        }
      }
    },

    addOverlay: function(className) {
      $moduleEl.addClass(className);
    },

    removeOverlay: function(className) {
      $moduleEl.removeClass(className);
    }
  };
});
