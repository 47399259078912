SNI.Application.addModule('on-tv', (context) => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let debug     = context.getService('logger').create('module.on-tv');
  let ll        = context.getService('lazy-load');
  let url       = context.getService('url');
  let element   = context.getElement();
  let $element  = $(element);
  let settings;


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  function init() {
    debug.log('On-TV Module: initialized');

    settings = context.getConfig();

    /** Called on load */
    var now = new Date();
    // Enable the below just if you want to test
    //now.setHours(10,52, 0, 0);
    //now.setMinutes(51);
    showWhatsOnTv(now, $element);

    //fire the lazy load scroll event so images that weren't visible (before showWhatsOnTv fired) will load if in the viewport
    ll.forceScroll();
  }

  function createLabel(sel, text) {
    return `<span class="${sel}">${text}</span>`;
  }
  // TODO: Break this down into smaller parts for easier comprehension/testability
  function showWhatsOnTv(dateTime, element) {

    let whatOnNow,
        whatsOnNext,
        $whatOnNow,
        $whatsOnNext,
        whatsOnTonight,
        $whatsOnTonight,
        onNowVal,
        onNextKey,
        onNextVal,
        prevVal,
        prevKey,
        currVal,
        currKey,
        flag,
        itemsCount      = 0,
        version         = element.find('[data-version]').data('version'),
        watchUrl        = version === 'right-rail'? url.watchSiteOnRightRail() : url.watchSite(),
        mediaBlockClass = 'm-MediaBlock',
        onNowClass      = 'is-OnNow',
        buttonClass     = version === 'responsive' ? 'm-ScheduleCard__a-Button' : `${mediaBlockClass}__a-Button`,
        mediaLabelClass = version === 'responsive' ? 'm-ScheduleCard__a-SubHeadline' : `${mediaBlockClass}__a-Label`,
        mediaLabel2Class = `${mediaBlockClass}__a-Label--OnTV`,
        mediaElement    = `<div class="${mediaBlockClass}__m-MediaWrap"></div>`,
        mediaSelector   = `.${mediaBlockClass}__m-MediaWrap`,
        textSelector    = `.${mediaBlockClass}__m-TextWrap`,
        onNowLabel      = createLabel(mediaLabelClass, 'On Now'),
        nextLabel       = createLabel(mediaLabelClass, 'Up Next'),
        onTonightLabel  = createLabel(mediaLabelClass, 'On Tonight'),
        whatsHotLabel   = createLabel(mediaLabelClass, 'What\'s Hot'),
        watchOnNext     = false,    // When there is no watch now then we append the watch button the On next item
        nextLabelRR     = nextLabel,
        watchButton     = function(url) {
          return `<a href="${url}" class="${buttonClass}">Watch Live TV</a>`;
        };
    let watchLive = watchButton(watchUrl);    
    //get values from the ettings onfig
    let onTonightTimeGMT = settings.onTonightTimeGMT;
    let isWhatsHotOn = settings.isWhatsHotOn;
    let scheduleMap  = new Map();
    // This could be optimized, currently search is Big O n.
    element.find('[data-time]').each(function(indexInArray, value) {
      let airTime = new Date($(value).attr('data-time'));
      scheduleMap.set(airTime,value);

      //if element's airTime equals onTonightTimeGMT then it is whatsOnTonight
      var airTimeHourMin =`${airTime.getUTCHours()}:${airTime.getUTCMinutes()}`;
      if (airTimeHourMin === onTonightTimeGMT) {
        whatsOnTonight = value;
      }

    });

    for (var [key,value] of scheduleMap.entries()) {
      currVal=value;
      currKey=key;

      if (dateTime<=new Date(key)) {
        if (!onNowVal && prevVal && prevKey) {
          onNowVal=prevVal;
          flag=true;
        }
      }
      if (!onNextVal && flag) {
        onNextVal=value;
        onNextKey=key;
      }
      prevVal=currVal;
      prevKey=currKey;

    }
    let nextAirDate= new Date(onNextKey);
    let tenMinutesBefore= new Date(nextAirDate - 1000*60*10);
    if (onNowVal && dateTime<=tenMinutesBefore) {
      whatOnNow=onNowVal;
    }
    if (onNextVal) {
      whatsOnNext=onNextVal;
    }

    //testing...
    //whatOnNow = element.find('[data-time]:first')[0];

    if (whatOnNow) {
      itemsCount++;
      $whatOnNow = $(whatOnNow);

      $whatOnNow.find('.' + mediaBlockClass).addClass(onNowClass);
      //if mediaWrap doesn't exist from template, add mediaWrap for label
      if ($whatOnNow.find(mediaSelector).length <= 0) {
        $whatOnNow.find('.' + mediaBlockClass).prepend(mediaElement);
      }
      let $media = $whatOnNow.find(mediaSelector);
      let $text = $whatOnNow.find(textSelector);

      //mobile
      if (version === 'mobile') {
        $text.prepend(onNowLabel);
        $text.append(watchLive);
      } else if (version === 'responsive') {  // this a different case from mobile
        $text.append([onNowLabel, watchLive]);
      } else {
        $media.append(onNowLabel);
        if (watchUrl.length) {
          $text.append(watchLive);
        }
      }
      $whatOnNow.show();
    } else if (watchUrl.length) {
      watchOnNext = true;
      if (version !== 'responsive') $(whatsOnNext).find(textSelector).append(watchLive);
    }

    if (whatsOnNext && $(whatsOnNext).length) {
      itemsCount++;
      $whatsOnNext = $(whatsOnNext);

      //if mediaWrap doesn't exist from template, add mediaWrap for label
      if ($whatsOnNext.find(mediaSelector).length <= 0) {
        $whatsOnNext.find('.' + mediaBlockClass).prepend(mediaElement);
      }
      let $media = $whatsOnNext.find(mediaSelector);
      let $text = $whatsOnNext.find(textSelector);
      let addedItems = [nextLabel];
      if (watchOnNext) addedItems.push(watchLive);
      if (version === 'mobile') {
        $text.prepend(nextLabel);
      } else if (version === 'responsive') {
        $text.append(addedItems);
      } else if (version === 'right-rail') {
        $media.prepend(nextLabelRR);
      } else {
        $media.append(nextLabel);
      }

      $whatsOnNext.show();
    }

    //if WhatsHot doesn't have image, wrap label in mediaWrap
    if (isWhatsHotOn) {
      itemsCount++;
      let $whatsHotElement = element.find('[data-item="hot"]');
      let $whatsHotLabel = $whatsHotElement.find('[data-label]');
      if ($whatsHotElement.find(mediaSelector).length <= 0) {
        $whatsHotLabel.wrap(mediaElement);
      }
      if (version === 'responsive') {
        $whatsHotElement.find(textSelector).append(whatsHotLabel);
        $whatsHotElement.wrap('<div data-time=""></div>');
      }
    }

    //if either OnNow or WhatsHot is not present then show OnTonight
    if (!whatOnNow || !isWhatsHotOn) {
      itemsCount++;
      $whatsOnTonight = $(whatsOnTonight);
      //if mediaWrap doesn't exist from template, wrap label in mediaWrap
      if (version === 'responsive' && $whatsOnTonight.length>0) {
        $whatsOnTonight.find(textSelector).append(onTonightLabel);
      } else {
        if ($whatsOnTonight.find(mediaSelector).length <= 0) {
          let $whatOnTonightLabel = $whatsOnTonight.find('.' + mediaBlockClass + ' > span');
          $whatOnTonightLabel.wrap(mediaElement);
        }
      }
      $whatsOnTonight.show();
    }

    element.find('[data-time]:not(:visible)').remove();
    if (version === 'responsive' && itemsCount <3) {
      $element.find('[data-version="responsive"]').removeClass('l-Columns--3up').addClass('l-Columns--2up');
    }
    //make some things easier to style
    if (version === 'right-rail') {
      element.find('.' + mediaBlockClass).find('.' + mediaLabelClass + ':not(:first)').remove();
      element.find('.' + mediaLabelClass).each(function() {
        $(this)
          .filter(function() {
            return ($(this).closest('.' + onNowClass).length === 0);
          })
          .prependTo( $(this).closest(mediaSelector) )
          .removeClass(mediaLabelClass)
          .addClass(mediaLabel2Class);
      });
    }

  }

  return {
    init,
    showWhatsOnTv,
    behaviors: ['lazy-load']
  };

});
