/**
 * Load More Behavior
 */

SNI.Application.addBehavior('load-more', (context) => {

  let debug = context.getService('logger').create('behavior.load-more');
  const defaults = {
    loadTechique    : 'ajax', // not implemented yet but it would be nice to be able to AJAX content...
    loadButtonClass : '.load-more button',
    showLessButtonClass: '.load-less button',
    hideButton:false,
    loadContainer   : '.load-more-container',
    loadDataSource  : '.list-load-more',
    limit           : 0
  };
  let lazyLoad = context.getService('lazy-load');
  let config = Object.assign({}, defaults, context.getConfig('loadMore'));
  let $element = $(context.getElement());
  let $dataSource = $element.find(config.loadDataSource);
  let $loadButton = $element.find(config.loadButtonClass);
  let $showLessButton = $element.find(config.showLessButtonClass);
  let $loadContainer = $element.find(config.loadContainer);
  let $hideButton = $element.find(config.hideButton);


  return {
    config,
    init: () => {
      debug.log('Load More Behavior: Init Start');
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'load-more-btn' :
          debug.log('Load More Behavior: Load More Button Clicked');
          this.loadMoreItems();
          break;
        case 'load-less-btn' :
          debug.log('Load Less Behavior: Show Less Button Clicked');
          this.loadLessItems();
          break;
      }
    },

    loadMoreItems: () => {
      let loadedLength = $loadContainer.children().length;
      if (config.limit !== 0) {
        $loadContainer.append( $dataSource.children().slice(0, config.limit).addClass('hiddenBefore') );
      } else {
        $loadContainer.append( $dataSource.contents().addClass('hiddenBefore') );
      }
      // a11y: set focus on first tabbable link in newly-loaded items
      $loadContainer.children().eq(loadedLength).find('a:not([tabindex=-1])').first().focus();
      
      lazyLoad.forceScroll();

      //hide button when appending last set of results
      if ( $dataSource.children().length === 0 ) {
        if ($hideButton) {
          $loadButton.hide();
          $showLessButton.show();
        } else {
          $loadButton.parent().hide();
        }
      }
    },

    loadLessItems:() => {
      $dataSource.append($loadContainer.find('.hiddenBefore').removeClass('hiddenBefore'));
      $loadButton.show();
      $showLessButton.hide();
      // a11y focus on first tabbable link in last item
      $loadContainer.children().last().find('a:not([tabindex=-1])').first().focus();
    }

  };

});
