import './logger';
import './check';

/**
 * Analytics logic that used window.CQ_Analytics directly is now located in our Adobe DTM library, as of the upgrade of AEM to version 6.1.
 * We're keeping this wrapper module for safety and maintenance reasons.
 */

SNI.Application.addService('analytics', function(application) {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */
  let debug = application.getService('logger').create('service.analytics');
  let check = application.getService('check').new(debug);

  let callDynamicPageview = function() {
    if (check.exists('SNI.Analytics.track')) {
      SNI.Analytics.track();
    }
  };

  let attachClickTracking = function(options) {
    if (check.exists('SNI.Analytics.attachClickTracking')) {
      SNI.Analytics.attachClickTracking(options);
    }
  };

  let getValue = function(key) {
    let oldSchoolS = check.exists('s') ? application.getGlobal('s') : false;
    return oldSchoolS ? oldSchoolS[key] : null;
  };

  let setValue = function(key, value) {
    let oldSchoolS = check.exists('s') ? application.getGlobal('s') : false;
    if (oldSchoolS) {
      oldSchoolS[key] = value;
    }
  };

  let getProp = function(index) {
    return getValue('prop' + index);
  };

  let setProp = function(index, value) {
    return setValue('prop' + index, value);
  };

  let getEvar = function(index) {
    return getValue('eVar' + index);
  };

  let setEvar = function(index, value) {
    return setValue('eVar' + index, value);
  };

  let legacyPV = function() {
    if (check.exists('s.t')) {
      application.getGlobal('s').t();
    }
  };

  let trackPrintEvent = function(eventName) {
    if (check.exists('moduleTrack')) {
      debug.log('trackPrintEvent', eventName);
      application.getGlobal('moduleTrack')(window, 'print', 'page print', '0', window.location.href, 'on-page interaction', eventName);
    }
  };

  let trackSaveRecipeEvent = function(eventName) {
    if (check.exists('moduleTrack')) {
      debug.log('trackSaveRecipeEvent', eventName);
      application.getGlobal('moduleTrack')(window, 'save', 'save recipe', '0', window.location.href, 'on-page interaction', eventName);
    }
  };

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */
  return {

    callDynamicPageview,
    attachClickTracking,
    getValue,
    setValue,
    getProp,
    setProp,
    getEvar,
    setEvar,
    legacyPV,
    trackPrintEvent,
    trackSaveRecipeEvent,


    init: function() {
      debug.warn('.init() is called.  We want to eliminate this.');
    }

  };

});
