SNI.Application.addService('device-type', (application) => {
  let debug = application.getService('logger').create('service.device-type');

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  let isMobile = !!application.getGlobal('cqMobile');

  let isTablet = (/ipad|android|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
  // sniffing for 'ipad' in userAgent hasn't worked for a while;
  //   this will pick up any tablet-sized devices having touch
  let smaller_dim = Math.min(window.screen.width, window.screen.height);
  isTablet ||= smaller_dim >= 768 && smaller_dim <= 1024 && window.navigator.maxTouchPoints > 0;
  // remove any known mobile devices (e.g., picked up by 'android' in regex above)
  isTablet &&= !isMobile;

  let isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent)) && !application.getGlobal('MSStream');

  // Let's only use these guys when it's absolutely unavoidable! This is a code smell!
  let isChrome = /Chrome/.test(navigator.userAgent);
  let isSafari = /Safari/.test(navigator.userAgent);
  let isIE = /Trident\//.test(navigator.userAgent);
  let isEdge = /Edge/.test(navigator.userAgent);

  debug.log(`isMobile: ${isMobile}, isTablet: ${isTablet}`);

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    isIE,
    isEdge,
    isChrome,
    isSafari,
    isTablet,
    isMobile,
    isIOS
  };
});
