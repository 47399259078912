/*
 * Query State Service: maintain & construct path & query string for requests to user comment service proxy
 *   factory pattern to allow possible multiple instances to co-exist on a page
 */

SNI.Application.addService('user/comments-query-state', (application) => {

  const debug = application.getService('logger').create('service.user/comments-query-state'),
        userInterface = application.getService('user/user-data');

  const mdManager = application.getGlobal('mdManager');

  const brand = mdManager.getSite().toUpperCase();
  const assetType = (t => t === 'pointofinterestpage' ? 'company' : t)(mdManager.getParameterString('Type'));
  const assetId = mdManager.getDetailId();    // identifies topic (typically a recipe) of comments

  // ties together UI and query params for sort orders:
  const sortOrders = [
    {dispName: 'Most Recent', order: 'NEWEST'},
    {dispName: 'Most Helpful',  order: 'MOST_REACTED'},
    {dispName: 'Oldest',      order: 'OLDEST'},
    {dispName: 'Ratings',     order: 'RATING_DESC'}];

  // Proxy Path:  Dalton => /dalton-comments/ (was G!gya => /comments/)
  const query_path = 'dalton-comments';

  function getQueryPath() {
    return query_path;
  }

  function getStreamId() {
    return assetId;
  }

  function QueryState() {
    debug.log('constructor');
    this.sortOrder = 0;                 // currently selected sort order: initially most recent
    this.cursor = null;                 // key to be used for next incremental fetch in current order
    this.bMore = false;                 // flag indicating that there are more results in current order
  }

  // this is effectively a static member since it is constant
  const getSortOrders = function() {
    return sortOrders;
  };

  QueryState.prototype.getOrder = function(order) {
    return this.sortOrder;
  };

  QueryState.prototype.setOrder = function(order) {
    this.sortOrder = order;
  };

  QueryState.prototype.setPageInfo = function(pageInfo) {
    this.bMore = pageInfo.hasNextPage;
    this.cursor = pageInfo.endCursor;
  };

  QueryState.prototype.resetPageInfo = function() {
    this.bMore = false;
    this.cursor = null;
  };

  QueryState.prototype.isMore = function() {
    return this.bMore;
  };

  // form the GraphQL path & qury string from current state
  QueryState.prototype.getQuery = function() {
    let userLog = userInterface.getLoginStatus() ? `/user/${userInterface.getUserId()}` : '';
    return `${query_path}/brand/${brand}/type/${assetType}/id/${assetId}${userLog}?sort=${sortOrders[this.sortOrder].order}${this.cursor ? `&cursor=${encodeURIComponent(this.cursor)}` : ''}`;
  };

  QueryState.prototype.getUserQuery = function() {
    return `user/brand/${brand}/type/${assetType}/id/${assetId}/user/${userInterface.getUserId()}`;
  };

  // create an instance of the query state service (to allow > 1 per page)
  function create() {
    debug.log('create instance');
    return new QueryState();
  }

  return {
    create,
    getSortOrders,
    getStreamId,
    getQueryPath,
  };

});
