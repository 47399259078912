SNI.Application.addService('video', (application) => {

  let debug = application.getService('logger').create('service.video');
  let nextVideo = 0;

  return {
    setNext(videoNumber) {
      nextVideo = parseInt(videoNumber);
      debug.log('videoNumber: ', videoNumber, 'type: ', typeof nextVideo);
    },
    getNext(player) {
      return nextVideo;
    }
  };

});
