SNI.Application.addModule('collage', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  // let debug = context.getService('logger').create('module.collage');

  let modUtil = context.getService('utility');

  let defaults = {
    numRows: 2,
    gutterWidth: 5,
    imageSizes: {'5':161, '9':301, '13':441}
  };

  let collage = null;

  let _getRandomBetween = function(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  };

  let _shuffle = function(array) {
    return array.sort(function() { return 0.5 - Math.random(); });
  };

  /**
    * @source: the array we're sourcing from
    * @index: the index of the item in the source
    * @dest: the destination array of the item
  */
  let _choosePreset = function(source, index, dest) {
    dest.push(source[index]);
    source.splice(index,1);
  };

  let Collage = function(element, settings) {

    let photoRatios = {
      'square': { scale: 1 },
      'portrait': { scale: 3/4 },
      'landscape': { scale: 4/3 }
    };

    this.rowPresetChoices = [
      {
        name: 'small',
        height: 148,
        images: [photoRatios.landscape, photoRatios.portrait, photoRatios.square, photoRatios.square]
      },
      {
        name: 'medium',
        height: 197,
        images: [photoRatios.landscape, photoRatios.square, photoRatios.portrait]
      },
      {
        name: 'large',
        height: 294,
        images: [photoRatios.landscape, photoRatios.portrait]
      }
    ];

    this.$element = $(element);
    this.$cta = this.$element.find('.m-VisualCta');

    this.rows = [];
    this.chosenRowPresets = [];
    this.rows_total = 0;
    this.image_pool = [];

    this.container = this.$element.find('.m-MediaWrap:visible');
    this.gutter_width = settings.gutterWidth;
    this.rowCount = settings.numRows;
    this.imageSizes = settings.imageSizes;

    // If you've instantiated a Collage, we assume you want to
    // immediately render it. `render` can be called through this
    // module's API if you want to re-calc the layout, re-randomize
    // and re-render at runtime. That's a lot of "r"s.
    this.render();

    let that = this;
    $(window).on('orientationchange',function(){
      setTimeout(function() {
        that.render();
      }, 500);
    });

    window.addEventListener('resize',function(){
      setTimeout(function() {
        that.render();
      }, 500);
    });
  };

  Collage.prototype = {

    getCtaUrl: function() {
      let url = this.$cta.find('[href]').attr('href') || '#';
      this.container.on('click', function(e) {
        window.location.href = url;
        e.preventDefault();
      });
      return url;
    },

    randomize: function() {
      // Generate a subset of images to use for the rows
      // for (var i=0; i <= this.photoFiles.length; i++) {
      //   this.random = _getRandomBetween(0, this.photoFiles.length);
      //   this.image = this.photoFiles[this.random];
      //   this.image_pool.push(this.image);
      //   this.photoFiles.splice(this.random, 1);
      // }

      // To balance out the visualization, we _always_ want to
      // include a "large" row preset (when the `numRows` are exactly 2).
      // So, if we're on the second or, in this case, last iteration
      // and we haven't selected the "large" row preset, then choose it manually.
      let rowCount = this.rowCount;
      let rowPresetChoices = this.rowPresetChoices.slice();
      this.chosenRowPresets = [];

      if (rowCount === 2){
        rowCount = rowCount - 1;
        _choosePreset(rowPresetChoices, 2, this.chosenRowPresets);
      }

      // Randomly choose rows from the row presets
      for (let j=0; j < rowCount; j++){
        this.random = _getRandomBetween(0, rowPresetChoices.length);
        _choosePreset(rowPresetChoices, this.random, this.chosenRowPresets);
      }

      // Randomize further by shuffling the chosen presets
      _shuffle(this.chosenRowPresets);

      // Randomize further by shuffling the photos within this preset
      // for (var k=0, len=this.chosenRowPresets.length; k < len; k++){
      //   _shuffle(this.chosenRowPresets[k].images);
      // }
    },

    calculateLayout: function(row) {
      this.row = row;
      this.row_height = this.row.height;
      this.row_images = this.row.images;

      let x,
          cur_item,
          item_width,
          difference,
          height = 50,
          item_dimensions = [],
          numItems = this.row.images.length,
          container_width = $('body').hasClass('modded-overlay') ? window.innerWidth : this.container.parent().innerWidth();

      // Start iteratively sizing-up each item in the row
      // until they collectively grow up to, or beyond, the
      // containers' width. but then snap them back to the max width
      this.row = 0;
      while (this.row < container_width) {

        // loop through each item
        for (x=0; x < numItems; x++) {
          this.image = this.row_images[x];

          // calculate the width and height for each item
          item_width = Math.floor(this.image.scale * height);

          cur_item = {};
          cur_item.width = item_width;
          cur_item.height = height;

          if (height === 463) cur_item.height = 462;

          item_dimensions.push(cur_item);

          // update the row width based on the item widths minus the gutter total
          this.row += item_width;
        }

        this.row = this.row + (this.gutter_width * (numItems - 1));

        // reset row width if its less than the container width
        if (this.row < container_width) {
          this.row = 0;
          item_dimensions = [];
        }

        // if the computed row width is greater than the container width calculate
        // the difference and subtract from the last item's width
        if (this.row > container_width) {
          difference = this.row - container_width;
          item_dimensions[item_dimensions.length - 1].width -= difference;
        }

        // add 1 to the min-height/ideal height and repeat
        height += 1;
      }
      return item_dimensions;
    },

    chooseImageRendition: function(container, img) {
      let thisSize,
          imgSizes  = Object.keys(this.imageSizes),
          images    = [img.attr('data-src-sm'), img.attr('data-src-md'), img.attr('data-src-lg')];

      //just want to ensure all the image sizes are set before we try to use them :)
      for (let i=images.length-1; i >= 0; i--) {
        if ((typeof(images[i]) !== 'string') || !images[i]) {
          images.pop();
        }
      }
      for (let i=0; i < images.length; i++) {
        thisSize = Number(this.imageSizes[imgSizes[i]+'']);
        if ((thisSize >= container.width) && (thisSize >= container.height)) {
          return images[i];
        }
      }
      return images[images.length-1];
    },

    render: function() {

      this.randomize();

      // initialization here is important because this
      // controls the iterator that chooses from the image pool.
      // if you want images to be more "randomly" chosen,
      // you could adjust this.
      let n = 0,
          li,
          selectedImage,
          altText,
          imagePath,
          width,
          height = 0,
          imageDimensions,
          rowHeights = [],
          listItems = this.container.find('li');

      this.$imgs = [];

      if (this.container.find('.o-Capsule__a-Image').length) this.container.find('.o-Capsule__a-Image').remove();

      // Time to render each preset and its calculated layout.
      // Render calls will _always_ re-calculate layout.
      // Rendering is dom-idempotent. In the sense that it will not
      // append the rendered nodes to existing DOM collages. it replaces.
      for (let i=0; i < this.chosenRowPresets.length; i++) {

        imageDimensions = this.calculateLayout(this.chosenRowPresets[i]);

        // Render to DOM
        for (let y=0; y < imageDimensions.length; y++) {

          this.image = imageDimensions[y];
          width = this.image.width;
          height = this.image.height;

          selectedImage = listItems.eq(n).find('img:first');
          altText = selectedImage.attr('alt') || '';
          imagePath = this.chooseImageRendition(this.image, selectedImage);

          li = $('<li />', {
            height: height,
            width: width,
            'class': 'o-Capsule__a-Image'
          });

          this.$imgs.push( $('<img />', {
            src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',  //1x1 transparent gif
            'data-src': imagePath,
            'alt': altText,
            width: width,
            height: height
          }).appendTo(li) );
          li.appendTo(this.container);

          n++;
        }
        rowHeights.push(height);
      }

      this.initLazyLoad(this.$imgs);

      //if more than 2 rows tall, vertically center in the first two rows
      if (this.rowCount > 2) {
        this.$cta.css({
          'transform' : 'translate(-50%, 0)',
          'top' : ( ( ( rowHeights[0] + rowHeights[1] - this.$cta.outerHeight() + this.gutter_width ) / 2 ) + this.gutter_width )
        });
      }
    },

    initLazyLoad: function($imgs) {
      let randomString = ((Math.random() * 10000000000000000) + '_' + Date.now()).replace(/\./g, ''),
          events = [
            'scroll.collage-lazyload_' + randomString
            //'resize.collage-lazyload_' + randomString
          ];
      let collage = this;

      $(window).on(events.join(' '), function() {
        if ($imgs.length === 0) {
          for (let j=0; j < events.length; j++) {
            $(window).off(events[j]);
          }
        } else {
          for (let i=0; i < $imgs.length; i++) {
            if ($imgs[i] && modUtil.isInViewport($imgs[i][0], 'partial')) {
              $imgs[i]
                .hide()
                .filter('[data-src]')
                .one('load', function() {
                  $(this).closest('li').css('background-image', 'url(' + $(this).attr('data-src') + ')');
                  // don't need now, with theming:  $(this).siblings('.shim').addClass('faded');
                  collage.$cta.fadeIn(700);
                })
                .attr('src', $imgs[i].attr('data-src'))
                .each(function(idx, elt) {
                  //Cache fix for browsers that don't trigger .load()
                  if (elt.complete) $(elt).trigger('load');
                });

              $imgs.splice(i, 1);
              i--;
            }
          }
        }
      }).trigger(events[0]);
    }

  };  // end prototype


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------


  let init = function() {
    let config = Object.assign({}, defaults, context.getConfig());
    collage = new Collage(context.getElement(), config) || collage;
  };

  let destroy = function() {
    collage = null;
  };

  return {
    init,
    destroy
  };

});
