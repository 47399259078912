import './logger';
import './analytics';
import './cookie';
import './device-type';

SNI.Application.addService('print', function(application) {

  const debug = application.getService('logger').create('services.print');
  const analytics = application.getService('analytics');
  const PRINT_EVENTS = {
    mouse: 'printer button',
    keyboard: 'print shortcut key',
    system: 'file print'
  };

  function configureAndPrint() {
    window.print();
  }

  function invoke(initiator, e) {
    // Default to system print if initiator is not in PRINT_EVENTS;
    let defaultToSystem = !Object.keys(PRINT_EVENTS).includes(initiator);
    let printEvent = defaultToSystem ? PRINT_EVENTS['system'] : PRINT_EVENTS[initiator];
    debug.log('print event:', printEvent);

    configureAndPrint();
    analytics.trackPrintEvent(printEvent);
  }

  function bindShortcutKeys(callback) {
    //allows us to capture command+p and/or control+p so that if a user tries to print, let's simulate a click on our print button for an optimized print experience.
    $(window).on('keydown', function(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 80) { /*ctrl+p or command+p*/
        debug.log('Keydown event', e);
        e.preventDefault();
        if (typeof callback === 'function') {
          callback();
        }
      }
    });
  }

  function init() {
    bindShortcutKeys(function() {
      invoke('keyboard');
    });
  }

  return {
    init,
    invoke,
    bindShortcutKeys,
  };

});
