SNI.Application.addService('state', application => {

  const state = {};

  const triggerWatchers = (store, key, newValue) => {
    const config = store.__config;
    const oldValue = config.data[key];
    // Only update value if it wasn't the same as the previous value
    if (newValue !== oldValue) {
      config.data[key] = newValue;
      if (config.watch && config.watch[key]) {
        config.watch[key](newValue);
      }
      if (config.watchAll) {
        config.watchAll(key, newValue);
      }
    }
  };

  const configureData = (store, { data = {} }) => {
    Object.keys(data).forEach(key => {
      if (store.hasOwnProperty(key)) {
        store[key] = data[key];
      } else {
        store.__config.data[key] = data[key];
        Object.defineProperty(store, key, {
          get: () => store.__config.data[key],
          set(val) {
            triggerWatchers(store, key, val);
          },
          enumberable: true,
          configurable: true
        });
      }
    });
  };

  const configureWatchers = (store, { watch = {}, watchAll }) => {
    Object.assign(store.__config, { watch, watchAll });
  };

  const configureMethods = (store, { methods = {} }) => {
    Object.assign(store.__config, { methods });
    Object.assign(store, methods);
  };

  const configureComputed = (store, { computed = {} }) => {
    Object.assign(store.__config, { computed });
    Object.keys(computed).forEach(key => {
      if (!store.hasOwnProperty(key)) {
        Object.defineProperty(store, key, {
          get: computed[key]
        });
      }
    });
  };

  const observe = (store, storeConfig) => {

    configureData(store, storeConfig);
    configureWatchers(store, storeConfig);
    configureMethods(store, storeConfig);
    configureComputed(store, storeConfig);

  };

  return {
    getStateTree: () => state,
    createStore(id, storeConfig) {
      if (typeof id === 'object' || storeConfig === undefined) {
        throw new Error('create should be given an id, only passed in a config');
      }
      storeConfig.data = storeConfig.data || {};

      const store = {
        data: {},
        watch: {},
        watchAll: {},
        methods: {}
      };

      Object.defineProperty(store, '__id', {
        value: id,
        enumberable: false,
        writable: false,
        configurable: false
      });

      Object.defineProperty(store, '__config', {
        value: storeConfig,
        enumberable: false,
        writable: false,
        configurable: true
      });

      observe(store, storeConfig);

      state[id] = store;
      return store;
    },

    updateStore: observe

  };

});
