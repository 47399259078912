SNI.Application.addModule('recipe-lead', (context) => {

  const debug = context.getService('logger').create('module.recipe-lead');

  const dalton = SNI.Config.useDaltonLogin ? context.getService('dalton/dalton-interface') : null;
  const userInterface = SNI.Config.useDaltonLogin ? context.getService('user/user-data') : null;

  const mdManager = context.getGlobal('mdManager');
  const recipeId = mdManager.getDetailId();
  const utility = context.getService('utility');
  const $element = $(context.getElement());
  const $mealPlanBtn = $('.a-Button--MealPlan');
  const $addToMealPlan = $mealPlanBtn.find('.add-to-meal-plan');
  const $addedToMealPlan = $mealPlanBtn.find('.added-to-meal-plan');
  const $leadContainer = $element.find('.o-RecipeLead');
  const config = context.getConfig();

  let DatMCP; // assign later, before calling

  const {
    graphQLEndpoint,
    isMealPlanEnabled,
    myMealPlanUrl,
  } = config;

  const requestVariables = {
    'id': recipeId
  };
  let clickedAddToMealPlanBeforeLogin,
      isInMealPlan;
  clickedAddToMealPlanBeforeLogin = isInMealPlan = false;

  function fetchToken() {
    let token;
    if (SNI.Config.useDaltonLogin) {
      token = userInterface.getUserToken();
    }
    return token;
  }

  function setupMealPlanHandler() {
    if (SNI.Config.useDaltonLogin) {
      $mealPlanBtn.on('click', function(e) {
        if (userInterface.getLoginStatus()) {
          isInMealPlan ? removeRecipeFromMealPlan() : addRecipeToMealPlan();
        } else {
          clickedAddToMealPlanBeforeLogin = true;
          dalton.login();
        }
      });
    }
  }

  const recipeInMealPlan = `
    query recipeInMealPlan($id: ID!){
      recipe(id: $id) {
        userState {
          inMealPlan
        }
      }
    }
  `;

  const addToMealPlan = `
    mutation addToMealPlan(
      $id: ID!
    ) {
      addToMyMealPlan(
        input: {
          items: {
            id:$id
            type: Recipe
          }
        }
      ) {
        status
      }
    }
  `;

  const removeFromMealPlan = `
    mutation removeMyMealPlanAssets(
      $id: ID!
    ) {
      removeMyMealPlanAssets(
        input: {
          items: {
            id: $id,
            type: Recipe
          }
        }
      ) {
        status
      }
    }
  `;

  function getMealPlanStatus() {
    const authToken = fetchToken();
    utility.postGraphQLRequest(graphQLEndpoint, authToken, recipeInMealPlan, requestVariables)
      .then(
        data => {
          if (data && data.recipe.userState.inMealPlan) {
            updateMealPlanStatus(true);
            debug.log(`recipe-lead: Recipe id: ${recipeId} is in users meal plan`);
          } else {
            isInMealPlan = false;
            debug.log(`recipe-lead: Recipe id: ${recipeId} not in users meal plan`);
          }
        }
      )
      .catch(err => {
        debug.log('Failed to track Recipe - in Meal Plan status. Error :', err);
      });
  }

  // UI status!
  function updateMealPlanStatus(added=true) {
    if (added) {
      isInMealPlan = true;
      $mealPlanBtn.attr('title', 'Remove from Meal Plan');
      $addToMealPlan.hide();
      $addedToMealPlan.show();
    } else {
      isInMealPlan = false;
      $mealPlanBtn.attr('title', 'Add to Meal Plan');
      $addToMealPlan.show();
      $addedToMealPlan.hide();
    }
  }

  function addRecipeToMealPlan() {
    const authToken = fetchToken();
    if (authToken && recipeId) {
      utility.postGraphQLRequest(graphQLEndpoint, authToken, addToMealPlan, requestVariables)
        .then(
          data => {
            updateMealPlanStatus(true);
            showNotificationBar('Recipe added.');
            clickTracking(myMealPlanUrl, 'meal plan', 'add recipe to meal plan', 'fnk:meal plan:add to meal plan');
          }
        )
        .catch(err => {
          debug.log('Failed to add recipe to meal plan. Error: ', err);
        });
    }
  }


  function removeRecipeFromMealPlan() {
    const authToken = fetchToken();
    if (authToken && recipeId) {
      utility.postGraphQLRequest(graphQLEndpoint, authToken, removeFromMealPlan, requestVariables)
        .then(
          data => {
            updateMealPlanStatus(false);
            showNotificationBar('Recipe removed.');
            clickTracking(myMealPlanUrl, 'meal plan', 'remove recipe from meal plan', 'fnk:meal plan:remove from meal plan');
          }
        )
        .catch(err => {
          debug.log('Failed to remove recipe from meal plan. Error: ', err);
        });
    }
  }


  // 'Snackbar' Notification bar
  function showNotificationBar(text) {
    const showNotificationBarClass = 'fnk-notification-bar--show';
    let $notificationBar = $element.find('.fnk-notification-bar');
    const modalExists = $notificationBar.length !== 0;

    if (!modalExists) {
      // Create and add notification bar
      const markup = `
        <div class="fnk-notification-bar fnk-notification-bar--show">
          <p class="fnk-notification-bar__text">
            ${text}
          </p>
          <a href="${myMealPlanUrl}" class="fnk-notification-bar__button">VIEW PLAN</a>
        </div>
      `;
      $element.append(markup);

      // Add class for animation
      $notificationBar = $element.find('.fnk-notification-bar');
      let $notificationLink = $element.find('.fnk-notification-bar__button');
      $notificationBar.addClass(showNotificationBarClass);
      $notificationLink.on('click', function() {
        clickTracking(myMealPlanUrl, 'meal plan', 'view plan');
      });
      setTimeout(() => {
        $notificationBar.removeClass(showNotificationBarClass);
      }, 3000);
    } else {
      // Update text in notification bar
      const $notificationBarText = $notificationBar.find('.fnk-notification-bar__text');
      $notificationBarText.text(text);

      // Add class for animation
      $notificationBar.addClass(showNotificationBarClass);
      setTimeout(() => {
        $notificationBar.removeClass(showNotificationBarClass);
      }, 3000);
    }
  }


  function clickTracking(href, module, linkTitle, action) {
    if (window && window.moduleTrack) {
      const currentPage = window.location.href;
      // https://discoveryinc.atlassian.net/wiki/spaces/ANY/pages/282074519/Discovery+Standard+Module+Click+Tracking
      window.moduleTrack(this, module, linkTitle, action, '0', currentPage, href, 'click');
    }
  }

  // If there's no avatar, adjust the css and spacing
  function adjustStylesBasedOnContent() {
    if ($('[data-module="recipe-summary"] .o-Attribution__a-Image').length === 0) {
      debug.log('Recipe does not have an avatar');
      $leadContainer.addClass('o-RecipeLead--NoAvatar');
      $('.container-site').addClass('container-site--NoAvatar');
    } else {
      $leadContainer.addClass('o-RecipeLead--HasAvatar');
      $('.container-site').addClass('container-site--HasAvatar');
    }
  }

  const IDSP_handlers = (function() {
    if (SNI.Config.useDaltonLogin) {
      return {
        'dalton:logged-in': (dta) => {
          debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
          getMealPlanStatus();
          if (clickedAddToMealPlanBeforeLogin && !isInMealPlan) {
            addRecipeToMealPlan();
          }
          clickedAddToMealPlanBeforeLogin = false;
        },
        'dalton:logged-out': () => {
          debug.log('msg recd: Dalton user is logged out');
          if (isMealPlanEnabled && recipeId) {
            updateMealPlanStatus(false);
          }
        }
      };
    }
  })() || {};

  const messageHandlers = {
    'kdp-library-play-video': ({ playerId, videoIndex = 0 }) => {
      const lookForVideo = $element.find(`[data-player-id="${playerId}"]`);
      if (lookForVideo.length > 0) {
        $element.addClass('recipe-lead--showing-video');
        $leadContainer.addClass('o-RecipeLead--VideoShowing');
      }
    },
    ...IDSP_handlers
  };


  return {
    behaviors: ['truncate', 'invoke-inline-video', 'affix'],
    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },
    init() {
      debug.log('init');
      adjustStylesBasedOnContent();
      // Hide grey placeholder boxes for ads on recipe page once they load
      DatMCP = context.getGlobal('DatMCP');
      if (DatMCP) {
        DatMCP.execute(function(DatMCP) {
          DatMCP.addEventListener('slotCreativeLoaded', function(e) {
            debug.log('Creative slot loaded, hiding placeholder:', e.slot);
            const adSlotEl = e.slot.element;
            $(adSlotEl).addClass('hide-ad-placeholder');
          });
        });
      }

      if (isMealPlanEnabled && recipeId) {
        requestAnimationFrame(() => $mealPlanBtn.show());
        setupMealPlanHandler();
      }
    }
  };

});
