SNI.Application.addModule('search-filters', (context) => {

  //let debug = context.getService('logger').create('module.search-filters');
  let $ele = $(context.getElement());
  let device = context.getService('device-type');
  let statsSel = '.o-SearchStatistics';

  if (device.isMobile) {
    $ele.find('[data-refine-button]').first().each(function() {
      let $btn = $(this);
      $(statsSel).css({paddingRight: $btn.outerWidth(true)});
      $btn.on('click', function() {
        $btn.toggleClass('is-Opened');
        $ele.find('[data-refine-options]').toggle();
      });
    });
    $ele.find('input[data-href-on][data-href-off]').on('change', function() {
      window.location.href = $(this).attr(`data-href-${$(this).is(':checked') ? 'on' : 'off'}`);
    });
  }
  return {};

});
