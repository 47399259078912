/*
 * Query State Service: maintain & construct path & query string for requests to user content service proxy
 *   factory pattern to allow possible multiple instances to co-exist on a page
 */

SNI.Application.addService('user/summary-query-state', (application) => {

  const debug = application.getService('logger').create('service.user/summary-query-state');

  const mdManager = application.getGlobal('mdManager');

  const brand = mdManager.getSite().toUpperCase(); 
  
  function QueryState(type, id) {
    debug.log('constructor');
    this.assetType = type;        // type of topic (recipe or company)
    this.assetId = id;            // identifies topic (typically a recipe) of comments
  }
  
  // form the GraphQL path & qury string from current state
  QueryState.prototype.getQuery = function() {
    return `ratings/brand/${brand}/type/${this.assetType}/id/${this.assetId}`;
  };
  
  // create an instance of the query state service (to allow > 1 per page)
  function create(type, id) {
    debug.log('create instance');
    return new QueryState(type, id);
  }

  return {
    create,
  };
});
