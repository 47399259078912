/*
 * Opens the print dialog on click.
 */
SNI.Application.addModule('asset-print', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const mName = 'asset-print';
  const debug = context.getService('logger').create('module.' + mName);
  const print = context.getService('print');

  const $printBtnContainer = $(context.getElement());
  const recipePrintTarget = '#print-recipe-lead',
        recipeLeadImage = '.o-RecipeLead__m-RecipeMedia .a-Image',
        gvpRecipeImage = '.kdp .kdp-poster__image';

  function hideIngredientsIfEmpty() {
    if ($('.o-Ingredients__m-Body').html().trim() === '') {
      $('.o-Ingredients').addClass('o-Ingredients--hide-print');
    } else {
      $('.o-Ingredients').removeClass('o-Ingredients--hide-print');
    }
  }

  function setPrintImage() {
    let printContainer = $(recipePrintTarget);
    let leadImage = $(recipeLeadImage).add($(gvpRecipeImage)).first();
    if (printContainer && leadImage) {
      debug.log('setting print image');
      printContainer.append(leadImage.clone());
    }
  }

  function init() {
    debug.log('init');
    hideIngredientsIfEmpty();

    setPrintImage();

    print.init();

    $printBtnContainer.addClass('o-AssetPrint--Loaded');
  }

  let onclick = (event, element, elementType) => {
    if (elementType === 'print-asset') {
      event.preventDefault();
      debug.log('print button clicked');
      print.invoke('mouse', event);
    }
  };

  // Public
  //-----------------------------------------------------------
  return {
    init,
    onclick,
  };

});
