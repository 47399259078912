SNI.Application.addBehavior('tab', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let $element = $(context.getElement());
  let activeClass = 'is-Active';
  let debug = context.getService('logger').create('behavior.tab');
  let check = context.getService('check').new(debug);

  /* ========================================================================
   * Bootstrap: tab.js v3.1.1
   * http://getbootstrap.com/javascript/#tabs
   * ========================================================================
   * Copyright 2011-2014 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   * ======================================================================== */
  let _registerPlugin = function($) {

    let Tab = function(element) {
      this.element = $(element);
    };

    Tab.prototype.show = function($elt) {
      let $ul      = $elt.closest('ul:not(.dropdown-menu)');
      let selector = $elt.data('target');

      if (!selector) {
        selector = $elt.attr('href');
        selector = selector && selector.replace(/.*(?=#[^\s]*$)/, '');   //strip for ie7
      }

      if ($elt.parent('li').hasClass(activeClass)) return;

      let previous = $ul.find(`.${activeClass}:last a`)[0];

      let $target = $(selector);

      this.activate($elt.parent('li'), $ul);
      this.activate($target, $target.parent(), function() {
        $elt.trigger({
          type: 'tab.shown',
          relatedTarget: previous
        });
      });
    };

    Tab.prototype.activate = function(element, container, callback) {
      let $active    = container.find(`> .${activeClass}`);
      let transition = callback
        && check.jqueryPlugin('emulateTransitionEnd')
        && $.support.transition
        && $active.hasClass('fade');

      function next() {
        $active
          .removeClass(activeClass)
          .find(`> .dropdown-menu > .${activeClass}`)
          .removeClass(activeClass);

        element.addClass(activeClass);

        if (transition) {
          element[0].offsetWidth;   // reflow for transition
          element.addClass('in');
        } else {
          element.removeClass('fade');
        }

        if (element.parent('.dropdown-menu')) {
          element.closest('li.dropdown').addClass(activeClass);
        }

        callback && callback();
      }

      transition ?
        $active
          .one($.support.transition.end, next)
          .emulateTransitionEnd(150) :
        next();

      $active.removeClass('in');
    };

    let old = $.fn.tab;
    $.fn.tab = function(option, $elt) {
      let data  = $element.data('bs.tab');
      if (!data) $element.data('bs.tab', (data = new Tab(this)));
      if (typeof option === 'string') data[option]($elt);
    };

    $.fn.tab.Constructor = Tab;

    // TAB NO CONFLICT
    // ===============
    $.fn.tab.noConflict = function() {
      $.fn.tab = old;
      return this;
    };

  };

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  let init = function() {
    if (!$.fn.tab) {
      _registerPlugin($);
    }
  };

  let onclick = function(evt, elt, eltType) {
    if (!check.jqueryPlugin('tab')) return;
    if (eltType === 'tab-ctrl') {
      evt.preventDefault();
      $element.tab('show', $(elt));
    }
  };

  return {

    init,

    onclick

  };

});

