SNI.Application.addModule('tweet-embed', (context) => {

  const debug = context.getService('logger').create('module.tweet-embed');

  let module,
      element,
      defaults,
      twttr,
      config;

  defaults = {
    tweetURL: '',
    jsURL: '//platform.twitter.com/widgets.js',
    embedURL : 'https://api.twitter.com/1/statuses/oembed.json',
    embedName: 'twitter',
    hideConversation: false,
    hideMedia: false
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setEmbedConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function loadEmbed() {
    let apiPath = config.embedURL,
        url = config.tweetURL,
        hideConversation =  config.hideConversation,
        hideMedia = config.hideMedia;

    //Make the API request
    $.ajax({
      url: apiPath,
      dataType:'jsonp',
      data: {
        url: url,
        hide_thread: hideConversation,
        align: 'center',
        hide_media: hideMedia,
        omit_script: true
      },
      context: this
    })
      .done(function(response){
        element.html(response.html);
        twttr.widgets.load();
      })
      .fail(function(xhr, response) {
        debug.warn('loadEmbed: tweet GET failed: ', response);
      });
  }

  module = {
    init() {
      setEmbedConfig(defaults, context.getConfig());
      element = $(context.getElement());
      twttr = context.getGlobal('twttr');
      loadEmbed();
    },

    updateObject,
    setEmbedConfig
  };

  return module;

});
