import './check';
import './device-type';
import './logger';

SNI.Application.addService('read-more', (application) => {

  let config,
      readMoreConfig;
  const check = application.getService('check');
  const SniAds = application.getGlobal('SniAds');
  const device = application.getService('device-type');
  const debug = application.getService('logger').create('service.read-more');

  function adLibLoaded() {
    return check.exists(['SniAds.River', 'SniAds.Event']);
  }

  function setConfig(moduleConfig) {
    config = moduleConfig;
  }

  function setReadMoreConfig(config) {
    readMoreConfig = config;
    debug.log('Read more config', readMoreConfig);
  }

  /**
   * Calculate the initial height of the content when its hidden
   * @param {number} parentTop Offset top for the parent element
   * @param {number} childTop Offset top for the child element
   * @param {number} childHeight Initial height of the child element
   * @returns {number} Difference between the two values plus the initial height of the child element
   * @private
   */
  function calcInitialHeight(parentTop, childTop, childHeight) {
    return childTop - parentTop + childHeight;
  }

  function handleReadMore(data = {}) {
    let containerClass = data && data.containerClass || '',
        $container = $(`.${containerClass}`),
        readMoreRemoved = $container.data('expanded');
    if (!device.isMobile && !readMoreRemoved) {
      $container.data('expanded', true);
      if (adLibLoaded()) {
        SniAds.River.repeatBigbox({
          adClass: 'rr-ad bigbox-ad module text-center',
          target: `.${containerClass}-aside`,
          base: config.adInterval,
          disable: false,
          mobile: {
            disable: true
          }
        });
      }
    }
  }

  /**
   * Adjusts height of contents container to calculated one.
   */
  function setHeight($targetModule) {
    const $revealTarget = $targetModule.find('[data-read-more-target]');
    const divHeight = 310;
    const $element = $targetModule;
    const elementOffset = $element.offset();
    const revealTargetOffset = $revealTarget.offset();
    let height = (readMoreConfig.stream && !readMoreConfig.disableStream) ? readMoreConfig.itemHeight : calcInitialHeight(elementOffset.top, revealTargetOffset.top, divHeight);

    debug.log('Setting height to', height);

    $element
      .height(height)
      .css({
        'width': (readMoreConfig.stream && !readMoreConfig.disableStream) ? 'auto' : '100%',
        'overflow': 'hidden',
        'position': 'relative'
      });

    if (readMoreConfig.collapsed) {
      $element.addClass(readMoreConfig.collapsed);
    }
  }
  
  function unsetHeight($targetModule) {
    $targetModule.css({'height':'auto'});
    $targetModule.removeClass(readMoreConfig.collapsed);
  }

  /**
   * Skip read more if the article is short
   * Perform 2 checks to bail out of adding the readmore button: 
   * 1) if a shopping element is detected then exit
   * 2) If article height is less than a threshold then skip
   * @param {object} $element 
   */
  function skipReadMore($element) {
    /*  If there are shopping elements present then skip  */
    let shoppingModules = ['shopping', 'shopping-embed', 'shopping-promo'];
    let shoppingSelectors = shoppingModules.map((name) => `[data-module="${name}"]`).join(',');
    let $shoppingElements = $element.find(shoppingSelectors);
    if ($shoppingElements.length>0) {
      debug.log('Shopping elements are present');
      return true;
    }
    /* Otherwise check for the length of the article to make sure it needs a button */
    let result;
    let desiredHeight = readMoreConfig.readMoreThreshold; // A pixel height that will be a minimum length before we add the read more button.  In other words, we won't add it to a short article
    let moduleHeight = ($element.get(0) && $element.get(0).scrollHeight);
    let imageHeights = 0; // Images that are not loaded will need to be accounted for, otherwise the article will appear to be shorter than it is
    let debugMsg = 'Adding read more';
    $element.find('.a-Image').each((i,e) => {
      if (e.naturalHeight ===0) {  // If image height is zero then assume 400px image height.   The image might be lazy loaded and we need a default height value
        imageHeights += 400;
      }
    });
    
    if (desiredHeight && moduleHeight) {
      result = (moduleHeight + imageHeights) < desiredHeight;
      debugMsg = result ? 'Skipping read more' : debugMsg;
    }
    debug.log(debugMsg);
    return  result; 
  }

  function removeSection($element) {
    $element.find('[data-read-more-section]').remove();
    $element.height('auto');
    if (readMoreConfig.collapsed) {
      $element.removeClass(readMoreConfig.collapsed);
    }
  }

  function removeReadMore($element, containerClass) {
    removeSection($element);
    handleReadMore({containerClass});
  }
  return {
    handleReadMore,
    setConfig,
    setReadMoreConfig,
    skipReadMore,
    removeSection,
    removeReadMore,
    calcInitialHeight,
    setHeight,
    unsetHeight
  };
});
