/*
 * Social Share Bar Module
 *   - pop up a new window rather than open in a new tab
 *   - manage dynamic updates of sticky sharebar on mobile requested by messages as article stream and vertical photo gallery are scrolled
 */

SNI.Application.addModule('social-share', (context) => {

  const debug = context.getService('logger').create('module.social-share');
  const util = context.getService('utility');
  const sharebarsvc = context.getService('social/sharebar');
  const deviceType = context.getService('device-type');

  let element = context.getElement();

  /*
   * Dynamic ShareBar functions: change the content shared when a social sharebar button is pressed
   * for the mobile use cases that have a single floating sharebar (article stream & vertical gallery)
   */

  function updatePI(dta) {
    element.querySelector('a[data-mode="pinterest"]').dataset.pinHref = `https://www.pinterest.com/pin/create/button?url=${encodeURIComponent(dta.url+'?soc=sharepin')}&media=${encodeURIComponent(dta.image)}&description=${encodeURIComponent(dta.description)}&title==${encodeURIComponent(dta.title)}`;
  }
  
  function updateFB(dta) {
    element.querySelector('a[data-mode="facebook"]').href=`https://www.facebook.com/sharer.php?u=${encodeURIComponent(dta.url+'?soc=sharefb')}`;
  }
  
  function updateTW(dta) {
    element.querySelector('a[data-mode="twitter"]').href=`https://twitter.com/intent/tweet?url=${encodeURIComponent(dta.url+'soc=sharetw')}&text=${encodeURIComponent(dta.title)}&"original_referer=${encodeURIComponent(dta.url)}`;
  }
  
  function updateLI(dta) {
    element.querySelector('a[data-mode="linkedin"]').href='';
  }
  
  function updateEM(dta) {
    element.querySelector('a[data-mode="email"]').href=`mailto:?subject=${encodeURIComponent('Shared from ${publisherName}')}&body=${encodeURIComponent(dta.url)}`;
  }
  
  function updateBtn(sv, dta) {
    debug.log('updateBtn ' + sv);
    switch (sv) {
      case 'PI': 
        updatePI(dta);
        break;
      case 'FB':
        updateFB(dta);
        break;
      case 'TW':
        updateTW(dta);
        break;
      case 'EM':
        updateEM(dta);
        break; 
      case 'LI':
        updateLI(dta);
        break;       
    }
  }
  
  // these values are dynamically updated in vertical gallery
  function getMetaData() {
    return {
      description: document.querySelector('meta[property="og:description"]').getAttribute('content') || '',
      title: document.querySelector('meta[property="og:title"]').getAttribute('content') || '',
      url: location.protocol + document.querySelector('meta[property="og:url"]').getAttribute('content') || '',
      image: document.querySelector('meta[property="og:image"]').getAttribute('content') || ''          
    };
  }
  
  
  function updateShareBar(dta) {
    debug.log('updating with: ' + context.getConfig('socialShareServices'), dta);
    if (!context.getConfig('socialShareServices')) return;   // TODO find out why article stream config getting clobbered
    for (let sv of context.getConfig('socialShareServices').split('-')) {
      debug.log('updateShareBar btn ' + sv);
      updateBtn(sv, dta);
    }
    debug.log('updateShareBar  done');
    return;
  }
  
  /*
   * utility function for debugging in lower environments: change shared url to public prod domain
   * e.g., www.hgtv.env.sni.hgtv.com => www.hgtv.com
   * enable with ?debug=true
   */

  function mkProdUrl(s) {
    // extract host  "www.hgtv.env.sni.hgtv.com"
    let envhost = /\/\/(.*?)\/.*/.exec(document.getElementsByTagName('body')[0].dataset.shortenUrl)[1]; 
    let pieces = envhost.split('.');    // ["www", "hgtv", "cel2", "sni", "hgtv", "com"]
    if (pieces.length > 3) {
      let newhost = `${pieces[0]}.${pieces[1]}.${pieces[pieces.length - 1]}`;     // "www.hgtv.com"
      s = s.replace(envhost, newhost);
    }
    if (s.match(/(hgtv|diy|travel)\.\w*?\.home\.sndimg/)) {
      s = s.replace(/(hgtv|diy|travel)\.\w*?\.home\.sndimg/, 'hgtvhome.sndimg');
    } else if (s.match(/(food|cook)\.\w*?\.fnr\.sndimg/))
      s = s.replace(/(food|cook)\.\w*?\.fnr\.sndimg/, 'food.fnr.sndimg');
    return s;
  }

  function init() {
    debug.log('init id=' + element.id);
    if (deviceType.isMobile) sharebarsvc.showOrRemove(element);
  }

  return {
    init,

    messages: [
      'socialShare.updateShareBar',
      'socialShare.remove',
      'socialShare.requested',
      'socialShare.resetSource'
    ],

    onmessage: (name, data) => {
      debug.log(name + ' T3 message received in #' + element.id, data);
      switch (name) {
        case 'socialShare.requested':
          debug.log('Insert a sharebar', data.data);
          // not updating from video.js -- no worse than production
          // updateShareBar(data.data);
          break;
        case 'socialShare.updateShareBar':
          // metaData passed from article-stream; vertical gallery case must scrape from meta og 
          if (!data) 
            data = getMetaData();
          // update the sharebar with current asset metadata
          debug.log('Update sharebar with', data);
          updateShareBar(data);
          break;
      }
    },          

    onclick(ev, elt, eltType) {

      switch (eltType) {
        case 'social-share':
          ev.preventDefault();
          ev.stopPropagation();
          // Pinterest has already scanned its button:
          let soclink = (elt.dataset.mode === 'pinterest' && elt.dataset.pinHref && elt.dataset.pinHref !== '') ? elt.dataset.pinHref : elt.href;
          if (util.isDebug()) soclink = mkProdUrl(soclink);
          debug.log('social-share button clicked ' + elt.dataset.mode + ' ' + soclink);
          window.open(soclink, '_blank', 'width=715,height=760');
          break;
      }
    },

  };
});

