SNI.Application.addModule('grouped-site-search-results', (context) => {
  'use strict';

  let deviceType = context.getService('device-type');

  // Scroll selected Tab to view
  function scrollToTab() {
    let tabs = $(context.getElement()).find('.m-TabbedContent__m-TabNav'),
        activeTab = tabs.find('.m-TabbedContent__a-Tab.is-Active');

    tabs.width($(window).width() - 20);
    if (activeTab.offset().left > tabs.width()) {
      let scrollPos = activeTab.offset().left + activeTab.outerWidth(true)/2 + tabs.scrollLeft() - tabs.width()/2;
      tabs.animate({
        scrollLeft: scrollPos
      }, 150);
    }
  }
  
  return {
    init: () => {
      if (deviceType.isMobile) {
        scrollToTab();
      }
    }
  };
});
