SNI.Application.addBehavior('lightbox', context => {

  const { eventHandler } = context.getService('common-handlers');
  const el = context.getElement();
  const $el = $(el);
  const debug = context.getService('logger').create('behavior.lightbox');
  const store = context.getService('state').createStore(`${el.id}-lightbox`, {
    data: {
      expanded: false
    },
    methods: {
      expand() {
        this.expanded = true;
      },
      collapse() {
        this.expanded = false;
      }
    },
    watch: {
      expanded(expand) {
        if (expand) {
          $el.addClass('is-Expanded').removeClass('is-Collapsed');
        } else {
          $el.addClass('is-Collapsed').removeClass('is-Expanded');
        }
      }
    },
    watchAll(key, val) {
      debug.log('Updated state', key, val);
    }
  });

  Object.assign(store, context.getConfig('lightbox'));

  return {
    onmessage: {
      'lightbox.expand': () => store.expand(),
      'lightbox.collapse': () => store.collapse()
    },
    onclick: eventHandler({
      'lightbox.expand': () => context.broadcast('lightbox.expand'),
      'lightbox.collapse': () => context.broadcast('lightbox.collapse')
    }, true)
  };

});
