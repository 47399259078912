SNI.Application.addBehavior('social-pinterest', (context) => {

  let $element = $(context.getElement());
  let debug = context.getService('logger').create('behavior.social-pinterest');
  let pinSvc = context.getService('pinterest-button');
  let config = context.getConfig('social-pinterest');

  return {
    init() {
      debug.log('Start pin behavior', config);
      if ($element && config.insertToChild) {
        pinSvc.setPinButton({$element, itemConfig: config, data:{}});
      } else {
        debug.error('No elements provided to put Pinit buttons into.');
        return false;
      }
    }
  };
});
