import Kefir from 'kefir';
import { pauseAllVideos } from '../libs/player';
import hash from 'string-hash';

export const SLING_SELECTOR = 'video-overlay';

export function buildUrlWithSelector(link, isMobile = false) {
  let originalUrl = link.href;
  let selector = SLING_SELECTOR;

  if (originalUrl.includes(`.${selector}`)) {
    return originalUrl;
  }

  if (isMobile && !originalUrl.includes('.mobile.')) {
    selector += '.mobile';
  }

  const extensionPattern = /\.(mobile\.?)?(html)?$/;
  let extensionMatch = link.pathname.match(extensionPattern);
  extensionMatch = extensionMatch ? extensionMatch[0] : '';
  let extension = `.${selector}${extensionMatch}`;

  return extensionMatch
    ? link.href.replace(extensionMatch, extension)
    : link.href + extension;
}

const hideOnClickOutside = (id, callback) =>
  Kefir.fromEvents($(document), 'click touchend')
    .skipWhile(event => document.getElementById(id).contains(event.target))
    .take(1)
    .onValue(event => callback(id, event));

SNI.Application.addBehavior('invoke-video-overlay', context => {

  const el = context.getElement();
  const $contexts = $(el).find('[data-type="watch-button"]');
  const $wrappers = $contexts.parent();
  const device = context.getService('device-type');
  const modalService = context.getService('modal');
  const debug = context
    .getService('logger')
    .create('behavior.invoke-video-overlay');
  const modalIdPrefix = `modal-${el.id}`;
  const defaults = {
    clearModal: false
  };
  let config = $.extend(true, {}, defaults, context.getConfig());
  let detachedVid;  // Remove the video player from the DOM but keep it in memory

  function displayModal(modal, id) {
    pauseAllVideos();
    modal.show();
    hideOnClickOutside(id, () => modal.hide());
    context.broadcast('invoke-video-playlist.play', {
      modalId: id
    });
  }

  function invokeOverlay(overlayUrl, originalUrl) {
    debug.log('Invoking overlay for', overlayUrl);
    let modalId = `${modalIdPrefix}${hash(overlayUrl)}`;
    let modal = modalService.getModal(modalId);

    if (modal) {
      if (detachedVid && modal.$container) {
        modal.$container.append(detachedVid);
        modal.$container.css('visibility','visible');
      }
      displayModal(modal, modalId);
      return;
    }

    $.get(overlayUrl)
      .done((content, _, { status }) => {
        /**
         * If the content returns with an html tag that means something went wrong.
         * We're expecting to receive a fragment of html representing the video overlay
         * only.
         */
        if (content.includes('<html') || status !== 200 || !content.trim().length) {
          window.location = originalUrl;
        }
        debug.log('Received content for overlay');
        modal = modalService.create({
          modalId,
          content,
          plugin: {
            isVideoOverlay: true
          },
          overrides: {
            preventStop: true,
            noScroll: true
          }
        });
        // This is needed to add some modal fixes for this experience in FF
        modal.$container.addClass('o-Modal--VideoPlaylist');
        modal.$container.on('hide', () => {
          pauseAllVideos();
          if (config.clearModal) {
            modal.$container.css('visibility', 'hide');
            detachedVid = modal.$container.find('[data-module="video-playlist"]').detach();
          } else {
            modal.$container.find(':visible').each(function() {
              let $el = $(this);
              if ($el.css('visibility') === 'visible') {
                $el.css('visibility', '');
              }
            });
          }
        });
        displayModal(modal, modalId);
      })
      .fail(() => {
        window.location = originalUrl;
      });
  }

  $wrappers.on('click', function(event) {
    event.preventDefault();
    event.stopPropagation();

    const link = $(this)
      .find('a')
      .get(0);
    const url = buildUrlWithSelector(link, device.isMobile);

    invokeOverlay(url, link);
  });

  return {};
});
