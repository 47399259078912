
/**
  * Pinterest Embed : Profile, Board, or Pin
  * -------------------------------------------------------------
  */
SNI.Application.addModule('pinterest-embed', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let debug = context.getService('logger').create('module.pinterest-embed');
  let $element = $(context.getElement());

  const defaults = {
    'pinURL': 'https://www.pinterest.com/pin/create/button/?',
    'jsURL': '//assets.pinterest.com/js/pinit.js',
    'pintype': 'profile',
    'pinSize': 'small',
    'boardWidth': '596',
    'scaleHeight': '240',
    'scaleWidth': '80'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  function attachPinEmbed($container) {

    let $pinEmbed = buildPinEmbed();
    $container.append($pinEmbed);

  }

  function buildPinEmbed($container) {

    let $pinEmbed = $('<a></a>')
      .attr('data-pin-do', getPinDo())
      .attr('href', config.pinURL);

    // set attributes are different for pin, same for board and profile
    if (config.pintype === 'board' || config.pintype === 'profile') {
      $pinEmbed
        .attr('data-pin-board-width', config.boardWidth)
        .attr('data-pin-scale-height', config.scaleHeight)
        .attr('data-pin-scale-width', config.scaleWidth);
    } else if (config.pintype === 'pin') {
      $pinEmbed
        .attr('data-pin-width', config.pinSize);
    }

    return $pinEmbed;

  }

  // returns the correct value for the data-pin-do attribute based ont
  // the pintype in the config
  function getPinDo() {
    let pinDo = '';

    switch  (config.pintype) {
      case 'board':
        pinDo = 'embedBoard';
        break;

      case 'profile':
        pinDo = 'embedUser';
        break;

      case 'pin':
        pinDo = 'embedPin';
        break;
    }

    return pinDo;
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    init() {
      debug.log('Pinterest Embed: Init Start');
      attachPinEmbed($element);
    }
  };
});

