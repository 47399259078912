/*
 * User Comments Feed Module: get & dipslay reviews & replies for current page (recipe or point of interest)
 */

SNI.Application.addModule('user/comments-feed', (context) => {
  const debug = context.getService('logger').create('module.user/comments-feed'),
        utility = context.getService('utility'),
        queryState = context.getService('user/comments-query-state').create(),
        network = context.getService('user/network').create(context.getConfig('chitterProxyEndpoint'), queryState),
        dataAdapter = context.getService('user/chitter-adapter').create(queryState),
        templates = context.getService('user/templates'),
        dalton = context.getService('dalton/dalton-interface'),
        device = context.getService('device-type'),
        userInterface = context.getService('user/user-data');

  let element = context.getElement();
  let commentsContainer, commentsFooter, commentsSortMenu, compReviewInit, userHistory;

  // adapt Chitter response to expected format
  function processResponse(resp) {
    return dataAdapter.processResponse(resp);
  }

  function insertShotComp(html, mode) {
    if (mode === 'init') {
      element.insertAdjacentHTML('afterBegin', html);
    } else {
      commentsContainer.classList.remove('loaded');
    }
  }

  function insertCompReview(html, mode) {
    if (mode === 'init') {
      element.insertAdjacentHTML('afterBegin', html);
      SNI.Application.startAll(html);
      updateAvatar();
      compReviewInit = true;
    } else if (mode === 'replace') {
      let userReview = element.querySelector('#user-review');
      userReview.parentNode.removeChild(userReview);
      element.insertAdjacentHTML('afterBegin', html);
      SNI.Application.startAll(html);
      updateAvatar();
    } else {
      commentsContainer.classList.remove('loaded');
    }
  }

  function insertReplyComment(html, mode, targetId, comment) {
    if (mode === 'replace'){
      let userReply = comment.querySelector(`#reply-${targetId}`);
      let parent = comment.querySelector('.comment-wrapper');
      parent.removeChild(userReply);
      parent.insertAdjacentHTML('beforeend', html);
    }
  }

  async function updateAvatar() {
    let avatar = userInterface.getUserAvatar() || dataAdapter.getDefaultUserAvatar();
    debug.log('updating user avatar on page ' + avatar);
    Array.from(element.getElementsByClassName('reply-compose-box')).forEach( e => {
      e.getElementsByClassName('comment-img')[0].src = avatar;
    });
    document.getElementById('user-review').getElementsByClassName('comment-img')[0].src = avatar;
  }

  // insert the marked up comments in the page; initialize some element variables
  // modes:  'init' => with header & footer; 'replace' => removes currently displayed comments; "append" => add to bottom of currently displayed comments
  function insertComments(html, mode) {
    debug.log('insertComments, mode=' + mode);
    if (mode === 'init') {
      element.insertAdjacentHTML('beforeEnd', html);
      commentsSortMenu = element.querySelector('.comments-sort-order .comments-sort-order-select');
      commentsContainer = element.getElementsByClassName('comments')[0];
      commentsFooter = element.getElementsByClassName('comments-footer')[0];
      commentsContainer.classList.add('loaded');
    } else if (mode === 'replace') {
      commentsContainer.innerHTML = html;
      commentsContainer.classList.add('loaded');
    } else if (mode === 'append') {
      let loadedLength = commentsContainer.children && commentsContainer.children.length || 0;
      commentsContainer.insertAdjacentHTML('beforeEnd', html);
      commentsContainer.classList.add('loaded');
      // tab focus on first button of first comment in added set; don't do for first set
      if (loadedLength)
        commentsContainer.children[loadedLength].querySelector('button').focus();
    } else if (mode === 'appendTop') {
      commentsContainer.insertAdjacentHTML('afterbegin', html);
      commentsContainer.classList.add('loaded');
    }
    // show/hide 'more' button:
    if (queryState.isMore())
      commentsFooter.classList.remove('hidden');
    else
      commentsFooter.classList.add('hidden');
    // set selected sort in drop-down menu:
    if (mode === 'init' || mode === 'replace') {
      commentsSortMenu.querySelectorAll('.comments-sort-order-option').forEach(e => e.ariaSelected = 'false');
      commentsSortMenu.querySelector(`.comments-sort-order-option[data-sort-order="${queryState.getOrder()}"]`).ariaSelected = 'true';
    }
    if (userInterface.getLoginStatus())
      enableDelete(userInterface.getUserId());
    else
      disableDelete();
  }

  function insertCommentError(element){
    let errorElement = element.getElementsByClassName('composebox-error')[0];
    errorElement.innerHTML = 'Please fill required fields';
    errorElement.style.display = 'block';
  }

  function clearCommentError(element){
    let errorElement = element.getElementsByClassName('composebox-error')[0];
    errorElement.innerHTML = '';
    errorElement.style.display = '';
  }

  async function updateUI(fmtMode) {
    debug.log('updateUI, mode=' + fmtMode);
    debug.log('updateUI, user= ' + JSON.stringify(userInterface.getUser()));
    let opts = { mode: fmtMode };
    if (userInterface) opts.userId = userInterface.getUserId();
    if (userInterface && userInterface.getLoginStatus()) {
      if (!userHistory) {
        try {
          let userData = await network.getUserHistory();
          userHistory = dataAdapter.processUserHistory(userData);
        } catch (err) {
          debug.log('Failed to fetch user history', err);
        }
      }
      if (userHistory && userHistory.review){
        let user = {
          commentText: userHistory.review.text,
          rating: userHistory.review.authorAssetRatings.length > 0 ? userHistory.review.authorAssetRatings[0].value : null,
          avatar: userInterface.getUserAvatar(),
        };
        insertCompReview(templates.fmtComposeBox('submitted', '', user), fmtMode);
      } else {
        if (device.isMobile && fmtMode === 'init' || device.isMobile && !userInterface.getLoginStatus()){
          insertShotComp(templates.fmtShowCompose(), fmtMode);
        } else {
          insertCompReview(templates.fmtComposeBox('review', '', userInterface.getUser()), fmtMode);
        }
      }
    } else {
      if (device.isMobile && fmtMode === 'init' || device.isMobile && !userInterface.getLoginStatus()){
        insertShotComp(templates.fmtShowCompose(), fmtMode);
      } else {
        insertCompReview(templates.fmtComposeBox('review', '', userInterface.getUser()), fmtMode);
      }
    }
    network.getComments()
      .then(dta => insertComments(templates.fmtComments(processResponse(dta), opts, userHistory), fmtMode))
      .catch(e => { debug.error('request failed: ' + e); });
  }

  // which comment does this click pertain to?
  function getCommentId(elt) {
    return elt.closest('.comment').dataset.commentId;
  }

  async function fetchUserHistory() {
    try {
      let userData = await network.getUserHistory();
      return dataAdapter.processUserHistory(userData);
    } catch (err) {
      debug.log('Failed to fetch user history', err);
    }
  }

  async function upvote(el, id) {
    let response, type, voteId;
    let count = parseInt(el.innerHTML.replace(/\D/g, ''));

    if (el.classList.contains('successful-upvote')){
      count = count - 1;
      type = 'thumbsDown';
      el.classList.remove('successful-upvote');
    } else {
      count = count + 1;
      type = 'thumbsUp';
      voteId = id;
      el.classList.add('successful-upvote');
    }

    el.innerHTML = `${count > 0 ? '+' : ''}${count}`;
    if (type === 'thumbsDown') {
      userHistory = await fetchUserHistory();
      let reaction = userHistory.thumbsUp.find(react => react.commentId === id);
      if (reaction) voteId = reaction.reactionId;
    }

    response = await network.postReaction(voteId, type);
    debug.log('vote response: ', response);
  }

  async function postComment(el, commentTxt, rating, commentId, type){
    debug.log('formatting review post');
    if (userHistory && userHistory.review && type === 'review') {
      let user = {
        commentText: userHistory.review.text,
        rating: userHistory.review.authorAssetRatings.length > 0 ? userHistory.review.authorAssetRatings[0].value : null,
        avatar: userInterface.getUserAvatar(),
      };
      insertCompReview(templates.fmtComposeBox('submitted', '', user), 'replace');
      return;
    }

    let parentEl, dataLvl = '';
    let date = new Date();
    let user = userInterface.getUser();
    let rootId = '';
    let week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let formattedDate = `${week[date.getDay()]} ${date.getUTCMonth() + 1} ${date.getUTCDate()} ${date.getUTCFullYear()}`;

    user.commentText = commentTxt;
    user.commentId = userInterface.getUserId();
    user.userImageUrl = user.avatar || dataAdapter.getDefaultUserAvatar();
    user.userName = userInterface.getUserName();
    user.new = 'Less than a minute ago';
    user.type = type;
    user.postDate = formattedDate;
    user.upVotes = 0;
    user.rating = rating;

    debug.log('*** pending: ' + JSON.stringify(user));
    if (type === 'review') {
      user.level = 0;
      insertCompReview(templates.fmtComposeBox('submitted', user.id, user), 'replace');
      insertComments(templates.fmtComment(user), 'appendTop');
    } else if (type === 'reply'){
      parentEl = element.querySelector(`[data-comment-id*="${commentId}"]`);
      let rootEl = parentEl.closest('[data-level="0"]');
      rootId = rootEl.getAttribute('data-comment-id');
      dataLvl = parentEl.getAttribute('data-level');
      user.level = parseInt(dataLvl) + 1;
      user.committedReply = true;
      insertReplyComment(templates.fmtComment(user), 'replace', commentId, parentEl);
    }

    //Chitter only allows 5000 characters, production does not warn users of max limit.
    commentTxt = commentTxt.substring(0, 5000);

    let response = await network.postComment(commentTxt, type, rootId, commentId, rating);
    processNetResponse(response, commentId);
  }

  function toggleReply(event, id) {
    let parent = event.target.parentNode.parentNode;

    if (parent.classList.contains('comment-replyLink-active') && event.currentTarget.querySelector(`#reply-${id}`)){
      parent.classList.remove('comment-replyLink-active');
      event.currentTarget.querySelector(`#reply-${id}`).remove();
    } else {
      parent.classList.add('comment-replyLink-active');
      parent.insertAdjacentHTML('afterend', templates.fmtComposeBox('reply', id, userInterface.getUser()));
    }
  }

  function deleteReplyComment(event, id) {
    let replyClassName = 'comment-replyLink-active';
    let parent = event.currentTarget.querySelector(`[data-comment-id*="${id}"]`);
    let replyActive = parent.getElementsByClassName(replyClassName)[0];
    replyActive.classList.remove(replyClassName);
    parent.querySelector(`#reply-${id}`).remove();
  }

  // show the delete icon for own comments, taking care to omit replies nested below
  function enableDelete(uid) {
    if (!commentsContainer) return;
    let userDeleteBtns = commentsContainer.querySelectorAll(`.comment[data-user-id='${uid}'] > .comment-wrapper > .comment-footer .comment-delete`);
    Array.from(userDeleteBtns).map(e => e.hidden = false);
  }

  function disableDelete() {
    if (!commentsContainer) return;
    let deleteBtns = commentsContainer.getElementsByClassName('comment-delete');
    Array.from(deleteBtns).map(e => e.hidden = true);
  }

  async function deleteComment(commentId) {
    let currentEl, rootEl, rootId, type, commentTxt, dataLevel, parentEl, parentId, timestamp;

    currentEl = element.querySelector(`[data-comment-id*="${commentId}"]`);
    timestamp = currentEl.getAttribute('original-time');
    commentTxt = currentEl.getElementsByClassName('comment-body')[0].innerHTML.trim();
    dataLevel = currentEl.hasAttribute('data-level') && currentEl.getAttribute('data-level');

    if (dataLevel === '0') {
      type = 'review';
      parentId = commentId;
      rootId = commentId;
      insertCompReview(templates.fmtComposeBox('review', '', userInterface.getUser()), 'replace');
    } else {
      type = 'reply';
      parentEl = currentEl.closest(`[data-level="${parseInt(dataLevel) - 1}"]`);
      parentId = parentEl.getAttribute('data-comment-id');
      rootEl = currentEl.closest('[data-level="0"]');
      rootId = rootEl.getAttribute('data-comment-id');
    }

    currentEl.remove(); //delete occurs on FE prod no matter what status of post
    await network.postDeleteComment(commentTxt, commentId, type, rootId, parentId, timestamp);
  }


  async function flagComment(id) {
    let comment = element.querySelector(`[data-comment-id*="${id}"]`);
    comment.getElementsByClassName('comment-flag')[0].classList.add('successful-flag');
    let response = await network.postReaction(id, 'flagged');
    debug.log('flag comment: ', response);
  }

  function toggleSortMenu() {
    debug.log('toggle sort menu');
    if (commentsSortMenu.classList.contains('hidden')) {
      commentsSortMenu.classList.remove('hidden');
      utility.bindClickOutside($('[data-type="comments-sort-open"]'), 'comments-sort', toggleSortMenu);
    } else {
      commentsSortMenu.classList.add('hidden');
      utility.unbindClickOutside('comments-sort');
    }
  }

  function setSortOrder(o) {
    if (o == queryState.getOrder())   // eslint-disable-line eqeqeq
      debug.log('setSortOrder: sort order already ' + o + ', not reloading');
    else {
      debug.log('setSortOrder: ' + o);
      queryState.setOrder(o);
      queryState.resetPageInfo();
      updateUI('replace');
    }
  }

  // module entry point
  function init() {
    debug.log('initial load');
    updateUI('init');
  }

  function prevReview(){
    let prevReview = element.querySelector(`[data-comment-id*="${userInterface.getUserId()}"]`);
    let review = {
      element: prevReview,
      text: '',
      rating: 0,
    };

    if (prevReview) {
      review.text = prevReview.getElementsByClassName('comment-body')[0].innerHTML.trim();
      review.rating = parseInt(prevReview.getElementsByClassName('rating-stars')[0].getAttribute('title').charAt(0));
    }
    return review;
  }

  let updateUserUI = {
    loggedIn: () => {
      debug.log('login occurred', compReviewInit);
      if (compReviewInit){
        if (userHistory && userHistory.review || prevReview().element !== null) {
          let user = {
            commentText: userHistory && userHistory.review && userHistory.review.text ?  userHistory.review.text : prevReview().text,
            rating: userHistory && userHistory.review && userHistory.review.authorAssetRatings.length > 0 ? userHistory.review.authorAssetRatings[0].value : prevReview().rating,
            avatar: userInterface.getUserAvatar(),
          };
          insertCompReview(templates.fmtComposeBox('submitted', '', user), 'replace');
          updateAvatar();
          enableDelete(userInterface.getUserId());
          return;
        } else {
          updateAvatar();
        }
      }
    },
    loggedOut: () => {
      debug.log('log out called', compReviewInit);
      if (compReviewInit){
        userInterface.clearUserAvatar();
        insertCompReview(templates.fmtComposeBox('review', '', userInterface.getUser()), 'replace');
      }
      if (userHistory && userHistory.review) {
        let review = element.querySelector(`[data-comment-id*="${userHistory.review.id}"]`);
        if (review) review.getElementsByClassName('comment-delete')[0].remove();
      }
      disableDelete();
    },
  };

  let messageHandlers = {
    'dalton:logged-in': updateUserUI.loggedIn,
    'dalton:logged-out': updateUserUI.loggedOut,
  };

  function processNetResponse(res) {
    debug.log('net response: ', res);
    let message, error, el, newId, pendingEl, trash;
    if (res.status === 'success') {
      if (res.message.errors && res.message.errors.length > 0) {
        error = true;
        if (res.message.errors[0].detail === 'Max root comments exceeded') {
          message = 'Only one review per product is allowed';
          trash = true;
        }
      }
      if (res.message.success && res.message.success.length > 0) {
        for (let item of res.message.success){
          if (item.type === 'comment') newId = item.id;
        }
      }
    } else if (res.status === 'error') {
      error = true;
      message = 'An error has occurred, please try again later';
    }
    el = element.querySelector(`.pending-${res.type}`);

    if (error) {
      el.innerHTML = message;
      el.classList.remove('comment-status-info');
      el.classList.add('comment-status-error');
    }

    pendingEl = el.closest('[data-level]');
    pendingEl.setAttribute('data-comment-id', newId);
    pendingEl.setAttribute('data-user-id', userInterface.getUserId());
    pendingEl.setAttribute('original-time', new Date().toISOString());
    if (trash) pendingEl.getElementsByClassName('comment-delete')[0].hidden = true;
    //  but why?? if (brand === 'cook' ) pendingEl.getElementsByClassName('comment-footer-left')[0].insertAdjacentHTML('beforeend', templates.fmtTrashCan());
  }

  return {
    init,
    messages: Object.keys(messageHandlers),
    onmessage(message, data) {
      messageHandlers[message](data);
    },

    onclick(ev, elt, eltType) {

      switch (eltType) {
        case 'comment-upvote':
          debug.log('comment-upvote clicked');
          upvote(elt, getCommentId(elt));
          break;

        case 'comment-post-review':
          debug.log('comment-review button clicked');
          let review = ev.currentTarget.querySelector('#user-review');
          let reviewText = review.getElementsByClassName('composebox-textarea')[0].value;
          let rating = 0;

          if (reviewText.trim() === '') {
            insertCommentError(review);
            return;
          } else {
            clearCommentError(review);
          }


          if (!userInterface.getLoginStatus()) {
            dalton.login();
          } else {
            if (ev.currentTarget.querySelector('.selected-star')){
              rating = ev.currentTarget.querySelector('.selected-star').getAttribute('data-value');
            }
            postComment(review, reviewText, rating, '', 'review');
          }
          break;

        case 'comment-post-reply':
          debug.log('comment-reply button clicked');
          let commentId = getCommentId(elt);
          let comment = ev.currentTarget.querySelector(`#reply-${commentId}`);
          let commentText = comment.getElementsByClassName('composebox-textarea')[0].value;

          if (commentText.trim() === '') {
            insertCommentError(comment);
            return;
          } else {
            clearCommentError(comment);
          }

          if (!userInterface.getLoginStatus()) {
            dalton.login();
          } else {
            postComment(comment, commentText, 0, commentId, 'reply');
          }
          break;

        case 'comment-reply-toggle':
          debug.log('comment-reply-toggle clicked');
          toggleReply(ev, getCommentId(elt));
          break;

        case 'comments-cancel-btn':
          debug.log('comment-reply-cancel clicked');
          deleteReplyComment(ev, getCommentId(elt));
          break;

        case 'comment-delete':
          debug.log('comment-delete clicked');
          let modalDelete = element.querySelector('#comments-modal');
          if (modalDelete) {
            modal.remove();
          } else {
            element.insertAdjacentHTML('afterBegin', templates.fmtModal('delete', getCommentId(elt)));
          }
          break;

        case 'comment-flag':
          debug.log('comment-flag clicked');
          if (elt.classList.contains('successful-flag')) return;

          let modalFlag = element.querySelector('#comments-modal');
          if (modalFlag) {
            modal.remove();
          } else {
            element.insertAdjacentHTML('afterBegin', templates.fmtModal('flag', getCommentId(elt)));
          }
          break;

        case 'comments-load-more':
          debug.log('comments-load-more clicked');
          updateUI('append');  // gets the "next" response, query parms alreay set up
          break;

        case 'comments-sort-open':
          debug.log('comments-sort-open clicked');
          toggleSortMenu();
          break;

        case 'comments-sort-order':
          debug.log('comments-sort-order clicked');
          setSortOrder(elt.dataset.sortOrder);
          break;

        case 'comment-user-rating':
          let parent = $(elt).parent();
          let dataValue = $(elt)[0].getAttribute('data-value');

          parent.children().each(function() {
            let child = $(this)[0];
            if (child.getAttribute('data-value') === dataValue) {
              $(this).addClass('selected-star');
              $(this).attr('aria-checked', 'true');
            } else if (child.getAttribute('data-value') < dataValue) {
              if ($(this).hasClass('selected-star')) $(this).removeClass('selected-star');
              $(this).addClass('rating-star-full');
              $(this).attr('aria-checked', 'false');
            } else {
              if ($(this).hasClass('rating-star-full')) $(this).removeClass('rating-star-full');
              if ($(this).hasClass('selected-star')) $(this).removeClass('selected-star');
              $(this).attr('aria-checked', 'false');
            }
          });
          break;

        case 'comment-show-compose':
          insertCompReview(templates.fmtComposeBox('review', '', userInterface.getUser()), 'replace');
          break;

        case 'close-modal':
          debug.log('close-modal clicked');
          element.querySelector('#comments-modal').remove();
          break;

        case 'confirm-modal':
          debug.log('comfirm-modal clicked');
          let modal = elt.closest('#comments-modal');
          let id = modal.getAttribute('target-comment-id');
          let type = modal.getAttribute('data-action-type');

          if (type === 'delete') {
            deleteComment(id);
          } else if (type === 'flag') {
            flagComment(id);
          }

          modal.remove();
          break;
      }

    },
    onmouseover(e, element, elementType) {
      switch (elementType) {
        case 'comment-user-rating':
          let parent = $(element).parent();
          let dataValue = $(element)[0].getAttribute('data-value');

          parent.children().each(function() {
            let child = $(this)[0];
            if (child.classList.contains('composebox-rating-star')) {
              if (child.getAttribute('data-value') <= dataValue) {
                $(this).addClass('rating-star-full');
              } else {
                if ($(this).hasClass('rating-star-full')) {
                  $(this).removeClass('rating-star-full');
                }
              }
            }
          });

          break;
      }
    },

  };

});
