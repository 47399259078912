SNI.Application.addModule('show-more', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let debug = context.getService('logger').create('module.show-more');
  let $element = $(context.getElement());
  let elementID = $element.attr('id');
  let maxHeight = 0;
  let $showMoreContainer = $element.find('.show-more-container');

  const defaults = {
    moreText        : 'more',
    lessText        : 'less'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  function showMore() {
    $element.css('max-height', maxHeight);
    if (config.clearParentStyleSel) {
      $element.closest(config.clearParentStyleSel).removeAttr('style');
    }
  }

  function showLess() {
    $element.css('max-height', '');
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  return {

    showMore,
    showLess,
    elementID,

    behaviors: ['toggle-proxy'],

    init() {

      debug.log('Show More: Init Start');

      let showMoreLink, showLessLink;

      showMoreLink = $('<span>').addClass('show-link show-more-link').text(config.moreText);
      showLessLink = $('<span>').addClass('show-link show-less-link').text(config.lessText);

      // add in the show less link inside the last child so it appears inline
      $element.children().last().append(showLessLink);

      // show more link is at the bottom right corner by default so it lives as the last child
      $element.append(showMoreLink);

      // Calculate what the max-height of this container should be. We can't use an arbitrary, large value (e.g. 1000px) because
      // there are CSS transitions applied and a large value causes transition delays. This will take the height of the <ul> and
      // add 34px padding for the <h6> and the show/hide <span> elements.
      if ($showMoreContainer.length){
        maxHeight = $showMoreContainer.height();
      } else {
        maxHeight = $element.find('ul').height() + 34;
      }

    },

    messages:['toggledOn' , 'toggledOff'],

    onmessage: function(name, containerID) {
      if (containerID === elementID) {
        if (name === 'toggledOn'){
          showMore();
        } else if (name === 'toggledOff') {
          showLess();
        }
      }
    }

  };

});
