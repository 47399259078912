SNI.Application.addModule('photo-inspiration-banner', context => {
  const device = context.getService('device-type'),
        config = context.getConfig(),
        behaviors = ['button-link'];

  const {
    searchIcon,
    searchInput,
    btnReset,
    navbar,
    navbarHome,
    facets,
    filters,
    btnToggle,
    overlay,
    btnRooms,
    btnStyles,
    btnColors,
    backBtnClass,
    isActiveClass,
    isOpenClass,
  } = config;

  const $searchIcon = document.querySelector(searchIcon),
        $btnReset = document.querySelector(btnReset),
        $searchInput = document.querySelector(searchInput),
        $btnRooms = document.querySelector(btnRooms),
        $btnStyles = document.querySelector(btnStyles),
        $btnColors = document.querySelector(btnColors),
        $navbar = document.querySelector(navbar),
        $navbarHome = document.querySelector(navbarHome),
        $filters = document.querySelectorAll(filters),
        $btnToggle = document.querySelector(btnToggle),
        $overlay = document.querySelector(overlay),
        categoryMap = [
          {'el': $btnRooms, 'title': 'rooms'},
          {'el': $btnStyles, 'title': 'styles'},
          {'el': $btnColors, 'title': 'colors'}
        ];
  
  function searchPhoto(event, source, $searchInput, isMobile) {
    event.preventDefault();
    
    if (isMobile && !$searchInput.value) return;
    if (!isMobile && (!$searchInput.value || (source==='input' && event.keyCode!==13))) return;
    
    const formAction = 'https://photos.hgtv.com/photos';

    let formTerm = decodeURI($searchInput.value)
      .replace(/\%3D/g, '')
      .replace(/\=/g, '')
      .replace(/\%3C/g, '')
      .replace(/</g, '')
      .replace(/\%28/g, '')
      .replace(/\(/g, '')
      .replace(/\"/g, '')
      .replace(/\%3F/g, '')
      .replace(/\?/g, '');

    let searchQueryAction = formTerm
      .replace(/\-/g, '_')
      .replace(/\s/g, '-')
      .replace(/\%20/g, '-');
      
    let href = formAction + '/' + searchQueryAction + '-';

    if (window.top !== window.self) {
      window.open(href);
    } else {
      window.location = href;
    }
    return false;
  }
  
  function setupMobilePhotoInspirationBanner() {
    function resetInput() {
      $searchInput.value = '';
      $searchInput.focus();
      $btnReset.style.display = 'none';
      $searchIcon.style.display = 'block';
    }
  
    function categoryClick(title, isNavbarOpen=false) {
      const $facets = document.querySelector(facets),
            $selectedFacet = document.querySelector(`.is-accent--${title}`),
            $selectedFacetFilters = document.querySelector(`${facets}-${title}`);
  
      if (isNavbarOpen) {
        $navbar.classList.remove(isOpenClass);
        $navbarHome.style.display = 'none';
        $facets.style.display = 'flex';
        $selectedFacet.classList.remove(isActiveClass);
        $selectedFacetFilters.style.display = 'none';
        return;
      }
  
      for (let i=0; i<$filters.length; i++) {
        $filters[i].style.display = 'none';
      }

      $navbar.classList.add(isOpenClass);
      $facets.style.display = 'none';
      $navbarHome.style.display = 'flex';

      $selectedFacetFilters.style['padding-left'] = $navbarHome.getBoundingClientRect().y + 14;
      $selectedFacetFilters.style.display = 'flex';
      $selectedFacet.classList.add(isActiveClass);
    }
    
    $searchIcon.addEventListener('click', function(event) {
      searchPhoto(event, null, $searchInput, device.isMobile);
    });

    $btnReset.addEventListener('click', function() {
      resetInput();
    });

    $searchInput.addEventListener('keypress', (event) => {
      if (event.keyCode===13) searchPhoto(event, null, $searchInput, device.isMobile);
      
      if ($searchInput.value.trim() === '') {
        $btnReset.style.display = 'none';
      } else {
        $btnReset.style.display = 'block';
      }
    });

    $searchInput.addEventListener('focus', (event) => {
      $searchIcon.style.display = 'none';
    });

    $searchInput.addEventListener('blur', (event) => {
      if ($searchInput.value.trim() === '') {
        $btnReset.style.display = 'none';
        $searchIcon.style.display = 'block';
      }
    });

    categoryMap.forEach(function(category) {
      const {el, title} = category;
      document.querySelector(`.is-accent--${title} .${backBtnClass}`).addEventListener('click', function() {
        categoryClick(title, true);
      });

      el.addEventListener('click', function() {
        categoryClick(title, false);
      });
    });
  }

  function setupPhotoInspirationBanner() {
    function collapseFilters() {
      const $facets = document.querySelectorAll(facets);

      for (let i=0; i<$facets.length; i++) {
        if ($facets[i] && $facets[i].classList) $facets[i].classList.remove(isActiveClass);
      }
  
      for (let i=0; i<$filters.length; i++) {
        if ($filters[i] && $filters[i].classList) $filters[i].classList.remove(isActiveClass);
      }
      
      if ($btnToggle && $btnToggle.classList) $btnToggle.classList.remove(isActiveClass);
      $overlay.style.display = 'none';
    }

    function categoryClick(title, isNavbarOpen=false) {
      const $selectedFacet = document.querySelector(`.button__facet--${title}`),
            $selectedFacetFilters = document.querySelector(`.buttons-filters--${title}`),
            $isActiveFacet = document.querySelector(`.button__facet--${title}.${isActiveClass}`),
            activeEl = [$selectedFacet, $selectedFacetFilters, $btnToggle];
  
      collapseFilters();
  
      if (!isNavbarOpen || $isActiveFacet) return;
  
      $overlay.style.display = 'block';

      activeEl.forEach(function($el) {
        $el.classList.add(isActiveClass);
      });
    }

    $searchInput.addEventListener('keyup', function(event) {
      searchPhoto(event, 'input', $searchInput, device.isMobile);
    });

    $searchIcon.addEventListener('click', function(event) {
      searchPhoto(event, 'icon', $searchInput, device.isMobile);
    });

    window.addEventListener('scroll', function() {
      collapseFilters();
    });

    $btnToggle.addEventListener('click', function() {
      collapseFilters();
    });

    categoryMap.forEach(function(category) {
      const {el, title} = category;
      el.addEventListener('click', function() {
        categoryClick(title, true);
      });
    });
  }

  const module = {
    behaviors,
    init() {
      if (!config) return;
      if (SNI.Config.brand === 'hgtv') {
        if (device.isMobile)
          setupMobilePhotoInspirationBanner();
        else
          setupPhotoInspirationBanner();
      }
    }
  };

  return module;
});