SNI.Application.addModule('article-stream', function(context) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  const loc = context.getGlobal('location'),
        SniAds = context.getGlobal('SniAds'),
        contentInsights = context.getGlobal('Amzn_ci_v4'),
        adsSvc = context.getService('ads'),
        check = context.getService('check'),
        template = context.getService('template'),
        debug = context.getService('logger').create('module.article-stream'),
        tracker = context.getService('scroll-tracker'),
        metadata = context.getService('metadata'),
        analytics = context.getService('analytics'),
        device = context.getService('device-type'),
        manager = context.getService('stream-manager');

  let defaults,
      config,
      $element,
      $endcap,
      $nextUp,
      adLibLoaded,
      currentEntry,
      currentIntervalEntry,
      lastEntry,
      $entryContainer,
      $container;

  defaults = {
    index: 1,
    last: 10,
    interval: 1500,
    adInterval: 1500,
    offset: .60,
    container: 'area',
    mobileAdContainer: 'article-body',
    mobileAdBlock: 'section',
    desktopAdContainer: 'container-aside',
    hardlineClass: 'stream-end',
    trigger: '.stream-trigger',
    endpointSelector: 'lazy-fetch',
    deferAttr: 'data-deferred-module',
    url: loc.pathname,
    ended: false
  };

  /**
    Adds the next up item back in
  **/
  function articleStreamEnd(lastEntrySet) {
    let last = lastEntrySet || lastEntry;
    if (last && last.hasOwnProperty('class') && !config.ended) {
      let lastClass = last.class,
          nextEntry = manager.getNextEntry(manager.getResponse()),
          lastResponse,
          lastMdm,
          lastSeo;
      if (device.isMobile) {
        $endcap.appendTo($(`.${lastClass}`).find('.container-site .container-fluid').find('.row')).addClass(config.hardlineClass);
        $nextUp.insertBefore($(`.${config.hardlineClass}`));
        $nextUp.css('margin-left', '.5rem');
      } else {
        $nextUp.appendTo($(`.${lastClass}`).find('.container-site').find('.col-md-18'));
        if (last.url) {
          $.get(`${last.url}.weRecommend.html`, function(data) {
            if ($('.nextUp').length > 0) {
              $(data.replace(/data-src/g, 'src')).insertAfter('.nextUp');
            }
          });
        }
      }
      config.ended = true;
      if (nextEntry) {
        nextEntry = nextEntry.replace(/\.html$/i, '');
        manager.fetchResponse(`${nextEntry}.${config.endpointSelector}.json`).then((val) => {
          lastResponse = val;
          if (lastResponse.mdManager) {
            lastMdm = lastResponse.mdManager;
          }
          if (lastResponse.seoMetaData) {
            lastSeo = lastResponse.seoMetaData;
          }
          if (lastMdm.Url && lastMdm.Url !== '') {
            $nextUp.find('.m-MediaBlock__m-MediaWrap').find('a').attr('href',`${lastMdm.Url}`);
          }
          if (lastSeo['og:image'] && lastSeo['og:image'] !== '') {
            let newImage = `${lastSeo['og:image']}`;
            if (!device.isMobile) {
              newImage = newImage.replace(/^(\/.+\.)(\d{3,4})(\.)(\d{3,4})(\.\w+)$/m, '$1231$3174$5');
            }
            $nextUp.find('.m-MediaBlock__m-MediaWrap').find('img').attr('src', newImage);
          }
          if (lastMdm.Url && lastMdm.Url !== '') {
            $nextUp.find('.m-MediaBlock__m-TextWrap').find('a').attr('href',`${lastMdm.Url}`);
          }
          if (lastSeo['og:title'] && lastSeo['og:title'] !== '') {
            $nextUp.find('.m-MediaBlock__a-HeadlineText').text(`${lastSeo['og:title']}`);
          }
          if (lastSeo['og:description'] && lastSeo['og:description'] !== '') {
            $nextUp.find('.m-MediaBlock__a-Description').text(`${lastSeo['og:description']}`);
          }
        }).catch((reason) => {
          debug.error('addEntry: unable to fetch nextUp: ', reason);
        });
      }
    }
  }


  /*
    Initialize newly loaded article
  */
  function processArticle(entry, append, order) {

    let $entryContainer, leaderBoard;

    debug.log('processArticle', entry.order, entry.class);

    if (config.index < config.last && typeof entry !== 'undefined' && append) {
      if (entry.hasOwnProperty('content') && entry.content !== '' && entry.class) {
        config.index += 1;

        $(entry.content).appendTo($(`.${config.container}`)).addClass(entry.class).removeClass(config.container);
        $entryContainer = $(`.${entry.class}`);
        $entryContainer.find('.o-Breadcrumb').css('position', 'relative');
        if (device.isMobile) {
          $entryContainer.find('.social-share').css('float', 'right');
        } else {
          // Have the sharebar set data using JSON data from lazy-fetch
          $entryContainer.find('[data-module="social-share"]').data('seoIndex', order);
        }
        manager.setTracked();
        if (config.index > 1) {
          if (config.index===config.last) {
            lastEntry = entry;
          }
          SNI.Application.startAll($entryContainer[0]);
          prepDeferred($entryContainer);
          if (contentInsights) {
            contentInsights(null, $entryContainer[0]);
          }
          if (adLibLoaded) {
            leaderBoard = $entryContainer.find('[data-has-ad]').first();
            if (leaderBoard && entry.order) {
              let leaderBoardId = `${leaderBoard.attr('id')}${entry.order}`;
              leaderBoard.attr('id', leaderBoardId);
              let adType = leaderBoard.data('has-ad');
              adsSvc.tryAppendSlot(leaderBoardId, adType, {}, true);
            }
            if (device.isMobile) {
              const $sections = $entryContainer.find(`.${config.mobileAdContainer}`).children();
              if ($sections.length > 0) {
                // This might be unnecessary?
                $sections.addClass(config.mobileAdBlock);

                $sections.last().after(template.riverAd(entry.class));
                adsSvc.tryAppendSlot(`mobile_rr_bigbox_${entry.class}`, 'dfp_bigbox', {}, true);
              }
            } else {
              const $adContainer = $entryContainer.find(`.${config.desktopAdContainer}`);
              if ($adContainer.length > 0) {
                $adContainer.addClass(`${entry.class}-aside`);

                // Modified because class value has been modified to avoid potentially starting with
                // a number, which is an invalid class name
                const containerId = entry.class.replace('cl-', 'dfp_bigbox_') + '_1';
                adsSvc.tryAppendSlot(containerId, 'dfp_bigbox', {}, true);
                SniAds.River.repeatBigbox({
                  adClass: 'rr-ad bigbox-ad module text-center',
                  target: `.${entry.class}-aside`,
                  base: config.adInterval,
                  disable: false,
                  mobile: {
                    disable: true
                  }
                });
              }
            }
          }
        }
      }
    } else if (config.index < config.last && typeof entry !== 'undefined' && !append) {
      $entryContainer = $(`.${config.container}`).find('[data-sni-area="content"]');
      $entryContainer.addClass(entry.class);
    }
    let loadedUrl = entry.loadedUrl;
    if (loadedUrl) {
      manager.setAsUsed(loadedUrl);
    }
    // Append next up as soon as possible
    if (config.index === config.last) {
      articleStreamEnd(entry);
    }
  }

  function prepDeferred(target) {
    if (typeof target !== 'undefined') {
      target.find(`[${config.deferAttr}]`).each((index, el) => {
        let $el = $(el);

        $el.attr('data-module', $el.attr(`${config.deferAttr}`))
          .removeAttr(`${config.deferAttr}`);
      });
    }
  }

  /*
   Update metadata from article entry
   Fires a page view on article change when article count has increased
  */
  function updateMdm(entry, stat, articleChange, watchScrollInterval = false) {
    if (typeof entry !== 'undefined' && entry.hasOwnProperty('mdm')) {
      let articleIndex = manager.views.articles;
      let metaInfo = entry.mdm || manager.mdm.getEntry(articleIndex);
      let currentStat = stat || manager.views.behavioralInteraction();
      metadata.updateFromJSON(metaInfo);

      if (typeof currentStat !== 'undefined') {
        metadata.updateFromJSON({'behavioralInteraction': currentStat});
      }

      if (manager.views.totalPages > 1 && articleChange || watchScrollInterval) {
        if (manager.views.isNewEntry) {  // only fire a page view when direction is down
          if (!watchScrollInterval) {
            tracker.updateScrollIntervalEdge();
          }
          $(document).trigger('update-mdm', {stat, metaInfo, watchScrollInterval});
          analytics.callDynamicPageview();
        }
      }
    }
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {
    behaviors: ['affiliate'],
    messages: ['sni-ads.repeatingBigBoxPrePageview', 'scroll-tracker.threshold', 'scroll-tracker.interval', 'scroll-tracker.viewed', 'stream-manager.entryLoaded', 'article-stream.end', 'readMore.removed'],

    init() {
      config = Object.assign({}, defaults, context.getConfig());
      $element = $(context.getElement());
      $container = $element.parents('body');
      adLibLoaded = check.exists(['SniAds.River', 'SniAds.Event']);
      if (device.isMobile) {
        $endcap = $element.find('.rR').detach();
      }
      //  manager.getTracked(); //  gets tracked data from SESSION
      manager.setSelector(config.endpointSelector); //  lazy fetch
      manager.addEntry(config.url, false, false); // Get the first entry info - but do not append since it is on the page
      manager.setMaxIndex(config.last);
      tracker.setNamespace('scroll.stream');
      tracker.setViewport(true);
      tracker.setInterval(config.interval);
      tracker.setThreshold(config.offset);
      tracker.setContainer($container);
      tracker.setScrollHandler();

      context.broadcast('scroll-tracker.interval');
    },

    destroy() {
      tracker.removeScrollHander();
    },

    processArticle,
    updateMdm,
    prepDeferred,

    onmessage(name, data) {
      currentEntry = manager.getEntry();
      $entryContainer = $(`.${currentEntry.class}`);
      switch (name) {
        // Handle in article page views/counts only
        case 'sni-ads.repeatingBigBoxPrePageview':  // Event not detected by Mobile?
          manager.views.incrementPage();
          updateMdm(currentEntry);
          debug.log('Page view @', manager.views.behavioralInteraction());
          break;
          // Load new entries
        case 'scroll-tracker.threshold':

          let futureEntry = manager.getNextUpUrl();
          debug.log('@data.index', data.index, 'last:', config.last);
          if (futureEntry && data.index < config.last) {
            debug.log('Update page - get new entry');
            manager.addEntry(futureEntry, true);
          } else {
            debug.warn('scroll-tracker.threshold: unable to get current entry from stream manager');
            context.broadcast('article-stream.end');
          }

          break;
        // Do article init
        case 'stream-manager.entryLoaded':
          debug.log('stream-manager.entryLoaded: processArticle: entry: ', data.order);
          processArticle(data.item, data.append, data.order);
          break;
        // Deprecated in Desktop, used in mobile
        case 'scroll-tracker.interval':
          const stats = manager.views.behavioralInteraction();
          updateMdm(currentIntervalEntry || currentEntry, stats, true, true);

          if (!manager.getStoredEntriesByValue('viewed', false) && config.index === config.last) {
            context.broadcast('article-stream.end');
          }
          break;
        case 'scroll-tracker.viewed':

          /*
            Triggered when a new article is in view.
            Updates metadata
            Updates analytics page view
            Ads RR ads on desktop
          */

          currentEntry = currentIntervalEntry = data.current;
          $entryContainer = $(`.${currentEntry.class}`);
          updateMdm(currentEntry, data.stats, true);

          if (currentEntry.hasOwnProperty('class') && currentEntry.order === 0) {
            $nextUp = $entryContainer.find('.nextUp').detach();
          }
          if (device.isMobile && data.current && data.current.og) {
            context.broadcast('socialShare.updateShareBar', {
              description: data.current.og.description || '',
              title: data.current.og['og:title'] ? data.current.og['og:title'] : (data.current.og.title || ''),
              url: (data.current.og['og:url']) ? data.current.og['og:url'] : (data.current.og.hubURL || ''),
              image: data.current.og['og:image'] || ''
            });
          }
          /*
            If current entry is not viewed then initialize big box and RR ads
          */
          debug.log(currentEntry.viewed, 'viewed?', manager.views.behavioralInteraction());
          if (currentEntry && !currentEntry.viewed) {
            manager.updateStoredEntry(currentEntry.url, 'viewed', true);
          }
          manager.cleanUpViewedEntries();
          break;
        case 'article-stream.end':
          debug.warn('Stream ended unexpectedly');
          articleStreamEnd(); // Will use the module variable called lastEntry to complete the stream
          break;
      }
    }

  };
});
