import '../services/logger';
import '../services/check';

SNI.Application.addModule('next-up', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let debug     = context.getService('logger').create('module.next-up');
  let check     = context.getService('check').new(debug);
  let element   = context.getElement();
  let $element  = $(element);

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  const defaults = {
    blockSelector     : '.m-MediaBlock',
    linkSelector      : '.m-MediaBlock__a-Headline a',
    localStorageName  : 'nextUp'
  };

  let settings = Object.assign({}, defaults, context.getConfig());

  let init = function() {

    debug.log('Next-Up Module: initialized');

    // if no local storage just show the first one
    if (!check.supports('localStorage')) {
      $element.find(settings.selector + ':first').show();
      return false;
    }
    // go through each block and see if the link is to a page already visited
    $element.find(settings.blockSelector).each(function() {
      //console.log('i am this');
      var link = $(this).find(settings.linkSelector).attr('href');
      if (!isURLinLS(settings.localStorageName, link)) {
        // find the first block that is not in local storage, show it, and get out
        $(this).show();
        return false;
      }

    });

    addCurrentPageIntoLS(settings.localStorageName);

  };

  // storing the URL as an array of objects in case we ever want to add more data to it, like number of times visited or something
  function addCurrentPageIntoLS(lsKey) {

    let currentURL = window.location.pathname,
        URLsInLS = localStorage.getItem(lsKey),
        foundURL = false;

    try {
      URLsInLS = JSON.parse(URLsInLS);
    } catch (e) {
      URLsInLS = '';
    }

    // if we have an existing array
    if (Array.isArray(URLsInLS)) {

      $(URLsInLS).each(function(){

        // we currently are already storing this URL
        if (this.url === currentURL) {
          foundURL = true;
          return false;
        }

      });

      if (!foundURL) {
        URLsInLS.push({'url':currentURL});
      }

    } else {

      // we do not have an existing array so make one
      URLsInLS = [{'url':currentURL}];

    }

    localStorage.setItem(lsKey,JSON.stringify(URLsInLS));
  }

  function isURLinLS(lsKey, URL) {
    let URLsInLS = localStorage.getItem(lsKey),
        foundURL = false;

    try {
      URLsInLS = JSON.parse(URLsInLS);
    } catch (e) {
      URLsInLS = '';
    }

    if (Array.isArray(URLsInLS)) {

      $(URLsInLS).each(function(){
        // if we find an instance, return true
        if (this.url === URL) {
          foundURL = true;
          // break out of .each
          return false;
        }
      });

      // once we go through them, if not found, return false
      if (foundURL) {
        return true;
      } else {
        return false;
      }

    } else {

      // if no array, return false
      return false;
    }
  }

  return {
    init
  }; //end return

});
