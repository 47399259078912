/**
* Password Reset (and Forgot Password) module (for Dalton IDSP)
*/

SNI.Application.addModule('password-reset', (context) => {

  const dalton = context.getService('dalton/dalton-interface'),
        utility = context.getService('utility'),
        template = context.getService('template'),
        debug = context.getService('logger').create('module.password-reset'),
        containerId = 'profile',
        containerElt = document.getElementById(containerId);
  let qEmail, qToken;

  function updateUI(isLoggedIn) {
    debug.log('updateUI: ' + isLoggedIn + ' qEmail: ' + qEmail + ' qToken: ' +  qToken);

    if (qEmail)
      dalton.forgotPassword({ container: '#' + containerId });
    else if ((qToken || isLoggedIn) && !containerElt.querySelector('#IDSP-reset') )
      dalton.resetPassword({ container: '#' + containerId, token: qToken, exchange: !!qToken });
    else if (!isLoggedIn && !qToken)
      containerElt.innerHTML = template.idspLoginMarkup('Reset Password');
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      updateUI(true);
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      updateUI(false);
    },
  };

  function init() {
    qEmail = utility.getUrlParam(window.location.href, 'email') === 'true';
    qToken = utility.getUrlParam(window.location.href, 'token');
    debug.log('init: qEmail ' + qEmail + ' qToken ' + qToken);
  }

  return {

    init,

    behaviors: ['dalton/login-logout'],

    messages: Object.keys(messageHandlers),
    onmessage(message, data) {
      messageHandlers[message](data);
    },

  };

});
