/**
 *
 * This behavior converts any http protocol to tel protocol for mobile,
 * thereby providing the ability to invoke the phone number for the dialer .
 *
 **/
SNI.Application.addBehavior('call-protocol', (context) => {

  const defaults = {
    phoneNumber: false
  };

  let element  = context.getElement();
  let $element = $(element);

  let debug = context.getService('logger').create('behavior.call-protocol');
  let config = Object.assign({}, defaults, context.getConfig());

  function updateCallProtocol() {

    $element.find('[data-caller]').each(function(){

      let $this = $(this),
          callNumber = $this.attr('href'),
          callProtocol;

      if (callNumber){
        callProtocol = callNumber.replace('http://', 'tel:');
        $this.attr('href', callProtocol);
      }

    });

  }

  return {

    config,

    init() {
      debug.log('Call Protocol Convert: Init Start');
      updateCallProtocol();
    }

  };

});
