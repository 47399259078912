SNI.Application.addModule('class-schedule', (context) => {
  const debug = context.getService('logger').create('module.class-schedule');
  let element,
      defaults = {
        headerClass:            '.o-Header',
        promoItemClass:         '.m-PromoItem',
        columnContainer:        '.l-Columns.l-Columns--2up',
        mediaBlockClass:        '.m-MediaBlock',
        liveClass:              '.class-live',
        isLiveClass:            'isLive',
        isLive:                 '.isLive',
        scheduleClass:          '.class-schedule',
        isHeader:               false
      };

  let config = Object.assign({}, defaults, context.getConfig());
  const showLive = !config.isHeader;

  if (config.isHeader) {
    config.maxResults = config.maxResults + 1;
  }

  function showActiveClasses($element) {
    let classes = [], $container, $classEl;

    if (($element.parents(config.headerClass).length) > 0) {
      $container = $element;
      $classEl = $container.find(config.promoItemClass);
    } else if ($element.find(config.columnContainer).length > 0) {
      $container = $element.find(config.columnContainer);
      $classEl = $container.find(config.mediaBlockClass);
    }

    $classEl.each((index, cl) => {
      classes.push(cl);
      $(cl).remove();
    });

    $.each(classes, (indexInArray, schedule) => {
      if ($container.children().length < parseInt(config.maxResults)) {
        const currTime = new Date(),
              currGMTTime = currTime.toGMTString(),
              nowTime = Date.parse(new Date(currGMTTime)),
              airStartTime = new Date(Number($(schedule).attr('classstart'))),
              airEndTime = new Date(Number($(schedule).attr('classend'))),
              $classSchedule = $(schedule);

        debug.log('class-schedule: User time: ', currTime);
        debug.log('class-schedule: User time in GMT: ', currGMTTime);
        debug.log('class-schedule: User GMT in millisec: ', nowTime);
        if (nowTime > airEndTime.getTime()) return;
        // LIVE classes
        if (showLive && (nowTime > airStartTime.getTime()) && (nowTime < airEndTime.getTime())) {
          debug.log('class-schedule: LIVE show');
          $classSchedule.addClass(config.isLiveClass);
          $classSchedule.find(config.liveClass).show();
          if (config.isHeader) {
            $classSchedule.find(config.isLive).show();
          }
          $container.append(schedule);
        } else if (config.isHeader && airStartTime.getTime() > nowTime) {
          // Upcoming Classes
          // Show only Upcoming Classes (not live) for users with Free subscription
          debug.log('class-schedule: Upcoming show');
          $classSchedule.find(config.scheduleClass).css('display', 'flex');
          $container.append(schedule);
        }
      }
      $element.show();
    });
  }

  return {
    behaviors: ['truncate'],

    init() {
      element = context.getElement();

      debug.log('class-schedule: initializing');

      showActiveClasses($(element));

      debug.log('class-schedule: initialized');
    }
  };

});
