SNI.Application.addModule('recipe-search-form', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const defaults = {
    searchType : 'module search'
  };

  let $element = $(context.getElement());
  let config = Object.assign({}, defaults, context.getConfig());

  let cqWCMDisabled = context.getGlobal('cqWCMDisabled');
  let debug = context.getService('logger').create('module.recipe-search-form');
  let check = context.getService('check').new(debug);

  let $searchInput;
  let $facetFilter;
  const HEADER_INPUT_ID = 'searchHeader';
  const PHOTO_INPUT_ID = 'searchPhoto';
  const HEADER_SEARCH_COOKIE = 'headerSearch';
  const PHOTO_SEARCH_COOKIE = 'photoSearch';

  let cookieSvc = context.getService('cookie');

  //Assigns user search term to cookie to be displayed on pages.
  let setCookie = function(sTerm) {

    let actualInputID = $searchInput.attr('id');

    if (actualInputID === HEADER_INPUT_ID) {
      cookieSvc.set(HEADER_SEARCH_COOKIE, sTerm, false, false, true);
    }

    if (actualInputID === PHOTO_INPUT_ID) {
      cookieSvc.set(PHOTO_SEARCH_COOKIE, sTerm, false, false, true);
    }
  };

  //Displays cookie from user input to remain in search form.
  let applyCookie = function() {

    let cookieHeader = cookieSvc.get(HEADER_SEARCH_COOKIE);
    let cookiePhoto = cookieSvc.get(PHOTO_SEARCH_COOKIE);
    let actualInputID = $searchInput.attr('id');

    //Apply header search to form and cookie, clear out photo cookie
    if (actualInputID === HEADER_INPUT_ID && cookieHeader) {
      $searchInput.val(cookieHeader);
    }

    //Apply photo library search to form and cookie, clear out header search and header cookie
    if (actualInputID === PHOTO_INPUT_ID && cookiePhoto) {
      $searchInput.val(cookiePhoto);
    }

    /* need to think about this one....
    //Pass header search cookie to photoLibrary search when viewing search results
    $('.collage').on('click', function() {
      cookieSvc.set(PHOTO_SEARCH_COOKIE, cookieHeader, false, false, true);
      $photoInput.val(cookiePhoto);
    });
  */

  };

  // reset the form (action on hitting reset button)
  let resetInput = function() {
    $searchInput.val('').focus();
    $element.find('.typeahead-result').remove();
    $element.find('.typeahead-field').removeClass('show-reset');
  };

  // update reset button visibility based on input empty or not
  let ckClrResetBtn = function($input) {
    if ($input.val().length <= 0) {
      $element.find('.typeahead-field').removeClass('show-reset');
    } else {
      $element.find('.typeahead-field').addClass('show-reset');
    }
  };

  let doSubmit = function($form) {

    //strip special characters from Url and searchBoxes
    let formAction = $form.attr('action');
    let queryActionSuffix = check.isAuthor() ? '-.html' : '-';
    let formTerm = decodeURI($searchInput.val())
      .replace(/\%3D/g, '')
      .replace(/\=/g, '')
      .replace(/\%3C/g, '')
      .replace(/</g, '')
      .replace(/\%28/g, '')
      .replace(/\(/g, '')
      .replace(/\%3F/g, '')
      .replace(/\?/g, '')
      .replace(/\"/g, '');

    $searchInput.val(formTerm);

    setCookie(formTerm);

    let searchQueryAction = formTerm
      .replace(/\-/g,'_')
      .replace(/\s/g,'-')
      .replace(/\%20/g,'-');

    let href = formAction + '/' + searchQueryAction + queryActionSuffix + $facetFilter.val();

    if ( check.isAuthor() && cqWCMDisabled ) {
      href = href + '?wcmmode=disabled';
    }

    let searchFormMetrics = { 'internalSearchType': config.searchType };

    if (check.supports('localStorage')) {
      localStorage.setItem('searchFormMetrics', JSON.stringify(searchFormMetrics));
    }

    /** If in an iframe, open search in a new window.*/
    if (window.top !== window.self) {
      window.open(href);
    } else {
      /** doing window location here for CQ. If we just change the action and natively submit this form, it will add a querystring (or POST). Either of these methods break CQ caching, so we cannot do it. Because of that, we have to hack a bit with the form submission */
      window.location = href;
    }

    return false;

  };


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  let init = function() {

    // search input element
    $searchInput = $element.find('[data-type="search-input"]');
    $facetFilter = $element.find('[data-type="facet-filter"]');

    //Apply cookie to search input (header or photo) to stay in form until replaced or exit.
    applyCookie();

    // would rather use the proper T3 event API but typeahead plugin conflicts:
    $element.find('[data-type="reset-btn"]').on('click', function(evt) {
      evt.preventDefault();
      resetInput();
    });

    // assumes module is the form elt; T3 doesn't handle onsubmit well: doesn't pass elt or elt-type)
    $element.on('submit', function(evt) {
      evt.preventDefault();
      doSubmit($element);
    });

  };

  // T3 click is basically useless bc evt swallowed by typeahead plugin (works when typeahead off)
  // let onclick = function(evt, elt, eltType) {
  // };

  // bind showing/hiding of reset button on keyup (input changed)
  let onkeyup = function(evt, elt, eltType) {
    if (eltType === 'search-input') {
      ckClrResetBtn($(elt));
    }
  };

  return {

    init,

    onkeyup

  };

});
