SNI.Application.addBehavior('shop-this-look', context => {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let deviceType;

  let analytics;
  let template;
  let isMobile;
  let isTablet;
  let shopContainer;
  let analyticsContainer;
  let shopOverlay;
  let productManager;
  let overlayType;
  let photoViewerScrollOffset;
  let defaults;
  let config;
  let shopping;
  let dynamicGalleryType;
  let mdManager;
  let debug;

  defaults = {
    shopOverlayClass: '.shop-overlay',
    overlayCloseEventName: 'click.closeShop',
    closeOverlaySel: '[data-shop-this-look-close]',
    containerSelector: '.slideshow-wrapper',
    shopInsertionSelector: '.slide-container',
    shoppableElement: '.slide',
    shopBtnSelector: '.a-ShopButton',
    productHeaderSelector: '.shop-overlay .products-header',
    stlEnabled: 'productEnabled',
    dynamicSTL: false,
    maxSTLCheck: 'curated',
    maxSTLPreLoad: 3,
    productsInSet: 6,
    vendorCode: 'vendor1',
    disableSets: false, // Disable the fetching of batch product sets
    productListContainer: '.o-PhotoGalleryPromo__m-ProductsList',
    partnerText: 'partner;wayfair/shop_this_look',
    shopNamespace: 'ShopThisLook',
    container: '#photo-gallery',
    singleImage: false,
    componentName: false
  };

  function createOverlay(currentContext, productId, overlay, oType, mobile, overlayClass, closer, eventName, currentSelector, wrapper) {
    appendOverlay(overlay);

    if (mobile) {
      $(wrapper).addClass('no-scroll');
    }

    setScrollPoints(oType, true);

    $(overlayClass)
      .find(closer)
      .on(eventName, () => {
        removeOverlay(oType, overlayClass, closer, wrapper);
      });

    currentContext.broadcast('stl.updateClickTracking', {
      sel: currentSelector
    });
  }

  function removeOverlay(oType, sel, eventName, wrapper) {
    $(sel)
      .off(eventName);

    deleteOverlay(sel);

    if (isMobile) {
      if (oType === 'mobile-overlay') {
        $(wrapper).removeClass('no-scroll');
      } else {
        $(wrapper).removeClass('no-scroll').css('position', 'relative');
      }
    }

    setScrollPoints(oType, false);
  }

  function appendOverlay(overlay) {
    $(document.body).append(overlay);
  }

  function deleteOverlay(selector) {
    $(document.body).find(selector).remove();
  }

  function getGalleryType() {
    let libraryViewerClass = 'photoLibraryViewerPage';
    let inlineClass = 'inline-horizontal';
    let verticalClass = 'vertical';
    let siteContainerSelector = '.container-site';

    if (($('body').hasClass(libraryViewerClass) || $(siteContainerSelector).hasClass(inlineClass)) && isTablet) {
      return 'tablet-viewer';
    } else if (($('body').hasClass(libraryViewerClass) || $(siteContainerSelector).hasClass(inlineClass) || $(siteContainerSelector).hasClass(verticalClass)) && isMobile) {
      return 'mobile-scroll';
    } else if ($('body').hasClass(libraryViewerClass) || $(siteContainerSelector).hasClass(inlineClass) || $(siteContainerSelector).hasClass(verticalClass)) {
      return 'desktop-gallery';
    } else if (!$(siteContainerSelector).hasClass(verticalClass) && isMobile) {
      return 'mobile-overlay';
    } else {
      return false;
    }
  }

  function setTabletScroll() {
    $('body').addClass('pv-no-scroll');
    $('.shop-overlay').addClass('pv-shop-overlay');
    $('.products').addClass('pv-products');
  }

  function removeTabletScroll() {
    $('body').removeClass('pv-no-scroll');
    $('.shop-overlay').removeClass('pv-shop-overlay');
    $('.products').removeClass('pv-products');
  }

  function setMobileScroll() {
    photoViewerScrollOffset = $(window).scrollTop();
    $(window).scrollTop(0);
    $('body').addClass('no-scroll');
  }

  function removeMobileScroll() {
    $('body').removeClass('no-scroll');
    $(window).scrollTop(photoViewerScrollOffset);
    photoViewerScrollOffset = 0;
  }

  function setDesktopScroll() {
    photoViewerScrollOffset = $(window).scrollTop();
    $(window).scrollTop(0);
    $('body').addClass('pv-no-scroll');
    $('body').css('overflow', 'hidden');
    $('.shop-overlay').addClass('pv-shop-overlay');
    $('.products').addClass('pv-products');
  }

  function removeDesktopScroll() {
    $('body').removeClass('pv-no-scroll');
    $('body').css('overflow', 'auto');
    $('.shop-overlay').removeClass('pv-shop-overlay');
    $('.products').removeClass('pv-products');
    $(window).scrollTop(photoViewerScrollOffset);
    photoViewerScrollOffset = 0;
  }

  function setScrollPoints(type, isAdd) {
    switch (type) {
      case 'tablet-viewer':
        if (isAdd) {
          setTabletScroll();
        } else {
          removeTabletScroll();
        }
        break;
      case 'mobile-scroll':
        if (isAdd) {
          setMobileScroll();
        } else {
          removeMobileScroll();
        }
        break;
      case 'desktop-gallery':
        if (isAdd) {
          setDesktopScroll();
        } else {
          removeDesktopScroll();
        }
        break;
    }
  }

  function hasProductsList($card) {
    return $card && $card.find('[data-target-curatedsrc]').length;
  }

  function shop() {
    shopContainer = $(context.getElement()).find(config.shoppableElement);
    if (SNI.Config.isEnabled('shopThisLook')) {
      let productIds = [];
      let $deferred;
      let addSet;
      shopContainer.each(function() {
        let currentSlide = $(this);
        let currentSlideContainer = $(this).find(config.shopInsertionSelector);
        let currentProductId = currentSlide.data('productId');
        let productEnabled = currentSlide.data('productEnabled');
        let hasWayfairProducts = currentSlide.data('hasWayfairProducts');
        let hasCuratedProducts = hasProductsList(currentSlide);
        let showSTL = productEnabled && hasWayfairProducts;
        
        if (!config.dynamicSTL) {
          addShopButton(currentSlide, isMobile, currentSlideContainer, currentProductId);
        } else {
          if (showSTL) {
            // do not add STL when card has curated products
            if (isMobile && hasCuratedProducts) {
              debug.log('Hiding STL on mobile due to existence of curated PL @', currentProductId);
              currentSlide.find('[data-target-productlist]').hide();
            }
            productIds.push(currentProductId);
          }
        }
      });

      // Has products & isMobile or has products and is not requesting sets
      if (productIds.length > 0 && (isMobile || !config.disableSets)) {
        addSet = productManager.addSet(productIds); // Add products in bulk whenever possible
        let checkMax = config.maxSTLCheck;
        let max = (dynamicGalleryType === checkMax || checkMax === 'both') ? config.maxSTLPreLoad : null; // limit the number of pre-loaded product slides
        if (isMobile || config.singleImage) {
          $deferred = addSet.$deferred;
          $deferred.then(data => setUpAllProds(data, max))
            .fail(result => {
              debug.log('Unable to retrieve product from Vendor API');
            }); //?
        }
      }
    }
  }

  function addShopButton($shopElement, mobile, container, productId) {
    if ($shopElement.data('productEnabled')) {
      if (mobile) {
        $(container).prepend(template.shopButton(productId));
      } else {
        $(container).append(template.shopButton(productId));
      }
    }
  }

  function formatCurrency(price) {
    let fixed = Number(price).toFixed(2);
    return Number.isNaN(fixed) ? price : `$${fixed}`;
  }

  function formatPrices(item) {
    let unit_price = item['unit_list_price'];
    let sale_price = item['sale_price'];
    if (unit_price) {
      item['formatted_price'] = formatCurrency(unit_price); // If formatting fails return the original value
    }
    if (sale_price) {
      item['formatted_sale_price'] = formatCurrency(sale_price);
    }
    return item;
  }

  function renderProductList(data) {
    let prodList = data.map(formatPrices).map(template.shopItemDesktop);
    return prodList;
  }

  function setUpAllProds(result = {}, max) {
    let Ids = Object.keys(result);
    if (Ids.length > 0) {
      Ids.forEach(itemId => {
        let itemData = result[itemId] && result[itemId]['data'];
        if (itemData && itemData.length && itemData.length > 0) {
          let inPreloadSet = generateProductMarkup(itemData, itemId, max);

          if (!isMobile || (isMobile && !productManager.getProductStatus(itemId).shown && inPreloadSet)) {
            trackSTLImpression(itemData);
            //  Save the render status and the impression status.  These values will be referenced on Mobile to determine which steps to skip
            productManager.updateProductStatus(itemId, {
              rendered: true,
              shown: true
            });
          }
        }
      });
    }
  }

  function setUpProdData(resultSet, isItem) {
    let manager = productManager;
    let item = manager.getProduct(manager.activeProduct);
    let data = item && item.data;

    if (data) {
      if (data && data.length && data.length > 0) {
        debug.log('Setting up product markup for:', manager.activeProduct);
        generateProductMarkup(data);
        return true; // return otherwise hide the list
      }
    }
    hideProductList();
  }

  function hideProductList() {
    let hideClasses = 'has-Products is-Loaded is-Sponsored';
    if (isMobile) { // needs to check hideonmobile?
      return false; // Product list is already hidden on mobile
    }
    let $productListElement = $(config.productListContainer);
    $productListElement.removeClass(hideClasses);
  }

  function trackSTLImpression(data) {
    // Concat product names separated by ,; into one string
    function concatProdTitles(str, {name}) {
      let prodWithVendor = `${config.vendor}|${name}`;
      return str ? str += `,;${prodWithVendor}` : prodWithVendor;
    }

    // Format data for tracking
    let metadata = {
      partner: config.vendor,
      title: data.reduce(concatProdTitles, null),
      imagecount: mdManager.getParameter('imageCount') || 'n/a',
      productcount: data.length,
      componentname: config.componentName ? config.componentName : (mdManager.getParameter('componentName') || 'n/a')
    };

    // Track data
    shopping.trackProductImpression(metadata);
  }

  /**
   * Generate the STL product list.  Gets called repeatedly on desktop but should only be called once for each mobile container
   * @param {object} data| the product data to render
   * @param {string} activeId | ID of the image in view, specially needed to target the correct mobile container
   * @param {number} max
   */
  function generateProductMarkup(data, activeId, max) {
    let html = renderProductList(data);
    let $productListElement;
    let $productListTarget;
    let id = activeId || productManager.activeProduct;
    let productStatus = productManager.getProductStatus(id);
    let $mobileContainer;
    let index;

    if (isMobile && id) {
      if (productStatus.rendered) return false; // don't need to render the markup again
      /*  On mobile we need to target the specific container  */
      $mobileContainer = $(`[data-product-id="${id}"]`);
      index = $mobileContainer.data('card-index');
      if (max && index > max) {
        return false; // skip any further loads
      }
      $productListElement = $mobileContainer.find(config.productListContainer);
      $productListTarget = $mobileContainer.find(config.productListTarget);
    } else {
      $productListElement = $(config.productListContainer);
      $productListTarget = $(config.productListTarget);
      $productListTarget.parent().scrollTop(0);
    }

    $productListTarget.html(html);
    $productListElement.addClass('has-Products is-Loaded is-Sponsored');

    if (isMobile) {
      productManager.updateProductStatus(id, {
        rendered: true
      });
    }

    context.broadcast('productList.generated');
    return true;
  }

  /**
   * Called on slide change by inline gallery and vertical gallery.  The slide data provides the product ID that we can reference to get STL data
   * @param {object} content
   */
  function updateProductList({newElement, cProds}) {
    let $shopElement = $(newElement);
    let productId = $shopElement.data('productId');
    let productEnabled = $shopElement.data('productEnabled');
    let hasWayfairProducts = $shopElement.data('hasWayfairProducts');
    let hasCuratedProducts = cProds && cProds.length;
    let showSTL = productEnabled && hasWayfairProducts && !hasCuratedProducts;
    let manager = productManager;
    let product;
    let isItem;
    let productStatus = manager.getProductStatus(productId);
    
    manager.activeProduct = productId;

    if (productId && showSTL) {
      product = productManager.getAsyncProduct(productId); // return a product or deferred
      isItem = product['singleItem'];
      //   Render/request list
      if (product['received'] && product['data']) {
        debug.log('active product in memory ', product);
        product['$deferred'] = null; // minor cleanup
        let prodData = product['data'];
        if (prodData && prodData.length && prodData.length > 0) {
          //  Call generate product markup.  If the markup has been rendered on mobile then the function will exit early
          generateProductMarkup(product.data, null);
          //  On desktop call impressions after rendering the product list; also call impressions on mobile if the shown status == false
          if (!isMobile || (isMobile && !productStatus.shown)) {
            trackSTLImpression(product.data);
            //  Save the render status and the impression status.  These values will be referenced on Mobile to determine which steps to skip
            manager.updateProductStatus(productId, {
              rendered: true,
              shown: true
            });
          }
        } else {
          hideProductList();
        }
      } else if (product['$deferred']) { //  If addSet was called then a deferred will be returned
        $.when(product.$deferred).then(productManager.responseHandler).then(data => {
          debug.log('Set up product data (inner)', data);
          setUpProdData(data, isItem); // passing isItem in case we need to test for single item in the future
        }).fail(result => {
          debug.log('Unable to retrieve product from Vendor API', manager.activeProduct);
        });
      }
    } else {
      // HIDE Product List
      hideProductList();
    }
  }

  function updateSTLMetadata({newElement, type, cProds}) {
    let $shopElement = $(newElement);
    let $elementContainer;
    let partnerTag = config.partnerText;
    let mdmData;
    let metadata = {};
    let taggroup;
    let productEnabled = $shopElement.data('productEnabled');
    let hasWayfairProducts = $shopElement.data('hasWayfairProducts');
    let hasCuratedProducts = cProds && cProds.length;
    let showSTL = productEnabled && hasWayfairProducts && !hasCuratedProducts;

    if (type && type === 'dynamic') {
      $elementContainer = $(newElement);
    } else {
      $elementContainer = $(newElement).find(config.shopInsertionSelector);
    }

    if (!$elementContainer.length) {
      debug.error('updateSTLMetadata: $elementContainer is empty');
    }

    if (showSTL) {
      mdmData = $elementContainer.data('mdm');
      if (overlayType === 'mobile-scroll' && (type && type === 'dynamic')) {
        mdmData = mdmData || '';
      } else {
        mdmData = mdmData || $elementContainer.parents(config.shoppableElement).data('mdm');
      }
      try {
        metadata = JSON.parse(mdmData);
      } catch (e) {
        debug.log('Could not parse Photo metadata');
      }
      debug.log('updateSTLMetadata: metadata: ', metadata);
      taggroup = metadata.TagGroup1;
      if (typeof taggroup !== 'undefined' && !taggroup.includes(partnerTag)) {
        taggroup = `${partnerTag},${taggroup}`;
        metadata.TagGroup1 = taggroup;
        if (overlayType === 'mobile-scroll' && (type && type === 'dynamic')) {
          $elementContainer.data('mdm', JSON.stringify(metadata));
        } else {
          $elementContainer.parents(config.shoppableElement).data('mdm', JSON.stringify(metadata));
        }
      }
    }
  }

  function setupClickTracking(analyticsService, currentSelector, currentNamespace) {
    analyticsService.attachClickTracking({
      selector: currentSelector,
      namespace: currentNamespace
    });
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {

    messages: ['shopElementChanged', 'contentUpdated', 'stl.updateClickTracking'],

    init() {
      deviceType = context.getService('device-type');
      template = context.getService('template');
      analytics = context.getService('analytics');
      debug = context.getService('logger').create('behavior.shop-this-look');
      config = Object.assign({}, defaults, context.getConfig('shop-this-look'));
      dynamicGalleryType = context.getConfig('galleryType');
      productManager = context.getService('product-manager');
      shopping = context.getService('track-shopping');
      mdManager = context.getGlobal('mdManager');
      debug.log('config: ', config);
      isMobile = deviceType.isMobile;
      isTablet = deviceType.isTablet;
      productManager.updateConfig({
        endpoint: config.vendorAPI,
        vendor: config.vendorCode,
        productsInSet: config.productsInSet
      });
      shop();
      overlayType = getGalleryType();
      analyticsContainer = config.dynamicSTL ? config.productListTarget : config.shopInsertionSelector;
      setupClickTracking(analytics, analyticsContainer, config.shopNamespace);
    },

    destroy() {
      removeOverlay(overlayType, config.shopOverlayClass, config.overlayCloseEventName, config.container);
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'open-shop-overlay-btn':
          let prodID = $(element).data('productId');
          shopOverlay = template.shopOverlay(isMobile, prodID);
          createOverlay(context, prodID, shopOverlay, overlayType, isMobile, config.shopOverlayClass, config.closeOverlaySel, config.overlayCloseEventName, config.productHeaderSelector, config.container);
          break;
        case 'close-shop-overlay-btn':
          removeOverlay(overlayType, config.shopOverlayClass, config.overlayCloseEventName, config.container);
          break;
        case 'stl-product-link':
          const productTitle = $(element).closest('div[data-product-title]').data('product-title');

          const metadata = {
            partner: config.vendor,
            title: `${config.vendor}|${productTitle}`,
            componentname: config.componentName ? config.componentName : (mdManager.getParameter('componentName') || 'n/a'),
            imagecount: mdManager.getParameter('imageCount') || 'n/a',
            productcount: 'n/a'
          };

          shopping.trackProductClick(metadata);
          break;
        default:
          break;
      }
    },

    onmessage(name, data) {
      switch (name) {
        case 'shopElementChanged':
          if (config.dynamicSTL) {
            updateProductList(data);
          }
          updateSTLMetadata(data);
          break;
        case 'contentUpdated':
          let prodId = $(data.newContent).data('productId');
          if (config.dynamicSTL) {
            productManager.addSet(prodId); // only adding one but should add a set
          } else {
            addShopButton($(data.newContent), isMobile, $(data.newContent).find(config.shopInsertionSelector), $(data.newContent).data('productId'));
          }
          break;
        case 'stl.updateClickTracking':
          let sel = config.dynamicSTL ? analyticsContainer : data.sel;
          setupClickTracking(analytics, sel, config.shopNamespace);
          break;
      }
    }

  };
});
