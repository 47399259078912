SNI.Application.addBehavior('braze', context => {

  const mdManager = context.getGlobal('mdManager');
  const mdManagerParam = (param) => {
    return mdManager.getParameterString(param);
  };
  const userInterface = context.getService('user/user-data');
  let util  = context.getService('utility');
  let debugMode = util.isDebug();
  let debug = context.getService('logger').create('behavior.braze');
  const brazeDestroy = () => {
    if (window.braze) {
      //teardown Braze SDK
      braze.destroy();
      //remove braze altogether to prevent reuse
      window.braze = undefined;
    }
  };

  const isBrazeLoaded = () => {
    return typeof braze !== 'undefined';
  };

  const getContentTags = (tag1, tag2) => {
    const tags = [tag1, tag2].filter(tag => tag !== '');
    return tags.join(', ');
  };

  const brazePageView = () => {
    const eventParams = {
      title: mdManagerParam('Title'),
      categoryName: mdManagerParam('CategoryDspName'),
      pageType: mdManagerParam('Type'),
      url: mdManagerParam('Url'),
      contentTags: getContentTags(mdManagerParam('ContentTag1'), mdManagerParam('ContentTag2'))
    };
    debug.log('BRAZE: PAGE-VIEW', eventParams);

    if (isBrazeLoaded()) {
      braze.logCustomEvent('PAGE-VIEW', eventParams);
      debug.log('BRAZE: EVENT REGISTERED');
    }
    
  };

  const messageHandlers = {
    'dalton:logged-in': async (data) => {
      // Only initialize if the user is logged in and ID is present
      if (SNI.Config.braze?.enabled && userInterface.getUserId()) {

        // Do not reinitialize if the user is the same
        if (window.braze && window.braze.getUser()?.getUserId() === userInterface.getUserId()) {
          return;
        }

        const braze = await import('@braze/web-sdk' /* Braze library */);
        if (window.braze) {
          debug.error('Braze is already globally set');
        } else {
          window.braze = braze;
        }
        braze.initialize(SNI.Config.braze.key, {
          baseUrl: SNI.Config.braze.url,
          //enable logging when we are in debug mode
          enableLogging: debugMode,
          allowUserSuppliedJavascript: true
        });

        // show all in-app messages without custom handling
        braze.automaticallyShowInAppMessages();

        // set the current user's external ID before starting a new session
        // you can also call `changeUser` later in the session after the user logs in
        braze.changeUser(userInterface.getUserId());

        braze.getUser().setEmail(data.userEmail);

        // `openSession` should be called last - after `automaticallyShowInAppMessages`
        braze.openSession();

        brazePageView();

      } else if (window.braze) {
        // If the user is logged out or braze is disabled, remove any lingering sessions
        brazeDestroy();
      }
    },
    'dalton:logged-out': () => {
      brazeDestroy();
    },
    'braze:newsletter': (params) => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('NEWSLETTER', params);
        debug.log('BRAZE: NEWSLETTER');
      }
    },
    'braze:scroll-depth': (eventParams) => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('SCROLL-DEPTH', eventParams);
        debug.log('BRAZE: SCROLL-DEPTH: ', eventParams?.scrollDepth);
      }
    },
    'braze:affiliate-brand-button': (data) => {
      const brazeData = data.split('|');
      const eventParams = {
        brand: brazeData[0],
        name: brazeData[1],
        date: new Date()
      };

      debug.log(eventParams);

      if (isBrazeLoaded()) {
        braze.logCustomEvent('AFFILIATE-BRAND-BUTTON', eventParams);
        debug.log('BRAZE: AFFILIATE-BRAND-BUTTON');
      }
    },
    'braze:sweepstakes-email-entry': () => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('SWEEPSTAKES-EMAIL-ENTRY', {
          sweepstakeId: mdManagerParam('SweepsID'),
          brand: mdManagerParam('Site'),
          sweepstakeName: mdManagerParam('Title'),
          date: new Date()
        });
        debug.log('BRAZE: SWEEPSTAKES-EMAIL-ENTRY');
      }
    },
    'braze:sweepstakes-form-sent': () => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('SWEEPSTAKES-FORM-SENT', {
          sweepstakeId: mdManagerParam('SweepsID'),
          brand: mdManagerParam('Site'),
          sweepstakeName: mdManagerParam('Title'),
          date: new Date()
        });
        debug.log('BRAZE: SWEEPSTAKES-FORM-SENT');
      }
    },
    'braze:video-start': ({title, contentTags}) => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('VIDEO-START', {
          categoryName: mdManagerParam('CategoryDspName'),
          title,
          contentTags
        });
        debug.log('BRAZE: VIDEO-START');
      }
    },
    'braze:video-end': ({title, contentTags}) => {
      if (isBrazeLoaded()) {
        braze.logCustomEvent('VIDEO-END', {
          categoryName: mdManagerParam('CategoryDspName'),
          title,
          contentTags
        });
        debug.log('BRAZE: VIDEO-END');
      }
    }
  }; //end messageHandlers

  return {
    messages: Object.keys(messageHandlers),
    onmessage(msg, data) {
      messageHandlers[msg](data);
    },
  };
});
