SNI.Application.addModule('product-launch', (context) => {
  const lazy = context.getService('lazy-load'),
        modal = context.getService('modal');
  let element,
      $element,
      $modal,
      $carousel,
      $currentScrollPos; // Find the hidden carousel markup

  let defaults = {
    modalId: 'default-Modal',
    carousel: '.products-wrapper',
    closeButtonParent: '.o-ProductsOverlay',
    isCentered: false,
    hasTransition: false,
    overrides: {
      backdropAlternate: '',
      noScroll: false,
      deferStop: true,
      clearContents: true
    }
  };

  let settings = context.getConfig();
  let config = $.extend(true, {}, defaults, settings);

  function setLazyLoad() {
    let $images = $carousel.find('img');
    lazy.addImages({
      settings: {
        usePlaceholder: false
      },
      container: $carousel
    });
    lazy.loadImages($images);
  }

  function generateModal() {
    let data = $carousel && $carousel.get(0).outerHTML; //  include the [data-module="carousel"] element
    if (data) {
      $modal = modal.create({
        modalId: config.modalId,
        content: data,
        closeButtonParent: config.closeButtonParent,
        isCentered: config.isCentered,
        hasTransition: config.hasTransition,
        clearContents: false,
        overrides: {
          backdropAlternate: config.overrides.backdropAlternate,
          noScroll: config.overrides.noScroll,
          deferStop: config.overrides.deferStop,
          clearContents: config.overrides.clearContents
        }
      });
      $modal.show();
    }
  }

  //  Since the carousel is un-initalized and not visible we need to force lazy load images to trigger
  let onclick = (e, container, dataType) => {
    if (dataType === config.modalId) {
      $currentScrollPos = $(document).scrollTop();
      e.preventDefault();
      //  Lazy Load utility should be run before loading to modal
      setLazyLoad();

      // Create the modal
      generateModal();

      if ($currentScrollPos !== null) {
        window.scrollTo(0, $currentScrollPos);
      }
    }
  };

  return {
    behaviors: ['deferred-loader', 'affiliate'],
    onclick,
    init() {
      element = context.getElement();
      $element = $(element);
      $carousel = $element.find(config.carousel); // Find the hidden carousel markup
    }
  };
});
