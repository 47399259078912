/**
* Profile page module -- Dalton identity provider
*/

SNI.Application.addModule('profile', (context) => {

  let dalton = context.getService('dalton/dalton-interface'),
      template = context.getService('template'),
      debug = context.getService('logger').create('module.profile'),
      containerId = 'profile',
      containerElt = document.getElementById(containerId);

  function updateUI(isLoggedIn) {
    debug.log('updateUI', isLoggedIn);
    if (isLoggedIn) {
      // show Dalton profile maintenance form
      if (containerElt.querySelector('#IDSP-profile'))
        dalton.closeForm();
      containerElt.innerHTML = '';
      dalton.profile({ container: '#' + containerId });
    } else {    // logged out cases:
      if (containerElt.querySelector('#IDSP-profile')) {
        // remove Dalton SDK profile form if it looks like there is one
        dalton.closeForm();
        // and that pesky loader markup
        containerElt.innerHTML = '';
      }
      if (!containerElt.querySelector('#IDSP-reset') && !containerElt.querySelector('#IDSP-login'))
        // and show a big "Log In" button only if there is not an inline password reset or login form from the SDK
        containerElt.innerHTML = template.idspLoginMarkup();
    }
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      updateUI(true);
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      updateUI(false);
    },
  };

  function init() {
    debug.log('init');
  }

  return {

    init,

    behaviors: ['dalton/login-logout'],

    messages: Object.keys(messageHandlers),
    onmessage(message, data) {
      messageHandlers[message](data);
    },

  };

});
