SNI.Application.addService('product-manager', application => {

  const debug = application.getService('logger').create('service.product-manager');
  const products = {
    // '000-000-000000-0000': {
    //   received: true,
    //   data: {products:[0]},
    // }
  };
  const productStatus = {
    //  '000-000-000000-0000' : { rendered: true, shown: false};
  };

  //  Find a known vendor sizing string
  const vendorImgSizing = {
    'vendor1': {
      src: '/compr-r85/',
      target: '/resize-h150-p1-w150%5Ecompr-r85/'
    }
  };

  let managerConfig = {
    endpoint: '',   // Should point to the vendor API
    vendor: '', // Vendor configuration.  Will likely be Dynamically set in the future
    productsInSet: 6
  };

  function addProduct(id, $deferred){
    if (products[id]) {
      return products[id];  // do not add if it is already in the data
    }
    products[id] = {
      received: false,
      singleItem: true,
      data: null,
      $deferred
    };
    productStatus[id] = {
      rendered: false,
      shown: false
    };
  }
  
  function updateProductStatus(id, update) {
    productStatus[id] = $.extend(productStatus[id], update);
  }

  /* On mobile we want to avoid showing the products again for the same ID */
  function getProductStatus(id) {
    return productStatus[id];
  }

  function imgURLSizing(url) {
    let options = vendorImgSizing[managerConfig.vendor];  // Look up replacement strings
    let {src, target} = options;
    if (options && options.src && options.target) {
      return url.replace(src, target);
    } else {
      return url;
    }
  }

  /**
   * 
   * @param {object} item:  item.both_dimensions refers to a standard field returned by the API that contains a fully renditioned image
   *              item.image_url is a fallback that contains an original size image url (un-resized)
   *              imgURLSizing is run on the original image and it returns a modified URL with rendition parts added
   *              finally if all else fails we return the original image 
   */
  function updateImageSizes(item) {
    let url = item.both_dimensions;
    let urlFallback,
        updated_url;
    if (url) {
      item['image_url'] = url;
    } else {
      urlFallback = item.image_url;
      if (urlFallback) {
        updated_url = imgURLSizing(url);
        item['image_url'] = updated_url;
      }
    }
    return item;
  }

  /**
   * Updates the exisiting set of photo IDs with the final product data
   * It runs the array through a cleanup/map function as needed
   * @param {options} id = photo ID, data = product results array 
   */
  function updateProduct(options) {
    let {id, data} = options;
    let cleanData = data.map(updateImageSizes);
    if (cleanData.length===0) {
      debug.log('Cleared out products!', id);   /// REMOVE
    }
    let update = { data: cleanData, received: true};
    return $.extend(products[id], update);
  }

  /**
   * Handle the AJAX responses and update the product data with the results
   * @param {object} data 
   */
  function responseHandler(data) {
    let result,
        output = data['vendor-output-from-request'],
        err = data['error'];
    if (err) {
      debug.log('Product API Error:', err);
    }
    if (data && output) {
      result = output.result;
      if (result && result.length) {
        debug.log('Got product results!');
        result.forEach(item => {
          updateProduct({
            id: item.id, 
            data: item.products
          });
        });
      } else {
        debug.log('No product results.');
      }
    }
    return products;  // return a reference to products
  }

  function requestProducts(prodids) {
    let prods = prodids.join(',');
    return $.get(`${managerConfig.endpoint}${managerConfig.vendor}/${prods}.html.json`);   // REX-445 API URL Sample: .../vendor-api.json/wayfair/prod-id-1,prod-id-2.html.json (uses .html.json selector for AEM dispatcher)
  }

  /**
   * Accepts an array that will be used to create an AJAX call.  Then the product record will be updated with a reference so the response can be hanlded later
   * @param {array} prods 
   */
  function createDeferreds(prods) {
    let $setDeferred = requestProducts(prods),
        $deferred;
    prods.forEach((item => {
      addProduct(item, $setDeferred);
    }));
    $deferred = $setDeferred.then(responseHandler).fail(result => {
      debug.log('Unable to retrieve products from Vendor API');
    });
    return {$deferred};
  }
  /**
   * Break up an array into multiple arrays with 6 items or less then return an array of arrays with the smaller sets
   * @param {array} sets 
   */
  function breakUpSet(sets) {
    let splitArray = [],
        setMax = managerConfig.productsInSet > 0 ? managerConfig.productsInSet : 1;
    while (sets.length>setMax) {
      splitArray.push(sets.splice(0,setMax)); //  remove the first ten
    }
    if (sets.length>0) {
      splitArray.push(sets);  // Append the rest
    }
    return splitArray.slice();  // return new array
  }

  /**
   * Accepts a photo ID or photo IDs and calls the API for each
   * @param {string|array} productSet 
   */
  function addSet(productSet) {
    let prods = Array.isArray(productSet) ? productSet : [productSet],
        prodSets = breakUpSet(prods),
        $deferredArray; // Create an array of arrays so that we can make a call for each set
    $deferredArray = prodSets.map(createDeferreds);
    return $deferredArray[0]; // return the first set
  }

  function getAsyncProduct(id) {
    if (products[id]) {
      return products[id];
    } else {
      return addSet(id);
    }
  }
  function getProduct(id) {
    return products[id];
  }
  function updateConfig(config){
    $.extend(managerConfig, config);
  }

  return {
    addProduct,
    updateProductStatus,
    getProductStatus,
    addSet,
    getAsyncProduct,
    activeProduct:'',
    getProduct,
    updateConfig,
    responseHandler
  };
});
