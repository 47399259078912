SNI.Application.addModule('select-button', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  let init = function() {

    let $element = $(context.getElement());

    $element.on('change', function() {
      if ($element.val() !== '') {
        document.location = $element.val();
      }
    });

  };

  return {

    init 
    
  };

});
