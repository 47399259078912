/**
 *  There are a variation of brand names used through out the various services
 *  The basic brand == mdManager.getSite()
 *  There are variations of the basic brand for socialUpload, analytics and moderation
 */
SNI.Application.addService('getBrand', (application) => {

  let mdManager = application.getGlobal('mdManager'),
      debug = application.getService('logger').create('service.getBrand'),
      check = application.getService('check').new(debug),
      cookie = application.getService('cookie');

  const BRANDS = {
    tracking : {  //  does not map to the brands on social Uploader
      'food': 'fn',
      'hgtv': 'hgtv',
      'travel': 'tc',
      'diy': 'diy',
      'cook': 'cctv'
    },
    short : {
      'food': 'fn',
      'hgtv': 'hgtv',
      'travel': 'tc',
      'diy': 'hgtv',  // configured in cloudinary to fall under HGTV
      'cook': 'cctv',
      'cctv': 'cctv'
    },
    long : {
      'food': 'foodnetwork',
      'hgtv': 'hgtv',
      'travel': 'travel',
      'diy': 'hgtv',  // configured in cloudinary to fall under HGTV
      'cctv': 'cctv',
      'cook': 'cctv'
    }
  };

  let isSyndicated = () => {
    let brand = (check.supports('localStorage') && localStorage.getItem('syndicated_brand')) || '';
    if (!brand) {
      brand = cookie.get('syndicated_brand') || '';
    }
    return brand;
  };

  let basicBrand = (function() {
    let code = (mdManager ? mdManager.getSite() : isSyndicated()).toLowerCase();
    code = code.replace(/golden-/,'');
    return code;
  })();

  function basic() {
    return basicBrand;
  }

  function specific(type) {
    if (type in BRANDS) {
      let set = BRANDS[type];
      if (basicBrand in set) {
        return set[basicBrand];
      }
    }
    return basicBrand;
  }

  return {
    basic,
    specific
  };

});
