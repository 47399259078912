SNI.Application.addModule('search-filter', (context) => {

  const location = context.getGlobal('location'),
        mdManager = context.getGlobal('mdManager'),
        debug = context.getService('logger').create('module.search-filter'),
        check = context.getService('check').new(debug),
        localStorage = check.supports('localStorage') ? context.getGlobal('localStorage') : false;

  let module,
      defaults,
      config;

  defaults = {
    filterField: 'filter',
    removalString: 'remove:',
    refinementString: 'refinement:'
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setFilterConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function getMDMParameter(val) {
    return mdManager.getParameterString(val);
  }

  function updateString(updated, existing, insert = false) {
    let updatedString;
    if (insert) {
      updatedString = existing.replace(/(refinement\:)(.+)/g,'$1remove:$2');
    } else {
      updatedString = updated + existing;
    }
    return updatedString;
  }

  function setRemovalKey(val) {
    if (localStorage) {
      localStorage.setItem('removedFilter', val);
    }
  }

  function isFilterLink ({ tagName, className, dataset }) {
    return tagName === 'A' && className.includes('search-filter-link') && !!dataset.href;
  }

  function findLinkTag (element) {
    let linkTag = element;
    // Because we're listening "click" on dropdown item and click
    // could be inside the "A" (e.g. on span) we need to 
    // traverse tree until we found the "A" tag with specific attrs
    while (linkTag !== null) {
      if (isFilterLink(linkTag)) break;
      linkTag = linkTag.parentNode;
    }
    return linkTag;
  }

  module = {
    behaviors: ['hover-intent'],

    init: function() {
      setFilterConfig(defaults, context.getConfig());
    },

    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'remove-single-facet':
          event.preventDefault();
          setRemovalKey(updateString(config.refinementString+config.removalString, $(element).text()));
          location.href = $(element).attr('href');
          break;
        case 'clear-all-facets':
          event.preventDefault();
          setRemovalKey(updateString(config.removalString, getMDMParameter(config.filterField), true));
          location.href = $(element).attr('href');
          break;
        case 'dropdown-menu':
        case 'sub-navigation-item': {
          const linkTag = findLinkTag(event.target);

          if (!linkTag) return;

          event.preventDefault();
          event.stopPropagation();
          location.href = linkTag.dataset.href;
        }
      }
    },

    updateObject,
    setFilterConfig,
    getMDMParameter,
    updateString,
    setRemovalKey
  };

  return module;

});
