SNI.Application.addModule('recipe-head', (context) => {

  const debug = context.getService('logger').create('module.recipe-head');

  return {
    behaviors: ['truncate'],
    init() {
      debug.log('init');
    },
  };

});
