import '../services/logger';
import '../services/device-type';
import '../services/template';
import '../services/lazy-load';
import '../services/track-shopping';

SNI.Application.addModule('shopping', context => {

  const debug = context.getService('logger').create('module.shopping'),
        device = context.getService('device-type'),
        template = context.getService('template'),
        lazy = context.getService('lazy-load'),
        shopping = context.getService('track-shopping');

  let $element,
      $carousel,
      defaults,
      config;

  defaults = {
    type: 'inspirational',
    productListSelector: '.productList',
    productListOrganism: '.o-ProductList',
    carouselSelector: '.m-Carousel',
    activeClass: '.active',
    fullWidth: false,
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function hideAllProducts() {
    $element.find(config.productListSelector).removeClass('show').addClass('hide');
  }

  function displayProductList(index) {
    $element.find(`${config.productListSelector}[data-index='${index}']`).removeClass('hide').addClass('show');
  }

  function updateProduct(el) {
    let currentIndex;
    if ($(el).attr('data-index')) {
      currentIndex = $(el).attr('data-index');
      context.broadcast('carousel.update.slide', {
        index: currentIndex,
        carouselId: $element.find('[data-module="carousel"]').attr('id')
      });
    }
  }

  function getMetaData() {
    let metadata1 = $element.data('mdm') || {},
        metadata2 = {};

    if (!$carousel.length) {
      metadata2 = $(config.carouselSelector, context.getElement())
        .find('[data-mdm]')
        .data('mdm');
    } else {
      metadata2 = $carousel
        .find(config.activeClass)
        .find('[data-mdm]')
        .data('mdm');
    }

    return updateObject(metadata1, metadata2);
  }

  function trackProductClick(title) {
    const metadata = getMetaData();

    metadata.products = title;
    shopping.trackProductClick(metadata);
  }

  return {
    behaviors: ['lazy-load', 'truncate'],

    init: () => {
      debug.log('shopping init!');
      $element = $(context.getElement());
      $carousel = $element.find('[data-module="carousel"]');
      setConfig(defaults, context.getConfig());
      if (config.fullWidth || device.isMobile) {
        $(config.productListOrganism).prepend(template.productIndicator('o-ProductList__a-ProductIndicator'));
      }
    },

    onmessage: {
      'carousel.slideUpdated': data => {
        switch (config.type) {
          case 'inspirational':
            if (data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id')) {
              hideAllProducts();
              displayProductList(data.updatedSlide);
              shopping.trackProductImpression(getMetaData());
              lazy.forceScroll();
            }
            break;
          default:
            break;
        }
      }
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'update-product':
          event.preventDefault();
          if (config.type === 'multi') {
            event.preventDefault();
            updateProduct(element);
          }
          break;
        case 'product-link':
          trackProductClick($(element).closest('div[data-product-title]').data('product-title'));
          break;
        default:
          break;
      }
    }
  };

});
