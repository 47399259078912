SNI.Application.addModule('asset-multi-media', context => {

  const debug = context.getService('logger').create('module.asset-multi-media');
  const el = context.getElement();
  const $el = $(el);
  const defaultConfig = {
    behaviors: [],
    lastVideoNum: 0,
    isStarted: false
  };
  const store = context.getService('state').createStore('asset-multi-media', {
    data: Object.assign({}, defaultConfig, context.getConfig()),
    computed: {
      videoModule: () => $el.find('[data-module=video-embed]'),
      videoId: () => store.videoModule.attr('id')
    },
    methods: {
      getVideoNumber(slide) {
        return parseInt($(slide).attr('data-vid-num'));
      },
      pauseVideo(id) {
        context.broadcast('video.pause', { id: store.videoId });
      },
      playVideo(id, videoNum)  {
        SNI.Video.Players[id].play(videoNum, true);
      }
    }
  });
  
  const mdManager = context.getGlobal('mdManager');

  const onmessage = {
    'lightbox.expand': () => {
      store.playVideo(store.videoId, store.lastVideoNum);
    },

    'lightbox.collapse': () => {
      store.pauseVideo(store.videoId);
    },

    'carousel.slide.clicked': ({slide}) => {
      const videoNum = store.getVideoNumber(slide);
      store.lastVideoNum = videoNum;

      // Ensure lightbox is expanded
      // This also causes the video to play
      context.broadcast('lightbox.expand');
    }
  };

  debug.log('siteSectionId: ', mdManager && mdManager.getParameterString('Site') + '-' + mdManager.getParameterString('CategoryDspName'));
  debug.log('SNI.Config.Video.fallbackSiteSectionId: ', SNI.Config.Video && SNI.Config.Video.fallbackSiteSectionId);

  return {
    behaviors: store.behaviors.concat('toggle-on-message'),
    onmessage
  };

});
