/**
 *
 */
SNI.Application.addModule('recipe-method', (context) => {


  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const debug = context.getService('logger').create('module.recipe-method');
  const VIDEO_SCROLL_TOP_ELEMENT = '[data-video-scroll-anchor]';
  const ASSET_MULTI_COLLAPSE = '.o-AssetMultiMedia.is-Collapsed';

  let onclick = function(e, container, type) {
    debug.log('WatchHowTo click');
    if (type === 'watchHowTo') {

      debug.log('WatchHowTo scroll');

      const assetVideoId = $('[data-module=video-embed]', '.assetMultimedia').attr('id');
      debug.log(`AssetMultimedia Video ID: ${assetVideoId}`);

      if (assetVideoId) {
        debug.log('WatchHowTo lightbox expand', {id: assetVideoId});
        $('html, body').animate({scrollTop: $(VIDEO_SCROLL_TOP_ELEMENT).offset().top}, 'slow');
        if ($(ASSET_MULTI_COLLAPSE).length > 0) {
          context.broadcast('lightbox.expand', {id: assetVideoId});
        } else {
          context.broadcast('lightbox.watchHow', {id: assetVideoId});
        }
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };


  return {
    onclick
  };


});
