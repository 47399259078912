import './template';

SNI.Application.addService('hotspot', function(application) {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let service,
      cache,
      debug = application.getService('logger').create('service.hotspot'),
      check = application.getService('check').new(debug),
      template = application.getService('template'),
      defaults;

  defaults = {
    timeout: 60000,
    hotspotSelector: '.hotspot',
    rendererTypes: {
      product: 'product'
    }
  };

  cache = {};

  function outOfRange(n) {
    return (n <= 0) || (n >= 1);
  }

  function adjustHotspot(rendition, hotspot) {
    hotspot.x = (hotspot.x - rendition.x) / rendition.w;
    hotspot.y = (hotspot.y - rendition.y) / rendition.h;
    return hotspot;
  }

  function toPercentage(n) {
    return Math.round(n * 100);
  }

  function adjustUrl(url) {
    let base = application.getGlobal('cqBasePage'),
        baseAdjusted,
        currentUrl;

    currentUrl = url || '';

    if (currentUrl.indexOf(defaults.hotspotSelector) !== -1) {
      return currentUrl;
    }

    if (base) {
      base = base.replace(/^https?:\/\/[^\/]*/, '');
      baseAdjusted = base;

      if (baseAdjusted.indexOf('.html') === -1) {
        baseAdjusted += defaults.hotspotSelector;
      } else {
        baseAdjusted = baseAdjusted.replace('.html', '.hotspot.html');
      }

      currentUrl = currentUrl.replace(base, baseAdjusted);
    } else {
      currentUrl = currentUrl.replace('.html', '.hotspot.html');
    }

    return currentUrl;
  }

  function getHref(hotspot) {
    return hotspot.url || '#';
  }

  function getTitle(hotspot) {
    return hotspot.title || '';
  }

  function getProductDom(hotspot) {
    let self = this,
        currentTemplate = $(template.hotspotProduct()),
        sponsorContent,
        productImage;

    productImage = currentTemplate.find('.hotspotProductImage');

    if (hotspot.image) {
      productImage.find('img').attr({
        src: hotspot.image,
        alt: hotspot.title,
        title: hotspot.title
      });
    } else {
      productImage.remove();
    }

    currentTemplate.find('h2').html(hotspot.title);

    currentTemplate.find('.hotspotProductDescription').html(hotspot.caption);

    if (hotspot.sponsorLogo) {

      if (hotspot.sponsorUrl) {
        sponsorContent = $('<a>').attr({href:hotspot.sponsorUrl, target:'_blank'});
      } else {
        sponsorContent = $('<div>');
      }

      $('<img/>').attr({src:hotspot.sponsorLogo, alt:''}).appendTo(sponsorContent);

      currentTemplate.find('.hotspotProductSponsor').append(sponsorContent);
    }

    currentTemplate.off('click.moreProduct');
    currentTemplate.on('click.moreProduct', '.hotspotProductMore button, .hotspotProductImage img, .hotspotProductTitle, .hotspotProductDescription', function(e) {
      e.preventDefault();
      self.moreInfo(hotspot);
    });

    if (self.settings.hotspotShowAll) {
      currentTemplate.off('click.productAll');
      currentTemplate.on('click.productAll', '.hotspotProductAll a', function(e) {
        e.preventDefault();
        self.displayAll(self.hotspots);
      });
    } else {
      currentTemplate.find('.hotspotProductAll').remove();
    }

    currentTemplate.off('click.productOverlayClose');
    currentTemplate.on('click.productOverlayClose', '.hotspotProductOverlayClose', function(e) {
      e.preventDefault();
      self.removeProduct();
      return false;
    });

    return currentTemplate;
  }

  function getAllDom() {
    let self = this,
        data = self.hotspotsAll,
        currentTemplate = $(template.hotspotProductsAll()),
        templateBlockList,
        templateBlock;

    templateBlockList = currentTemplate.find('.block-list-inline ul');
    templateBlock = currentTemplate.find('.block').detach();

    $.each(data, function(index, imageHotspots) {

      $.each(imageHotspots, function(index, hotspot) {

        var product, caption, hotspotImage;

        if (hotspot.dataType !== 'product') {
          return;
        }

        caption = hotspot.caption;
        product = templateBlock.clone().appendTo(templateBlockList);

        hotspotImage = product.find('.hotspotProductImage img');
        if (hotspot.image) {
          hotspotImage.attr({
            src: hotspot.image,
            alt: hotspot.title,
            title: hotspot.title
          });
        } else {
          if (self.settings.isMobile) {
            hotspotImage.parent().remove();
          } else {
            hotspotImage.remove();
          }
        }

        product.find('.hotspotProductTitle a').html(hotspot.title).attr('href', hotspot.url);
        product.find('.hotspotProductDescription').html(caption);

        product.off('click.moreInfo');
        product.on('click.moreInfo', '.hotspotProductImage img, .hotspotProductTitle a, .hotspotProductDescription', function(e) {
          e.preventDefault();
          self.moreInfo(hotspot);
        });
      });

    });

    currentTemplate.off('click.allBack');
    currentTemplate.on('click.allBack', '.hotspotProductsAllBack button, .hotspotProductOverlayClose', function(e) {
      e.preventDefault();
      self.removeAll();
    });

    return currentTemplate;
  }

  function removeProduct() {
    let self = this;
    if (self.modalProduct) {
      self.modalProduct.removeClass('active hotspotFixed');
      setTimeout(function(){
        self.modalProduct.remove();
      }, 510);
    }
  }

  function removeAll() {
    let self = this,
        isPhotoLibrary = self.settings.pageTypes.photoLibrary,
        isInline = self.settings.pageTypes.inline,
        isVertical = self.settings.pageTypes.vertical,
        isMobile = self.settings.isMobile;

    if (self.modalAll) {

      if (!isPhotoLibrary && !isVertical) {
        window.scrollTo(0, 0);
      }

      if (!isMobile && (isInline || isVertical)) {
        $('body').removeClass('pv-no-scroll');
      }

      self.modalAll.removeClass('active hotspotFixed');

      setTimeout(function(){
        if (isVertical) {
          $(window).scrollTop(self.settings.scrollOffset);
        }
        self.modalAll.remove();
      }, 300);

    }
  }

  function moreInfo(hotspot) {
    let eID,
        eName,
        room,
        hText,
        mdManager = application.getGlobal('mdManager'),
        legacy_s = application.getGlobal('s'),
        url;

    if (check.exists(['s', 'mdManager']))  {
      eID   = mdManager.getParameterString('EditorialTracking').replace(/[^a-z0-9\s]/gi, '') + ' Photo Gallery Hotspots';
      eName = mdManager.getParameterString('Title').replace(/[^a-z0-9\s]/gi, '');
      room = mdManager.getParameterString('CurrentRoom').replace(/[^a-z0-9\s]/gi, '') + ' Pictures';
      hText = hotspot.title.replace(/[^a-z0-9\s]/gi, '');

      legacy_s.linkTrackVars = 'prop1,prop2,eVar46,eVar47,eVar48,events';
      legacy_s.linkTrackEvents = 'event48';
      legacy_s.events = 'event48';
      legacy_s.eVar46 = eID;
      legacy_s.eVar47 = eID + ':' + hText;
      legacy_s.eVar48 = eID + ':Photos:' + eName + ':' + room + ':' + hText;
      legacy_s.tl(this,'o', eID+':Sponsor Site Click');
      legacy_s.eVar46 = '';
      legacy_s.eVar47 = '';
      legacy_s.eVar48 = '';
    }

    url = hotspot.url;

    if (url) {
      if (!window.open(url)) {
        window.location = url;
      }
    }
  }

  function displayAll() {
    let self = this,
        isPhotoLibrary,
        isInline,
        isVertical,
        isMobile;

    isPhotoLibrary = self.settings.pageTypes.photoLibrary;
    isInline = self.settings.pageTypes.inline;
    isVertical = self.settings.pageTypes.vertical;
    isMobile = self.settings.isMobile;

    if ((!isPhotoLibrary && !isVertical) || (isPhotoLibrary && isMobile)) {
      self.settings.scrollOffset = $(window).scrollTop();
      window.scrollTo(0, 0);
    }

    if ( isPhotoLibrary && !isMobile) {
      self.modalAll = self.getAllDom().appendTo('.pv-stage');
    } else {
      self.modalAll = self.getAllDom().appendTo('body');
      if ((isInline || isVertical) && !isMobile) {
        self.modalAll.css('width', '100%');
        $('body').addClass('pv-no-scroll');
      }
    }

    if (isMobile) {
      self.modalAll.css('min-height', $(document).height() );
    }

    if (self.settings.hotspotClass) {
      self.modalAll.addClass(self.settings.hotspotClass);
    }

    setTimeout(function() {
      self.modalAll.addClass('active');
      if (isVertical) {
        $('.hotspotProductsAll, .hotspotProductsBackdropAll').addClass('vertical-hotspot');
      }
    }, 1);

    setTimeout(function() {
      self.modalAll.addClass('hotspotFixed');
    }, 300);

    setTimeout(function() {
      self.removeProduct();
    }, 300);
  }

  function display(hotspot, hotspotsAll, settings) {
    let self = this,
        isPhotoLibrary,
        isInline,
        isVertical,
        isMobile;

    self.settings = settings || {};
    isPhotoLibrary = self.settings.pageTypes.photoLibrary;
    isInline = self.settings.pageTypes.inline;
    isVertical = self.settings.pageTypes.vertical;
    isMobile = self.settings.isMobile;

    if ((!isPhotoLibrary && !isInline) || (isPhotoLibrary && isMobile)) {
      self.settings.scrollOffset = $(window).scrollTop();
      window.scrollTo(0, 0);
    }

    if (isPhotoLibrary && !isMobile) {
      self.modalProduct = self.getProductDom(hotspot).appendTo('.pv-stage');
    } else if (isInline && !isMobile) {
      self.modalProduct = self.getProductDom(hotspot).appendTo('.rsOverflow ');
    } else {
      self.modalProduct = self.getProductDom(hotspot).appendTo('body');
    }

    if (isMobile) {
      self.modalProduct.css('min-height', $(document).height() );
    }

    if (self.settings.hotspotClass) {
      self.modalProduct.addClass(self.settings.hotspotClass);
    }

    setTimeout(function(){
      self.modalProduct.addClass('active');
      if (isVertical) {
        $('.hotspotProduct, .hotspotProductBackdrop').addClass('vertical-hotspot');
      }
    }, 10);

    setTimeout(function(){
      self.modalProduct.addClass('hotspotFixed');
    }, 1000);

    self.hotspotsAll = hotspotsAll;

    self.modalProduct.off('click.productOverlayClose');
    self.modalProduct.on('click.productOverlayClose', '.hotspotProductOverlayClose a', function(e){
      e.preventDefault();
      self.removeProduct();

      if (isPhotoLibrary || isVertical) {
        $(window).scrollTop(self.settings.scrollOffset);
      }
    });

    $('.hotspotProduct').off('click.hotspotProduct');
    $('.hotspotProduct').on('click.hotspotProduct', function(e) {
      if (e.target !== this) {
        return;
      }
      self.removeProduct();
      if (isPhotoLibrary || isVertical) {
        $(window).scrollTop(self.settings.scrollOffset);
      }
    });
  }

  function selectRenderer(type) {
    let api;
    switch (type) {
      case defaults.rendererTypes.product:
        api = {
          getHref,
          getTitle,
          display,
          displayAll,
          getProductDom,
          getAllDom,
          removeProduct,
          removeAll,
          moreInfo
        };
        break;
    }

    return api;
  }

  function adjustData(data) {

    let newData,
        imageUrl;

    newData = {};

    $.each(data, function(){

      let image = this;

      image.hotspots = $.map(image.hotspots, function(hotspot, index){

        hotspot.dataType = hotspot.dataType || 'product';
        hotspot = adjustHotspot(image.rendition, hotspot);

        if (outOfRange(hotspot.x) || outOfRange(hotspot.y)) {
          return null;
        }

        hotspot.x = toPercentage(hotspot.x);
        hotspot.y = toPercentage(hotspot.y);

        hotspot.renderer = selectRenderer(hotspot.dataType);

        return hotspot;

      });

      imageUrl = image.image.replace(/.rend.*/, '');
      newData[imageUrl] = image.hotspots;
    });

    return newData;
  }

  function get(settings) {
    let deferred,
        config;

    config = settings || {};
    config.url = config.url || window.location.pathname;
    config.url = adjustUrl(config.url);
    deferred = $.Deferred();

    if (cache[config.url]) {
      deferred.resolve(cache[config.url]);
      return deferred.promise();
    }

    $.ajax({
      url: config.url,
      timeout: config.timeout || defaults.timeout,
      dataType: 'json'
    }).done(function(data, textStatus, jqXHR){

      if (data.error) {
        deferred.reject(jqXHR, 'error', '');
        return;
      }

      data = adjustData(data);
      cache[config.url] = data;
      deferred.resolve(data);
    }).fail(function(jqXHR, textStatus, errorThrown){
      deferred.reject(jqXHR, textStatus, errorThrown);
    });
    return deferred.promise();
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  service = {
    get,
    adjustUrl,
    adjustData,
    adjustHotspot,
    getHref,
    getTitle,
    display,
    displayAll,
    getProductDom,
    getAllDom,
    removeProduct,
    removeAll,
    moreInfo,
    selectRenderer,
    toPercentage
  };

  return service;
});
