SNI.Application.addBehavior('customer-service', (context) => {

  const mdManager = context.getGlobal('mdManager');
  const defaults = {
    subDomain: 'help.',
    basePath: '.com/hc/en-us/requests/new',
    currentProtocol: 'https://'
  };

  let config = Object.assign({}, defaults, context.getConfig('customerService'));
  let element = context.getElement();

  function constructCustomerServiceLink(currentSite) {
    let finalLink;
    switch (currentSite) {
      case 'hgtv':
        finalLink = `${config.currentProtocol}${config.subDomain}${currentSite}${config.basePath}`;
        break;
      case 'travel':
        finalLink = `${config.currentProtocol}${config.subDomain}${currentSite}channel${config.basePath}`;
        break;
      case 'food':
        finalLink = `${config.currentProtocol}${config.subDomain}${currentSite}network${config.basePath}`;
        break;
      case 'cook':
        finalLink = `${config.currentProtocol}${config.subDomain}${currentSite}ingchanneltv${config.basePath}`;
        break;
      default:
        finalLink = `${config.currentProtocol}${currentSite}${config.basePath}`;
        break;
    }
    return finalLink;
  }

  function appendCustomerServiceLink(finalUrl, element) {
    let $element = $(element);
    $element.find('a:contains(\'Report This Listing\')').attr('href', finalUrl);
  }

  return {

    init() {
      config.site = mdManager.getParameterString('site');
      appendCustomerServiceLink(constructCustomerServiceLink(config.site), element);
    },

    constructCustomerServiceLink,
    appendCustomerServiceLink
  };
});
