SNI.Application.addModule('instagram-embed', (context) => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const debug = context.getService('logger').create('module.instagram-embed'),
        loader = context.getService('dependency-loader');
  let $element,
      defaults,
      config,
      instgrm,
      embedModule,
      omitscript = true;

  defaults = {
    url: '',
    hideCaption: false,
    jsURL: '//platform.instagram.com/en_US/embeds.js',
    embedURL : 'https://api.instagram.com/oembed',
    embedName: 'instagram'
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setEmbedConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function loadEmbed(){
    let apiPath = config.embedURL,
        url = config.url||'',
        hideCaption =  config.hideCaption;

    $.ajax({
      url: apiPath,
      data: {
        url: url,
        hidecaption: hideCaption,
        omitscript
      },
      context: this
    })
      .done(function(response) {
        $element.html(response.html);
        instgrm = context.getGlobal('instgrm');
        if (instgrm && instgrm.Embeds) instgrm.Embeds.process();
      })
      .fail(function(xhr, response) {
        debug.log('instagram embed GET error: ', response);
      });
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  embedModule = {
    init() {
      setEmbedConfig(defaults, context.getConfig());
      $element = $(context.getElement());
      loader.load(config.jsURL).then(() => {
        loadEmbed();
      }).catch(() => {
        omitscript = false;
        loadEmbed();
      });
    },
    updateObject,
    setEmbedConfig
  };

  return embedModule;

});
