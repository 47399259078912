SNI.Application.addModule('shopping-lead', (context) => {
  const debug = context.getService('logger').create('module.shopping-lead');
  const shopping = context.getService('track-shopping');
  const defaults = {
    carouselSelector: '.m-Carousel',
    slideSelector: '.m-ProductsList__m-Product',
    activeClass: '.active'
  };
  let $element;
  let $carousel;
  let config;
  let parentMetadata;

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function getMetaData() {
    const slideMetadata = $carousel
      .find(config.activeClass)
      .find('[data-mdm]')
      .data('mdm');

    return updateObject(parentMetadata, slideMetadata);
  }

  return {
    behaviors: ['affiliate'],
    init() {
      debug.log('init');
      $element = $(context.getElement());
      $carousel = $element.find('[data-module="carousel"]');
      setConfig(defaults, context.getConfig());
      parentMetadata = updateObject($element.data('product-count'), {
        componentname: config.parentComponent
      });
    },

    onmessage: {
      'carousel.loaded': data => {
        $element.addClass('is-Loaded');
        if (data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id')) {
          shopping.trackProductImpression(getMetaData());
        }
      },
      'carousel.slideUpdated': data => {
        if (data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id')) {
          shopping.trackProductImpression(getMetaData());
        }
      }
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'product-link':
          const slideMetadata = $(element).closest(config.slideSelector).data('mdm'),
                metadata = updateObject(parentMetadata, slideMetadata);

          shopping.trackProductClick(metadata);
          break;
        default:
          break;
      }
    }
  };
});
