SNI.Application.addBehavior('replace-content', context => {

  const debug = context.getService('logger').create('behavior.replace-content');
  const device = context.getService('device-type');
  const utility = context.getService('utility');
  const element = context.getElement();
  const lazy = context.getService('lazy-load');
  const template = context.getService('template');
  const $element = $(element);
  const config = context.getConfig();
  /* Content can be replaced by different types of events */

  let aemSelector = config.seasonsSelector;
  let targetContainer = config.targetContainer || '[data-horizontal-scroll]';
  let $targetContainer;
  let hasPreloader = false;

  function buildShowUrl(path) {
    // rewritten paths start with //www
    if (/^\/\/www/.test(path)) {
      return path;
    } else {
      return `${location.origin}${config.showRef}.html`;
    }
  }
  return {
    init() {
      $targetContainer = $element.find(targetContainer);
    },
    onchange(event, element, sel) {

      let val = element.value;
      let param = '?';
      if (val) {
        param += 'season=' + val;
      }

      // Use config.showRef when available.  It is the path to parent show page and needed in an episode page context
      const finalUrl = (config && config.showRef) ?
        utility.buildUrlWithSelector({href: buildShowUrl(config.showRef)}, device.isMobile, aemSelector, param) :
        utility.buildUrlWithSelector(location, device.isMobile, aemSelector, param);

      $element.addClass('is-Loading');

      if (!hasPreloader) {
        $element.find('.o-FullWidth__m-Header').append(template.preloaderHTML());
        hasPreloader =  true;
      }

      utility.getDynamicContent(finalUrl, $targetContainer, targetContainer, function handler(){
        debug.log('Season changed');
        $targetContainer.scrollLeft(0);
        lazy.lazyLoadImgsOutOfView($targetContainer);
        $targetContainer.trigger('scroll');
      },
      function failure(){
        debug.error('Could not retrieve episode list');
      },
      function final(){
        $element.removeClass('is-Loading');
      });
    }
  };

});
