SNI.Application.addModule('recipe-box-nav', (context) => {

  const namespace = 'recipe-box-nav';

  const utility = context.getService('utility');

  let $module = $(context.getElement());

  function toggleMenu(state = !$module.hasClass('is-Open')) {
    $module.toggleClass('is-Open', state);
    if (state) {  // true => is being opened here & now
      utility.bindClickOutside($module, namespace, function() {
        toggleMenu(false);
      });
    } else {
      utility.unbindClickOutside(namespace);
    }
  }

  return {
    onclick: function(event, element, elementType) {
      if (elementType === 'menu-toggle') {
        event.preventDefault();
        //event.stopPropagation();
        toggleMenu();
      }
    }
  };

});
