SNI.Application.addService('module-loader', function(application) {

  let debug = application.getService('logger').create('service.module-loader');

  return {

    loadRemote(url, el) {
      let $el = $(el);

      debug.log(`loading ${url}`);

      return new Promise( (resolve, reject) => {
        $.get(url, data => {
          $el.append($(data));
          application.startAll($el);
          resolve();
        });
      });

    },

    loadString(html, el = $('body')) {
      let $el = $(el);

      $el.append(html);
      application.startAll($el);
    }

  };

});
