SNI.Application.addService('social/sharebar', (application) => {

  let debug = application.getService('logger').create('service.sharebar');
  let deviceType = application.getService('device-type');
  let containerSel = '.o-SocialShare';

  // contexts where a non-sticky version is allowed on mobile should return true
  function isMobileException(ele) {
    return (ele.closest('#vote-modal'));
  }

  function adjustAnchorScroll($sb, $themeSel) {
    $('[href^=\'#\']').filter(function() {
      //prevent conflict with tab behavior
      if ($(this).attr('data-type') === 'tab-ctrl') {
        return false;
      }
      let $anchored = $($(this).attr('href'));
      $(this).data('anchored', $anchored);
      return ($anchored.length && $anchored.offset() && $anchored.offset().top && ($anchored.offset().top > 0));
    }).on('click', function(e) {
      let top = $(this).data('anchored').offset().top - getHeight();
      if (top > 0) {
        debug.log('animating scroll to top');
        $('html, body').scrollTop(top);
        e.preventDefault();
        return false;
      }
    });
  }

  function getThemeSel() {
    return $('.sni-theme-selector-theme-sel').closest('div');
  }

  function getHeight($container, $themeSel) {
    $themeSel = $themeSel || getThemeSel();
    $container = $container || $(containerSel);
    return ($container.outerHeight(true) + $themeSel.outerHeight());
  }

  // apply styles for fixed mobile sharebar
  function initShareBar(container, config) {
    debug.log('initShareBar 1 ' + deviceType.isMobile);
    if (deviceType.isMobile && container.id.endsWith('-1')) {
      // Recipes on mobile have sticky Next / Prev buttons that conflict with the social share bar that is also supposed to stick on mobile.
      // config.fixed should be set according to the template type - true if on a Recipe Page, false otherwise.  -- IS THIS STILL RELEVANT??
      debug.log('applying floating sharebar styles');
      let $bar = $(container.querySelector('.m-SocialIcons'));
      let $h = $('header:first, .o-Header:first');
      let $themeSel = getThemeSel();
      let shareTop = ($themeSel.length) ? $themeSel.outerHeight() - 2 : 0;
      $bar
        .css({
          top: shareTop
        });
      if ($h.length) {
        $(window).on('scroll', function() {
          let scrollTop = $(window).scrollTop();
          let headerHeight = $h.is(':visible') ? ($h.height() + $h.offset().top - shareTop) : 0;
          $bar
            .css('willChange', (scrollTop < (headerHeight + 200)) ? 'height' : 'auto')
            .toggleClass('is-Open', (scrollTop > headerHeight));
        }).trigger('scroll');
      }
      adjustAnchorScroll($bar, $themeSel);
    }
  }

  function removeExtraBars() {
    for (let container of document.querySelectorAll(containerSel)) {
      if (!container.id.endsWith('-1')) {
        debug.log('sharebar.show removing ' + container.id);
        container.parentNode.removeChild(container);
      }
    }
  }

  /*
   *  floating sharebar case: apply styles to first; remove others
   *
   */

  function showOrRemove(mod) {
    debug.log('showOrRemove');
    // remove the module if not first (and not a multi-sharebar exception)
    if (isMobileException(mod)) return;
    if (mod.id.endsWith('-1') )
      initShareBar(mod);
    removeExtraBars();
  }

  return {
    showOrRemove,
    getHeight
  };
});
