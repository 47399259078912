/**
  * Dropdown Filter: this is used for dropdowns there are generated from an HTML list
  * gives the list dropdown the ability to have a selected state
  */

SNI.Application.addModule('dropdown-filter', (context) => {
  'use strict';


  let element             = $(context.getElement()),
      $element            = $(element),
      debug               = context.getService('logger').create('module.dropdown-filter');

  let defaults = {
    format: 'YYYY-MM-DD'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  return {

    config,

    init: function() {

      let $selectedFilterItem = $element.find('.dropdown-menu').find('.selected');
      let offset = $selectedFilterItem.length ? $selectedFilterItem.position().top : false;
      if (offset) {
        debug.log('dropping down the filter');
        $element.find('.dropdown-menu').scrollTop(offset);
      } else {
        debug.warn('dropdown selected element not found');
      }

    }

  };

});
