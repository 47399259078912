SNI.Application.addModule('editorial-promo', context => {
  let $element = $(context.getElement());
  let debug = context.getService('logger').create('module.editorial-promo');

  return {
    behaviors: ['lazy-load'],
    messages: ['lazy-load.image-loaded'],
    onmessage: {
      'lazy-load.image-loaded': (name) => {
        debug.log('lazy-load message received:', name);
        $element.addClass('l-List--Loaded');
      },
    }
  };

});
