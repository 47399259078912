/*
  This is a generic module that will apply behavior functionality only
  It gets the list of the desired behaviors from the component's x-config
*/

SNI.Application.addModule('flex-behaviors', context => {
  let behaviors = [];
  let config = context.getConfig();
  let list = config && config.behaviorList;
  if (list) {
    behaviors = list;
  }
  return {
    behaviors
  };
});
