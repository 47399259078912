SNI.Application.addService('stream-manager', function(application) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  const sS = application.getGlobal('sessionStorage'),
        debug = application.getService('logger').create('service.stream-manager');

  let entries = {},
      tracked = [],
      urlList = [],
      order = 0,
      maxIndex = 9,
      entry,
      futureEntry,
      nextUpUrls = [],
      response,
      loadedUrl,
      initialUrl,
      insert = false, // track insert status
      selector,
      $entries = {},
      htmlFragmentSelector = 'lazy-fetch-html-content',
      /*
        CFF-262/ REX-21:
        Handles the behavioral interaction counts:
        It tracks current: article in view - current page in article - total pages viewed, e.g. 1-1-1
      */
      views = {
        articles: 1,      //  The index of the current article
        inArticlePage: 1, //  Will be set to last seen on a previously viewed article
        totalPages: 1,    //  Always increasing
        lastPage:{},      //  track the article last seen by key
        isNewEntry: true,
        //  Used to recall the last page viewed in a specific article.
        setLastSeen(){
          let index = this.articles;
          this.lastPage[index] = this.inArticlePage;
        },
        //  Used when switching to a previously viewed article
        getLastSeen(index) {
          let i = index || this.articles;
          return this.lastPage[i];
        },
        //  Increment pages viewed
        incrementPage(){
          this.inArticlePage++;
          this.totalPages++;
          this.setLastSeen();
        },
        // Article changed: increment article number and set last seen page in tracker
        incrementArticle(setPg){
          this.articles++;
          if (setPg) {
            this.inArticlePage = setPg;
            this.setLastSeen();
          }
        },
        //  Total pages can only increase
        incrementTotal(){
          this.totalPages++;
        },
        //  Used when switching to a previously viewed article
        setIndex(i=1, pg=1) {
          this.articles = i;
          this.inArticlePage = pg;
          this.lastPage[i] = pg;
        },
        //  Compose the behavioral interaction from tracked values
        behavioralInteraction() {
          return `${this.articles}-${this.inArticlePage}-${this.totalPages}`;
        },
      },
      /* REX-21
         Metadata values saved here to allow predictable access to the data.
         Previously the values would match the "current entry" which can change as the page scrolls
         The data is stored by entry index.
         We will need to revisit the overall entry management to remove deprecated functionality
      */
      mdm = {
        entries: {},
        addEntry(index,val) {
          debug.log('Adding entry at', index);
          this.entries[index] = val;
        },
        getEntry(index) {
          return this.entries[index];
        }
      },

      seo = {
        entries: {},
        entry(index, val) {
          if (index && val) {
            this.entries[index] = val;
          } else if (index) {
            return this.entries[index];
          }
        }
      };

  function getJSON(url) {
    return $.getJSON(url);
  }
  function fetchResponse(suppliedUrl, currentUrl) {
    let promise = new Promise(
      (resolve, reject) => {
        if (typeof currentUrl !== 'undefined') {
          getAsyncEntry(suppliedUrl, currentUrl)
            .done((data) => {
              setResponse(data);
              resolve(data);
            })
            .fail((jqxhr, settings, exception) => {
              debug.error('setResponse: unable to fetch response: ', exception);
              reject(exception);
            });
        } else {
          debug.warn('setResponse: unable to fetch response: url undefined: ', currentUrl);
          reject('Url Undefined');
        }
      });
    return promise;
  }

  //   This is a hack to get the HTML block of the response separately (if loadHtml flag set in initial response),
  //   so it is processed by the link rewriter server-side.  It then replaces the HTML in the JSON object initially retrieved,
  //   so downstream code will insert the processed HTML and present friendly URLs in the streamed assets.
  function getHTML(dta) {
    let promise = new Promise(
      (resolve) => {
        debug.log('Entering getHTML for ', getLoadedUrl(), 'Order', order);
        if (!getInsert()) {
          debug.log('Skipping 2nd XHR call');
          //  Skip the first call (since we are likely on the first article)
          return resolve(dta);
        }
        if (!dta.loadHtml || dta.loadHtml !== 'true') {
          // don't get HTML content separately
          debug.log('getHTML: NOT getting HTML separately.');
          return resolve(dta);
        } else {
          // get HTML content separately to get links rewritten
          let loadedUrl = getLoadedUrl();
          let entry = $entries[loadedUrl];
          let $html;
          if (entry && entry.loadingHtml) {
            debug.log('Using prefetched HTML entry.');
            $html = entry.$req2;
          } else {
            debug.log('Did not prefetch the article body.  Fetching now.');
            $html = $.get(loadedUrl + `.${htmlFragmentSelector}.html`);
          }
          $html.then((html) => {
            dta.data = html;
            dta.loadedUrl = loadedUrl;
            debug.log('Loading markup from fix Reponse');
            resolve(dta);
          })
          // even if this request fails, fall back to unprocessed HTML in original response:
            .always((data) => { resolve(data); });
        }
      });
    return promise;
  }

  function setResponse(val) {
    if (typeof val !== 'undefined') {
      response = val;
    }
  }

  function getResponse() {
    if (typeof response !== 'undefined') {
      return response;
    } else {
      return false;
    }
  }

  function setSelector(val) {
    if (typeof val !== 'undefined') {
      selector = val;
    }
  }

  function getSelector() {
    if (typeof selector !== 'undefined') {
      return selector;
    } else {
      return false;
    }
  }

  function setLoadedUrl(val) {
    if (typeof val !== 'undefined') {
      loadedUrl = val;
    }
  }

  function getLoadedUrl() {
    if (typeof loadedUrl !== 'undefined') {
      return loadedUrl;
    } else {
      return false;
    }
  }

  function setInsert(val=false) {
    insert = val;
  }

  function getInsert() {
    return insert;
  }

  function getNextEntry(currentResponse, list = 'dynamicList', seo = 'seoMetaData', sel = 'nextUP') {
    debug.log('At get next entry with', urlList.length, 'entries');
    if (typeof currentResponse !== 'undefined') {
      if (currentResponse.hasOwnProperty(list)) {
        let obj = currentResponse[list];
        let arr = [];
        //  convert the list to an array so that we can easily concat future arrays to it
        for (let prop in obj) {
          arr.push(obj[prop]);
        }
        //  set a max size for the url list array
        if (urlList.length<30) {
          urlList = urlList.concat(arr);
        }

        let unique = findUnique(arr); // find using dynamic list first
        if (!unique) {
          unique = findUnique(urlList); // otherwise try the fallback list
        }
        return unique;
      } else if (urlList.length>1) {
        return findUnique(urlList);
      } else if (currentResponse.hasOwnProperty(seo) && currentResponse[seo].hasOwnProperty(sel)) {
        return currentResponse[seo][sel];
      } else {
        debug.error('getNextEntry: unable to set next entry: no dynamic list or override asset');
        return false;
      }
    } else {
      if (urlList.length>1) {
        return findUnique(urlList);
      }
    }
  }

  function getFutureEntry() {
    return futureEntry;
  }

  function getNextUpUrl() {
    return nextUpUrls.length ? nextUpUrls.pop() : getNextEntry() || '';
  }

  function findUnique(list) {
    if (typeof list !== 'undefined' && typeof tracked !== 'undefined') {
      debug.log('findUnique: list: ', list);
      for (let item in list) {
        debug.log('Make sure', list[item], 'DNE', initialUrl);
        if ($.inArray(list[item], tracked) === -1 && list[item] !== initialUrl) {
          debug.log('findUnique: returned item: ', list[item]);
          return list[item];
        }
      }
      return false;
    }
  }

  /**
   * Create an xhr entry to store a jquery $.getJSON request
   * This is created using the next Up url from a new entry
   * The second XHR call is also created and stored as $req2
   * The second XHR when in use is the article HTML
   * The article stream logic has been updated so that there is a fallback
   * request when addAsyncEntry is not used (i.e. on the first article)
   * @param {string} url
   */
  function addAsyncEntry(url) {
    url = url.replace(/\.html$/i, '');
    let currentSelector = getSelector();
    let processedUrl = `${url}.${currentSelector}.json`;
    let $json = getJSON(processedUrl);

    $json.then((data) => {
      let $req2 = $.Deferred();
      if (data.loadHtml && data.loadHtml === 'true') {
        $entries[url].loadingHtml = true;
        $req2 = $.get(url + `.${htmlFragmentSelector}.html`);
      } else {
        $req2.resolve(data);
      }
      $entries[url].$req2 = $req2;
      return $req2;
    }).done(() => {
      debug.log('Done getting req2 html');
    }).fail(() => {
      debug.log('Failed to fetch article JSON');
    });
    $entries[url] = {
      $req: $json,
      used: false,
      loadedUrl: url,
      loadingHtml: false,
      completedLoading: false
    };
  }

  function cleanUpAsync() {
    for (let entry in $entries) {
      if ($entries[entry].used) delete $entries[entry];
    }
  }

  /**
   *
   * @param {string} suppliedUrl | the URL used to track this article
   * @param {string} processedUrl the URL with selectors used to fetch the article
   * Return a prefetched entry if available or return $.getJSON
   */
  function getAsyncEntry(suppliedUrl, processedUrl) {
    let entry = $entries[suppliedUrl];
    if (entry) {
      debug.log('Getting async entry that was saved', entry);
      return entry.$req;
    } else {
      return getJSON(processedUrl);
    }
  }

  function createEntry(currentResponse, nextUrl, mdm = 'mdManager', og = 'seoMetaData', content = 'data') {
    // Prefetch next article JSON right away
    if (nextUrl) {
      trackEntry(nextUrl);  // tracking entry in the same format that is requested to improve tracking checks
      addAsyncEntry(nextUrl);
    }
    return {
      url: (currentResponse.hasOwnProperty(mdm) && currentResponse[mdm].hasOwnProperty('Url')) ? currentResponse[mdm].Url : false,
      nextUp: (typeof nextUrl !== 'undefined') ? nextUrl : false,
      // Prefix the DetailID to avoid potentially starting with a number, which is invalid class name
      class: (currentResponse.hasOwnProperty(mdm) && currentResponse[mdm].hasOwnProperty('DetailID')) ? 'cl-' + currentResponse[mdm].DetailID : false,
      viewed: false,
      tracked: false,
      current: false,
      loadedUrl: currentResponse['loadedUrl'],
      mdm: (currentResponse.hasOwnProperty(mdm)) ? currentResponse[mdm] : false,
      og: (currentResponse.hasOwnProperty(og)) ? currentResponse[og] : false,
      content: (currentResponse.hasOwnProperty(content)) ? currentResponse[content] : false,
      order: +order,
      lastPg: 1
    };
  }

  function setAsUsed(url) {
    if ($entries[url]) {
      $entries[url].used = true;
    }
    cleanUpAsync();
  }

  function storeEntry(currentEntry, identifier) {
    if (typeof currentEntry !== 'undefined' && currentEntry.hasOwnProperty(identifier)) {
      if (currentEntry[identifier] !== '') {
        entries[currentEntry[identifier]] = currentEntry;
        return true;
      } else {
        debug.warn('storeEntry: unable to store entry: empty identifier: ', identifier, currentEntry[identifier]);
        return false;
      }
    }
  }

  function getTracked() {
    if (sS.getItem('trackedStreamEntries')) {
      tracked = JSON.parse(sS.getItem('trackedStreamEntries'));
    } else {
      debug.log('getTracked: no tracked stream entries');
    }
    return tracked;
  }

  function trackEntry(entryUrl) {
    if (typeof entryUrl !== 'undefined') {
      if ($.inArray(entryUrl, tracked) === -1) {
        tracked.push(entryUrl);
      }
    }
  }

  function setTracked() {
    if (typeof tracked !== 'undefined' && tracked.length > 0) {
      sS.setItem('trackedStreamEntries', JSON.stringify(tracked));
    }
  }

  function getTrackedSet() {
    if (typeof tracked !== 'undefined') {
      return tracked;
    } else {
      return false;
    }
  }

  function setEntry(val) {
    if (typeof val !== 'undefined') {
      entry = val;
    }
  }

  function getEntry() {
    if (typeof entry !== 'undefined') {
      return entry;
    } else {
      return false;
    }
  }

  function getStoredEntryByValue(prop, val) {
    if (typeof prop !== 'undefined' && typeof val !== 'undefined') {
      for (let item in entries) {
        if (entries[item].hasOwnProperty(prop) && entries[item][prop] === val) {
          return entries[item];
        }
      }
      return false;
    }
  }

  function updateStoredEntry(identifier, prop, val) {
    if (typeof identifier !== 'undefined' && typeof prop !== 'undefined' && typeof val !== 'undefined')
      if (entries.hasOwnProperty(identifier) && entries[identifier].hasOwnProperty(prop)) {
        entries[identifier][prop] = val;
      }
  }

  function getStoredEntriesByValue(prop, val) {
    if (typeof prop !== 'undefined' && typeof val !== 'undefined') {
      let tempEntries = {};
      for (let item in entries) {
        if (entries[item].hasOwnProperty(prop) && entries[item][prop] === val) {
          tempEntries[item] = entries[item];
        }
      }
      if ($.isEmptyObject(tempEntries)) {
        return false;
      } else {
        return tempEntries;
      }
    }
  }

  function getStoredEntries() {
    return entries;
  }

  function setCurrentEntry(id, sel = 'class', prop = 'current', val = true) {
    if (typeof id !== 'undefined') {
      for (let item in entries) {
        if (entries[item][sel] === id) {
          entries[item][prop] = val;
        } else {
          entries[item][prop] = !val;
        }
      }
    }
  }

  // Use this function to get an entry in a more direct way
  function getEntryBySelector(id) {
    if (typeof id !== 'undefined') {
      for (let item in entries) {
        if (entries[item]['class'] === id) {
          return entries[item];
        }
      }
    }
  }

  function getEntryElements() {
    let elementSet = [];
    for (let item in entries) {
      if (entries[item].hasOwnProperty('class') && entries[item].class !== '') {
        elementSet.push($(`.${entries[item].class}`));
      }
    }
    if (elementSet.length > 0) {
      return $(elementSet);
    } else {
      return false;
    }
  }

  function cleanUpViewedEntries() {
    for (let item in entries) {
      let obj = entries[item];
      if (obj && obj.viewed) {
        obj.content = null;
      }
    }
  }

  function setMaxIndex(index) {
    maxIndex = index;
  }

  function getMaxIndex() {
    return maxIndex;
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {

    addEntry(suppliedUrl, insert = false) {
      if (typeof suppliedUrl !== 'undefined') {
        let processedUrl,
            currentSelector,
            tempEntry,
            clean;

        currentSelector = getSelector();
        if (currentSelector) {
          processedUrl = `${suppliedUrl.replace(/\.html$/i, '')}.${currentSelector}.json`;
          setLoadedUrl(suppliedUrl);
          trackEntry(suppliedUrl);
          setInsert(insert);
          fetchResponse(suppliedUrl, processedUrl)
            .then(getHTML)  //   Get HTML fragment if needed
            .then((val) => {
              debug.log('Fetch response, final step');
              let fentry = getNextEntry(val);
              tempEntry = createEntry(val, fentry);
              nextUpUrls.push(fentry);
              mdm.addEntry(tempEntry.order, val['mdManager']);  // save mdManager values to a central location.
              seo.entry(tempEntry.order, val['seoMetaData']); // Save seo values for Sharebar

              if (!insert) {
                setEntry(tempEntry);            // set as current entry the first time
              }

              if (storeEntry(tempEntry, 'url')) { // add to entries array
                if (tempEntry.url.indexOf('.html') > 0) {
                  clean = tempEntry.url.substring(0,tempEntry.url.lastIndexOf('.html'));
                } else {
                  clean = tempEntry.url;
                }

                if (tempEntry.order === 0) {
                  initialUrl = clean; // save first clean entry to prevent duplicate streams with small data sets
                }
                application.broadcast('stream-manager.entryLoaded', {
                  item: tempEntry,
                  append: insert,
                  order: tempEntry.order
                });
              }
              order++;
            }).catch((reason) => {
              debug.error('addEntry: unable to fetch response: ', reason);
              application.broadcast('article-stream.end');
            });
        }
      }
    },
    fetchResponse,
    getResponse,
    setResponse,
    setSelector,
    getSelector,
    setLoadedUrl,
    getLoadedUrl,
    getNextEntry,
    findUnique,
    createEntry,
    storeEntry,
    getTracked,
    getTrackedSet,
    trackEntry,
    setTracked,
    setEntry,
    setMaxIndex,
    getMaxIndex,
    setAsUsed,
    getEntry,
    getFutureEntry,
    getNextUpUrl,
    getStoredEntryByValue,
    updateStoredEntry,
    getStoredEntriesByValue,
    getStoredEntries,
    getEntryBySelector,
    cleanUpViewedEntries,
    setCurrentEntry,
    getEntryElements,
    views,  // page view tracking
    mdm, //  metadata tracking
    seo
  };

});
