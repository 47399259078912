SNI.Application.addService('common-handlers', application => {

  return {
    eventHandler: (events, preventDefault = false) =>
      (event, element, elementType) => {
        if (preventDefault) {
          event.preventDefault();
        }
        if (elementType in events) {
          events[elementType](event, element, elementType);
        }
      }

  };

});
