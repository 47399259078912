/*
 * Network Service: handle gets & posts to Chitter service
 *   factory pattern to allow injection of Query State Service instance & endpoint value
 */

SNI.Application.addService('user/network', (application) => {
  const debug = application.getService('logger').create('service.user/network'),
        userInterface = application.getService('user/user-data'),
        device = application.getService('device-type'),
        queryState = application.getService('user/comments-query-state'),
        mdManager = application.getGlobal('mdManager'),
        brand = SNI.Config.brand;

  const TEMPLATE_FIELD_NAME = 'overall';
  const TEMPLATE_ID = 'd68fbcf799be4183a29b8a712626db67';

  function fetchWriteBody() {
    let type = mdManager.getPageType() === 'pointofinterestpage' ? 'company' : mdManager.getPageType();
    return {
      assetReference: {
        brand: brand === 'food' ? 'FOOD' : 'COOK',
        type : type,
        id: queryState.getStreamId(),
      },
      timestamp: new Date().toISOString(),
      userReference: {
        type: 'Gigya',
        id: userInterface.getUserId(),
      },
      useriq: {
        identities: [{
          type: 'Gigya',
          id: userInterface.getUserId(),
        }],
        context: {
          device_type: device.isMobile ? 'mobile' : 'desktop',                  //mobile-or-desktop
          platform: device.isMobile ? 'mobile' : 'web',                         //mobile / web / unknown
          application: brand === 'food' ? 'Food Network' : 'Cooking Channel',   //Food Network / Cooking Channel
          brand: brand === 'food' ? 'Food Network' : 'COOK',                    //Food Network / COOK
        }
      }
    };
  }

  // constructor
  function Network(endpt, queryState) {
    debug.log('constructor');
    this.USER_CONTENT_ENDPOINT = endpt;
    this.queryState = queryState;
    debug.log('endpoint ' + this.USER_CONTENT_ENDPOINT);
  }

  // get the current page of comments (reviews & replies) for current topic (recipe or point of interest)
  Network.prototype.getComments = async function() {
    let url = this.USER_CONTENT_ENDPOINT + this.queryState.getQuery();
    debug.log(`getComments: ${url}`);
    const resp = await fetch(url);
    if (resp.status >= 200 && resp.status <= 299) {
      return resp.json();
    } else {
      debug.error(`Error in getComments ${url}: ${resp.status} ${resp.statusText}`);
      throw Error(`Error in getComments ${url}: ${resp.status} ${resp.statusText}`);
    }
  };

  Network.prototype.getUserHistory = async function() {
    let url = this.USER_CONTENT_ENDPOINT + this.queryState.getUserQuery();
    debug.log('getUserHistory() ' + url);
    const resp = await fetch(url);
    debug.log('getUserHistory() ' + JSON.stringify(resp));
    if (resp.status >= 200 && resp.status <= 299) {
      return resp.json();
    } else {
      debug.error(`Error in getComments ${url}: ${resp.status} ${resp.statusText}`);
      throw Error(`Error in getComments ${url}: ${resp.status} ${resp.statusText}`);
    }
  };

  Network.prototype.postReaction = async function(reactionId, type) {
    debug.log('Posting reaction ' + queryState.getStreamId() + ' ' + reactionId);
    let sentType, typeValue, typeAction;
    let uid, sig, timeSecret;
    let bulk = [];
    if (type === 'thumbsUp') {
      sentType = 'POSITIVE';
      typeValue = 'ThumbsUp';
      typeAction = 'CREATE';
    } else if (type === 'thumbsDown') {
      sentType = 'POSITIVE';
      typeValue = 'ThumbsUp';
      typeAction = 'DELETE';
    } else if (type === 'flagged') {
      sentType = 'FLAGGED';
      typeValue = 'Flagged';
      typeAction = 'CREATE';
    }

    let reaction = {
      ...fetchWriteBody(reactionId),
      id: reactionId,
      type: 'reaction',
      channel: 'Reviews',
      reactionTarget: {
        id: reactionId,
        type: 'comment' // e.g. another comment
      },
      sentimentType: sentType, // POSITIVE, NEGATIVE, FLAGGED
      value: typeValue, // ThumbsUp, ThumbsDown, Flagged
      action: typeAction, // create, update, delete
    };

    try {
      uid = '';
      sig = '';
      timeSecret = '';
      reaction.userReference.name = userInterface.getUserName();
      reaction.userReference.imageUrl = userInterface.getUserAvatar();
      bulk.push(reaction);
      let response = await postAction('comment', uid, sig, timeSecret, bulk, this.USER_CONTENT_ENDPOINT);
      return {status: 'success', message: response.data};
    } catch (err) {
      return {status: 'error', message: err,};
    }
  };

  Network.prototype.postDeleteComment = async function(commentText, id, type, rootId, parentId, timestamp) {
    debug.log('post delete for comment ' + queryState.getStreamId() + ' ' + id);
    let bulk = [];
    let uid, sig, timeSecret;
    let commentBody = {
      ...fetchWriteBody(),
      id: id,
      type: 'comment',
      action: 'DELETE',
      text: commentText,
      language: 'english',
      channel: 'Reviews',
      timestamp: timestamp
    };

    if (type === 'reply') {
      commentBody.rootCommentId = rootId;
      commentBody.parentId = parentId;
    }

    try {
      uid = '';
      sig = '';
      timeSecret = '';
      commentBody.userReference.name = userInterface.getUserName();
      commentBody.userReference.imageUrl = userInterface.getUserAvatar();
      bulk.push(commentBody);
      let response = await postAction('comment', uid, sig, timeSecret, bulk, this.USER_CONTENT_ENDPOINT);
      return {status: 'success', message: response.data};
    } catch (err) {
      return {status: 'error', message: err,};
    }
  };

  Network.prototype.postComment = async function(commentText, type, rootId, parentId, rating) {
    debug.log('post for comment ' + queryState.getStreamId() + ' ' + commentText);
    let bulk = [];
    let uid, sig, timeSecret, ratingBody;
    let commentBody = {
      ...fetchWriteBody(),
      type: 'comment',
      action: 'CREATE',
      text: commentText,
      language: 'english',
      channel: 'Reviews',
    };

    if (type === 'review' && rating > 0) {
      ratingBody = {
        ...fetchWriteBody(),
        value: parseInt(rating),
        action: 'CREATE',
        type: 'rating',
        templateFieldName: TEMPLATE_FIELD_NAME,
        templateId: TEMPLATE_ID, //static id attached to overall
      };
    } else if (type === 'reply') {
      commentBody.rootCommentId = rootId;
      commentBody.parentId = parentId;
    }

    try {
      commentBody.userReference.name = userInterface.getUserName();
      commentBody.userReference.imageUrl = userInterface.getUserAvatar();
      if (type === 'review' && rating > 0) {
        ratingBody.userReference.name = userInterface.getUserName();
        ratingBody.userReference.imageUrl = userInterface.getUserAvatar();
        bulk.push(ratingBody);
      }
      uid = '';
      sig = '';
      timeSecret = '';
      bulk.push(commentBody);
      debug.log('posting...' + JSON.stringify(commentBody));
      let response = await postAction('comment', uid, sig, timeSecret, bulk, this.USER_CONTENT_ENDPOINT);
      return {status: 'success', message: response.data, type: type, parentId: parentId};
    } catch (err) {
      return {status: 'error', message: err, type: type, parentId: parentId};
    }
  };

  async function postAction(type, UID, UIDSig, timestamp, contents, endPoint) {
    debug.log('posting to proxy');
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'text/plain');
    if (SNI.Config.useDaltonLogin) {
      myHeaders.append('Authorization', userInterface.getUserToken());
    }
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(contents),
      redirect: 'follow'
    };
    let querypath = endPoint + '/' + queryState.getQueryPath();
    debug.log('posting: ' + querypath);
    return await fetch(querypath, requestOptions)
      .then(response => response.json())
      .then(result => { return result; })
      .catch(error => { return error; });
  }

  Network.prototype.getSummary = async function() {
    let url = this.USER_CONTENT_ENDPOINT + this.queryState.getQuery();
    debug.log(`getSummary: ${url}`);
    const resp = await fetch(url);
    if (resp.status >= 200 && resp.status <= 299) {
      return resp.json();
    } else {
      debug.error(`Error in getSummary ${url}: ${resp.status} ${resp.statusText}`);
      throw Error(`Error in getSummary ${url}: ${resp.status} ${resp.statusText}`);
    }
  };

  // create an instance of the network service
  function create(endpt, queryState) {
    debug.log('create instance');
    return new Network(endpt, queryState);
  }

  return {
    create
  };
});
