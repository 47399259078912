/* eslint-disable */
import Kefir from 'kefir';
/* eslint-enable */
import { pauseAllVideos } from '../libs/player';

SNI.Application.addBehavior('invoke-inline-video', context => {
  const el = context.getElement();
  const $videoButton = $(el).find('.m-MediaBlock__VideoButton');
  const $leadContainer = $(el).find('.o-RecipeLead');
  const device = context.getService('device-type');
  const kdPlayer = context.getService('kd-player');
  const debug = context
    .getService('logger')
    .create('behavior.invoke-inline-video');
  let videoLoaded = false;
  let videoShowing = false;
  let $videoPlaylist;
  let isMPX = false;

  // Note that the toggleVideo(true) doesn't run for gvp as it's not needed
  function toggleVideo(show) {
    // Needs these attributes so it plays inline for mobile browsers
    const $video = $(el).find('video');
    debug.log('Inline video is: ', $video);
    $video.attr('playsinline', '');
    $video.attr('webkit-playsinline', '');

    // Show the video upon click
    if (show) {
      debug.log('Showing video');
      const $oRecipe = $('.o-Recipe');
      const $recipelead = $('.recipe-lead');
      const $mediaBlock = $(el).find('.o-RecipeLead__m-RecipeMedia');

      const imageHeight = $mediaBlock[0].getBoundingClientRect().height;
      const videoHeight = $videoPlaylist[0].getBoundingClientRect().height;
      let heightDifference = videoHeight > imageHeight ? videoHeight - imageHeight : imageHeight - videoHeight;
      heightDifference = device.isMobile ? heightDifference - 30 : heightDifference;
      debug.log('videoHeight', videoHeight);
      debug.log('imageHeight', imageHeight);
      debug.log('heightDifference', heightDifference);

      // Translates are used here over animating height so we don't trigger a layout change
      $leadContainer.addClass('o-RecipeLead--VideoShowing');
      $recipelead.css({ transform: 'translateY(' + heightDifference + 'px)' });
      $oRecipe.css({ transform: 'translateY(-' + heightDifference + 'px)' });

      setTimeout(() => {
        $recipelead.css({ transform: 'translateY(0px)', transition: 'transform 0.5s' });
        $oRecipe.css({ transform: 'translateY(0px)', transition: 'transform 0.5s' });
        setTimeout(() => {
          // The transforms are then removed, otherwise sticky video won't work
          $recipelead.css({ transform: 'none', transition: 'none' });
          $oRecipe.css({ transform: 'none', transition: 'none' });
          // This adjusts the placeholder image so it's the right size when video is fixed
          $leadContainer.addClass('o-RecipeLead--VideoAnimationComplete');
        }, 400);
      }, 0);

      // Handle playing/resuming the video
      if (!videoLoaded) {
        debug.log('First time inline video shown, trigger message to play video');
        videoLoaded = true;
        context.broadcast('invoke-inline-video-playlist.play');
      } else {
        debug.log('Reopened inline video, trigger message to resume playing');
        context.broadcast('invoke-inline-video-playlist.resume');
      }

      // Tells affix.js the video has been opened, so it can hide the fixed navigation on mobile
      context.broadcast('invoke-inline-video-playlist.video-opened');

      // If the video has taken more than 5 seconds to start, hide the loader and try to trigger it
      setTimeout(() => {
        debug.log('Dotloader/video play trigger back up fired');
        removeDotLoader(true);
      }, 5000);

    } else {
      debug.log('Hiding video');
      context.broadcast('affix.pause-video');

      // Emit difference message based on whether the video is current fixed
      if ($leadContainer.hasClass('o-RecipeLead--FixedVideoActive')) {
        context.broadcast('invoke-inline-video-playlist.video-closed-while-fixed');
      } else {
        // Reset this so that the video image can be clicked to show the video again
        videoShowing = false;
        context.broadcast('invoke-inline-video-playlist.video-closed');
      }
    }
  }

  // Does a variety of things to prepare the recipe lead and video for usage
  function prepareRecipeLead() {
    debug.log('Preparing video');

    $videoPlaylist = $(el).find('.o-VideoPlaylist');
    let $videoDragContainer = $(el).find('.o-VideoPlaylist__VideoDragContainer');
    let $videoContainer = $(el).find('.o-VideoPlaylist__VideoContainer');

    debug.log('$videoDragContainer', $videoDragContainer);

    // If tablet, add class to make the drag tab always appear
    if (device.isTablet) {
      debug.log('Device is tablet, always show drag tab');
      $videoDragContainer.addClass('o-VideoPlaylist__VideoDragContainer--Tablet');
    }

    // Set up click listener for close button on sticky video
    $videoContainer.find('.o-VideoPlaylist__a-Button--Close').on('click', function(e) {
      e.preventDefault();
      toggleVideo(false);
    });
  }

  // Set up click listener for media block to show the video
  function bindVideoButtonClickForMPX() {
    $videoButton.on('click', function(event) {
      debug.log('Click video tile image');
      debug.log('videoShowing status: ', videoShowing);

      // Only bind to event if there is a video
      if ($leadContainer.hasClass('o-RecipeLead--HasVideo') && !videoShowing) {
        debug.log('Video tile clicked, proceed');
        videoShowing = true;
        toggleVideo(true);
      }
    });
    // After the above function is bound to the video button, add class to stop loading animation in watch button and let you click the button and also to reset the video player width/height dimensions to fit the container again (its width and height are set explicitly at first with CSS so the correctly sized placeholder image is loaded by the player)
    $leadContainer.addClass('o-RecipeLead--VideoButtonBound');
  }

  // Removes the dot loader overlay from the video, and also can attempt to trigger the video to play
  function removeDotLoader(triggerVideo = false) {
    const $dotLoader = $(el).find('.a-DotLoader--Loading');
    const callSource = triggerVideo ? 'Backup Function' : 'on-media-load-start message ';

    debug.log('removeDotLoader fired by:', callSource);

    // If the dotloader is still showing, hide it and try to trigger the video
    if ($dotLoader.length > 0) {
      debug.log('Dotloader overlay still showing, remove it');
      $dotLoader.removeClass('a-DotLoader--Loading');
      // Timeout set based on transition time for opacity so that can complete before adding display: none to stop the animation
      setTimeout(() => {
        $dotLoader.addClass('a-DotLoader--Complete');
      }, 350);
      // Makes it so img.preview, .customPlayButton no longer have opacity 0
      if ($videoPlaylist.length > 0) {
        $videoPlaylist.addClass('o-VideoPlaylist--Loaded');
      }

      // Attempts to trigger the video if it hasn't started playing already
      if (triggerVideo) {
        debug.log('Video not playing, attempt to trigger it');
        // This may not work in Safari or on mobile due to restrictions on trigger videos in setTimeouts, but the user can at least click the overlay
        $('.recipe-lead .customPlayButton').trigger('click');
      }
    } else {
      debug.log('Dotloader overlay no longer showing, do nothing');
    }
  }

  return {
    init: function() {
      prepareRecipeLead();
    },
    onmessage: {
      // Needed as this is now controlled by the video-playlist component
      'mpx-player-init': () => {
        isMPX = true;
        // This is only needed for COOKXP-1655. Remove once SEO AB tests are done
        if ($('.container-site').length > 0 && $('.container-site').hasClass('variant--H1Update') && $('.o-Attribution__a-Source').length > 0) {
          debug.log('Recipe has adjusted H1 variant and an attribution source');
          $('.container-site').addClass('container-site--adjustAttributionSource');
          if (device.isMobile) {
            $('.o-Attribution__m-MediaWrap').detach().prependTo('.o-Attribution__m-TextWrap');
            $('.o-Attribution__a-Source').detach().prependTo('.o-Attribution__m-Author');
          }
        } else {
          debug.log('Recipe does not have adjusted H1 variant and an attribution source');
        }
      },
      // Pauses video when the fixed video is closed
      'affix.pause-video': () => {
        if (isMPX) {
          pauseAllVideos();
        } else {
          kdPlayer.pauseAllKDPVideos();
        }
      },
      // When the user clicks the video promo component further down the page
      'launch-lead-video': () => {
        if (isMPX) {
          if (!videoShowing) {
            debug.log('launch-lead-video: Open and trigger video');
            // Works for kdp somehow, but adds multiple vids because videoshowing isn't working
            $videoButton.trigger('click');
          } else {
            debug.log('launch-lead-video: Resume video');
            // Need to set up version for kdp
            context.broadcast('invoke-inline-video-playlist.resume');
          }
        } else {
          const $gvpVideoButton = $('.kdp-poster__button');
          const $gvpPlayerElement = $('.o-RecipeLead .kdp .player');
          const playerId = $gvpPlayerElement.attr('data-player-id');
          if (playerId) {
            context.broadcast('invoke-inline-video.kdp', { playerId, $videoButtonPoster: $gvpVideoButton });
          }
        }
      },
      // When the video actually starts playing, hide the Loading overlay/animation
      'on-media-load-start': () => {
        debug.log('on-media-load-start message received, remove the dotLoader');
        removeDotLoader();
      },
      // When the player is loaded, attach the click event handler to VideoButton
      'on-player-loaded': () => {
        debug.log('on-player-loaded message received');
        bindVideoButtonClickForMPX();
      }
    },
  };
});
