SNI.Application.addBehavior('lead-video-launcher', (context) => {
  const debug = context
    .getService('logger')
    .create('behavior.lead-video-launcher');
  const device = context.getService('device-type');
  const el = context.getElement();

  function onclick(evt, elt, eltType) {
    let VIDEO_SCROLL_OFFSET_TOP;
    if ($('[data-video-scroll-anchor]').length > 0) {
      debug.log('Original anchor offset', $('[data-video-scroll-anchor]').offset().top);
      // The 100 and 150 numbers add some spacing above so it doesn't scroll directly to where the video is
      VIDEO_SCROLL_OFFSET_TOP = $('[data-video-scroll-anchor]').offset().top - (device.isMobile ? 100 : 150);
    } else {
      VIDEO_SCROLL_OFFSET_TOP = 0;
    }
    debug.log('VIDEO_SCROLL_OFFSET_TOP ' + VIDEO_SCROLL_OFFSET_TOP);

    switch (eltType) {
      case 'launch-lead-video':
        debug.log('Clicked, begin scrolling');
        evt.preventDefault();
        $('html, body').animate({ scrollTop: VIDEO_SCROLL_OFFSET_TOP }, 600);
        debug.log('Message sent: launch-lead-video');
        context.broadcast('launch-lead-video');
        break;
    }
  }

  return {
    onclick,
    messages: ['invoke-inline-video-playlist.video-failed'],
    onmessage: {
      'invoke-inline-video-playlist.video-failed': () => {
        const $el = $(el);
        debug.log('Video failed to load, hide parts of launch-lead-video');
        if ($el.find('.m-MediaBlock__a-Cta').length > 0) {
          $el.addClass('o-VideoPromo--HideAll');
        } else {
          $el.addClass('o-VideoPromo--HideImage');
        }
      }
    }
  };
});
