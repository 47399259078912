SNI.Application.addBehavior('play-mobile-video-inline', context => {
  const debug = context.getService('logger').create('behavior.play-mobile-video-inline');
  const defaults = { playInline: true };
  const config = Object.assign({}, defaults, context.getConfig());
  const { isMobile } = context.getService('device-type');

  if (!isMobile) return {};

  // once player is loaded, add the playsinline attribute to the video elt so that iPhone videos start playing in-line
  // https://webkit.org/blog/6784/new-video-policies-for-ios/
  // note: OnPlayerLoaded fires too soon (video element not always present yet), using OnReleaseSelected
  const setAtt = containerId => function() {
    debug.log(`setting play-inline attribute for ${containerId}`);
    $('#' + containerId)
      .find('video')
      .attr('playsinline', '');
    SNI.Video.Events.unsubscribe('OnReleaseSelected', this, containerId);
  };

  function prepare() {
    let containerId = config.player.containerId;
    containerId
      ? SNI.Video.Events.subscribe('OnReleaseSelected', setAtt(containerId), containerId)
      : debug.error('config.player.containerId not set, unable to configure play-inline');
  }

  return {
    config,

    init() {
      debug.log('init');

      if (config.playInline && config.videoSource !== 'GVP') {
        prepare();
      }
    }
  };
});
