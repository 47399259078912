let adCount = 1;

SNI.Application.addModule('dynamic-bigbox', function(context) {
  const ads = context.getService('ads');

  return {
    init: () => {

      adCount++;
      let attrs = { 'id': `dfp_bigbox_${adCount}` };

      // allow config'd attributes override (used for Chicory on mobile recipe)
      let config = context.getConfig();
      if (config?.attrs)
        Object.assign(attrs, config.attrs);

      let adContainer = document.createElement('div');

      $(adContainer).attr(attrs);

      context.getElement().appendChild(adContainer);

      ads.lazyLoadAd({ id: adContainer.id, type: 'dfp_bigbox' });
    }
  };
});
