SNI.Application.addService('pinterest-button', application => {

  let debug = application.getService('logger').create('svc.social-pinterest');
  let check = application.getService('check').new(debug);
  let deviceType = application.getService('device-type');
  let templates = application.getService('template');
  let device = application.getService('device-type');
  let pinScriptReady;
  let defaults = {
    pageURL: window.location.href,
    description: $('meta[property="og:description"]').attr('content'),
    addWhere: 'append',
    position: 'top left',
    imageURL: false,
    insertToChild: false,
    useNewLogo: false,
    doNotEncode: false,
    img: '//assets.pinterest.com/images/pidgets/pinit_fg_en_rect_gray_28.png',
    pinRound: false
  };

  if (!check.exists('PinUtils')) {
    debug.log('Service loading Pinit js');
    // TODO: Move to separate service across all Pinterest functionality
    pinScriptReady = $.getScript('//assets.pinterest.com/js/pinit.js')
      .done(function(script, textStatus) {
        debug.log('Pinit js loaded! It was a ' + textStatus);
      })
      .fail(function() {
        debug.log('Pinit js error!');
      });
  } else {
    pinScriptReady = $.Deferred();
    pinScriptReady.resolve();
  }

  function skipEncoding(url, skip) {
    return skip ? url : encodeURIComponent(url);
  }

  function setUpPinData(config, data) {
    let imageURL = (data && data.hasOwnProperty('imageURL') && data.imageURL) || (config && config.hasOwnProperty('imageURL') && config.imageURL);

    let media, url, description;
    let skip = config && config.doNotEncode;

    // When calling the Pinterest Build function, we should skip encoding since Pinterest will encode on their side
    media = skipEncoding(imageURL, skip);
    url = skipEncoding(config.pageURL, skip);
    description = skipEncoding(config.description, skip);

    return {
      media,
      url,
      description,
      img: config.img,
      useNewLogo: config.useNewLogo,
      pinRound: config.pinRound,
      position: config.position
    };
  }

  function setUpConfig(itemConfig) {
    return Object.assign({}, defaults, itemConfig);
  }

  function createPin(options) {
    // itemConfig is s
    let {$element, itemConfig, data} = options;
    let config = setUpConfig(itemConfig);
    let $slide;
    let pinData;
    let proceed = data.hasOwnProperty('proceed') ? data.proceed : true;

    if (proceed && $element) {
      pinData = setUpPinData(config, data);
      debug.log('SKIP encoding for now?', 'config:', pinData);
      $slide = data.element ? $element.find(data.element).find(config.insertToChild) : $element.find(config.insertToChild);

      // ----------------------------------------------------------
      if ($slide.length > 0 || deviceType.isMobile) {
        debug.log('Found a slide');
        addButton({$slide, pinData, config});
      } else {
        debug.log('Need to observe parent for changes');
        let targetNode = $element[0];
        let obsConfig = {
          childList: true,
          subtree: true
        };

        let obsCallback = function(mutations) {
          let $updatedSlide = data.element ? $element.find(data.element).find(config.insertToChild) : $element.find(config.insertToChild);
          if ($updatedSlide.length > 0) {
            addButton({$slide: $updatedSlide, pinData, config});
            this.disconnect();
          }
        };

        let observer = new MutationObserver(obsCallback);
        if (targetNode) {
          observer.observe(targetNode, obsConfig);
        } else {
          debug.warn('Unable to observe targetNode: Cannot add pin button.', targetNode);
        }
      }
    } else {
      debug.log('Proceed is false or no slide element provided');
      return false;
    }
  }
  function renderButton(config) {
    return templates.socialPinterest(config);
  }

  function addButton(options) {
    let {$slide, pinData, config} = options;
    let pinitElement = renderButton(pinData);
    $slide.remove('[data-type="pin-element"]');

    /* Add Pinit button(anchor) to the element */
    if ($slide.data('processed') !== true) {
      debug.log('Adding button to', options);
      if (config.addWhere === 'prepend') {
        $slide.prepend(pinitElement);
      } else {
        $slide.append(pinitElement);
      }
      $slide.attr('data-processed', true);
    }

    // Set event handlers for the newly created button
    // https://github.com/pinterest/widgets/blob/master/pinit_main.js
    if (check.exists('PinUtils')) {
      application.getGlobal('PinUtils').build();
    }
  }

  function setPinButton(options) {
    if (!(device.isIE || device.isEdge)) {
      pinScriptReady.then(function() {
        createPin(options);
      });
    }
  }

  function pinOne(options) {
    debug.log('Pin One ', options);
    pinScriptReady.then(function(){
      application.getGlobal('PinUtils').pinOne(options);
    });
  }

  return {
    setPinButton,
    pinOne,
    createPin,
    renderButton,
    setUpPinData,
    setUpConfig,
    addButton
  };
});
