/*
 * User Template Service: mark up user comments (reviews & replies) for display
 */

SNI.Application.addService('user/templates', (application) => {

  const queryState = application.getService('user/comments-query-state');    // only using static members
  const debug = application.getService('logger').create('service.user/templates');
  const utils = application.getService('utility');

  let userId;

  // mark up 5 ratings stars as on/off depending on rating passed in
  function fmtRatingStars(n) {
    const STARS_TOGGLE = (new Array(5)).fill('empty');
    let i = Math.floor(n);
    let h = n - i;

    function fmtStar(str, v) {
      return str + `<div class="rating-star rating-star-${v}"></div>`;
    }
    let starval = STARS_TOGGLE.slice().fill('full',0,i);
    if (i < 5) starval[i] = h > 0.0 ? (h > 0.5 ? 'full' : 'half') : 'empty';
    return `<div class="rating-stars" title="${n} of 5 stars">
            <span class="sr-only">rated ${n} of 5 stars</span>
            ${starval.reduce(fmtStar, '')}
            </div>`;
  }

  // mark up a single comment (user review or reply); recursively format any replies
  function fmtComment(c,h) {
    if (c.action && c.action === 'DELETE' || c.status && c.status === 'REJECTED') return '';

    const escapedUserName = utils.escapeHtml(c.userName);
    let commentTime = c.new && c.new !== '' ? c.new : fmtDate(c.originalTime, c.daysAgo);
    let isReply = c.committedReply ? 'committed-reply' : '';
    let voteId;
    if (h && h.thumbsUp) {
      let reaction = h.thumbsUp.find(react => react.commentId === c.commentId);
      if (reaction) voteId = reaction.reactionId;
    }

    return `<div class="comment ${isReply}" data-level="${c.level}" data-comment-id="${c.commentId}" data-user-id="${c.userId}" original-time="${c.originalTime}">
      <div class="comment-photo" style=""><img class="comment-img" alt="${escapedUserName}" src="${c.userImageUrl}" width="37" height="37" />
      ${c.loginProviderImageUrl ? `<img class="comment-login-img" alt="${c.loginProviderName ? 'Logged in using ' + c.loginProviderName : ''}" src="${c.loginProviderImageUrl}"  width="14" height="14" />` : ''}
      </div>
      <div class="comment-wrapper">
        <div class="comment-header">
          <span class="comment-username">${escapedUserName}</span><span class="comment-time" title="${c.postDate}">${commentTime}</span>
        </div>
        ${c.rating ? `<div class="comment-rating">${fmtRatingStars(c.rating)}</div>` : ''}
        ${c.new && c.new !== '' ? `<div class="comment-status comment-status-info ${c.type ? 'pending-' + c.type : ''}">Your comment is awaiting moderation</div>` : ''}
        <div class="comment-body">
          ${c.commentText}
        </div>
        <div class="comment-footer">
          <div class="comment-footer-left">
            <button class="comment-link comment-upvote ${ voteId ? 'successful-upvote' : ''}" data-type="comment-upvote" tabindex="0" aria-label="${voteId ? 'undo ' : ''}upvote">${ c.upVotes === 0 ? '' : '+'}${c.upVotes}</button>
            ${c.new && c.new !== '' || c.level && c.level >= 2 ? '' : '<button class="comment-link comment-reply" data-type="comment-reply-toggle" tabindex="0">Comment</button>'}
            <button class="comment-link comment-delete" data-type="comment-delete" tabindex="0" ${userId !== c.userId ? 'hidden' : ''} aria-label="delete comment"></button>
          </div>
          <div class="comment-footer-right">
            <button class="comment-link comment-flag ${h && h.flagged.includes(c.commentId) ? 'successful-flag' : ''}" data-type="comment-flag" tabindex="0" aria-label="flag comment"></button>
          </div>
        </div>
        ${c.replies && c.replies.length ? fmtComments(c.replies, {claz: 'comment-replies', mode: 'wrapped'}, h) : ''}
      </div>
    </div>`;
  }

  function fmtDate(timeStamp, daysAgo) {
    let fmtTime = '';

    if (daysAgo >= 1 && daysAgo <= 30) {
      let dayTxt = daysAgo > 1 ? 'days' : 'day';
      fmtTime = `${daysAgo} ${dayTxt} ago`;
    } else if (daysAgo > 30){
      let actionDate = new Date(timeStamp);
      let calMonth = ['January','February','March','April','May','June','July','August','September','October','November','December'];

      fmtTime = `${calMonth[actionDate.getMonth()]} ${actionDate.getDate()}, ${actionDate.getFullYear()}`;
    } else if (daysAgo < 1){
      const MSEC_PER_HOUR = 60 * 60 * 1000;
      let hourDiff = Math.round((Date.now() - Date.parse(timeStamp))/ MSEC_PER_HOUR);

      if (hourDiff < 1) {
        fmtTime = 'just now';
      } else {
        let hourTxt = hourDiff > 1 ? 'hours' : 'hour';
        fmtTime = `${hourDiff} ${hourTxt} ago`;
      }
    }

    return fmtTime;
  }

  function fmtModal(type, id) {
    let header, body, confirm, cancel;

    if (type === 'delete') {
      header = 'Delete review';
      body = 'Are you sure you want to permanently delete your review?';
      confirm = 'Yes';
      cancel = 'No';
    } else if (type === 'flag') {
      header = 'Report review to site owner';
      body = 'Are you sure you want to mark this review as inappropriate?';
      confirm = 'Yes, flag this review';
      cancel = 'Cancel';
    }

    return `
      <div id="comments-modal" role="dialog" tabindex="-1" aria-labelledby="gig-comments-dialog-caption-r-reviews" data-action-type="${type}" target-comment-id="${id}">
        <div class="comments-dialog">
          <div class="comments-dialog-caption-container">
            <div class="comments-dialog-caption" id="comments-dialog-caption-r-reviews">${header}</div>
            <div class="comments-dialog-close" role="button" tabindex="0" data-type="close-modal"></div>
          </div>

          <div class="comments-dialog-body">${body}</div>
          <div class="comments-dialog-buttons">
            <div class="comments-dialog-button comments-dialog-button-ok" role="button" tabindex="0" data-type="confirm-modal">${confirm}</div>
            <div class="comments-dialog-button comments-dialog-button-cancel" role="button" tabindex="0" data-type="close-modal" >${cancel}</div>
          </div>
        </div>
      </div>
    `;
  }

  function fmtUserRating(){
    return `<div class="composebox-ratings" data-module="user/ratings">
                <div class="composebox-rating-title" id="user-stars-group">Your Rating:</div>
                <div class="composebox-rating-value" role="radiogroup" aria-labelledby="user-stars-group" data-dimension="_overall">
                  <div data-value="1" class="composebox-rating-star" role="radio" tabindex="0" data-type="comment-user-rating" aria-label="rate 1 star" aria-checked="false"></div>
                  <div data-value="2" class="composebox-rating-star" role="radio" tabindex="0" data-type="comment-user-rating" aria-label="rate 2 stars" aria-checked="false"></div>
                  <div data-value="3" class="composebox-rating-star" role="radio" tabindex="0" data-type="comment-user-rating" aria-label="rate 3 stars" aria-checked="false"></div>
                  <div data-value="4" class="composebox-rating-star" role="radio" tabindex="0" data-type="comment-user-rating" aria-label="rate 4 stars" aria-checked="false"></div>
                  <div data-value="5" class="composebox-rating-star" role="radio" tabindex="0" data-type="comment-user-rating" aria-label="rate 5 stars" aria-checked="false"></div>
                </div>
              </div>`;
  }

  function fmtCompBoxHeader(format) {
    if (format === 'submitted') {
      return '<div class="selfreview-header">Your Review</div>';
    } else {
      return `<div class="composebox-area">
                <div class="composebox-editor">
                    <textarea class="composebox-textarea" data-type="comment-reviews" placeholder="${format === 'reply' ? 'Leave a comment' : 'Did you make this recipe? Leave a review! (required)'}" aria-label="user comment"></textarea>
                    <p></p>
                </div>
              </div>`;
    }

  }

  function fmtCompBoxContainer(format, user) {
    if (format === 'submitted'){
      return `<div class="selfreview-body-container">
                  <span class="selfreview-field-title">Review:</span>
                  <span class="selfreview-body">${user.commentText}</span>
              </div>`;
    } else {
      return '';
    }
  }

  function fmtCompBoxFooter(format, user){
    let rating = format === 'review' ? fmtUserRating() : '';
    let ratingCount = user && user.rating || 0;
    let cancelBtn = '', btnText = '', btnClass = '', postType = '', submittedRating = '';

    if (format === 'reply'){
      cancelBtn = '<button data-type="comments-cancel-btn" class="composebox-cancel comments-button" tabindex="0">Cancel</button>';
      btnText = 'Comment';
      btnClass = 'comments-is-reply';
      postType = 'comment-post-reply';
    } else if (format === 'review'){
      btnText = 'Review';
      btnClass = 'comments-is-review';
      postType = 'comment-post-review';
    }

    if (format === 'submitted') {
      for (let i =0; i < 5; i++){
        if (i < ratingCount){
          submittedRating = submittedRating + '<div class="selfreview-rating-star selfreview-rating-star-full"></div>';
        } else {
          submittedRating = submittedRating + '<div class="selfreview-rating-star"></div>';
        }
      }

      return `<div class="selfreview-ratings">
                <div class="selfreview-rating-_overall">
                  <div class="comment-rating-title">My rating:</div>
                  <div class="comment-rating-value" title="${ratingCount}">
                      <span class="sr-only">rated ${ratingCount} out of 5 stars</span>
                      ${submittedRating}
                  </div>
                </div>
              </div>`;
    } else {
      return `<div class="composebox-footer">
                ${rating}
                <div class="composebox-footer-right">
                  <button class="composebox-post comments-button" data-type=${postType} tabindex="0">
                    Post
                    <span class=${btnClass}>${btnText}</span>
                  </button>
                  ${cancelBtn}
                </div>
            </div>`;
    }
  }

  function fmtShowCompose() {
    return '<div id="user-review" class="review-show-btn"><button data-type="comment-show-compose" class="review__a-Button">Add Your Review</button></div>';
  }

  function fmtComposeBox(format, userId, user) {
    let header = fmtCompBoxHeader(format);
    let container = fmtCompBoxContainer(format, user);
    let footer = fmtCompBoxFooter(format, user);
    let replyId = '', selectorClass = '';

    if (format === 'reply'){
      replyId = `reply-${userId}`;
      selectorClass = 'reply-compose-box';
    } else {
      replyId = 'user-review';
      selectorClass = 'review-compose-box';
    }

    return `
    <div class="comments-review ${selectorClass}" id="${replyId}">
      <div class="composebox-error"></div>
      <div class="composebox-photo" style="">
        <img class="comment-img" alt="" src="${user.avatar}" aria-hidden="false">
      </div>
      <div class="composebox-data">
        ${header}
        ${container}
        ${footer}
      </div>
    </div>`;
  }

  // format the header to the comments block (sort order drop down control)
  function fmtCommentsHeader(h) {
    let orders = queryState.getSortOrders();

    function fmtSortSelection(name, idx) {
      return `<button class="comments-sort-order-option" data-type="comments-sort-order" data-sort-order="${idx}" role="option" aria-selected="false">${name}</button>`;
    }

    return `<div class="comments-header"><div class="comments-sort-order">
      <button data-type="comments-sort-open" class="comments-sort-toggle" tabindex="0" >Sort by</button>
      <div class="comments-sort-order-select hidden" role="listbox">
        ${orders.map((o, i) => fmtSortSelection(o.dispName, i)).join('')}
      </div>
      </div></div>`;
  }

  // format the footer to the comments block ("Show more" button, which will be shown/hidden as applicable)
  function fmtCommentsFooter() {
    return `<div class="comments-footer">
            <button class="comments-more" tabindex="0" data-type="comments-load-more">Show more reviews</button>
            </div>`;
  }

  // top level of comments block formatter
  function fmtComments(cs, optsin, history) {
    debug.log('fmtComments, comment ct=' + cs.length);
    const defaults = { claz: 'comments', mode: 'init' };
    let opts = Object.assign({}, defaults, optsin);
    if (opts.userId) userId = opts.userId;
    return `${opts.mode === 'init' ? fmtCommentsHeader(history) : ''}
            ${(opts.mode === 'init' || opts.mode === 'wrapped') ? `<div class="${opts.claz}">` : ''}
            ${cs.map(c => fmtComment(c,history)).join('')}
            ${(opts.mode === 'init' || opts.mode === 'wrapped') ? '</div>' : ''}
            ${opts.mode === 'init' ? fmtCommentsFooter() : ''}
            `;
  }

  function fmtTrashCan() {
    return '<button class="comment-link comment-delete" data-type="comment-delete" tabindex="0"></button>';
  }

  // format the ratings/reviews summary: stars & number of reviews
  function fmtSummary(rs) {
    let stars = fmtRatingStars(rs.avgRating.toFixed(1));
    let html = '';
    let txtWrapElt = rs.txtWrapElt || 'h2';

    if (Math.round(rs.allCt) === 0 && rs.mode != 'page-top') {     // eslint-disable-line eqeqeq
      if (rs.mode === 'reviews-top')
        html = `<${txtWrapElt}>Be the first to review</${txtWrapElt}>`;
      // default (not page-top or reviews-top) => no output if no reviews
    } else if (rs.mode === 'compact')
      html = `${stars}<span>${rs.allCt}</span>`;
    else
      html = `${stars}<${txtWrapElt} class="reviews-ct">${rs.allCt} Review${Math.round(rs.allCt) === 1 ? '': 's'}</${txtWrapElt}>`;

    if (rs.link && html.length) html = `<a href="${rs.link}">${html}</a>`;

    debug.log('fmtSummary html: ' + html);

    return html;
  }

  return {
    fmtComments,
    fmtComment,
    fmtComposeBox,
    fmtShowCompose,
    fmtTrashCan,
    fmtModal,
    fmtSummary
  };
});
