SNI.Application.addModule('photo-category', (context) => {

  const device = context.getService('device-type'),
        debug = context.getService('logger').create('module.photo-category');

  let module,
      defaults,
      config;

  defaults = {
    teaser        : '.editorial-promo',
    desktopPromo  : 'one-column',
    mobilePromo   : 'two-column',
    cutoff        : 0,
    filterCtrl    : '.filter-control',
    filterUrl     : [],
    basePath      : null,
    pagination    : '',
    shade         : '.filter-shade',
    launcher      : '.launchme',
    launchImg     : '.rsImg'
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setCategoryConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function cleanArray(dirtyArray) {
    let newArray = [];
    for (let i = 0; i<dirtyArray.length; i++){
      if (dirtyArray[i]){
        newArray.push('/'+dirtyArray[i]);
      }
    }
    return newArray;
  }

  function updatePromo() {
    if (device.isMobile) {
      setTimeout(function waitForTeaser() {
        if ($(config.teaser).length) {
          changeLayout();
        } else if (config.cutoff > 20) {
          debug.log('updatePromo: Teaser not loaded, not altering layout');
        } else {
          config.cutoff++;
          setTimeout(waitForTeaser, 50);
        }
      }, 50);
    }
  }

  function directLauncher() {
    $(config.launcher).on('click', function(e) {
      e.preventDefault();
      window.location = $(config.launchImg).attr('href');
    });
  }

  function changeLayout() {
    $(config.teaser).removeClass(config.desktopPromo).addClass(config.mobilePromo);
  }

  function setInitialFilters() {
    let selectedFacets = device.isMobile ? $('.facet-select').find(':selected') : $('.facetOption.selected');
    if (!$.isEmptyObject(selectedFacets)) {
      selectedFacets.each(function() {
        config.filterUrl[$(this).data('position')] = $(this).data('tagpath');
      });
    }
  }

  function setFilterListeners() {
    if (device.isMobile) {
      $(config.filterCtrl).on('click', function() {
        $(config.shade).slideToggle();
      });
      $('.facet-select').each(function() {
        $(this).change(function() {
          let pagePatt = /\/p\/[0-9]+$/;
          let targetPath = $(this).find(':selected').data('targetpath');
          if (pagePatt.test(targetPath)) {
            config.pagination = pagePatt.exec(targetPath);
            targetPath = targetPath.slice(0, targetPath.search(pagePatt));
          }
          config.basePath = targetPath;
          config.filterUrl[$(this).find(':selected').data('position')] = $(this).find(':selected').data('tagpath');
        });
      });
      $(document).on('click touchstart', '.load-photos', function(e) {
        e.preventDefault();
        debug.log('setFilterListeners: basePath: ', config.basePath);
        if (typeof(config.basePath) !== 'undefined' && config.basePath !== null) {
          config.filterUrl = cleanArray(config.filterUrl);
          window.location = config.basePath + config.filterUrl.join('') + config.pagination;
        }
      });
    } else {
      $('.facetOption').each(function() {
        $(this).on('click', function(e) {
          e.preventDefault();
          let pagePatt = /\/p\/[0-9]+$/;
          let targetPath = $(this).data('targetpath');
          if (pagePatt.test(targetPath)) {
            config.pagination= pagePatt.exec(targetPath);
            targetPath = targetPath.slice(0, targetPath.search(pagePatt));
          }
          config.basePath = targetPath;
          config.filterUrl[$(this).data('position')] = $(this).data('tagpath');
          if (!device.isMobile) {
            $(this).parent('li').parent('#pv-pl-nav-select').parent('.dropdown-menu').siblings('.dropdown-toggle').html($(this).text() + ' <i class="icon icon-angle-down"></i>');
          }
        });
      });

      $('.btn-go').on('click', function(e) {
        e.preventDefault();
        if (typeof(config.basePath) !== 'undefined' && config.basePath !== null) {
          config.filterUrl = cleanArray(config.filterUrl);
          window.location = config.basePath + config.filterUrl.join('') + config.pagination;
        }
      });
    }
  }

  module = {
    init() {
      setCategoryConfig(defaults, context.getConfig());
      updatePromo();
      directLauncher();
      setInitialFilters();
      setFilterListeners();
    },

    updateObject,
    setCategoryConfig
  };

  return module;

});
