SNI.Application.addModule('point-of-interest', function(context) {

  let element             = $(context.getElement()),
      $element            = $(element),
      debug               = context.getService('logger').create('module.pointofinterest');

  let defaults = {
    websiteClass: '.o-PointOfInterest__a-Button--Website'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  return {
    behaviors: ['maps', 'customer-service', 'call-protocol'],

    config,

    init: function() {
      debug.log('this is point of interest');
      this.updateWebProtocol();
      this.updateButtonLayout();
    },

    updateWebProtocol: function() {
      $element.find(config.websiteClass).each(function(){

        let $this = $(this),
            webUrl = $this.attr('href'),
            newWebUrl;
        if ( webUrl.indexOf('http://http://') !== -1) {
          newWebUrl = webUrl.replace('http://http://', 'http://');
          $this.attr('href', newWebUrl);
        }

      });
    },

    updateButtonLayout: function() {
      $element.find('[data-button]').each(function(){
        let $this = $(this),
            buttonNumber = $this.children('a').length;

        if (buttonNumber < 3 && buttonNumber > 0) {
          $this.attr('class', 'l-Columns l-Columns--'+buttonNumber+'up');
        }

      });
    }
  };

});
