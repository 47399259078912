SNI.Application.addModule('header', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let defaults = {
    showClass:      'show-nav',
    hideClass:      'hide-nav'
  };
  let config = Object.assign({}, defaults, context.getConfig());
  let debug = context.getService('logger').create('module.header');
  let behaviors = ['hover-intent'];
  const cookie = context.getService('cookie');
  const isEditor = cookie.get('cq-authoring-mode');
  const headerNavTarget = 'o-Header__a-NavLink';

  // add IDSP behaviors if Dalton enabled
  if (SNI.Config.useDaltonLogin) {
    if (SNI.Config.brand === 'food')
      behaviors.push('dalton/nav-profile-fn');
    else if (SNI.Config.brand === 'cook')
      behaviors.push('dalton/nav-profile-cc');
    behaviors.push('dalton/login-logout');
    debug.log('Dalton identity service enabled. Adding behaviors: ' + behaviors.join(', '));
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  return {
    behaviors,
    config,
    onclick: function(event) {
      if (typeof event === 'undefined') return;
      let target = event.target;

      if (target && target.classList?.contains(headerNavTarget)) {
        let dataTarget = target.dataset?.target;
        if (dataTarget) {
          if (isEditor) dataTarget = `${dataTarget}.html?wcmmode=disabled`;
          window.location.href = dataTarget;
        }
      }
    },
  };

});
