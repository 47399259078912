SNI.Application.addModule('recipe-playlist', context => {

  const deviceType = context.getService('device-type');
  const debug = context.getService('logger').create('module.recipe-playlist');

  // make a click on the mobile playlist media strip act like a click on the playlist caorusel:
  function handleClick(evt, elt, etype) {
    if (deviceType.isMobile) {
      debug.log(`clicked on a ${etype}`);
      switch (etype) {
        case 'slide':
          $(document.body).animate({scrollTop: $('.lead-sections').offset().top-80}, 100);
          context.broadcast('carousel.slide.clicked', {
            slide: elt
          });
      }
    }
  }

  return {

    behaviors: ['lightbox', 'toggle-on-message'],

    onclick: handleClick

  };

});
