SNI.Application.addModule('content-feed', (context) => {

  // Private
  let $element = $(context.getElement());
  let debug = context.getService('logger').create('module.content-feed');

  // public

  let module = {

    behaviors: ['load-more', 'lazy-load'],

    init: () => {
      debug.log('Content Feed Module: Init Start');
    },

    messages: ['lazy-load.image-loaded'],
    onmessage: {
      'lazy-load.image-loaded': (name) => {
        debug.log('lazy-load message received:', name);
        $element.addClass('o-ContentFeed--Loaded');
      },
    }

  };

  return module;

});
