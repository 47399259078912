/*
 * Chitter Adapter Service: convert chitter comments response to data schema used to format HTML
 *   factory pattern to allow injection instance of Query State Service to maintain current pagination
 */

SNI.Application.addService('user/chitter-adapter', (application) => {

  const debug = application.getService('logger').create('service.user/chitter-adapter');
  const isCooking = SNI.Config.brand === 'cook';
  const defaultUsername = isCooking ? 'Cooking Channel User' : 'Food Network User';

  // small images identifying social networks when used for log in
  const loginProviderAvatars = new Map([
    ['yahoo', '/etc/clientlibs/assets/images/common/YahooUser.png'],
    ['facebook', '/etc/clientlibs/assets/images/common/FacebookUser.png'],
    ['googleplus', '/etc/clientlibs/assets/images/common/GooglePlusUser.png'],
    ['twitter', '/etc/clientlibs/assets/images/common/TwitterUser.png'],
    ['amazon', '/etc/clientlibs/assets/images/common/AmazonUser.png']]);
  const DEFAULT_USER_PHOTO_URL = '/etc/clientlibs/assets/images/common/Avatar_empty.png';

  const MSEC_PER_DAY = 24 * 60 * 60 * 1000;

  // constructor, allowing service to be aware of queryState to keep track of pagination
  function ChitterAdapter(queryState) {
    debug.log('constructor');
    this.queryState = queryState;
  }

  // convert Chitter feed comment to something that's usable by our formatter
  ChitterAdapter.prototype.processComment = function(c) {

    // can be used for 'flagged' reactions if needed
    function getReactions(rxntype) {
      let ct = 0;
      if (c.reactions && c.reactions.length > 0) {
        c.reactions.map(reaction => {
          if (reaction.value && reaction.value === rxntype) ct++;
        });
      }
      return ct;
    }

    // my.foodnetwork.com domain no longer works; in a func to allow other validations as needed
    function ckUserAvatar(imgUrl) {
      return ! /\/\/my.foodnetwork.com\//.test(imgUrl) ;
    }

    debug.log('processComment, id=' + c.id);

    return {
      commentId: c.id,
      action: c.action,
      status: c.moderationStatus,
      level: c.level,
      userImageUrl: c.userReference.imageUrl && ckUserAvatar(c.userReference.imageUrl) ? c.userReference.imageUrl.replace('http://', '//') : DEFAULT_USER_PHOTO_URL,
      loginProviderName: c.userReference.loginProvider? c.userReference.loginProvider.toLowerCase() : null,
      loginProviderImageUrl: c.userReference.loginProvider ? loginProviderAvatars.get(c.userReference.loginProvider.toLowerCase()) : null,
      userName: c.userReference.name ? c.userReference.name : defaultUsername,
      userId:  c.userReference.id,
      daysAgo: Math.round((Date.now() - Date.parse(c.timestamp))/ MSEC_PER_DAY),
      postDate: (new Date(Date.parse(c.timestamp))).toDateString(),
      originalTime: c.timestamp,
      rating: c.authorAssetRatings && c.authorAssetRatings[0] ? c.authorAssetRatings[0].value : 0,
      commentText: c.text,
      upVotes: getReactions('ThumbsUp'),
      replies: c.nestedReplies && c.nestedReplies.length > 0 ? this.processComments(c.nestedReplies, c.level + 1) : []
    };
  };

  ChitterAdapter.prototype.processUserHistory = function(c) {
    return {
      thumbsUp: c.userData.thumbsUp,
      flagged: c.userData.flagged,
      review: c.userData.review[0]
    };
  };


  // process all comments in response
  ChitterAdapter.prototype.processComments = function(cs, level = 0) {
    debug.log('processComments, comment ct=' + cs.length);
    return cs.map(c => Object.assign(c, {level: level})).map(c => this.processComment(c));
  };

  // process review summary data
  ChitterAdapter.prototype.processSummary = function(s) {
    debug.log('processSummary');
    let rs = s.ratingsSummaries.filter(rs => rs.templateFieldName === 'overall')[0];
    if (!rs) rs = { count: 0, averageValue: 0 };
    return {
      allCt: rs.count,
      reviewCt: rs.count,
      replyCt: s.totalComments,
      avgRating: rs.averageValue
    };
  };

  // top-level driver for processing the get comments response
  ChitterAdapter.prototype.processResponse = function(resp, type='comments') {
    debug.log('processResponse');
    if (type === 'comments') {
      // is there a next page? if so, what is its starting cursor
      if (resp.pageInfo) this.queryState.setPageInfo(resp.pageInfo);
      return this.processComments(resp.comments);
    } else if (type === 'summary') {
      return this.processSummary(resp);
    }
  };

  ChitterAdapter.prototype.getDefaultUserAvatar = function() {
    return DEFAULT_USER_PHOTO_URL;
  };

  // factory funciton for an instance of the Chitter adapter service; inject queryState to update pagination
  function create(queryState) {
    debug.log('create instance');
    return new ChitterAdapter(queryState);
  }

  return {
    create
  };
});

