SNI.Application.addBehavior('affiliate', context => {

  /**
     *  __   __              ___  ___
     * |__) |__) | \  /  /\   |  |__
     * |    |  \ |  \/  /--\  |  |___
     *
     */

  const affiliateService = context.getService('affiliate');

  /**
     *  __        __          __
     * |__) |  | |__) |    | /  `
     * |    \__/ |__) |___ | \__,
     *
     */

  return {

    messages: ['ajax.areaContentLoaded', 'stream-manager.entryLoaded', 'modal.shown'],

    onmessage(name) {
      switch (name) {
        case 'ajax.areaContentLoaded':
          affiliateService.resetAffiliateSpec();
          break;
        case 'stream-manager.entryLoaded':
          affiliateService.resetAffiliateSpec();
          break;
        case 'modal.shown':
          affiliateService.resetAffiliateSpec();
          break;
      }
    }

  };

});
