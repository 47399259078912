SNI.Application.addModule('how-to', (context) => {
  'use strict';

  let $element   = $(context.getElement()),
      device     = context.getService('device-type'),
      check      = context.getService('check'),
      debug      = context.getService('logger').create('modules.how-to');

  let defaults = {
    materialsList: '.m-RequiredMaterials__m-PromoList',
    materialsCta: '.m-PromoList__a-Cta',
    bannerBody: '.o-Banner__m-Body[style]',
    pageBody: '[data-sni-area=content]'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  //TODO: make this hackery not needed as a part of CCT-351
  function bgHack() {
    if (device.isMobile) { return; } //hack not needed on mobile
    var $src = $(config.bannerBody),
        $targ = $(config.pageBody);

    if ($src.length && $targ.length) {
      $targ.attr('style', $src.attr('style'));
      $src.removeAttr('style');
    }
  }

  return {

    config,

    init: function() {
      debug.log('inited!');

      bgHack();   /* hack to allow variable heights in h1 tag */

      if (check.exists('SniAds')) {
        context.getGlobal('SniAds').appendSlot('final_bigbox', 'dfp_bigbox');
      }
    },

    onclick(event, element, elementType) {
      if (elementType !== '') {
        event.preventDefault();
      }

      var $lead = $element.find('[data-how-to-lead]');

      if (elementType === 'showmore') {
        $lead.find(config.materialsList + ' li:nth-of-type(1n+4)').css('display', 'block');
        $lead.find(config.materialsCta).css('display', 'none');
      }
    }

  };

});
