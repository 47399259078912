SNI.Application.addModule('on-tv-dropdown', (context) => {
//-----------------------------------------------------------
// Private
//-----------------------------------------------------------

  const namespace = 'on-tv-dropdown';

  const debug     = context.getService('logger').create(`module.${namespace}`),
        utility   = context.getService('utility'),
        schedule  = context.getService('schedule'),
        device    = context.getService('device-type'),
        template  = context.getService('template'),
        url       = context.getService('url'),
        ele       = context.getElement();

  let schedulePromise;
  let $module = $(ele);
  let $recipeList = $module.find(`[data-${namespace}-listing]`);
  let $buttonWrap = $module.find(`[data-${namespace}-button-wrap]`);
  let $watchLink = $module.find(`[data-${namespace}-watch-link]`);
  let $scheduleLink = $module.find(`[data-${namespace}-schedule-link]`);


  function toggleMenu(state = !$module.hasClass('is-Open')) {
    $module.toggleClass('is-Open', state);
    if (state) {  // true => is being opened here & now
      utility.bindClickOutside($module, namespace, function() {
        toggleMenu(false);
      });
    } else {
      utility.unbindClickOutside(namespace);
    }
  }

  function getEpData($ep) {
    var $link = $ep.find('[data-show-title]');
    function get(name) {
      let value = $link.attr(name);
      if (!value) {
        debug.error('could not find episode data:', name, $ep);
      }
      return value;
    }
    return {
      isMobile: device.isMobile,
      episodeUrl: get('data-episode-url'),
      episodeTitle: get('data-episode-name'),
      showImage: get('data-show-avator-image'),
      showTitle: get('data-show-title'),
      recipeCount: $ep.find('.m-MediaBlock--recipe').children().length
    };
  }

  function parseData(markup) {
    let recipeSel = '.m-MediaBlock--recipe';
    let $currentEpisode = schedule.findCurrentlyAiring(markup);
    debug.log('currentEpisode', $currentEpisode);
    
    let validData;
    let data;
    let $activeRecipes;
    if ($currentEpisode === null)
      validData = false;
    else {
      $activeRecipes = $currentEpisode.find(recipeSel);
      $activeRecipes.show();
  
      data = getEpData($currentEpisode);
      debug.log('episode data:');
      debug.log(data);
      validData = !!data.episodeTitle && !!data.episodeUrl && !!data.showImage && !!data.showTitle;
    }
    
    return {
      header: validData ? $( template.onTVDropdownHeader(data) ) : '',
      recipes: validData ? $activeRecipes : null
    };
  }

  function init() {
    debug.log('time now: ' + (new Date()).toLocaleTimeString());
    $watchLink
      .html('Watch Live')
      .attr('href', url.watchSite());
    schedulePromise = schedule.getScheduleData().then(function(responseBody, code) {
      let offHours = true;
      if (code === 'success' && responseBody) {
        let parsed = parseData(responseBody);
        if (parsed.header) {
          if (device.isMobile) {
            $buttonWrap.append(parsed.header);
          } else {
            $recipeList.prepend(parsed.header);
          }
          if (parsed.recipes) {
            $recipeList.append(parsed.recipes);
          }
          offHours = false;
        }
      }
      if (offHours) {
        $buttonWrap
          .removeAttr('data-type')
          .removeClass('is-Toggle');
        $buttonWrap.find('a')
          .text('TV Schedule')
          .attr('href', $scheduleLink.attr('href'));
        if (device.isMobile) {
          $module.hide();  
        }
      }
      // don't show off-hours mobile 
      if (!offHours || !device.isMobile) {
        // component has been set up, transition to reveal
        $module.addClass('is-Active');
      }
    });

  }

  return {
    init,
    onclick: function(event, element, elementType) {
      switch (elementType) {
        case 'menu-toggle':
          if ($module.hasClass('is-Open')) {
            toggleMenu(false);
          } else {
            if ($recipeList.children().length > 0 || device.isMobile) {
              toggleMenu();
            } else {
              //force toggling to wait until we have data, which will be inserted by init()
              schedulePromise.then(function(responseBody, code) {
                if (code === 'success' && responseBody) {
                  toggleMenu(true);
                }
              });
            }
          }
          break;
      }
    }
  };
});
