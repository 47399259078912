SNI.Application.addModule('class-schedule-card', (context) => {
  let defaults = {
    upcomingClass: '.upcoming-class',
    classList:     '.l-list',
    classBlock:    '.m-ScheduleCard__m-MediaBlock',
    isLiveClass:   '.is-Live'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  const $element = $(context.getElement()),
        $classList = $element.find(config.classList),
        $classBlock = $classList.find(config.classBlock);

  let listUpcomingClasses = () => {
    $classBlock.each((index, el) => {
      const $el = $(el);
      if ($classList.find('.active').length < config.maxResults) {
        const classStart = new Date(Number($el.find(config.upcomingClass).data('start'))),
              classEnd = new Date(Number($el.find(config.upcomingClass).data('end'))),
              currTime = new Date(),
              currGMTTime = currTime.toGMTString(),
              nowTime = Date.parse(new Date(currGMTTime)),
              airStartTime = classStart.getTime(),
              airEndTime = classEnd.getTime();
        
        if (nowTime > airEndTime) return;
        // LIVE class
        if ((nowTime > airStartTime) && (nowTime < airEndTime)) {
          $el.addClass(config.isLiveClass);
          $el.addClass('active');
        } else if (airStartTime > nowTime) { // upcoming class
          $el.addClass('active');
        }
      } else {
        $el.hide();
      }
    });
    $classList.show();
  };

  return {
    behaviors: ['truncate'],

    init() {
      listUpcomingClasses();
    }
  };
});