/**
 * @fileoverview Read More Behavior
 * @author Jonathan Kemp
 */

/*
 * For when you want to "Read more", but you don't need to see it all on load.
 */

import '../services/read-more';

SNI.Application.addBehavior('read-more', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const config = Object.assign({}, context.getConfig('read-more'));
  const SniAds = context.getGlobal('SniAds');
  const debug = context.getService('logger').create('behavior.read-more');
  const check = context.getService('check').new(debug);
  const readMoreSvc = context.getService('read-more');
  const setHeight = readMoreSvc.setHeight;
  const unsetHeight = readMoreSvc.unsetHeight;
  readMoreSvc.setReadMoreConfig(config);
  let readMoreSuspend = false;

  let $module = (config.stream && !config.disableStream) ? $(config.targetModule) : $(context.getElement());

  function adjustHeight() { // behaviors can use the current module as target
    setHeight($module);
    readMoreSuspend = false;
  }
  
  // turn off read more UI & fn when "Ingredients" tab shown
  function clearHeight() {
    unsetHeight($module);
    readMoreSuspend = true;
  }

  function windowResizeCallback() {
    if (!readMoreSuspend)
      adjustHeight();
  }
  
  function slotRenderCallback(slot) {
    if (!readMoreSuspend && slot.slot.getSlotElementId().indexOf('dfp_native_ingredient') > -1 && !slot.isEmpty ){
      adjustHeight();
    }
  }

  function readMore(element, isSkipReadMore) {
    debug.log('readMore: element: ', element);
    // Remove window resize callback to prevent further height adjustments.
    $(window).off('resize', windowResizeCallback);
    let $elem = $(element),
        containerClass = $elem.data('container-class');
    // Remove advertisements callback to prevent further height adjustments.
    if (check.exists('SniAds.Event')) {
      SniAds.Event.unsubscribe('slotRenderComplete', slotRenderCallback);
    }
    // show the hidden content
    if (config.stream && !config.disableStream) {
      $elem.closest(config.targetModule)
        .css({
          'overflow': 'visible',
          'height': 'auto'
        });
      $('.content-well')
        .css({
          'float': 'none'
        });
    } else {
      $module
        .css({
          'overflow': 'visible',
          'height': 'auto'
        });
    }

    if (config.collapsed) {
      if (config.stream && !config.disableStream) {
        $elem.closest(config.targetModule).removeClass(config.collapsed);
      } else {
        $module.removeClass(config.collapsed);
      }
    }

    // remove the container from the DOM
    // Make sure to remove the container after the class has been removed,
    // so that the styles can go back to normal. See CCN-1676.
    $(element).closest('[data-read-more-section]').remove();
    readMoreSvc.handleReadMore({containerClass});
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'read-more':
          debug.log('read-more: click');
          readMore(element);
          break;

        default:
          break;
      }
    },
    
    onmessage: {
      'read-more-off': () => clearHeight(),
      'read-more-on': () => $module.find('[data-read-more-section]').length > 0 ? adjustHeight() : null,
    },

    init() {
      const $revealTarget = $('[data-read-more-target]');
      let skipRM;
  
      skipRM = readMoreSvc.skipReadMore($module);

      if ($revealTarget.length === 0 || skipRM === true) {
        // Remove 'read-more' button and collapsed state.
        readMoreSvc.removeSection($module);
      } else {
        adjustHeight();
        $(window).on('resize', windowResizeCallback);
      }

      // CCN-1067
      // Force it to recalculate module height after native ingredient ad slot is loaded to account for
      // variable height slots that previously pushed the contents below the visible area.
      if (check.exists('SniAds.Event')) {
        SniAds.Event.subscribe('slotRenderComplete', slotRenderCallback);
      }
    }
  };
});
