// import '../vendor/draggabilly';

/**
 * @fileoverview Affix Behavior
 * @author Jonathan Kemp
 */

/*
 * Sticks a specified element to the top of the browser window on scroll.
 */
SNI.Application.addBehavior('affix', (context) => {
  'use strict';

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const SniAds = context.getGlobal('SniAds');
  const element = context.getElement();
  const defaults = {
    endAffix: '.directions',
    affixClass: 'affix',
    fadeOutClass: 'fadeOutUp',
    fadeInClass: 'fadeInDown',
    animatedClass: 'animated',
    leaderboardSelector: 'dfp_leaderboard',
    pushdownSelector: 'dfp_pushdown_brandscape'
  };
  const utility = context.getService('utility');
  const config = Object.assign({}, defaults, context.getConfig());
  const deviceType = context.getService('device-type');
  const debug = context.getService('logger').create('behavior.affix');
  const check = context.getService('check').new(debug);
  const isMobile = deviceType.isMobile;
  let isFixedVideo = false;
  let $avatar;
  let $fixedParent;
  let $dragContainer;
  let $dragElement;
  let $draggable;
  let stopFixedVideo = false;
  let stopFixedNavigation = false;

  let $stickElement,
      startStickHeight,
      $stopElement,
      stopHeight,
      stopDistance,
      pauseStickElement,
      down = false;

  // Ensures that the height of the element being moved is maintained in the document flow so the layout isn't affected
  function maintainHeight() {
    let browserShim = (window.navigator.userAgent.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edge/') > -1) ? 1 : 0;
    if (!$stickElement.data('heightMaintainer')) {
      if (isFixedVideo) {
        let $heightMaintainer = $('<div data-affix-behavior-height-maintainer />');
        $stickElement.wrap($heightMaintainer).data('heightMaintainer', $heightMaintainer);
      } else {
        let $heightMaintainer = $('<div data-affix-behavior-height-maintainer />').height($stickElement.outerHeight(true) + browserShim);
        $stickElement.wrap($heightMaintainer).data('heightMaintainer', $heightMaintainer);
      }
    }
  }

  // Undoes the effects of maintainHeight
  function cleanupHeight() {
    if ($stickElement.data('heightMaintainer')) {
      $stickElement.unwrap('[data-affix-behavior-height-maintainer]').removeData('heightMaintainer');
    }
    if (isFixedVideo) {
      $avatar.removeClass('m-RecipeSummary--FixedVideo');
      $fixedParent.removeClass('o-RecipeLead--FixedVideo o-RecipeLead--FixedVideoActive');
      $stickElement.removeClass('affix animated');
      // Resize fixes video player controls layout
      $(window).trigger('resize');
    }
  }

  /**
   * Stick and unstick the specified element. The element should fade out/in at a specified point.
   */
  function makeItStick() {
    const $document = $(document);
    const affixClass = config.affixClass;

    // Stick element after scrolling past it
    if ($document.scrollTop() >= startStickHeight) {
      // Logic for fixed video
      if (isFixedVideo) {
        // Only move fixed video if it's open and visible
        if (!stopFixedVideo && $fixedParent.hasClass('o-RecipeLead--VideoShowing')) {
          $avatar.addClass('m-RecipeSummary--FixedVideo');
          // Adjusts the styling of the drag handle and makes placeholder image appear
          $fixedParent.addClass('o-RecipeLead--FixedVideoActive');
          $stickElement.addClass(affixClass);
          // Resize fixes video player controls layout
          $(window).trigger('resize');
        }
      } else {
        // Logic for fixed navigation
        if (!stopFixedNavigation) {
          $stickElement.addClass(affixClass);
        } else {
          $stickElement.removeClass(affixClass);
        }
      }
    }

    // Unstick element when scrolled above its original location
    if ($document.scrollTop() <= startStickHeight) {
      if (isFixedVideo) {
        // Enables fixed video again if it was closed
        putBackFixedVideo(false);
      } else {
        $stickElement.removeClass(affixClass);
      }
    }

    // Adjust position on horizontal scroll
    if ($document.scrollLeft() > 0) {
      // Asset Navigation - 'Next/Prev' button nav bar
      if ($stickElement.hasClass('o-AssetNavigation', 'affix')) {
        let horizontalScrollPos = -($document.scrollLeft() + 1);
        $stickElement.css('margin-left', horizontalScrollPos);
      }
    } else {
      $stickElement.removeAttr('style');
    }

    // Fade out pre-next buttons
    if ((pauseStickElement - $document.scrollTop()) < 0) {
      $stickElement
        .removeClass(config.fadeInClass)
        .addClass(config.fadeOutClass);
      down = true;
    }

    // Fade back in pre-next buttons
    if ((pauseStickElement - $document.scrollTop()) > 0) {
      // this flag is set after element fades in bottom so fadeInDown only happens on the way up and not on DOM load
      if (down) {
        $stickElement
          .removeClass(config.fadeOutClass)
          .addClass(config.fadeInClass);
      }
    }
  }

  // Puts the fixed video back to it's original location when closed
  function putBackFixedVideo(stopFixed = true) {
    const affixClass = config.affixClass;

    $avatar.removeClass('m-RecipeSummary--FixedVideo');
    $fixedParent.removeClass('o-RecipeLead--FixedVideoActive');
    $stickElement.removeClass(affixClass);

    // Prevents the video from appearing in a weird position if it becomes fixed again
    $draggable.draggabilly('setPosition', 0, 0);
    // Prevents the video from being fixed on scroll events until user scrolls above video's original location
    stopFixedVideo = stopFixed ? true : false;
    // Resize fixes video player controls layout
    $(window).trigger('resize');
  }

  function init() {
    // Get the element which will have the affix behavior
    $stickElement = $(element);
    debug.log('Original $stickElement', $stickElement);
    let $oRecipeLead = $stickElement.find('.o-RecipeLead');
    debug.log('$oRecipeLead', $oRecipeLead);

    // First we check if there is a '.o-RecipeLead' element. If not, do nothing because we're not on the recipe beta template and don't need a fixed video. If there is, check its classes to see if there's a video. If not, we don't need to move forward with the affix behavior
    if ($oRecipeLead.length === 0 || ($oRecipeLead.length > 0 && ($oRecipeLead.hasClass('o-RecipeLead--HasPhotoNoVideo') || $oRecipeLead.hasClass('o-RecipeLead--NoPhotoAndNoVideo')))) {
      debug.log('Doesn\'t have video, do nothing');
      return;
    } else if ($stickElement.hasClass('recipe-lead')) {
      // Check if this element is the fixed video. If so, set values for drag functionality
      isFixedVideo = true;

      // Stick element is changed for sticky video from the original element
      $stickElement = $(element).find('.o-VideoPlaylist__VideoContainer');
      $dragContainer = $(element).find('.o-VideoPlaylist__VideoDragContainer');
      $fixedParent = $(element).find('.o-RecipeLead');
      $avatar = $(document).find('.m-RecipeSummary');

      // On mobile a different drag element (what the user clicks to move the video) is used
      if (isMobile) {
        $dragElement = $(element).find('.o-VideoPlaylist__VideoContentContainer');
      } else {
        $dragElement = $dragContainer;
      }

      debug.log('New $stickElement', $stickElement);
      debug.log('$fixedparent', $fixedParent);
      debug.log('$avatar', $avatar);

      // Fixed video requires some additional spacing
      requestAnimationFrame(() => startStickHeight = $stickElement.offset().top + 200);
    } else {
      requestAnimationFrame(() => startStickHeight = $stickElement.offset().top);
    }

    debug.log('startStickHeight: ', startStickHeight);

    // Check for a stop element, which will make the affixed item stop being fixed when that point is passed
    $stopElement = $(config.endAffix);
    if ($stopElement.length) {
      requestAnimationFrame( () => {
        stopHeight = $stopElement.height();
        stopDistance =  $stopElement.offset().top;
        pauseStickElement = (stopDistance + stopHeight);
      });
    }

    // Wait for the ad event so that the measurements don't get messed up by an ad appearing and changing the layout
    if (check.exists('SniAds.Event')) {
      if (!isMobile) {
        SniAds.Event.subscribe('slotRenderComplete', function(slot) {
          // If ad unit exists, add height to the start of the sticky spot
          if (slot.slot.getSlotElementId().indexOf(config.leaderboardSelector) > -1 && !slot.isEmpty) {
            startStickHeight = startStickHeight + $(`#${config.leaderboardSelector}`).height();
            debug.log('startStickHeight: leaderboard rendered: ', startStickHeight, $(`#${config.leaderboardSelector}`).height());
          }

          if (slot.slot.getSlotElementId().indexOf(config.pushdownSelector) > -1 && !slot.isEmpty) {
            if ($(`#${config.pushdownSelector}`).height() <= 66) {
              startStickHeight = startStickHeight + 66;
            } else {
              startStickHeight = startStickHeight + $(`#${config.pushdownSelector}`).height();
              debug.log('startStickHeight: pushdown rendered: ', startStickHeight, $(`#${config.pushdownSelector}`).height());
            }
          }
        });
      }
    }

    // When the affixed element is a fixed/sticky video, enable the drag functionality
    if (isFixedVideo) {
      $fixedParent.addClass('o-RecipeLead--FixedVideo');

      let dragOptions;

      if (isMobile) {
        dragOptions = {
          axis: 'x',
          containment: '.o-VideoPlaylist__VideoDragContainer',
          handle: '.o-VideoPlaylist__VideoContainerOverlay'
        };
      } else {
        dragOptions = {
          handle: '.o-VideoPlaylist__VideoDragHandle'
        };
      }

      // Set up dragging functionality
      $draggable = $dragElement.draggabilly(dragOptions);

      $draggable.on('staticClick', function(event, pointer) {
        if (isMobile) {
          $stickElement.addClass('o-VideoPlaylist__VideoContainer--Expanded');
          $draggable.draggabilly('disable');
        }
      });

      // Fucntionality is different on mobile devices
      if (isMobile) {
        // Hide sticky video when dragged away to the right
        $draggable.on('dragEnd', function(event, pointer) {
          var position = $draggable.position();
          var containerHide = $dragContainer.width() / 4;
          if (position.left > containerHide) {
            putBackFixedVideo();
            context.broadcast('affix.pause-video');
            $draggable.draggabilly('setPosition', 0, 0);
            setTimeout(() => {
              // Resize fixes video player controls layout
              $(window).trigger('resize');
            }, 1000);
          } else if (position.left > 1) {
            debug.log('Not dragged not far enough to close');
            $draggable.draggabilly('setPosition', 0, 0);
          }
        });
        // Shrink sticky video on scroll
        $(document).on('scroll', utility.throttle(function() {
          if ($stickElement.hasClass('o-VideoPlaylist__VideoContainer--Expanded')) {
            $stickElement.removeClass('o-VideoPlaylist__VideoContainer--Expanded');
            $draggable.draggabilly('enable');
            // Resize fixes video player controls layout
            $(window).trigger('resize');
          }
        }, 100));
      }
    }

    $stickElement.addClass(config.animatedClass);
    maintainHeight();

    // Prev/Next needs less throttling because the sticky effect is more noticable when it's longer
    let throttleTiming = isFixedVideo ? 100 : 50;

    // Bind stick functionality to scroll event, with throttle so it's not constantly firing
    $(document).on('scroll', utility.throttle(makeItStick, throttleTiming));

  }

  function destroy() {
    cleanupHeight();
    $stickElement = null;
    startStickHeight = null;
    $stopElement = null;
    stopHeight = null;
    stopDistance = null;
    pauseStickElement = null;
    $(document).off('scroll', makeItStick);
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    init: init,

    messages: ['invoke-inline-video-playlist.video-opened',
      'invoke-inline-video-playlist.video-closed',
      'invoke-inline-video-playlist.video-closed-while-fixed'],

    onmessage: {
      // When recipe lead video is opened, the fixed navigation is turned off for mobile pages
      'invoke-inline-video-playlist.video-opened': () => {
        debug.log('Video opened, hide fixed navigation on mobile');
        if (!isFixedVideo && isMobile) {
          stopFixedNavigation = true;
        }
      },
      // This should make it so the fixed navigation works again on mobile. Not currently used as you can only close the video when it's in fixed mode, and that doesn't close it, it just puts it back and pauses it. Left this in in case it's decided to add the close button back to the non fixed video to close it completely and show the image again
      'invoke-inline-video-playlist.video-closed': () => {
        debug.log('Video closed, show fixed navigation');
        if (!isFixedVideo && isMobile) {
          stopFixedNavigation = false;
        }
      },
      // This puts back the fixed video, but will NOT make it so the fixed navigation works again, since the video isn't really closed, just paused and put back
      'invoke-inline-video-playlist.video-closed-while-fixed': () => {
        if (isFixedVideo) {
          debug.log('Message received - Put back fixed video');
          putBackFixedVideo();
        }
      },
      // When video promo is clicked, prevent the fixed video from showing until the animation scrolling to the video's original location is complete
      'launch-lead-video': () => {
        stopFixedVideo = true;
        // 625 is based on the 600 animation time in lead-video-launcher.js
        setTimeout(() => {
          stopFixedVideo = false;
        }, 625);
      },
    },

    destroy: destroy,

    makeItStick
  };
});
