SNI.Application.addService('gallery-manager', (application) => {
  /**
   * Manages gallery tracking - used for multi gallery management
   */
  let active = {
    index: 0, // current gallery in the collection
    previousIdx: 0,
    suspendChecks: false,
    data: {},
    renderIndex: 1,
    galleriesViewed: 0,
    totalSlides: 1,
    atBegin: true,
    atEnd: false,
    sequence: [],
    hasStreamed: [],
    rendered: [],
    prefetch: [], //index values of prefetched galleries to prevent double render
    slideIndex: 0  // current slide in the gallery
  };
  let galleries = [];
  let template = application.getService('template');
  let debug = application.getService('logger').create('service.gallery-manager');
  let check = application.getService('check').new(debug);
  let metadata = application.getService('metadata');
  let mdManager = check.exists('mdManager') ? application.getGlobal('mdManager') : false;
  let analytics = application.getService('analytics');
  
  const SniAds = application.getGlobal('SniAds');
  let deviceType = application.getService('device-type');
  // Copied from inline-gallery
  // Should be trimmed to use the relevant settings only
  let defaults = {
    adTarget :'[data-target-adcontainer]',
    bigboxName: '',
    $ad: '',
    attributionAllowed: true,   // default to true to be compatible with vote overlay; the x-config should override this value
    galleryAdSetup: {
      container: '.pv-content-wrapper',
      dismiss_elts: '.rsArrowIcn',
      disable_elts: '',
      blockDelay: 10
    },
    views: {
      consecutiveSlide: 0,
      consecutiveGallery: 0   // in the future this could be an asset not a gallery
    },
    galleryContainer: '.slideshow-wrapper',
    pvSlideWrapCon        : '.pv-slideshow-wrapper',
    nextArrowSelector: '.rsArrowRight',
    arrowDisabledSelector: '.rsArrowDisabled',
    preventFutherClicks: false,
    sliderMessages        : {
      afterSlideName      : 'gallery.afterSlide',
      beforeAnimName      : 'gallery.beforeAnimation',
      beforeSizeName      : 'gallery.beforeSizeSet',
      afterContentSetName : 'gallery.afterContentSet'
    },
    rsConfig: {
      autoHeight: false,
      autoScaleSlider: true,
      autoScaleSliderWidth: 966,
      autoScaleSliderHeight: 725,
      imageAlignCenter: false,
      globalCaption: false,
      fadeinLoadedSlide: false,
      arrowsNav: true,
      arrowsNavAutoHide: false,
      arrowsNavHideOnTouch: false,
      controlNavigation: 'none',
      controlsInside: true,
      imageScalePadding: 0,
      transitionSpeed: 50,
      transitionType: 'move',
      loop: false,
      addActiveClass: true,
      deeplinking: {
        change: false,
        enabled: true,
        prefix: 'photo-'
      },
      fullscreen: {
        enabled: false,
        buttonFS: false,
        nativeFS: true
      },
      keyboardNavEnabled: true,
      numImagesToPreload: 4,
      navigateByClick: false,
      imageScaleMode: 'fit-if-smaller',
      sliderDrag: false,
      sliderTouch: true,
      easeOut: 'linear',
      preloaderHTML: template.preloaderHTML()
    }
  };
  let config = {};
  // load data from the module
  function loadData(data) {
    active.data = data;
  }
  // Get data for a specific gallery
  function getGalleryData(index) {
    return galleries[index];
  }
  function setHasStreamed(index) {
    active.hasStreamed.push(index);
  }
  function getHasStreamed(index) {
    return active.hasStreamed.indexOf(index) !== -1;
  }
  // get the active gallery data
  function getCurrentData() {
    return getGalleryData(active.index);
  }
  function isOnLastGallery() {
    //debug.log('Is on last', active.index, galleries.length);
    return active.index === galleries.length;
  }
  function didGalleryChange(index) {
    let check = {
      changed: false,
      direction: '',
      size: 1
    };
    if (active.index !== index) {
      check.changed = true;
      check.size = Math.abs(active.index - index);
      check.direction = active.index < index ? 'next' : 'prev';
    }
    return check;
  }
  // How much of the current gallery has been viewed
  function percentViewed(gIndex, index){
    let gallery = getGalleryData(gIndex);
    if (gallery && gallery.images && gallery.images.length>0) {
      return (index/gallery.images.length) * 100;
    }
  }
  function setPrefetch(index, renderOnly) {
    if (renderOnly) {
      active.rendered.push(index);
    } else {
      active.prefetch.push(index);
    }
  }
  // Used by the vote carousel to know if a gallery has been rendered/fetched
  function hasBeenPrefetched(index, src) {
    let check = active.prefetch.indexOf(index) !== -1 || active.rendered.indexOf(index) !== -1;
    return check;
  }

  function fromSequence(index){
    if (active.sequence.length) {
      active.sequence = active.sequence.filter(function(e){
        return !hasBeenPrefetched(e, 'from sequence');
      });
      return active.sequence[0];
    }
  }

  function getNextUpIndex(sourceIndex){
    let next = fromSequence();
    //debug.log('Currently on', sourceIndex, 'Found:', next,'One ahead is',sourceIndex +1,next!==sourceIndex+1?'They DONOT MATCH':'They match');
    //debug.log('Global', active.index, 'Length', galleries.length);
    if (next && next<galleries.length) {
      return next;
    } else {
      return false;
    }
  }
  function behavioralInteraction(){
    return `${active.galleriesViewed}-${active.slideIndex}-${active.totalSlides}`;
  }
  // Use when switching galleries back and forth
  function mobileBehavioralInteraction(data) {
    let {rIndex, index} = data;
    return `${rIndex}-${index}-${active.totalSlides}`;
  }
  // get current mdmd data
  function getCurrentMdm() {
    let item = getGalleryData();
    return item['mdmData'];
  }
  // move to the next gallery
  function advanceGallery(){
    if (active.index +1 === galleries.length) {
      active.index = 0;
    } else {
      active.index++;
    }
    return active.index;
  }
  // move back a gallery
  function reduceGallery() {
    if (active.index > 0) {
      active.index--;
    }
    return active.index;
  }
  // return the next gallery
  function nextGallery(){
    let index = advanceGallery();
    return galleries[index];
  }
  // return the previous gallery
  function prevGallery() {
    let index = reduceGallery();
    return galleries[index];
  }
  function setActiveSlide(index, isMobile) {
    active.slideIndex = index+1;
    if (isMobile) return;
    active.totalSlides++;
  }
  function suspendChecks(status) {
    if (typeof status !== 'undefined'){
      active.suspendChecks = status;
    }
    return active.suspendChecks;
  }
  // Due to scrolling between galleries, we now need to be able to set the active gallery on the fly - not just sequentially
  function getImage(gIndex, currentIndex) {
    let gallery = galleries[gIndex];
    let images = gallery.images;
    let slide = images[currentIndex];
    return {data: slide};
  }
  // move to the slide index provided and notify if we're near the gallery end
  function changeSlide(currentIndex) {
    let gallery = getCurrentData();
    let images = gallery['images'];
    let slide = images[currentIndex];
    let total  = images.length;
    let message = '';
    gallery.lastViewed = currentIndex;
    setActiveSlide(currentIndex);
    active.atBegin = false;
    active.atEnd = false;
    message = 'updated';

    // look ahead
    if (currentIndex + 1 === total) {
      active.atEnd = true;
      // Should load more slides
      if (active.index + 1 === galleries.length) {
        debug.log('Cannot advance past this gallery');
        message = 'gallery:end';
      } else {
        message = 'gallery:next';
        debug.log('On the last slide');
      }
    } else if (currentIndex === 0) {
      active.atBegin = true;
      // We are going backwards or we are starting
      message = 'gallery:prev';
    }

    return { message, data: slide };

  }
  function buildDesktopSlides(slides) {
    let markup = slides.map((slide, idx) => {
      let img = idx === 0 ? template.rsImage(slide) : template.rsLazyImage(slide);
      let imgSlide = template.rsSlide({imgPath: slide.image, imgMarkup: img});
      return imgSlide;
    });
    debug.log(markup);
    return markup;
  }

  function updateCredit($target, data) {
    let simple = !$target;
    let credit = template.photoCredit(data, simple);
    let copy = template.photoCopyright(data);
    let creditwrapped ='';
    if (credit) {
      if ($target) {
        $target.append(credit);
      } else {
        creditwrapped = template.photoCopyandCredit({credit, copy});
        return creditwrapped;
      }
    }
  }

  /**
   * Description markup is rendered using JSON data provided hidden in the slide
   * The data is passed to two separate template functions
   */
  function updateDescMarkup(options) {
    let {$galleryElement, descSel, creditsSel,  data, creditVoteLayout} = options;
    data.parentClass = 'o-PhotoGalleryPromo';
    let headline = data.title ? template.headline(data) : '';
    let descMarkup = data.description ? template.galleryDescription(data) : '';
    let $target = $galleryElement.find(descSel);
    let $credit, creditMarkup;
    $target.empty();
    if (!creditVoteLayout) {
      // Update the markup headline+ desc and credit separately
      $credit = $galleryElement.find(creditsSel);
      $credit.empty();
      updateCredit($credit, data);
      $target.append([headline, descMarkup]);
      debug.log('Using the old desc updater');
    } else {
      // Update the markup for headline + credit + description combined
      creditMarkup = updateCredit(false, data);
      $target.append([headline, creditMarkup, descMarkup]);
      debug.log('Using the new updater');
    }
  }

  /**
   * Build attributions works on an array with 1-3 attributions
   * Sample data:
   *  [{ "profile_avatar": "[URL]", "profile_url":"[URL]", "level":1}]
   * Attribution level determines the styling classes applied to an attribution name
   */

  function buildAttributions(arr) {
    let mediaItem = arr.filter(function(el) {
      return el.level === 1;
    }).pop();
    let mediaMarkup = mediaItem ? template.attributionMedia(mediaItem):'';
    let sorted = arr.sort(function(a, b){
      return a.level - b.level;
    });
    let labels = sorted.map(item => {
      item.name = template.attributionName(item);
      return item;
    }).map(template.attributionLabel).join('');
    let texts = template.attributionText(labels);
    let finalMarkup = template.galleryAttribution(mediaMarkup, texts);
    return finalMarkup;
  }

  /**
    * data has a sources array with all the attribution data
    * Sources can be up to 3, each rendering slightly different attribution markup
    * The overall template is buil by galleryAttribution
   */
  function updateAttributions($target, data) {
    let sources = data.sources,
        attr = '';
    // Attribution is allowed by default but is overriden in the config
    if (config.attributionAllowed && sources && sources.length>0) {
      attr = buildAttributions(sources);
      $target.append(attr);
    }
  }

  /* Moved all the logic used to build descriptions on overlays to this service so it can be shared
  */
  function buildSlideDesc(options) {
    let {$galleryElement,  attrSel, data} = options;
    let $attr = $galleryElement.find(attrSel);
    $attr.empty();
    updateDescMarkup(options);
    updateAttributions($attr, data);
  }

  // extracted existing process metadata - it is a placeholder for now
  function processMetadata(isInit, data) {
    let mdmData = data.mdmValues,
        title = data.displayTitle || data.galleryTitle || false,
        currentURL = data.slideShowURL || false,
        bh ='';
    if (mdmData) {
      if (title) {
        mdManager.setParameter('CurrentRoom', title);
      }
      if (data.useCustom) {
        active.totalSlides++;
        bh = mobileBehavioralInteraction(data);
        debug.log('Mobile bh', bh);
      } else {
        bh = behavioralInteraction();
      }
      mdmData.behavioralInteraction = bh;
      mdManager['Url'] = currentURL;
      metadata.updateFromJSON(mdmData);
      try {
        analytics.callDynamicPageview();
      } catch (e) {
        debug.error('Could not fire page view on vote gallery', e);
      }
    }
  }

  function resultsPageView() {
    metadata.updateFromJSON({Type: 'ballotpage'});
    try {
      analytics.callDynamicPageview();
    } catch (e) {
      debug.error('Ballot page analytics failed', e);
    }
  }

  function adLibLoaded() {
    return check.exists(['SniAds.Gallery', 'SniAds.Event']);
  }

  // Append the ad wrapper that will be used
  function createAdWrapper(selector) {
    let target = selector || config.adTarget;
    let $ad = $(target);
    config.$ad = $ad;
    let adSlot = template.overlayBigbox();
    let adText = template.adText();
    let innerSlot = `${adSlot}${adText}`;
    let adWrapper = template.adWrapper(innerSlot);
    $ad.append(adWrapper);
  }

  function refreshAds(customBigBox) {
    let isMobile = deviceType.isMobile;
    let bigboxName = customBigBox||config.bigboxName;
    if (!isMobile && adLibLoaded()) {
      let newSlot;
      if (bigboxName === '') {
        newSlot = SniAds.appendSlot('overlay_bigbox', 'dfp_bigbox');
        config.bigboxName = newSlot;
        SniAds.Gallery.setSyncSlot(newSlot);
      } else {
        SniAds.Gallery.next();
      }
    }
  }

  function setUpAds() {
    //  checkAdLibrary(context, check);
    if (adLibLoaded()) {
      // adLib = context.getService('ads');
      let adConfig = { galleryCfg: config.galleryAdSetup };
      SniAds.Gallery.init(adConfig);
      SniAds.Gallery.reset();
      createAdWrapper();
      SniAds.ready(function() {
        refreshAds();
      });
    }
  }

  // Listen to the gallery end - copied from inline gallery
  function areWeThereYet(currentSlider) {
    currentSlider.ev.one('rsTryingToAdvancePastLastSlide', function(event) {
      application.broadcast('gallery.lastSlideReached', {
        currentEvent: event
      });
    });
  }

  // Append the new markup for slide numbering
  function createSlideNumbering(target) {
    let count = template.slideCount();
    target.append(count);
  }

  // handle the logic of preloading images - called by the component
  function preloadImages($target, imagesSet, delay = 0) {
    let images = '';
    if ($target && imagesSet) {
      if (imagesSet.length>0) {
        images = imagesSet.map(src => { return `<img src="${src}" />`; });
        if (images) {
          setTimeout(function(){
            $target.append(images.join(''));
          }, delay);
        }
      }
    }
  }
  // Set the active gallery (using the gallery index)
  function setCurrentGallery(index) {
    active.previousIdx = active.index;
    active.index = index;
    active.galleriesViewed++;
    active.slideIndex = 1;
  }
  // Update the config
  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }
  function getDefaults() {
    return defaults;
  }
  function setConfig(obj) {
    config =  obj;
    return config;
  }
  function getConfig() {
    return config;
  }
  // Returns the active gallery set data
  function getActive(){
    return active;
  }
  function getRSDefaults() {
    return config.rsConfig;
  }
  function setSequence(total){
    active.sequence = Array(total).fill(1).map(function(v,i){
      return i;
    });
  }
  function getRenderIndex() {
    return active.renderIndex++;
  }
  // Get data from the top carousel and save it
  function setGalleriesData(data) {
    if (data) {
      galleries = data && data.slice();
      if (deviceType.isMobile) {
        setSequence(data.length);
      }

    }
  }

  function getAllGalleries(){
    return galleries;
  }
  return {
    loadData,
    setCurrentGallery,
    setActiveSlide,
    suspendChecks,
    getCurrentData,
    getCurrentMdm,
    getDefaults,
    getActive,
    getRSDefaults,
    getGalleryData,
    getHasStreamed,
    setHasStreamed,
    percentViewed,
    setPrefetch,
    getNextUpIndex,
    hasBeenPrefetched,
    isOnLastGallery,
    nextGallery,
    prevGallery,
    didGalleryChange,
    setGalleriesData,
    getAllGalleries,
    changeSlide,
    getImage,
    processMetadata,
    resultsPageView,
    createAdWrapper,
    refreshAds,
    areWeThereYet,
    createSlideNumbering,
    buildSlideDesc,
    buildDesktopSlides,
    updateAttributions,
    setConfig,
    setUpAds,
    getConfig,
    getRenderIndex,
    mobileBehavioralInteraction,
    behavioralInteraction,
    updateObject,
    preloadImages
  };
});